import React from 'react';
import { useTranslation } from 'react-i18next';
import { Space, Typography } from 'antd';
import moment from 'moment';
import { Modal } from 'components/Modal';
import { ReactComponent as InactivityImage } from 'assets/images/SleepAtTable.svg';
import styles from './InactivityModal.module.scss';

type InactivityModalProps = {
  timeLeft: number;
};

const InactivityModal = ({ timeLeft }: InactivityModalProps) => {
  const { t } = useTranslation();

  const timeLeftAsSeconds = moment.duration(timeLeft, 'milliseconds').asSeconds().toFixed(0);

  return (
    <Modal
      width={550}
      isOpen={true}
      onClose={()=>{}}
      title={t('activity.title')}
      className={styles.modal}
    >
      <Space.Compact direction="vertical" className={styles.container}>
        <Typography.Text className={styles.description}>
          {t('activity.desc', { sec: `${timeLeftAsSeconds}` })}
        </Typography.Text>

        <div className={styles.image}>
          <InactivityImage height="250px" />
        </div>

        <span className={styles.help}>{t('activity.clickAnywhere')}</span>
      </Space.Compact>
    </Modal>
  );
};

export default InactivityModal;
