import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios, { SERVICE } from 'libs/axios';
import { useMutation } from 'libs/reactQuery';
import { notification } from 'antd';
import { SMTPSettingsBodyType, SMTPSettingsOptionsType } from 'components';

type TestSMTPResponseType = { message: string, result: boolean };

export const useTestSMTP = (options: SMTPSettingsOptionsType<TestSMTPResponseType>) => {
  const { t } = useTranslation();
  const [isError, setIsError] = useState(false);

  const testSMTPMutate = async (body: Omit<SMTPSettingsBodyType, 'rootUnitId'>) => {
    setIsError(false);
    const { data } = await axios.post(`${SERVICE.ADMIN}/smtp/test-options`, body, {
      timeout: 10000,
    });
    return data;
  };

  const {
    mutate: testSMTP,
    mutateAsync: testSMTPAsync,
    isPending: isTestSMTPLoading,
  } = useMutation({
    mutationFn: testSMTPMutate,
    onError: () => {
      setIsError(true);
    },
    onSuccess: (response) => {
      if (options.onSuccess) {
        options.onSuccess(response);
      }

      notification.close('smtp-validation');
      if (response.result) {
        notification.success({
          key: 'smtp-validation',
          message: t('SMTP.testPassed'),
        });
      } else {
        setIsError(true);
        notification.error({
          key: 'smtp-validation',
          message: t('SMTP.testFailed'),
          description: response.message,
        });
      }
    },
  });

  return {
    testSMTP,
    testSMTPAsync,
    isTestSMTPLoading,
    isTestSMTPError: isError,
  };
};
