import { createContext, useContext } from 'react';
import { noop } from 'lodash';
import { Filter } from 'types/Filter';
import { UnknownType } from 'types/Unknown';
import { Sort } from 'hooks';
import { FilterRelation } from 'components/Filters';
import { PerPageNumber } from 'components/Pagination';

type FiltersContextType = {
  setFilterFieldsArgs: (args: Record<string, unknown>) => void;
  filterFields: Map<string, UnknownType>,

  sort: Sort<UnknownType> | null;
  setSort: (value: Sort<UnknownType> | null) => void;
  filter: Filter<unknown>,
  setFilter: React.Dispatch<React.SetStateAction<Filter<unknown>>>,
  filters: Record<string, unknown>,
  filterRelation: FilterRelation,
  page: number;
  perPage: PerPageNumber;
  setPage: (value: number) => void;
  setPerPage: (value: PerPageNumber) => void;
};

const initialState: UnknownType = {
  setFilterFieldsArgs: noop,
};

export const FiltersContext = createContext<FiltersContextType>(initialState);

const useFiltersContext = () => {
  const contextValue = useContext(FiltersContext);
  if (contextValue === undefined) {
    throw new Error('FiltersContext must be used within a FiltersProvider');
  }

  return contextValue;
};

export default useFiltersContext;
