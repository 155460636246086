import { IStatus } from '../../interfaces/IStatus';
import { LogActionEnum } from 'types/LogAction';
import { ColorEnum } from '../../types';
import {
  ClockCircleOutlined,
  CrownOutlined,
  DatabaseOutlined,
  FileAddOutlined,
  KeyOutlined,
  LoginOutlined,
  LogoutOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
  SwapOutlined,
  SyncOutlined,
  TeamOutlined,
  ToolOutlined,
  UserOutlined,
  VerticalAlignBottomOutlined,
  WalletOutlined,
} from '@ant-design/icons';
import { ReactComponent as PaymentsIcon } from 'assets/icons/Payments.svg';

export const getLogActions = (): IStatus[] => [
  {
    status: LogActionEnum.CREATE_PO_LVL_2,
    title: 'CREATE_PAYMENT_ORDER_LVL_2',
    color: ColorEnum.IDLE,
    icon: PaymentsIcon,
  },
  {
    status: LogActionEnum.APPROVE_PO_LVL_2,
    title: 'APPROVE_PAYMENT_ORDER_LVL_2',
    color: ColorEnum.SUCCESS,
    icon: PaymentsIcon,
  },
  {
    status: LogActionEnum.DECLINE_PO_LVL_2,
    title: 'DECLINE_PAYMENT_ORDER_LVL_2',
    color: ColorEnum.ERROR,
    icon: PaymentsIcon,
  },
  {
    status: LogActionEnum.DEPOSIT_CHECKOUT,
    title: 'DEPOSIT_CHECKOUT',
    color: ColorEnum.UPDATE,
    icon: VerticalAlignBottomOutlined,
  },
  {
    status: LogActionEnum.RESEND_TRANSACTION,
    title: 'RESEND_TRANSACTION',
    color: ColorEnum.UPDATE,
    icon: SyncOutlined,
  },
  {
    status: LogActionEnum.UPDATE_USER,
    title: 'UPDATE_USER',
    color: ColorEnum.UPDATE,
    icon: UserOutlined,
  },
  {
    status: LogActionEnum.CREATE_USER,
    title: 'CREATE_USER',
    color: ColorEnum.IDLE,
    icon: UserOutlined,
  },
  {
    status: LogActionEnum.CREATE_GROUP,
    title: 'CREATE_GROUP',
    color: ColorEnum.IDLE,
    icon: CrownOutlined,
  },
  {
    status: LogActionEnum.UPDATE_GROUP,
    title: 'UPDATE_GROUP',
    color: ColorEnum.UPDATE,
    icon: CrownOutlined,
  },
  {
    status: LogActionEnum.ADD_WHITELIST_WALLET,
    title: 'ADD_WHITELIST_WALLET',
    color: ColorEnum.IDLE,
    icon: WalletOutlined,
  },
  {
    status: LogActionEnum.ADD_SUB_WALLET_TO_UNIT,
    title: 'ADD_SUB_WALLET_TO_UNIT',
    color: ColorEnum.IDLE,
    icon: WalletOutlined,
  },
  {
    status: LogActionEnum.ADD_WALLET_TO_UNIT,
    title: 'ADD_WALLET_TO_UNIT',
    color: ColorEnum.IDLE,
    icon: WalletOutlined,
  },
  {
    status: LogActionEnum.CREATE_INVOICE,
    title: 'CREATE_INVOICE',
    color: ColorEnum.IDLE,
    icon: FileAddOutlined,
  },
  {
    status: LogActionEnum.REQUEST_REPLENISHMENT,
    title: 'REQUEST_REPLENISHMENT',
    color: ColorEnum.PENDING,
    icon: ClockCircleOutlined,
  },
  {
    status: LogActionEnum.CREATE_PAYMENT_ORDER,
    title: 'CREATE_PAYMENT_ORDER',
    color: ColorEnum.IDLE,
    icon: PaymentsIcon,
  },
  {
    status: LogActionEnum.CREATE_TRANSACTION_GROUP,
    title: 'CREATE_TRANSACTION_GROUP',
    color: ColorEnum.IDLE,
    icon: PlusCircleOutlined,
  },
  {
    status: LogActionEnum.UPDATE_TRANSACTION_GROUP,
    title: 'UPDATE_TRANSACTION_GROUP',
    color: ColorEnum.UPDATE,
    icon: SyncOutlined,
  },
  {
    status: LogActionEnum.DELETE_TRANSACTION_GROUP,
    title: 'DELETE_TRANSACTION_GROUP',
    color: ColorEnum.ERROR,
    icon: MinusCircleOutlined,
  },
  {
    status: LogActionEnum.CREATE_TRANSACTION_CATEGORY,
    title: 'CREATE_TRANSACTION_CATEGORY',
    color: ColorEnum.IDLE,
    icon: PlusCircleOutlined,
  },
  {
    status: LogActionEnum.UPDATE_TRANSACTION_CATEGORY,
    title: 'UPDATE_TRANSACTION_CATEGORY',
    color: ColorEnum.UPDATE,
    icon: SyncOutlined,
  },
  {
    status: LogActionEnum.DELETE_TRANSACTION_CATEGORY,
    title: 'DELETE_TRANSACTION_CATEGORY',
    color: ColorEnum.ERROR,
    icon: MinusCircleOutlined,
  },
  {
    status: LogActionEnum.CREATE_CLIENT,
    title: 'CREATE_CLIENT',
    color: ColorEnum.IDLE,
    icon: UserOutlined,
  },
  {
    status: LogActionEnum.ADD_CLIENT_WALLET,
    title: 'ADD_CLIENT_WALLET',
    color: ColorEnum.IDLE,
    icon: WalletOutlined,
  },
  {
    status: LogActionEnum.CREATE_UNIT,
    title: 'CREATE_UNIT',
    color: ColorEnum.IDLE,
    icon: CrownOutlined,
  },
  {
    status: LogActionEnum.UPDATE_UNIT,
    title: 'UPDATE_UNIT',
    color: ColorEnum.UPDATE,
    icon: CrownOutlined,
  },
  {
    status: LogActionEnum.ACTIVE_UNIT,
    title: 'ACTIVE_UNIT',
    color: ColorEnum.SUCCESS,
    icon: CrownOutlined,
  },
  {
    status: LogActionEnum.INACTIVE_UNIT,
    title: 'INACTIVE_UNIT',
    color: ColorEnum.ERROR,
    icon: CrownOutlined,
  },
  {
    status: LogActionEnum.UPDATE_UNIT_CLIENTS,
    title: 'UPDATE_UNIT_CLIENTS',
    color: ColorEnum.UPDATE,
    icon: TeamOutlined,
  },
  {
    status: LogActionEnum.UPDATE_UNIT_MANAGERS,
    title: 'UPDATE_UNIT_MANAGERS',
    color: ColorEnum.UPDATE,
    icon: TeamOutlined,
  },
  {
    status: LogActionEnum.CREATE_ROOT_UNIT,
    title: 'CREATE_ROOT_UNIT',
    color: ColorEnum.IDLE,
    icon: CrownOutlined,
  },
  {
    status: LogActionEnum.UPDATE_ROOT_UNIT,
    title: 'UPDATE_ROOT_UNIT',
    color: ColorEnum.UPDATE,
    icon: CrownOutlined,
  },
  {
    status: LogActionEnum.MASS_PAYOUT_INITIALIZATION,
    title: 'MASS_PAYOUT_INITIALIZATION',
    color: ColorEnum.PENDING,
    icon: SyncOutlined,
  },
  {
    status: LogActionEnum.CREATE_EXCHANGE,
    title: 'CREATE_EXCHANGE',
    color: ColorEnum.IDLE,
    icon: SwapOutlined,
  },
  {
    status: LogActionEnum.UPDATE_PASSWORD,
    title: 'UPDATE_PASSWORD',
    color: ColorEnum.UPDATE,
    icon: KeyOutlined,
  },
  {
    status: LogActionEnum.INVALID_PASSWORD,
    title: 'INVALID_PASSWORD',
    color: ColorEnum.ERROR,
    icon: KeyOutlined,
  },
  {
    status: LogActionEnum.SET_SMTP_OPTIONS,
    title: 'SET_SMTP_OPTIONS',
    color: ColorEnum.UPDATE,
    icon: ToolOutlined,
  },
  {
    status: LogActionEnum.UPDATE_NOTIFICATION_SETTING,
    title: 'UPDATE_NOTIFICATION_SETTING',
    color: ColorEnum.UPDATE,
    icon: ToolOutlined,
  },
  {
    status: LogActionEnum.UPDATE_MANY_USERS,
    title: 'UPDATE_MANY_USERS',
    color: ColorEnum.UPDATE,
    icon: UserOutlined,
  },
  {
    status: LogActionEnum.ADD_IP,
    title: 'ADD_IP',
    color: ColorEnum.IDLE,
    icon: DatabaseOutlined,
  },
  {
    status: LogActionEnum.UPDATE_IP,
    title: 'UPDATE_IP',
    color: ColorEnum.UPDATE,
    icon: DatabaseOutlined,
  },
  {
    status: LogActionEnum.DELETE_IP,
    title: 'DELETE_IP',
    color: ColorEnum.ERROR,
    icon: DatabaseOutlined,
  },
  {
    status: LogActionEnum.USER_LOGIN_SSO,
    title: 'USER_LOGIN_SSO',
    color: ColorEnum.IDLE,
    icon: LoginOutlined,
  },
  {
    status: LogActionEnum.USER_LOGIN_PASSWORD_LESS,
    title: 'USER_LOGIN_PASSWORD_LESS',
    color: ColorEnum.IDLE,
    icon: LoginOutlined,
  },
  {
    status: LogActionEnum.USER_SEND_PASSWORD_LESS,
    title: 'USER_SEND_PASSWORD_LESS',
    color: ColorEnum.PENDING,
    icon: LoginOutlined,
  },
  {
    status: LogActionEnum.USER_LOGIN,
    title: 'USER_LOGIN',
    color: ColorEnum.IDLE,
    icon: LoginOutlined,
  },
  {
    status: LogActionEnum.USER_LOGOUT,
    title: 'USER_LOGOUT',
    color: ColorEnum.ERROR,
    icon: LogoutOutlined,
  },
  {
    status: LogActionEnum.USER_DELETE_SESSION,
    title: 'USER_DELETE_SESSION',
    color: ColorEnum.ERROR,
    icon: LogoutOutlined,
  },
  {
    status: LogActionEnum.ADD_BOT_IP,
    title: 'ADD_BOT_IP',
    color: ColorEnum.IDLE,
    icon: DatabaseOutlined,
  },
];
