import { TFunction } from 'i18next';
import { EMAIL_SCHEMA, EMAIL_USERNAME_REGEX } from '../constants/validation';

export const emailValidator = (t: TFunction, value: string): Promise<Error | void> => {
  if (!value) {
    return Promise.reject(
      new Error(t('fields.email.validation.pleaseInputEmail')),
    );
  }
  if (value.includes('@')) {
    const username = value.split('@')[0];
    const domain = `@${value.split('@')[1]}`;
    if (username.length > 64) {
      return Promise.reject(
        new Error(t('fields.email.validation.usernameLength')),
      );
    }
    if (domain.length > 254) {
      return Promise.reject(
        new Error(t('fields.email.validation.domainLength')),
      );
    }
    if (username.includes('..')) {
      return Promise.reject(
        new Error(t('fields.email.validation.consecutiveDotsRules')),
      );
    }
    if (!EMAIL_USERNAME_REGEX.test(username)) {
      return Promise.reject(
        new Error(t('fields.email.validation.usernameRules')),
      );
    }
  }
  if (!EMAIL_SCHEMA.test(value)) {
    return Promise.reject(
      new Error(t('fields.email.validation.rules')),
    );
  }
  return Promise.resolve();
};
