import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { Select } from 'antd';
import clsx from 'clsx';
import { IBusiness } from 'interfaces/IBusiness';
import { BusinessOptionType, BusinessSelectPropsType } from './types';
import { useBusinesses } from 'pages/Administration/Businesses/hooks/useBusinesses';
import { useDebounce } from 'hooks/useDebounce';
import styles from './BusinessSelect.module.scss';

export const BusinessSelect = ({
  onChange,
  className,
  inputPlaceholder,
  disabled = false,
  value,
}: BusinessSelectPropsType): ReactElement => {
  const [searchText, setSearchText] = useState('');
  const debouncedSearchText = useDebounce(searchText, 1000);

  const { data, isLoading, refetch } = useBusinesses({
    filter: {
      and: [debouncedSearchText ? { name: { iLike: `%${debouncedSearchText.trim()}%` } } : {}],
    },
  });

  useEffect(() => {
    if (debouncedSearchText) {
      refetch();
    }
  }, [refetch, debouncedSearchText]);

  const businessOptions: BusinessOptionType[] = useMemo(
    () =>
      (data?.nodes || []).map((business: IBusiness) => ({
        key: +business.id,
        label: business.name,
        value: +business.id,
      })),
    [data?.nodes],
  );

  return (
    <div className={styles.selectWrapper}>
      <Select
        disabled={disabled}
        showSearch
        optionFilterProp="label"
        onSelect={onChange}
        loading={isLoading}
        searchValue={searchText}
        onSearch={setSearchText}
        className={clsx(styles.select, className)}
        placeholder={inputPlaceholder}
        options={businessOptions}
        defaultValue={value}
      />
    </div>
  );
};
