import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  RiAlertLine,
  RiCloseLine,
  RiErrorWarningLine,
  RiInformationLine,
} from '@remixicon/react';
import { Modal } from 'antd';
import useGetNotifications, { Notification, NotificationType } from 'api/notification/useGetNotifications';
import useReadNotification from 'api/notification/useReadNotification';
import clsx from 'clsx';
import { NotificationSocket } from 'interfaces/INotification';
import { useSocket } from 'contexts';
import { Button } from 'components/Button';
import styles from './AlertModal.module.scss';

const notificatitonBadgeMap = {
  [NotificationType.INFO]: <RiInformationLine size={20} />,
  [NotificationType.ERROR]: <RiErrorWarningLine size={20} />,
  [NotificationType.WARNING]: <RiAlertLine size={20} />,
};

const AlertModal = () => {
  const { socket } = useSocket();
  const { t } = useTranslation();

  const [notifications, setNotifications] = useState<(Notification | NotificationSocket)[]>([]);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const currentIndexRef = useRef(currentIndex);

  const { mutate } = useReadNotification();
  const { data } = useGetNotifications({
    sorting: [
      { field: 'type', direction: 'DESC' },
      { field: 'createdAt', direction: 'DESC' },
    ],
    filter: {
      and: [
        { isAlert: { is: true } },
        { readAt: { is: 'null' } },
      ],
    },
  });

  useEffect(() => {
    currentIndexRef.current = currentIndex;
  }, [currentIndex]);

  useEffect(() => {
    if (data?.nodes) {
      setNotifications(data.nodes);
    }
  }, [data]);

  const handleNewNotification = useCallback((newNotification: NotificationSocket) => {
    if (newNotification.isAlert) {
      setNotifications((prevNotifications) => {
        const insertIndex = Math.min(currentIndexRef.current + 1, prevNotifications.length);
        const newNotifications = [...prevNotifications];
        newNotifications.splice(insertIndex, 0, newNotification);
        return newNotifications;
      });
    }
  }, []);

  useEffect(() => {
    if (socket) {
      socket.on('notification', handleNewNotification);
    }
  }, [handleNewNotification, socket]);

  const goToPreviousAlert = useCallback(() => {
    setCurrentIndex((prev) => (prev > 0 ? prev - 1 : prev));
  }, []);

  const goToNextAlert = useCallback(() => {
    if (notifications[currentIndex]) {
      mutate(notifications[currentIndex].id);
      setCurrentIndex((prev) => (prev + 1 < notifications.length ? prev + 1 : 0));
      if (currentIndex + 1 >= notifications.length) {
        setNotifications([]);
      }
    }
  }, [currentIndex, notifications, mutate]);

  const handleForceCloseModal = useCallback(() => {
    if (notifications[currentIndex]) {
      mutate(notifications[currentIndex].id);
    }
    setNotifications([]);
  }, [currentIndex, notifications, mutate]);

  const currentNotification = notifications[currentIndex];

  return (
    <Modal
      centered
      width={440}
      maskClosable={false}
      afterClose={() => setCurrentIndex(0)}
      closeIcon={<RiCloseLine size={16} />}
      onCancel={handleForceCloseModal}
      open={Boolean(currentNotification)}
      footer={(
        <div className={clsx(styles.footer, { [styles.right]: notifications.length === 1 })}>
          {notifications.length > 1 && (
            <div className={styles.displayOf}>
              {currentIndex + 1} {t('of')} {notifications.length}
            </div>
          )}
          <div className={styles.controls}>
            {notifications.length > 1 && (
              <Button type="link" onClick={goToPreviousAlert} disabled={currentIndex - 1 < 0}>
                {t('previous')}
              </Button>
            )}
            <Button type="default" onClick={goToNextAlert}>
              {t(currentIndex === notifications.length - 1 ? 'gotIt' : 'next')}
            </Button>
          </div>
        </div>
      )}
    >
      {currentNotification && (
        <div className={styles.content}>
          <div className={clsx(styles.circle, {
            [styles.info]: currentNotification.type === NotificationType.INFO,
            [styles.error]: currentNotification.type === NotificationType.ERROR,
            [styles.warning]: currentNotification.type === NotificationType.WARNING,
          })}>
            {notificatitonBadgeMap[currentNotification.type]}
          </div>
          <div className={styles.text}>
            <span className={styles.title}>{currentNotification.title}</span>
            <span className={styles.desc}>{currentNotification.description}</span>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default AlertModal;
