import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, Select, SelectProps, Skeleton, Switch } from 'antd';
import styles from './NotificationSettings.module.scss';

type NotificationsProps = {
  settings: Record<string, boolean>;
  canEditUser: boolean;
  isSmtpConfigured: boolean;
  isLoading?: boolean;
  onChange: (option: Record<string, boolean>) => void;
};

const hiddenCategorizeSettings = ['securityConfirmEmail'];
const categorizeSettings = (settings: Record<string, boolean>) => {
  return Object.keys(settings).reduce((acc, key) => {
    const category = key.split(/(?=[A-Z])/)[0].toLowerCase();

    if (!acc[category]) {
      acc[category] = [];
    }

    acc[category].push(key);
    return acc;
  }, {} as Record<string, string[]>);
};

const notificationTypes = ['email', 'push', 'telegram', 'sms'];
const getSelectedTypes = (settings: Record<string, boolean>) => {
  return Object.keys(settings).filter((key) => notificationTypes.includes(key) && settings[key]);
};

const alwaysDisabledNotificationTypes = ['sms', 'telegram'];
const notificationTypeOptions: SelectProps['options'] = [
  { label: 'Email', value: 'email' },
  { label: 'Push', value: 'push' },
  { label: 'Telegram (Bot)', value: 'telegram' },
  { label: 'Sms', value: 'sms' },
];

const NotificationSettings = ({
  settings,
  isLoading = false,
  isSmtpConfigured,
  canEditUser,
  onChange,
}: NotificationsProps) => {
  const { t } = useTranslation();

  const categorizedSettings = useMemo(() => categorizeSettings(settings), [settings]);
  const selectedTypes = useMemo(() => getSelectedTypes(settings), [settings]);

  const disabledNotificationTypes = useMemo(() => {
    if (!isSmtpConfigured) {
      return [...alwaysDisabledNotificationTypes, 'email'];
    }
    return alwaysDisabledNotificationTypes;
  }, [isSmtpConfigured]);

  const handleToggleNotificationType = useCallback((newTypes: string[]) => {
    const updated: Record<string, boolean> = {
      email: false,
      push: false,
      telegram: false,
      sms: false,
    };
    newTypes.forEach((type) => {
      updated[type] = true;
    });
    onChange(updated);
  }, [onChange]);

  const handleToggle = useCallback((key: string) => (status: boolean) => {
    onChange({ [key]: status });
  }, [onChange]);

  const renderSwitch = useCallback((key: string) => {
    if (hiddenCategorizeSettings.includes(key)) return null;

    return (
      <div className={styles.switchWrapper} key={key}>
        <span>{t(`notifications.types.${key}`)}</span>
        <Switch
          checked={settings[key]}
          onChange={handleToggle(key)}
          disabled={!canEditUser}
        />
      </div>
    );
  }, [canEditUser, handleToggle, settings, t]);

  return (
    <>
      <div className={styles.inline}>
        <p className={styles.title}>{t('notifications.notificationTypes')}</p>
        <Select
          allowClear
          mode="multiple"
          value={selectedTypes}
          onChange={handleToggleNotificationType}
          // defaultValue={['email']}
          placeholder={t('notifications.notificationTypes')}
          disabled={!canEditUser}
          style={{ width: '60%' }}
          className={styles.select}
          popupClassName={styles.selectPopup}
        >
          {notificationTypeOptions && notificationTypeOptions.map((item) => (
            <Select.Option
              key={item.value}
              value={item.value}
              disabled={disabledNotificationTypes.includes(item.value as string)}
            >
              <div className={styles.selectOption}>
                <Checkbox
                  checked={selectedTypes.includes(item.value as string)}
                  disabled={disabledNotificationTypes.includes(item.value as string)}
                />
                {item.label}
              </div>
            </Select.Option>
          ))}
        </Select>
      </div>
      {isLoading && <Skeleton />}
      {!isLoading && (
        <>
          <div className={styles.card}>
            <span className={styles.title}>{t('notifications.categoriesTitle.privacySecurity')}</span>
            {categorizedSettings?.security?.map(renderSwitch)}
          </div>
          <div className={styles.card}>
            <span className={styles.title}>{t('notifications.categoriesTitle.finance')}</span>
            {categorizedSettings?.finance?.map(renderSwitch)}
          </div>
          {/* <div className={styles.card}>
            <span className={styles.title}>{t('notifications.categoriesTitle.other')}</span>
            {categorizedSettings?.other?.map(renderSwitch)}
          </div> */}
        </>
      )}
    </>
  );
};

export default NotificationSettings;
