import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'antd';
import clsx from 'clsx';
import { ITransactionGroup } from '../../api/types';
import { AuthorisationContext } from 'contexts';
import { getGroupColumns } from '../../utils';
import { CategoriesTable, CategoryColHandlersType } from '../CategoriesTable';
import styles from './GroupsTable.module.scss';

export type GroupColHandlersType = {
  onCreateCategory: (group: ITransactionGroup) => void;
  onEditGroup: (group: ITransactionGroup) => void;
};

export interface IGroupsTableProps {
  loading: boolean;
  groups: ITransactionGroup[];
  groupColHandlers: GroupColHandlersType,
  categoryColHandlers: CategoryColHandlersType,
}

export const GroupsTable = ({
  groups, loading,
  categoryColHandlers,
  groupColHandlers: { onCreateCategory, onEditGroup },
}: IGroupsTableProps) => {
  const { checkPermissions } = useContext(AuthorisationContext);
  const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    const emptyGroups = groups.filter(group => !group.categories.length);
    emptyGroups.forEach(emptyGroup => {
      setExpandedRowKeys(prev => prev.filter(rowKey => rowKey !== emptyGroup.id));
    });
  }, [groups]);

  const groupColumns = useMemo(() => getGroupColumns({
    onEditGroup, t,
    onCreateCategory,
    checkPermissions,
    expandedRowKeys,
    setExpandedRowKeys,
  }), [
    checkPermissions,
    expandedRowKeys,
    onCreateCategory,
    onEditGroup, t,
  ]);

  return (
    <div className={styles.wrapper}>
      <Table
        rowKey="id"
        pagination={false}
        loading={loading}
        dataSource={groups}
        columns={groupColumns}
        expandable={{
          expandedRowKeys,
          showExpandColumn: false,
          expandedRowClassName: () => styles.expandedRow,
          expandedRowRender: (group) => (
            <CategoriesTable
              loading={loading}
              rootUnitId={group.rootUnitId}
              categories={group.categories}
              categoryColHandlers={categoryColHandlers}
            />
          ),
        }}
        rowClassName={(category) => clsx(styles.row, {
          [styles.expand]: expandedRowKeys.includes(category.id),
        })}
      />
    </div>
  );
};
