import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useGetWhitelist from 'pages/Administration/Units/hooks/useGetWhitelist';
import { useTablePagination } from 'hooks';
import useTableColumns from 'hooks/useTableColumns';
import { LocalStorageKey } from 'utils/localStorage';
import getColumns from './utils/getColumns';
import { Pagination } from 'components';
import DraggableTable from 'components/DraggableTable';
import styles from './WhitelistTab.module.scss';

export type WhitelistTabProps = {
  isActive: boolean;
  uuid: string;
};

const WhitelistTab = ({ uuid, isActive }: WhitelistTabProps) => {
  const { t } = useTranslation();

  const { page, perPage, setPage, setPerPage } = useTablePagination({
    withoutURL: true,
  });

  const { data, isLoading, refetch } = useGetWhitelist({
    paging: { limit: perPage, offset: (page - 1) * perPage },
    sorting: [{ field: 'createdAt', direction: 'DESC' }],
    uuid,
  });

  const whitelist = data?.nodes || [];
  const totalCount = data?.totalCount || 0;

  const initialColumns = useMemo(() => getColumns({ uuid, isActive, t }), [uuid, isActive, t]);

  const { columns, setOrder } = useTableColumns({
    storageKey: LocalStorageKey.UNIT_WHITELIST_COLUMNS,
    initialColumns,
  });

  return (
    <div className={styles.wrapper}>
      <DraggableTable
        setOrder={setOrder}
        onRefresh={refetch}
        dataSource={whitelist}
        rowClassName={styles.row}
        rootClassName={styles.tableWrapper}
        loading={isLoading}
        columns={columns}
        scroll={{ x: 'max-content' }}
        pagination={false}
        rowKey="id"
      />
      <Pagination
        totalCount={totalCount}
        page={page}
        perPage={perPage}
        onPageChange={setPage}
        onPerPageChange={setPerPage}
        disablePageChange={isLoading || !totalCount}
        disablePerPageHandler={isLoading || !totalCount}
        elementsSize="xs"
      />
    </div>
  );
};

export default WhitelistTab;
