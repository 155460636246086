import axios, { SERVICE } from 'libs/axios';
import { queryClient, useMutation } from 'libs/reactQuery';
import { MutationOptions } from './types';

const useReadNotification = (mutationOptions?: MutationOptions) => {
  const readNotification = async (id: number) => {
    const url = `${SERVICE.NOTIFICATION}/notifications/${id}`;

    const { data } = await axios.patch(url);
    return data;
  };

  return useMutation({
    ...mutationOptions,
    mutationFn: readNotification,
    onMutate: async (id) => {
      const cacheKey = ['notification-read', id];
      const cachedData = queryClient.getQueryData(cacheKey);

      if (cachedData) {
        return Promise.reject();
      }
    },
    onSuccess: (data, id) => {
      queryClient.setQueryData(['notification-read', id], data);
    },
  });
};

export default useReadNotification;
