import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { cleverRefetchQueries } from 'libs/reactQuery';
import { notification } from 'antd';
import { StageEnum } from 'hooks/use2FA';
import { useColdStorageHardwareId } from 'components/ColdStorage/hooks';
import { useCreateWallet } from 'components/WalletOverview/hooks';
import { OTP_ERROR } from 'constants/index';
import { useAuthorisationContext } from 'contexts';
import { addSubaccountForm, FormData } from '../AddSubaccountForm';
import { Button } from 'components';
import { WalletOutlined } from '@ant-design/icons';
import styles from './AddSubaccountButton.module.scss';

export type AddSubaccountButtonProps = {
  unitId: number | undefined;
  disabled?: boolean;
};

const AddSubaccountButton = ({ unitId, disabled }: AddSubaccountButtonProps) => {
  const hardwareId = useColdStorageHardwareId();
  const { t } = useTranslation();

  const {
    twoFA: {
      stage,
      setStage,
      onSuccess,
      onError,
    },
  } = useAuthorisationContext();

  const { createWallet, isLoading } = useCreateWallet(unitId, {
    onSuccess: (res) => {
      onSuccess(res, async () => {
        await cleverRefetchQueries('subaccounts');

        addSubaccountForm.closeDrawer();

        notification.success({
          message: t('coldStorage.successfullyAdded'),
        });
      });
    },
    onError: (error) => {
      onError(error, () => {
        if (stage !== StageEnum.DEFAULT && error.response?.data?.message.includes(OTP_ERROR)) {
          setStage(StageEnum.DEFAULT);
        }
        notification.error({
          message: t('coldStorage.cannotAdded'),
          description: error.response?.data.message,
        });
      });
    },
  });

  const handleSubmit = (data: FormData, otpCode?: string) => {
    createWallet({
      ...data,
      otpCode,
      isSubWallet: true,
      hardwareId: hardwareId || undefined,
    });
  };

  const handleOpen = () => {
    addSubaccountForm.openDrawer({
      onSubmit: handleSubmit,
      loading: isLoading,
    });
  };

  useEffect(() => {
    if (addSubaccountForm.isOpened()) {
      addSubaccountForm.updateDrawer({ loading: isLoading });
    }
  }, [isLoading]);

  return (
    <Button
      type="default"
      textSize="14px"
      disabled={disabled}
      onClick={handleOpen}
      suffixIcon={<WalletOutlined style={{ fontSize: 16 }} />}
      className={styles.button}
    >
      {t('subaccount.form.title')}
    </Button>
  );
};

export default AddSubaccountButton;
