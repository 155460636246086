import React, { ReactElement, useEffect, useState } from 'react';
import { CirclePicker } from 'react-color';
import { useTranslation } from 'react-i18next';
import { Button, Dropdown, MenuProps } from 'antd';
import clsx from 'clsx';
import { ReactComponent as ArrowUp } from 'assets/icons/ArrowUp.svg';
import { ReactComponent as ResetColor } from 'assets/icons/ResetColor.svg';
import styles from './ColorPicker.module.scss';

export type ColorPickerPropsType = {
  value?: string;
  onChange?: (color: string) => void;
};

const colors = [
  '#FF9689', '#FCB9A9', '#FEAEA5', '#FFC7A2', '#FFD8BE',
  '#F7EEDF', '#FFEFEF', '#FFF2F2', '#8FC9CA', '#A3E1DC',
  '#ACDEE7', '#A3D4DD', '#B3EDEF', '#D4F0F1', '#DAFCFC',
  '#F2FFFF', '#97C1A9', '#B7CFB7', '#CCE2CB', '#C1EDC1',
  '#B9EED0', '#73e7a666', '#E7FEE9', '#F4FFF9', '#FA91AD',
  '#F3B0C2', '#D7C4D7', '#F5C8F5', '#EFDCEF', '#FFE1E9',
  '#FFF0FF', '#FFF9FD', '#7B94EE', '#93A9F9', '#A4B5F3',
  '#A3B1E0', '#C9D5FF', '#C3DBFF', '#E1EFFF', '#EEF2FF',
  '#FEC774', '#F3D09A', '#FFE2B7', '#F5F69E', '#FFFB90',
  '#F1F0D7', '#FFF6E9', '#FFFBD3', '#B29584', '#B98B70',
  '#CEA995', '#EAC1A9', '#FFC4A3', '#FCC5A7', '#FBD9C7',
  '#FFEDE3', '#575757', '#717171', '#939393', '#B9B9B9',
  '#CECECE', '#E7E7E7', '#F0F0F0', '#FFFFFF',
];

export const ColorPicker = ({
  value, onChange,
}: ColorPickerPropsType): ReactElement => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [color, setColor] = useState<string>(value || '#CCCCCC');
  const { t } = useTranslation();

  useEffect(() => {
    if (onChange) onChange(color);
  }, [color, onChange]);

  const resetColorButton = (
    <Button
      type="link"
      icon={<ResetColor />}
      onClick={() => setColor('#CCCCCC')}
      className={styles.resetColorButton}
    >
      {t('colorPicker.resetColor')}
    </Button>
  );

  const items: MenuProps['items'] = [
    {
      key: 'resetButton',
      label: resetColorButton,
    },
    { type: 'divider', className: styles.divider },
    {
      key: 'colorPicker',
      label: <CirclePicker colors={colors} onChange={({ hex }) => setColor(hex)} />,
    },
  ];

  return (
    <div className={styles.wrapper}>
      <Dropdown
        menu={{ items }}
        trigger={['click']}
        open={dropdownOpen}
        onOpenChange={setDropdownOpen}
        overlayClassName={styles.dropdown}
      >
        <div className={styles.selectedContent}>
          <span style={{ background: color }} className={styles.selectedColor} />
          <span className={styles.colorCode}>{color?.toUpperCase()}</span>
          <ArrowUp className={clsx(styles.arrowIcon, { [styles.mirror]: dropdownOpen })} />
        </div>
      </Dropdown>
    </div>
  );
};
