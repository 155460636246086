import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RiArrowDownSLine, RiArrowUpSLine, RiCheckLine } from '@remixicon/react';
import { cleverRefetchQueries } from 'libs/reactQuery';
import { Form, notification } from 'antd';
import { ISMTP } from 'interfaces/ISMTP';
import { SMTPSettingsBodyType } from './types';
import { useSetSMTP, useTestSMTP } from 'hooks';
import { Button } from 'components';
import { FormSMTPSettings } from './components';
import styles from './SmtpSettings.module.scss';

type SmtpSettingsProps = {
  smtp: ISMTP;
  rootUnitId: number;
};

const SmtpSettings = ({ smtp, rootUnitId }: SmtpSettingsProps) => {
  const { t } = useTranslation();
  const [smtpForm] = Form.useForm<SMTPSettingsBodyType>();
  const [SMTPConnected, setSMTPConnected] = useState(false);
  const [isExpand, setIsExpand] = useState(false);

  const { testSMTP, testSMTPAsync, isTestSMTPLoading, isTestSMTPError } = useTestSMTP({
    onSuccess: (response) => {
      setSMTPConnected(response.result);
    },
  });

  const { setSMTPAsync, isSetSMTPLoading } = useSetSMTP();

  const validateFields = async () => {
    try {
      await smtpForm.validateFields();
    } catch (error) {
      notification.close('smtp-validation');
      notification.warn({
        key: 'smtp-validation',
        message: t('SMTP.fields.fillAllFields'),
      });
      return false;
    }

    return true;
  };

  const handleCheckConnection = async () => {
    if (!await validateFields()) {
      return false;
    }

    try {
      const formData = smtpForm.getFieldsValue();
      await testSMTPAsync({ ...formData, port: Number(formData.port) });
    } catch (error) {
      notification.close('smtp-validation');
      notification.error({
        key: 'smtp-validation',
        message: t('SMTP.testFailed'),
      });
      return false;
    }
    return true;
  };

  const handleSave = async () => {
    if (!await handleCheckConnection()) {
      return;
    }

    try {
      const fields = smtpForm.getFieldsValue();
      await setSMTPAsync({
        ...fields,
        port: Number(fields.port),
        rootUnitId,
      });
      await cleverRefetchQueries('root-units');
      await cleverRefetchQueries('business');
    } catch (error) {
      // do nothing
    }
  };

  useEffect(() => {
    if (smtp?.host && smtp?.port && smtp?.email && smtp?.password) {
      testSMTP({
        host: smtp?.host,
        port: smtp?.port,
        email: smtp?.email,
        password: smtp?.password,
        secure: smtp?.secure,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ArrowIcon = isExpand ? RiArrowUpSLine : RiArrowDownSLine;

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <span className={styles.title}>{t('SMTP.title')}</span>
        <ArrowIcon
          size={20}
          className={styles.arrowExpand}
          onClick={() => {
            setIsExpand(prevState => !prevState);
          }}
        />
      </div>
      {isExpand && (
        <>
          <FormSMTPSettings
            form={smtpForm}
            setConnected={setSMTPConnected}
            initialValues={smtp}
            isLoading={isTestSMTPLoading || isSetSMTPLoading}
            isConnected={SMTPConnected}
            isError={isTestSMTPError}
            onReload={handleCheckConnection}
          />
          <Button
            type="default"
            className={styles.editButton}
            suffixIcon={<RiCheckLine size={16} />}
            onClick={handleSave}
            loading={isTestSMTPLoading}
          >
            {t('save')}
          </Button>
        </>
      )}
    </div>
  );
};

export default SmtpSettings;
