import { endOfDay } from 'date-fns';
import { TFunction } from 'i18next';
import { WalletCurrencyEnum } from 'types/WalletCurrencyEnum';
import { WalletNetworkEnum } from 'types/WalletNetworkEnum';
import {
  BetweenComparisonType,
  ComparisonType,
  FilterField,
  FilterFieldType,
} from 'components/Filters';

const getFilterFields = ({
  translate,
}: {
  translate: TFunction,
}): Map<string, FilterField> => new Map<string, FilterField>([
  ['currency', {
    searchable: true,
    label: translate('processing.currency'),
    field: 'currency',
    alwaysVisible: true,
    type: FilterFieldType.SELECT,
    comparisonType: ComparisonType.EQUAL,
    options: [
      { label: WalletCurrencyEnum.ETH, value: WalletCurrencyEnum.ETH },
      { label: WalletCurrencyEnum.TRX, value: WalletCurrencyEnum.TRX },
      { label: WalletCurrencyEnum.BTC, value: WalletCurrencyEnum.BTC },
      { label: WalletCurrencyEnum.BNB, value: WalletCurrencyEnum.BNB },
      { label: WalletCurrencyEnum.DAI, value: WalletCurrencyEnum.DAI },
      { label: WalletCurrencyEnum.USDT, value: WalletCurrencyEnum.USDT },
      { label: WalletCurrencyEnum.USDC, value: WalletCurrencyEnum.USDC },
    ],
  }],
  ['network', {
    searchable: true,
    label: translate('transaction.network'),
    field: 'network',
    alwaysVisible: true,
    type: FilterFieldType.SELECT,
    comparisonType: ComparisonType.EQUAL,
    options: [
      { label: WalletNetworkEnum.TRC20, value: WalletNetworkEnum.TRC20 },
      { label: WalletNetworkEnum.ERC20, value: WalletNetworkEnum.ERC20 },
      { label: WalletNetworkEnum.BEP20, value: WalletNetworkEnum.BEP20 },
      { label: WalletNetworkEnum.BITCOIN, value: WalletNetworkEnum.BITCOIN },
    ],
  }],
  ['walletAddress', {
    searchable: true,
    label: translate('users.transactions.addressFrom'),
    field: 'walletAddress',
    type: FilterFieldType.TEXT,
    comparisonType: ComparisonType.EQUAL,
  }],
  ['createdAt.from', {
    label: `${translate('created')} (${translate('dateFrom')})`,
    field: 'createdAt',
    type: FilterFieldType.DATE,
    maxDate: (filters) => (filters['createdAt.to'] ?? endOfDay(new Date())),
    betweenComparisonType: BetweenComparisonType.LOWER,
    comparisonType: ComparisonType.BETWEEN,
  }],
  ['createdAt.to', {
    label: `${translate('created')} (${translate('dateTo')})`,
    field: 'createdAt',
    type: FilterFieldType.DATE,
    maxDate: () => endOfDay(new Date()),
    minDate: (filters) => (filters['createdAt.from']),
    betweenComparisonType: BetweenComparisonType.UPPER,
    comparisonType: ComparisonType.BETWEEN,
  }],
]);

export default getFilterFields;
