import { useTranslation } from 'react-i18next';
import axios, { SERVICE } from 'libs/axios';
import { useMutation } from 'libs/reactQuery';
import { notification } from 'antd';
import { MutationOptions, UpdateRootUnitVariables } from './types';

const url = SERVICE.ADMIN + '/root-units';

const useUpdateRootUnit = (mutationOptions?: MutationOptions) => {
  const { t } = useTranslation();

  const updateRootUnit = async ({ id, body }: UpdateRootUnitVariables) => {
    const { data } = await axios.patch(`${url}/${id}`, body);
    return data;
  };

  return useMutation({
    ...mutationOptions,
    mutationFn: updateRootUnit,
    onSuccess(data, variables, context) {
      notification.success({ message: t('business.updated') });

      if (mutationOptions?.onSuccess) {
        mutationOptions.onSuccess(data, variables, context);
      }
    },
    onError(error, variables, context) {
      notification.error({ message: error.name, description: error.message });

      if (mutationOptions?.onError) {
        mutationOptions.onError(error, variables, context);
      }
    },
  });
};

export default useUpdateRootUnit;
