import React, { PropsWithChildren, useEffect } from 'react';
import { StageEnum } from 'hooks/use2FA';
import { useAuthorisationContext } from 'contexts';
import { Form2FA } from '../Form2FA';
import { FormScanQR } from '../FormScanQR';

type With2FAProps = PropsWithChildren<{
  onSubmit: (code: string) => void;
  style?: React.CSSProperties;
  isLoading?: boolean;
  withoutReset?: boolean;
  isDrawer?: boolean;
}>;

const With2FA = ({
  onSubmit,
  children,
  style,
  isLoading,
  withoutReset,
  isDrawer = false,
}: With2FAProps) => {
  const {
    twoFA: {
      qrcode,
      stage,
      setStage,
      prevStage,
      setPrevStage,
    },
    loading,
  } = useAuthorisationContext();

  useEffect(() => {
    if (!withoutReset) {
      setStage(StageEnum.DEFAULT);
      setPrevStage(StageEnum.DEFAULT);
    }
  }, [setPrevStage, setStage, withoutReset]);

  return (
    <>
      {stage === StageEnum.DEFAULT && children}

      {(stage === StageEnum.QR_CODE && !!qrcode) && (
        <FormScanQR
          qrCode={qrcode || 'test'}
          onBack={() => setStage(StageEnum.DEFAULT)}
          onContinue={() => {
            setPrevStage(StageEnum.QR_CODE);
            setStage(StageEnum.OTP_CODE);
          }}
          isDrawer={isDrawer}
          style={style}
        />
      )}

      {stage === StageEnum.OTP_CODE && (
        <Form2FA
          isLoading={Boolean(loading || isLoading)}
          onSubmit={onSubmit}
          onBack={() => setStage(prevStage)}
          isDrawer={isDrawer}
          style={style}
        />
      )}
    </>
  );
};

export default With2FA;
