import axios, { SERVICE } from 'libs/axios';
import { useQuery } from 'libs/reactQuery';
import { ClientQueryParams, ClientQueryResult } from './types';

const useGetClients = (queryParams?: ClientQueryParams, queryOptions?: ClientQueryOptions) => {
  const getClients = async () => {
    const url = `${SERVICE.CLIENT}/clients`;
    const { data } = await axios.get(url, { params: queryParams });
    return data;
  };

  return useQuery<ClientQueryResult>({
    ...queryOptions,
    queryKey: ['clients', queryParams?.filter],
    queryFn: getClients,
  });
};

export default useGetClients;
