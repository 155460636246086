import axios, { SERVICE } from 'libs/axios';
import { useMutation } from 'libs/reactQuery';
import { notification } from 'antd';
import { AssignLabelVariables, MutationOptions } from './types';

const useAssignLabel = (clientUUID: string, mutationOptions: MutationOptions) => {

  const assignLabel = async (body: AssignLabelVariables) => {
    const url = `${SERVICE.CLIENT}/clients/label/${clientUUID}`;
    await axios.patch(url, body);
  };

  return useMutation({
    ...mutationOptions,
    mutationFn: assignLabel,
    onSuccess(data, variables, context) {
      if (mutationOptions?.onSuccess) {
        mutationOptions.onSuccess(data, variables, context);
      }
    },
    onError(error, variables, context) {
      notification.error({ message: error.name, description: error.message });

      if (mutationOptions?.onError) {
        mutationOptions.onError(error, variables, context);
      }
    },
  });
};

export default useAssignLabel;
