import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RiCheckLine, RiCloseLine, RiPencilLine } from '@remixicon/react';
import { Input, notification } from 'antd';
import clsx from 'clsx';
import { MaybeRenderEmptyValue } from 'components';
import styles from './Annotation.module.scss';

type AnnotationProps = {
  annotation: string | null;
  onSave: (annotation: string) => Promise<{ error: boolean }>;
};

const Annotation = ({ annotation, onSave }: AnnotationProps) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(annotation);
  const [isEditing, setIsEditing] = useState(false);
  const Icon = isEditing ? RiCheckLine : RiPencilLine;

  const handleSave = useCallback(async () => {
    setIsEditing(false);

    if (value === annotation) {
      notification.close('update-annotation');
      notification.success({
        key: 'update-annotation',
        message: t('saved'),
      });
      return;
    }

    notification.close('update-annotation');
    notification.info({
      key: 'update-annotation',
      message: t('saving'),
    });
    const res = await onSave(value || '');
    if (res.error) {
      setValue(annotation);
      notification.close('update-annotation');
      notification.error({
        key: 'update-annotation',
        message: t('failedSave'),
      });
    } else {
      notification.close('update-annotation');
      notification.success({
        key: 'update-annotation',
        message: t('saved'),
      });
    }
  }, [annotation, onSave, t, value]);

  const handleIconClick = useCallback(() => {
    setIsEditing(prevState => {
      if (prevState) {
        handleSave();
      }
      return !prevState;
    });
  }, [handleSave]);

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  }, []);

  const handleCancel = useCallback(() => {
    setValue(annotation);
    setIsEditing(false);
  }, [annotation]);

  useEffect(() => {
    setValue(annotation);
  }, [annotation]);

  return (
    <div className={styles.container}>
      {!isEditing && (
        <MaybeRenderEmptyValue>
          {value}
        </MaybeRenderEmptyValue>
      )}
      {isEditing && (
        <Input
          autoFocus
          type="text"
          value={value || ''}
          onChange={handleChange}
          onPressEnter={handleSave}
        />
      )}

      <Icon
        className={styles.icon}
        onClick={handleIconClick}
      />
      {isEditing && (
        <RiCloseLine
          className={clsx(styles.icon, styles.close)}
          onClick={handleCancel}
        />
      )}
    </div>
  );
};

export default Annotation;
