import { FC, useCallback, useMemo } from 'react';
import { cleverRefetchQueries } from 'libs/reactQuery';
import useUpdateRootUnit from 'api/root-unit/useUpdateRootUnit';
import { IBusiness } from 'interfaces/IBusiness';
import { useBusinessById } from 'pages/Administration/Businesses/hooks';
import { FormEditBusinessProps } from '../../FormEditBusiness';

type ExternalProps = Omit<FormEditBusinessProps, 'onSubmit'>;

const withFetchRootUnit = (Component: FC<FormEditBusinessProps>) => {
  return (props: ExternalProps) => {
    const { data, refetch, isLoading } = useBusinessById(props.initialValues?.id || null);

    const onSuccess = useCallback(async () => {
      if (props.closeDrawer) {
        props.closeDrawer();
      }
      await Promise.all([
        cleverRefetchQueries('root-units'),
        refetch(),
      ]);
    }, [props, refetch]);

    const { mutate: updateBusiness, isPending: isUpdating } = useUpdateRootUnit({ onSuccess });

    const handleSubmit = useCallback((formData: FormData) => {
      updateBusiness({
        id: props.initialValues?.id as number,
        body: formData,
      });
    }, [props.initialValues?.id, updateBusiness]);

    const business = useMemo(() => {
      return {
        ...props.initialValues,
        ...data,
      } as IBusiness;
    }, [data, props.initialValues]);

    return (
      <Component
        {...props}
        initialValues={business}
        isLoading={props.isLoading || isUpdating || isLoading}
        onSubmit={handleSubmit}
      />
    );
  };
};

export default withFetchRootUnit;
