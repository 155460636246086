import React, { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Permission } from '@fanckler/processing-auth';
import { registerDrawer, SharedDrawerProps } from '@nuvalt/ui-kit';
import { Form, notification, Segmented } from 'antd';
import { head } from 'lodash';
import { IBusiness } from 'interfaces/IBusiness';
import { CreatePOVariablesType, useCreatePayment } from 'pages/Administration/Units/hooks/useCreatePayment';
import withFetchRootUnit from 'drawers/root-unit/FormEditBusiness/hocs/withFetchRootUnit/withFetchRootUnit';
import { useConfirmRootAdmin } from 'hooks/useConfirmRootAdmin';
import { useAuthorisationContext } from 'contexts';
import { Button, Drawer, DrawerContent, DrawerFooter, DrawerHeader, With2FA } from 'components';
import { BillingTab, CommissionTab, SettingsTab } from 'drawers/root-unit/FormEditBusiness/components';
import styles from 'drawers/root-unit/FormEditBusiness/FormEditBusiness.module.scss';

export type FormEditBusinessProps = {
  isLoading?: boolean;
  initialValues?: IBusiness;
  onSubmit: (formData: FormData) => void;
  defaultTab?: string;
} & SharedDrawerProps;

enum OptionsKeys {
  SETTINGS = 'settings',
  BILLING = 'billing',
  COMMISSION = 'commission',
}

const FormEditBusiness = ({
  onSubmit,
  isLoading,
  initialValues = {} as IBusiness,
  closeDrawer,
  defaultTab,
}: FormEditBusinessProps) => {
  const { isRoot } = useConfirmRootAdmin();
  const savedTopUpData = useRef<CreatePOVariablesType>();
  const {
    twoFA: {
      onError,
      onSuccess,
    },
    checkPermissions,
  } = useAuthorisationContext();
  const isBusinessExpired = useMemo(() => initialValues?.isExpired, [initialValues?.isExpired]);
  const canUpdateRootUnit = checkPermissions([Permission.ADMIN_IAM_ROOT_UNIT_UPDATE]);
  const canUpdateSubscription = checkPermissions([Permission.ADMIN_IAM_SUBSCRIPTION_CONTROL]);
  const canReadCommission = checkPermissions([Permission.CLIENT_COMMISSION_READ]);
  const [billingTabKey, setBillingTabKey] = useState(1);
  const [isActiveTopUpTab, setIsActiveTopUpTab] = useState(false);

  const [form] = Form.useForm();
  const [topUpForm] = Form.useForm();
  const { t } = useTranslation();

  const { mutateCreatePayment, isCreating } = useCreatePayment({
    onSuccess: async (data) => {
      onSuccess(data, () => {
        setBillingTabKey(prevState => prevState + 1);
        setIsActiveTopUpTab(false);
        notification.success({
          message: t('processing.paymentOrders.paymentCreated'),
        });
      });
    },
    onError: async (data) => {
      onError(data, () => {
        notification.error({
          description: data?.response?.data?.message,
          message: data?.response?.statusText,
        });
      });
    },
  });

  const options = useMemo(() => {
    const result = [];
    if (canUpdateRootUnit) {
      result.push({ value: OptionsKeys.SETTINGS, label: t('settings'), disabled: isBusinessExpired && !isRoot });
    }

    if (canUpdateSubscription) {
      result.push({ value: OptionsKeys.BILLING, label: t('business.subscription.billing') });
    }

    if (canReadCommission) {
      result.push({ value: OptionsKeys.COMMISSION, label: t('commission.title'), disabled: isBusinessExpired && !isRoot });
    }

    return result;
  }, [canReadCommission, canUpdateRootUnit, canUpdateSubscription, isBusinessExpired, isRoot, t]);
  const [activeTab, setActiveTab] = useState((isBusinessExpired && !isRoot && OptionsKeys.BILLING) || defaultTab || head(options)?.value);

  const handleActiveTopUpTab = (status: boolean) => {
    setIsActiveTopUpTab(status);
  };

  const handleSubmitTopUpFormUnit = (values: CreatePOVariablesType, code?: string) => {
    savedTopUpData.current = values;
    mutateCreatePayment({
      ...values,
      body: {
        ...values.body,
        otpCode: code,
      },
    });
  };

  const handleSubmit = () => {
    if (isActiveTopUpTab) {
      topUpForm.submit();
    } else {
      form.submit();
    }
  };

  const handleSubmitOtp = (code: string) => {
    if (savedTopUpData.current) {
      handleSubmitTopUpFormUnit(savedTopUpData.current, code);
    }
  };

  return (
    <Drawer>
      <DrawerHeader title={t('business.edit')} onClose={closeDrawer} />
      <With2FA onSubmit={handleSubmitOtp} isDrawer>
        <DrawerContent>
          {options.length > 1 && (
            <Segmented
              options={options}
              value={activeTab}
              onChange={(tab) => {
                setActiveTab(tab as OptionsKeys);
                setIsActiveTopUpTab(false);
              }}
              className={styles.segmented}
            />
          )}
          {activeTab === OptionsKeys.SETTINGS && canUpdateRootUnit && (
            <SettingsTab
              form={form}
              onSubmit={onSubmit}
              business={initialValues}
            />
          )}
          {activeTab === OptionsKeys.BILLING && canUpdateSubscription && (
            <BillingTab
              key={billingTabKey}
              business={initialValues}
              onActiveTopUpTab={handleActiveTopUpTab}
              topUpForm={topUpForm}
              onSubmit={handleSubmitTopUpFormUnit}
            />
          )}
          {activeTab === OptionsKeys.COMMISSION && canReadCommission && (
            <CommissionTab
              businessId={initialValues?.id}
            />
          )}
        </DrawerContent>
        {(activeTab === OptionsKeys.SETTINGS || (activeTab === OptionsKeys.BILLING && isActiveTopUpTab)) && (
          <DrawerFooter>
            <>
              <Button
                block
                htmlType="submit"
                loading={isLoading || isCreating}
                disabled={isLoading || isCreating}
                onClick={handleSubmit}
              >
                {isActiveTopUpTab ? (
                  t('business.subscription.topUpFromUnit')
                ) : (
                  t('save')
                )}
              </Button>

              <Button
                block
                type="link"
                color="error"
                onClick={closeDrawer}
              >
                {t('cancel')}
              </Button>
            </>
          </DrawerFooter>
        )}
      </With2FA>
    </Drawer>
  );
};

export default registerDrawer(
  withFetchRootUnit(
    FormEditBusiness,
  ),
);
