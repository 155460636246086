import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Permission } from '@fanckler/processing-auth';
import { registerDrawer, SharedDrawerProps } from '@nuvalt/ui-kit';
import { Form, notification, Segmented } from 'antd';
import { SegmentedValue } from 'antd/lib/segmented';
import { useUserUpdate } from 'api/users';
import { noop } from 'lodash';
import { IUser } from 'interfaces/IUser';
import { NotificationSettings } from 'pages/Administration/Managers/components/NotificationSettings';
import { useChangeNotifications } from 'pages/Administration/Managers/components/NotificationSettings/hooks';
import { useAuthorisationContext } from 'contexts';
import { Button, Drawer, DrawerContent, DrawerFooter, DrawerHeader } from 'components';
import { Account } from './components';
import styles from './FormEditMyAccount.module.scss';

type FormEditUserProps = SharedDrawerProps;

enum OptionsKeys {
  ACCOUNT = 1,
  NOTIFICATIONS = 2,
}

const FormEditMyAccount = ({ closeDrawer = noop, drawerId }: FormEditUserProps) => {
  const { user, setUser, checkPermissions } = useAuthorisationContext();
  const [form] = Form.useForm<IUser>();
  const formData = useRef<IUser>();
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState(OptionsKeys.ACCOUNT);
  const [isChangedForm, setIsChangedForm] = useState(false);

  const canEditUser = checkPermissions([Permission.ADMIN_IAM_USER_UPDATE]);
  const isSmtpConfigured = useMemo(() => Boolean(user?.rootUnit?.smtp), [user]);

  const {
    showSavingAlert,
    resetChanges,
    notificationSettings,
    isLoading: isLoadingSettings,
    isUpdating: isUpdatingSettings,
    isChanged: isChangedSettings,
    changeNotification,
    save: saveNotifications,
  } = useChangeNotifications(user?.uuid, activeTab === OptionsKeys.NOTIFICATIONS);

  const { mutateAsync: updateInternalUserAsync } = useUserUpdate(user?.id as number, {
    onSuccess: async (response) => {
      if (user) {
        setUser({
          ...user,
          name: response.name,
        });
      }
      notification.success({
        message: t('userUpdated'),
      });
    },
    onError: (e) => {
      notification.error({
        message: e.response?.data?.message,
      });
    },
  });

  const options = [
    { value: OptionsKeys.ACCOUNT, label: t('manager.account') },
    { value: OptionsKeys.NOTIFICATIONS, label: t('notifications.title') },
  ];

  const handleSave = async () => {
    if (isChangedSettings) {
      saveNotifications();
    }
    if (isChangedForm && formData.current) {
      await updateInternalUserAsync(formData.current);
    }
  };

  const handleChangedFormData = useCallback((data: IUser, status: boolean) => {
    setIsChangedForm(status);
    formData.current = data;
  }, []);

  const handleResetForm = () => {
    formData.current = undefined;
    setIsChangedForm(false);
    resetChanges();
    form.resetFields();
  };

  const handleChangeTab = (value: SegmentedValue) => {
    setActiveTab(value as OptionsKeys);

    if (value === OptionsKeys.ACCOUNT && isChangedSettings) {
      showSavingAlert();
    }
  };

  return (
    <Drawer id={drawerId}>
      <DrawerHeader title={t('myAccount.title')} onClose={closeDrawer} />
      <DrawerContent>
        <Segmented
          options={options}
          value={activeTab}
          onChange={handleChangeTab}
          className={styles.segmented}
        />
        {activeTab === OptionsKeys.ACCOUNT && user && (
          <Account
            form={form}
            user={user}
            onChangedData={handleChangedFormData}
          />
        )}
        {activeTab === OptionsKeys.NOTIFICATIONS && (
          <NotificationSettings
            settings={notificationSettings || {}}
            canEditUser={canEditUser}
            isLoading={isLoadingSettings}
            isSmtpConfigured={isSmtpConfigured}
            onChange={changeNotification}
          />
        )}
      </DrawerContent>
      {(isChangedSettings || isChangedForm) && (
        <DrawerFooter>
          <Button
            block
            type="primary"
            onClick={handleSave}
            loading={isUpdatingSettings}
            disabled={isUpdatingSettings}
          >
            {t('save')}
          </Button>
          <Button
            block
            type="link"
            color="error"
            onClick={handleResetForm}
          >
            {t('cancel')}
          </Button>
        </DrawerFooter>
      )}
    </Drawer>
  );
};

export default registerDrawer(FormEditMyAccount, {
  id: 'FormEditMyAccount',
});
