import React, {
  ReactElement, ReactNode, useCallback,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Result } from 'antd';
import { ResultStatusType } from 'antd/es/result';
import { getDefaultErrorInfo, getErrorStatusCode } from './utils';

export interface IErrorProps {
  error?: Error;
  status?: ResultStatusType;
  title?: string | number;
  subTitle?: string;
  extra?: ReactNode;
}

const Error = ({
  error,
  title,
  status,
  subTitle,
  extra,
}: IErrorProps): ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const errorStatusCode = getErrorStatusCode(status, error);
  const defaultErrorInfo = getDefaultErrorInfo(errorStatusCode, t);

  const onBackHomeClick = useCallback(() => navigate('/'), [navigate]);

  return (
    <Result
      status={defaultErrorInfo.status}
      title={title || defaultErrorInfo.title}
      subTitle={subTitle || defaultErrorInfo.subTitle}
      extra={extra || <Button type="primary" onClick={onBackHomeClick}>{t('backToMainPage')}</Button>}
    />
  );
};

export default Error;
