import { TFunction } from 'i18next';
import { IStatus } from '../../interfaces/IStatus';
import { ColorEnum } from '../../types';

export const getGasPriceIndicators = (t: TFunction): IStatus[] => [
  {
    status: 'normal',
    title: t('processing.resources.gasPrice.normal'),
    color: ColorEnum.PENDING,
  },
  {
    status: 'low',
    title: t('processing.resources.gasPrice.low'),
    color: ColorEnum.SUCCESS,
  },
  {
    status: 'high',
    title: t('processing.resources.gasPrice.high'),
    color: ColorEnum.ERROR,
  },
];
