import React from 'react';
import { useTranslation } from 'react-i18next';
import { RiArrowLeftLine, RiFileCopyLine, RiInformationLine } from '@remixicon/react';
import { FormInstance, Tag, Tooltip, Typography } from 'antd';
import clsx from 'clsx';
import { IBusiness } from 'interfaces/IBusiness';
import { CurrencyEnum } from 'types/Currency';
import { UnknownType } from 'types/Unknown';
import { truncateNumber } from 'utils';
import { getSubscriptionStatusProps } from 'drawers/root-unit/FormEditBusiness/utils';
import { Button, CopyToClipboard, CurrencyBadge } from 'components';
import styles from 'drawers/root-unit/FormEditBusiness/components/BillingTab/BillingTab.module.scss';
import { isSubscriptionActive } from 'drawers/root-unit/FormEditBusiness/components/ExpirationCard/calculateExpirationDate';
import TopUpWallet from 'drawers/root-unit/FormEditBusiness/components/TopUpWallet/TopUpWallet';

type TopUpFromUnitTabProps = {
  onBack: () => void;
  business: IBusiness;
  form: FormInstance<UnknownType>;
  onSubmit: (values: UnknownType) => void;
};

const TopUpFromUnitTab = ({
  onBack,
  business,
  form,
  onSubmit,
}: TopUpFromUnitTabProps) => {
  const { t } = useTranslation();

  const expirationDate = business.expirationAt ? new Date(business.expirationAt) : null;
  const isSubscriptionNotExpired = isSubscriptionActive(expirationDate);

  const tagProps = getSubscriptionStatusProps(Boolean(business.isExpired), Boolean(isSubscriptionNotExpired));

  return (
    <>
      <Typography.Title level={5} className={clsx(styles.headTitle, styles.inlineFlex)}>
        <Button
          onClick={onBack}
          type="link"
          suffixIcon={<RiArrowLeftLine size={18} />}
          className={styles.backButton}
        >
        </Button>
        {t('business.subscription.topUpFromUnit')}
      </Typography.Title>

      <div className={styles.card}>
        <Typography.Text className={styles.cardTitle}>
          {t('business.subscription.subscriptionWalletBalance')}
        </Typography.Text>
        <Typography.Title level={4} className={styles.walletBalance}>
          {truncateNumber({ number: (business.balance || 0) as number, currency: CurrencyEnum.USDT })}
          {' '} USDT
        </Typography.Title>
        <Tag color={tagProps.color} className={styles.tag}>
          {t(tagProps.text)}
        </Tag>
        {business.subscriptionWalletAddress && (
          <div className={styles.card}>
            <div className={styles.inlineFlex}>
              <Typography.Text className={styles.cardTitle}>
                {t('business.subscription.topUpAddress')}
                <Tooltip title={t('business.subscription.topUpAddressDescription')}>
                  <RiInformationLine size={16} />
                </Tooltip>
              </Typography.Text>
              <div className={styles.currencyBadge}>
                <CurrencyBadge currency="USDT" withoutText />
                <Typography.Text>
                  USDT <span className={styles.network}>TRC20</span>
                </Typography.Text>
              </div>
            </div>
            <Typography.Text className={styles.address}>
              {business.subscriptionWalletAddress}
              <CopyToClipboard text={business.subscriptionWalletAddress}>
                <RiFileCopyLine size={16} style={{ cursor: 'pointer', color: 'var(--black-45-percent-color)' }} />
              </CopyToClipboard>
            </Typography.Text>
          </div>
        )}
      </div>
      <TopUpWallet
        business={business}
        form={form}
        onSubmit={onSubmit}
      />
    </>
  );
};

export default TopUpFromUnitTab;
