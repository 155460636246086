import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { cleverRefetchQueries } from 'libs/reactQuery';
import useCreateInvoice from 'api/invoice/useCreateInvoice';
import { InvoiceFormDataType } from 'components/CreateInvoice/types';
import { useColdStorageHardwareId } from 'components/ColdStorage/hooks';
import { Button } from 'components';
import { createInvoiceForm } from 'components/CreateInvoice';
import { ExceptionOutlined } from '@ant-design/icons';
import styles from './CreateInvoiceButton.module.scss';

export type CreateInvoiceButtonProps = {
  unitId: number;
  disabled?: boolean;
};

const CreateInvoiceButton = ({ unitId, disabled }: CreateInvoiceButtonProps) => {
  const hardwareId = useColdStorageHardwareId();
  const { t } = useTranslation();

  const { mutate, isPending } = useCreateInvoice({
    onSuccess: async () => {
      await cleverRefetchQueries('invoices');
      createInvoiceForm.closeDrawer();
    },
  });

  const onSubmit = (formData: InvoiceFormDataType & { isAnyAmount: boolean }) => {
    mutate({
      unitId,
      isAnyAmount: formData.isAnyAmount,
      amount: formData.isAnyAmount ? '0' : String(formData.amount),
      network: formData.network,
      currency: formData.currency,
      title: formData.title?.trim() || undefined,
      expirationAt: formData.expirationAt,
      description: formData.description.trim() || undefined,
      customerEmail: formData.customerEmail.trim() || undefined,
      hardwareId: hardwareId || undefined,
    });
  };

  const handleOpen = () => {
    createInvoiceForm.openDrawer({
      onSubmit,
      isLoading: isPending,
    });
  };

  useEffect(() => {
    if (createInvoiceForm.isOpened()) {
      createInvoiceForm.updateDrawer({ isLoading: isPending });
    }
  }, [isPending]);

  return (
    <Button
      type="default"
      textSize="14px"
      disabled={disabled}
      onClick={handleOpen}
      suffixIcon={<ExceptionOutlined style={{ fontSize: 16 }} />}
      className={styles.button}
    >
      {t('invoice.createInvoice')}
    </Button>
  );
};

export default CreateInvoiceButton;
