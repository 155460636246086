import { Link } from 'react-router-dom';
import { IInvoice } from 'interfaces';
import { Path } from 'routes/interfaces/Path';
import { Button } from 'components';
import { InfoCircleOutlined, SwapOutlined } from '@ant-design/icons';
import { ReactComponent as ExternalLinkIcon } from 'assets/icons/ExternalLink.svg';
import styles from '../InvoicesTab.module.scss';

export type RenderActionButtonsParams = {
  isRoot: boolean,
  invoice: IInvoice,
  onView: (invoice: IInvoice) => void,
  onOpenTransferFundsDrawer: (uuid: string) => void,
};

const renderActionButtons = ({
  isRoot,
  invoice,
  onView,
  onOpenTransferFundsDrawer,
}: RenderActionButtonsParams) => (
  <div className={styles.buttons}>
    {['partially_paid', 'overpaid', 'completed'].includes(invoice.status) && (
      <Button
        type="link"
        withoutBgShadow
        withoutWaveEffect
        className={styles.button}
        disabled={!invoice.unit.isActive}
        onClick={() => onOpenTransferFundsDrawer(invoice.uuid)}
        prefixIcon={<SwapOutlined style={{ fontSize: 18 }} />}
      />
    )}

    <Button
      type="link"
      withoutBgShadow
      withoutWaveEffect
      className={styles.button}
      onClick={() => onView(invoice)}
      prefixIcon={<InfoCircleOutlined style={{ fontSize: 18 }} />}
    />

    {isRoot && (
      <Link
        target="_blank"
        className={styles.link}
        to={`${Path.INVOICES}/${invoice.uuid}`}
      >
        <ExternalLinkIcon style={{ width: 18 }} />
      </Link>
    )}
  </div>
);

export default renderActionButtons;
