import React, { PropsWithChildren, ReactElement, useCallback, useEffect, useState } from 'react';
import { useMatch, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Permission } from '@fanckler/processing-auth';
import { drawerStore } from '@nuvalt/ui-kit';
import * as Sentry from '@sentry/react';
import axios from 'libs/axios';
import { identify } from 'libs/hotjar';
import { queryClient } from 'libs/reactQuery';
import { notification } from 'antd';
import { get } from 'lodash';
import { IUser } from 'interfaces/IUser';
import { Path } from 'routes/interfaces/Path';
import { UnknownType } from 'types/Unknown';
import { use2FA } from 'hooks';
import { useAuthMe, useExpiredSessionInterceptor, useFingerprint, useLogin } from './hooks';
import { LoginData } from './hooks/useAuth';
import useAuthenticatedRoute from './hooks/useAuthenticatedRoute';
import { useSessionLogout } from './hooks/useSessionLogout';
import LocalStorage, { LocalStorageKey } from '../../utils/localStorage';
import { AuthorisationContext, IAuthorisationContext, LoginParams } from './AuthorisationContext';
import { resetModals } from 'components/Modal/actions';
import { PageLoader } from '../../components';

type IAuthorisationProviderProps = PropsWithChildren;

const AuthorisationProvider = ({ children }: IAuthorisationProviderProps): ReactElement => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const samlToken = searchParams.get('accessToken');
  const passwordless = searchParams.get('password');

  const [path, setPath] = useState<string | null>(null);
  const [token, setToken] = useState(LocalStorage.get(LocalStorageKey.ACCESS_TOKEN));
  const [initialLoading, setInitialLoading] = useState<boolean>(Boolean(samlToken || token));
  const [user, setUser] = useState<IUser | null>(null);

  const {
    qrcode,
    onError,
    onSuccess,
    stage,
    setStage,
    prevStage,
    setPrevStage,
    isDisabled,
    setDisabled,
  } = use2FA();

  const fingerprint = useFingerprint();

  const isLoginPage = !!(useMatch(Path.LOGIN));
  const isInvoicePage = !!(useMatch(Path.INVOICES_UUID));
  const isNotFetchCurrentUser = !isLoginPage || !isInvoicePage;

  // useRefreshTokenInterceptor(fingerprint, !isNotFetchCurrentUser);

  useEffect(() => {
    if (samlToken) {
      LocalStorage.set(LocalStorageKey.ACCESS_TOKEN, samlToken);
      setToken(samlToken);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [samlToken]);

  const setUserData = (data: IUser | null) => {
    setUser(data);
    if (data) {
      identify({ userId: data.id.toString(), email: data.email, name: data.name });

      if ((window as UnknownType)?.fcWidget) {
        (window as UnknownType).fcWidget.user?.setFirstName(data?.name || '');
        (window as UnknownType).fcWidget.user?.setEmail(data?.email || '');
      }
    }
  };

  const checkPermissions = useCallback((permissions: Permission[]) => {
    if (!user || !user?.group?.isActive) {
      return false;
    }

    return user.group.permissions.some(
      ({ name }) => permissions.some((item) => item === name),
    );
  }, [user]);

  useAuthenticatedRoute({ user, setPath, checkPermissions });

  useEffect(() => {
    if (user && path && user?.rootUnit?.isExpired) {
      navigate(path);
    }
  }, [navigate, path, user]);

  const { loginMutate, isLoading } = useLogin({
    onSuccess: (data: LoginData) => {
      onSuccess(data, () => {
        Sentry.setUser({
          id: data.currentUser.id,
          name: data.currentUser.name,
          uuid: data.currentUser.uuid,
          email: data.currentUser.email,
        });
        LocalStorage.set(LocalStorageKey.ACCESS_TOKEN, data.accessToken);
        LocalStorage.set(LocalStorageKey.LAST_ACTIVITY, Date.now().toString());
        LocalStorage.set(LocalStorageKey.BUSINESS_LOGO, data.currentUser.rootUnit?.logo || '');

        axios.defaults.headers.Authorization = `Bearer ${data.accessToken}`;
        setUserData(data.currentUser);
        setToken(data.accessToken);
      });
    },
    onError: (e: UnknownType) => {
      onError(e, () => {
        navigate(window.location.pathname);

        notification.error({
          description: e.response.data.message,
          message: e.response.statusText,
        });
      });
    },
  });

  useEffect(() => {
    if (passwordless) {
      loginMutate({
        passwordless: true,
        body: {
          password: passwordless,
        },
      });
    }
  }, [loginMutate, passwordless]);

  const clearSession = useCallback((options?: { message?: string }) => {
    axios.defaults.headers.Authorization = null;
    LocalStorage.remove(LocalStorageKey.ACCESS_TOKEN);
    LocalStorage.remove(LocalStorageKey.LAST_ACTIVITY);
    setUser(null);
    setPath(null);
    setToken(null);
    navigate(Path.LOGIN, {
      state: {
        reason: options?.message,
      },
    });
    drawerStore.set.closeLastOpened(); // TODO: maybe add drawerStore.set.closeAll() function here
    dispatch(resetModals());
    setTimeout(() => queryClient.clear(), 500);
  }, [dispatch, navigate]);

  const { logout } = useSessionLogout({
    clearSessionFn: clearSession,
  });

  const handleSuccessAuth = useCallback((data: IUser) => {
    setUserData(data);
    setInitialLoading(false);
    LocalStorage.set(LocalStorageKey.LAST_ACTIVITY, Date.now().toString());
    LocalStorage.set(LocalStorageKey.BUSINESS_LOGO, data.rootUnit?.logo || '');
  }, []);

  const handleErrorAuth = useCallback((e: UnknownType) => {
    notification.error({
      message: get(e, ['response', 'data', 'message']) || 'Unknown error',
      description: get(e, ['message']) || 'Unknown error',
    });
    LocalStorage.remove(LocalStorageKey.ACCESS_TOKEN);
    LocalStorage.remove(LocalStorageKey.LAST_ACTIVITY);

    setToken(null);
    setPath(null);
    setInitialLoading(false);
    queryClient.clear();
  }, []);

  const { refetch: refreshUser } = useAuthMe({
    onSuccess: handleSuccessAuth,
    onError: handleErrorAuth,
  }, !!((initialLoading && token) || !isNotFetchCurrentUser));

  const login = useCallback(({ email, password, otpCode, withPasswordless }: LoginParams) => {
    loginMutate({
      passwordless: withPasswordless,
      body: {
        email,
        password,
        fingerprint,
        otpCode,
      },
    });
  }, [fingerprint, loginMutate]);

  useExpiredSessionInterceptor(logout);

  const contextData: IAuthorisationContext = {
    user,
    setUser,
    path,
    token,
    login,
    logout,
    twoFA: {
      qrcode,
      stage,
      setStage,
      prevStage,
      setPrevStage,
      onError,
      onSuccess,
      isDisabled,
      setDisabled,
    },
    passwordless,
    fingerprint,
    refreshUser,
    checkPermissions,
    loading: isLoading || initialLoading,
  };

  return (
    <AuthorisationContext.Provider value={contextData}>
      {initialLoading ? <PageLoader /> : children}
    </AuthorisationContext.Provider>
  );
};

export default AuthorisationProvider;
