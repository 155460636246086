import React, { PropsWithChildren } from 'react';
import styles from './DrawerFooter.module.scss';

type DrawerFooterProps = PropsWithChildren;

const DrawerFooter = ({ children }: DrawerFooterProps) => {
  return (
    <div className={styles.drawerFooter}>
      {children}
    </div>
  );
};

export default DrawerFooter;
