import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Tooltip } from 'antd';
import { truncateString, TruncateStringConfig } from 'utils';
import { generateBlockchainScanLink } from 'utils/generateBlockchainScanLink';
import { CopyToClipboard } from 'components/CopyToClipboard';
import { CopyOutlined, EyeOutlined } from '@ant-design/icons';
import styles from './ScanLink.module.scss';

type ScanLinkPropsType = TruncateStringConfig & {
  network: string,
  txId: string,
  withoutIcon?: boolean;
  withCopy?: boolean;
  withoutText?: boolean
};

const ScanLink = ({
  txId,
  network,
  withCopy,
  withoutText,
  withoutIcon,
  type = 'center',
  endLength = 8,
  startLength = 8,
}: ScanLinkPropsType): ReactElement => {
  const { t } = useTranslation();

  const regex = /^internal_/gm;
  const isInternal = regex.test(txId);
  const truncatedTxId = !withoutText && truncateString(txId, { type, endLength, startLength });

  return (
    <div className={styles.wrapper}>
      {withCopy && (
        <CopyToClipboard text={txId}>
          <Button
            type="text"
            size="middle"
            icon={<CopyOutlined className={styles.copyIcon} />}
            className={styles.copyBtn}
          />
        </CopyToClipboard>
      )}

      {isInternal ? truncatedTxId : (
        <Link
          target={'_blank'}
          to={generateBlockchainScanLink(network, txId)}
          className={styles.link}
          {...(!withoutIcon ? { style: { display: 'flex', alignItems: 'center' } } : {})}
        >
          <Tooltip title={t('blockchainScan.viewOn')} placement="topLeft">
            {!withoutIcon && <EyeOutlined style={{ fontSize: '17px', marginRight: 10 }} />}
            {!withoutText && truncatedTxId}
          </Tooltip>
        </Link>
      )}
    </div>
  );
};

export default ScanLink;
