import React, { ReactElement } from 'react';
import { Checkbox, Space, Tooltip } from 'antd';
import styles from 'components/Filters/components/SelectWindow/SelectWindow.module.scss';

export type SelectItem = {
  key: string;
  label: string;
  selected: boolean,
  disabled?: boolean,
};

export type SelectWindowProps = {
  items: SelectItem[];
  children: ReactElement;
  onItemClick: (item: SelectItem, status: boolean) => void;
};

const SelectWindow = ({ items, children, onItemClick }: SelectWindowProps): ReactElement => (
  <Tooltip
    overlayClassName={styles.tooltip}
    placement="bottomLeft"
    trigger="click"
    color="white"
    title={(
      <Space size={20} direction="vertical">
        {items.map((item) => {
          const {
            key, label, selected, disabled,
          } = item;

          return (
            <Checkbox
              key={key}
              checked={selected}
              disabled={disabled}
              onChange={(event) => onItemClick(item, event.target.checked)}
            >
              {label}
            </Checkbox>
          );
        })}
      </Space>
    )}
  >
    {children}
  </Tooltip>
);

export default SelectWindow;
