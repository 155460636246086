import { PropsWithChildren, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UnknownType } from 'types/Unknown';
import { Sort, useTableFeatures } from 'hooks';
import { FiltersContext } from './FiltersContext';
import { PerPageNumber } from 'components/Pagination';

type FiltersProviderProps = {
  getFilterFieldsFn: (args: Record<string, UnknownType>) => Map<string, UnknownType>;
  allowedToSortFields?: string[];
  defaultSort?: Sort<string>;
  defaultPerPageOptions?: PerPageNumber[];
} & PropsWithChildren;

const FiltersProvider = ({
  children,
  getFilterFieldsFn,
  allowedToSortFields,
  defaultPerPageOptions,
  defaultSort = { field: 'createdAt' as string, order: 'descend' },
}: FiltersProviderProps) => {
  const { t } = useTranslation();
  const [filterFieldsArgs, setFilterFieldsArgs] = useState<Record<string, UnknownType>>();

  const filterFields = getFilterFieldsFn({
    translate: t,
    ...filterFieldsArgs,
  });

  const {
    sort,
    setSort,
    filter,
    setFilter,
    filters,
    filterRelation,
    page,
    perPage,
    setPage,
    setPerPage,
  } = useTableFeatures({
    defaultSort,
    filterFields,
    allowedToSortFields,
    defaultPerPageOptions,
  });

  const contextData = {
    sort,
    setSort,
    filter,
    setFilter,
    filters,
    filterRelation,
    page,
    perPage,
    setPage,
    setPerPage,
    setFilterFieldsArgs,
    filterFields,
  };
  return (
    <FiltersContext.Provider value={contextData}>
      {children}
    </FiltersContext.Provider>
  );
};

export default FiltersProvider;
