import { Notification } from 'api/notification/useGetNotifications';
import { Filters } from 'components/Notifications/types';

export const SET_NOTIFICATION_VISIBLE = 'SET_NOTIFICATION_VISIBLE';
export type SetNotificationVisible = {
  type: typeof SET_NOTIFICATION_VISIBLE;
  payload: boolean;
};

export const SET_NOTIFICATION_PAYLOAD = 'SET_NOTIFICATION_PAYLOAD';
export type NotificationPayload = Notification | null;
export type SetNotificationPayload = {
  type: typeof SET_NOTIFICATION_PAYLOAD;
  payload: NotificationPayload;
};

export const SET_NOTIFICATION_FILTERS = 'SET_NOTIFICATION_FILTERS';
export type NotificationFilters = Partial<Filters> | null;
export type SetNotificationFilters = {
  type: typeof SET_NOTIFICATION_FILTERS;
  payload: NotificationFilters;
};

export type NotificationActionType = SetNotificationVisible | SetNotificationPayload | SetNotificationFilters;
