import React from 'react';
import { useTranslation } from 'react-i18next';
import { RiCheckboxCircleFill } from '@remixicon/react';
import axios, { SERVICE } from 'libs/axios';
import { useMutation } from 'libs/reactQuery';
import { notification } from 'antd';
import { MutationOptions, UpdateNotificationSettingsVariables } from './types';
import { LoadingOutlined } from '@ant-design/icons';

const url = `${SERVICE.ADMIN}/notification-setting`;

const useUpdateNotificationSettings = (mutationOptions?: MutationOptions) => {
  const { t } = useTranslation();

  const updateNotificationSettings = async ({ uuid, body }: UpdateNotificationSettingsVariables) => {
    notification.close('notification-settings');
    notification.info({
      icon: (
        <LoadingOutlined
          style={{
            color: 'var(--brand-color)',
          }}
        />
      ),
      key: 'notification-settings',
      message: t('notifications.settings.savingInProgress'),
    });
    const { data } = await axios.put(`${url}/${uuid}`, body);
    return data;
  };

  return useMutation({
    ...mutationOptions,
    mutationFn: updateNotificationSettings,
    onSuccess(data, variables, context) {
      notification.close('notification-settings');
      notification.success({
        key: 'notification-settings',
        duration: 2,
        icon: (
          <RiCheckboxCircleFill
            style={{
              color: 'var(--green-status-color)',
            }}
          />
        ),
        message: t('notifications.settings.savingSuccess'),
      });

      if (mutationOptions?.onSuccess) {
        mutationOptions.onSuccess(data, variables, context);
      }
    },
    onError(error, variables, context) {
      notification.close('notification-settings');
      notification.error({
        key: 'notification-settings',
        duration: 2,
        message: t('notifications.settings.savingError'),
        description: error.message,
      });

      if (mutationOptions?.onError) {
        mutationOptions.onError(error, variables, context);
      }
    },
  });
};

export default useUpdateNotificationSettings;
