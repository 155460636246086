import React, { CSSProperties, Fragment, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown, notification } from 'antd';
import clsx from 'clsx';
import { useSelector } from 'init/rootReducer';
import { IWallet } from 'types/IWallet';
import { WalletNetworkEnum } from 'types/WalletNetworkEnum';
import { currencyByNetworkMap, disabledNetworks, networkByTypeMap } from 'constants/wallet';
import { CurrencyIcon } from 'utils/currencyIcon';
import { filterExistingNetworks } from './utils';
import AddWalletButton from './AddWalletButton';
import styles from './AddWallet.module.scss';

type AddWalletProps = {
  wallets: IWallet[];
  disabled?: boolean;
  onCreateWallet: (args: { network: string, currency: string }) => void;
  onAddColdWallet?: () => void;
  isLoading?: boolean;
  buttonType?: 'link' | 'primary' | 'default';
  iconType?: 'suffix' | 'prefix';
  canOpenColdStorageModal?: boolean;
  style?: CSSProperties;
  className?: string;
};

const AddWallet = ({
  wallets,
  disabled,
  onCreateWallet,
  isLoading,
  iconType = 'suffix',
  buttonType = 'link',
  canOpenColdStorageModal = false,
  style,
  onAddColdWallet,
  className,
}: AddWalletProps) => {
  const { t } = useTranslation();
  const isColdStorageMode = useSelector((state) => state.coldStorageReducer.isColdStorageMode);

  const handleClick = useCallback((network: WalletNetworkEnum) => (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (disabledNetworks.includes(network)) {
      event.stopPropagation();
      return;
    }
    const currency = currencyByNetworkMap.get(network);
    if (!currency) {
      return notification.error({ message: t('coldStorage.cannotAdded') });
    }

    onCreateWallet({
      network,
      currency,
    });
  }, [onCreateWallet, t]);

  const availableNetwork = useMemo(() => filterExistingNetworks(wallets), [wallets]);

  const isNoNetworks = useMemo(
    () => availableNetwork.filter(network => !disabledNetworks.includes(network)).length === 0,
    [availableNetwork],
  );

  const items = useMemo(() => availableNetwork.map((network) => {
    const name = networkByTypeMap.get(network);
    return ({
      label: (
        <span
          onClick={handleClick(network)}
          className={clsx({ [styles.disabled]: disabledNetworks.includes(network) })}
        >
          <img src={CurrencyIcon[network]} alt={name} />
          {name}
        </span>
      ),
      key: network,
    });
  }), [availableNetwork, handleClick]);

  if (canOpenColdStorageModal && isColdStorageMode) {
    return (
      <AddWalletButton
        isLoading={isLoading}
        buttonType={buttonType}
        iconType={iconType}
        disabled={disabled}
        onClick={onAddColdWallet}
        className={className}
      />
    );
  }

  if (isNoNetworks) {
    return <Fragment />;
  }

  return (
    <Dropdown
      overlayClassName={styles.dropdown}
      menu={{ items }}
      trigger={['click']}
      placement="bottomRight"
      arrow
      disabled={disabled}
    >
      <AddWalletButton
        style={style}
        isLoading={isLoading}
        buttonType={buttonType}
        iconType={iconType}
        disabled={disabled}
        className={className}
      />
    </Dropdown>
  );
};

export default AddWallet;
