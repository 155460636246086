import React from 'react';
import { Permission } from '@fanckler/processing-auth';
import { ColumnsType } from 'antd/lib/table';
import { TFunction } from 'i18next';
import { Path } from 'routes/interfaces/Path';
import { ITransactionCategory, ITransactionGroup } from '../api/types';
import { CheckPermissionsFunction } from 'contexts/AuthorisationContext/AuthorisationContext';
import { EditButton, RelationLink } from 'components';
import styles from '../components/CategoriesTable/CategoriesTable.module.scss';
import { ReactComponent as ArrowRight } from 'assets/icons/ArrowRight.svg';

export interface GetColumnsParams {
  t: TFunction;
  checkPermissions: CheckPermissionsFunction;
  rootUnitId: number | null;
  onEditCategory: (category: ITransactionCategory, rootUnitId: ITransactionGroup['rootUnitId']) => void;
}

export const getCategoryColumns = ({
  t, onEditCategory, rootUnitId, checkPermissions,
}: GetColumnsParams): ColumnsType<ITransactionCategory> => {
  const columns: ColumnsType<ITransactionCategory> = [
    {
      key: 'id',
      dataIndex: 'id',
    },
    {
      key: 'name',
      dataIndex: 'name',
    },
    {
      dataIndex: 'id',
      render: (id) => (
        <RelationLink
          path={Path.USERS_TRANSACTIONS}
          filters={{ 'category.id': id }}
          permissions={[Permission.CLIENT_TRANSACTION_READ]}
        >
          <div className={styles.link}>
            {t('administration.financialManagement.viewTransactions')}
            <ArrowRight className={styles.icon} />
          </div>
        </RelationLink>
      ),
    },
    {
      width: 66,
      dataIndex: '',
      fixed: 'right',
      align: 'center',
      render: (category: ITransactionCategory) => (
        <EditButton
          onClick={() => onEditCategory(category, rootUnitId)}
          disabled={!checkPermissions([Permission.CLIENT_TRANSACTION_CATEGORY_UPDATE]) || category.id === 1}
        />
      ),
    },
  ];

  return columns;
};
