import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { UnknownType } from 'types/Unknown';
import { FilterRelation } from 'components/Filters';
import { Chip } from 'components/Filters/components/Chip';
import useFiltersContext from 'components/Filters/context/FiltersContext';
import styles from './AppliedFiltersList.module.scss';

type AppliedFiltersListProps = {
  applyFilter: (args: {
    filters: Record<string, UnknownType>,
    filterRelation?: FilterRelation,
    visibleFields?: Record<string, boolean>,
  }) => void;
  searchValue?: string;
  onClearSearch?: () => void;
};

const AppliedFiltersList = ({
  applyFilter,
  searchValue,
  onClearSearch,
}: AppliedFiltersListProps) => {
  const { t } = useTranslation();
  const {
    filterFields,
    filters,
  } = useFiltersContext();

  const filtersArray = useMemo(() => Object.entries(filters), [filters]);

  const handleDelete = (key: string) => () => {
    const newFilters = { ...filters };
    delete newFilters[key];

    applyFilter({ filters: newFilters });
  };

  if (filtersArray.length === 0 && !searchValue) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <span className={styles.title}>{t('appliedFilters')} ({filtersArray.length + (searchValue ? 1 : 0)}):</span>
      {searchValue && (
        <Chip
          onDelete={onClearSearch}
        >
          {t('search')}: {searchValue}
        </Chip>
      )}
      {filtersArray.map(([key, value]) => (
        <Chip
          key={key}
          onDelete={handleDelete(key)}
        >
          {filterFields.get(key).label}: {typeof value === 'object' ? moment(value).format('DD.MM.YYYY') : value as string}
        </Chip>
      ))}
    </div>
  );
};

export default AppliedFiltersList;
