import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Empty, Table as AntdTable, TableProps as AntdTableProps } from 'antd';
import { ColumnsType, SorterResult } from 'antd/lib/table/interface';
import clsx from 'clsx';
import { UnknownType } from 'types/Unknown';
import { Sort } from 'hooks';
import styles from './Table.module.scss';

export type TableProps<RecordType extends Record<string, UnknownType>> = AntdTableProps<RecordType> & {
  columns: ColumnsType<RecordType>;
  onSort?: (sort: Sort<UnknownType> | null) => void;
  onRefresh?: () => void;
  rootClassName?: string;
};

const Table = <RecordType extends Record<string, UnknownType>>({
  onRefresh,
  onSort,
  ...props
}: TableProps<RecordType>) => {
  const { t } = useTranslation();

  const handleChange = useCallback((
    _pagination: UnknownType, // Unused: TablePaginationConfig,
    _filters: UnknownType, // Unused: Record<string, FilterValue | null>,
    sorter: SorterResult<RecordType> | SorterResult<RecordType>[],
  ) => {
    if (Array.isArray(sorter)) return;

    const sortResult = sorter.order
      ? { field: sorter.columnKey || sorter.field, order: sorter.order }
      : null;

    if (onSort) onSort(sortResult);
  }, [onSort]);

  const emptyViewJSX = (
    <div className={styles.empty}>
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />

      {onRefresh && (
        <Button onClick={onRefresh} className={styles.refresh}>
          {t('refresh')}
        </Button>
      )}
    </div>
  );

  const rowClassName = (record: RecordType, index: number, indent: number) => {
    const rowClassNameFromProps = typeof props?.rowClassName === 'function'
      ? props.rowClassName(record, index, indent)
      : props.rowClassName || '';

    return clsx(styles.row, rowClassNameFromProps);
  };

  return (
    <div className={clsx(styles.wrapper, props.rootClassName)}>
      <AntdTable {...props}
        onChange={handleChange}
        rowClassName={rowClassName}
        columns={props.dataSource?.length ? props.columns : []}
        showHeader={Boolean(props.dataSource?.length)}
        locale={{ emptyText: emptyViewJSX }}
      />
    </div>
  );
};

export default memo(Table) as typeof Table;
