import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'antd';
import { StarFilled, StarOutlined } from '@ant-design/icons';
import styles from './WalletItem.module.scss';

type FavoriteProps = {
  isFavorite: boolean;
  onToggleFavorite: () => void;
};

const Favorite = ({ isFavorite, onToggleFavorite }: FavoriteProps) => {
  const { t } = useTranslation();
  const FavoriteIcon = isFavorite ? StarFilled : StarOutlined;
  const title = t(isFavorite ? 'favoriteRemove' : 'favoriteAdd');

  return (
    <Tooltip title={title} key={title}>
      <FavoriteIcon
        className={styles.starIcon}
        onClick={onToggleFavorite}
      />
    </Tooltip>
  );
};

export default Favorite;
