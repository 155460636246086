import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Space, Tooltip } from 'antd';
import clsx from 'clsx';
import { IPermission } from '../../interfaces/IPermission';
import { categorySettings } from 'pages/Administration/AdministrationGroups/components/GroupForm/components/PermissionsList/PermissionsList';
import { formatPermissions } from 'pages/Administration/AdministrationGroups/utils/formatPermissions';
import { LockOutlined } from '@ant-design/icons';
import styles from './Permissions.module.scss';

const getColor = (name: string) => {
  const c = name.endsWith('c') && 'green';
  const r = name.endsWith('r') && 'blue';
  const u = name.endsWith('u') && 'gold';
  const d = name.endsWith('d') && 'red';

  return c || r || u || d || 'default';
};

export interface IPermissionsProps {
  permissions: IPermission[];
}

const Permissions = ({ permissions }: IPermissionsProps): ReactElement => {
  const { t } = useTranslation('permissions');

  const areTherePermissions = Boolean(permissions.length);

  const bodyJSX = (
    <span
      className={clsx(styles.permissions, {
        [styles.disabled]: !areTherePermissions,
      })}
    >
      <LockOutlined className={styles.icon} />
      <span>{t('translation:permissions')}</span>
    </span>
  );

  if (!areTherePermissions) {
    return bodyJSX;
  }

  const groupedPermissions = formatPermissions(
    permissions || [],
    categorySettings as any,
  );

  const tooltipContentJSX = (
    <div className={styles.content}>
      {groupedPermissions.map((item, index) => (
        <Space
          key={item?.group}
          direction="vertical"
          style={{ width: '100%' }}
        >
          <h2 style={{ fontSize: 14, fontWeight: 600, marginBottom: 0 }}>
            {t(`permissionsList.${item.group}`)}
          </h2>
          {item.category.map((category: any) => {
            return (
              <Space
                key={category?.name}
                direction="vertical"
                style={{ width: '100%', marginBottom: 0 }}
              >
                <h3
                  style={{
                    color: '#333333a3',
                    fontSize: 12,
                    fontWeight: 600,
                    marginBottom: 0,
                  }}
                >
                  {t(`permissionsList.${category?.name}`)}
                </h3>
                <ul className={styles.permissionsList}>
                  {category?.permissions?.map((perm: any) => (
                    <li
                      key={category?.name + perm?.name}
                      className={styles.permissionsItem}
                    >
                      <span>{t(perm.name)}</span>
                    </li>
                  ))}
                </ul>
              </Space>
            );
          })}
          {groupedPermissions.length - 1 !== index && (
            <Divider
              className={styles.divider}
              style={{ margin: 0, marginTop: '10px', marginBottom: '12px' }}
            />
          )}
        </Space>
      ))}
    </div>
  );

  return (
    <Tooltip
      overlayClassName={styles.tooltip}
      placement="bottom"
      color="transparent"
      arrowPointAtCenter={false}
      overlayInnerStyle={{ padding: 0, backgroundColor: 'transparent' }}
      overlay={tooltipContentJSX}
    >
      {bodyJSX}
    </Tooltip>
  );
};

export default Permissions;
