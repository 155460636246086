import { useEffect } from 'react';
import {
  QueryClient,
  useMutation as useMutationOriginal,
  useQuery as useQueryOriginal,
  UseQueryOptions as UseQueryOptionsOriginal,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';

export type { UseMutateFunction, UseQueryResult, UseMutationOptions } from '@tanstack/react-query';
export { QueryClientProvider, useQueries, keepPreviousData } from '@tanstack/react-query';

export const useMutation = useMutationOriginal;

export type UseQueryOptions<
  TQueryFnData = unknown,
  TError = AxiosError<{ message: string }>,
  TData = TQueryFnData,
> = UseQueryOptionsOriginal<TQueryFnData> & {
  onSuccess?: (data: TData) => void;
  onError?: (e: TError) => void;
};

export const useQuery = <T>(options: UseQueryOptions<T>) => {
  const {
    onSuccess,
    onError,
    ...queryOptions
  } = options;

  const query = useQueryOriginal(queryOptions);

  useEffect(() => {
    if (query.isSuccess && onSuccess) {
      onSuccess(query.data);
    }
  }, [query.isSuccess, query.data, onSuccess]);

  useEffect(() => {
    if (query.isError && onError) {
      onError((query.error || {}) as AxiosError<{ message: string }>);
    }
  }, [query.isError, query.error, onError]);

  return query;
};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      retryOnMount: false,
      retry: false,
    },
  },
});

export const cleverRefetchQueries = async (key: string) => {
  const allCache = queryClient.getQueryCache().getAll();
  const keys = allCache.filter((query) => query.queryKey[0] === key);

  return Promise.all(
    keys.map(({ queryKey }) => queryClient.invalidateQueries({ queryKey })),
  );
};
