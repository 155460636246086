import React from 'react';
import { useTranslation } from 'react-i18next';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import styles from './ConnectionConditions.module.scss';

export type ConnectionConditionsProps = {
  conditions: string[];
};

const ConnectionConditions = ({ conditions }: ConnectionConditionsProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <div className={styles.iconWrapper}>
        <ExclamationCircleOutlined />
      </div>

      <ul className={styles.list}>
        {conditions.map((item, i) => (
          <li key={i} className={styles.item}>
            {t(item)}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ConnectionConditions;
