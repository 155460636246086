import React from 'react';
import { ColumnsType } from 'antd/es/table';
import { TFunction } from 'i18next';
import { IReplenishment } from 'interfaces/IReplenishment';
import { NestedUnitType } from 'interfaces/IUnit';
import { IWallet } from 'types/IWallet';
import { PaymentStatusEnum } from 'types/PaymentStatus';
import { UnknownType } from 'types/Unknown';
import { VerifyButton } from 'pages/Administration/ReplenishmentRequests/components';
import renderEmptyCell from 'utils/renderEmptyCell';
import { checkIfEnoughBalance } from './checkIfEnoughBalance';
import { CountUp, CurrencyNetworkTd, DateTime } from 'components';
import { StatusBadge } from 'components/StatusBadge';
import UpliftRequestButton from '../components/UpliftRequestButton';

export type GetColumnsParams = {
  currentUnit: NestedUnitType | undefined;
  wallets: IWallet[];
  uuid: string | undefined;
  onRefresh: () => void;
  t: TFunction;
  onDecline: (payload: UnknownType) => void;
  openVerifyModal: (user: IReplenishment) => void;
};

const getColumns = ({
  currentUnit,
  wallets,
  uuid,
  t,
  onRefresh,
  onDecline,
  openVerifyModal,
}: GetColumnsParams): ColumnsType<IReplenishment> => {
  const columns: ColumnsType<IReplenishment> = [
    {
      key: 'id',
      title: t('id'),
      dataIndex: 'id',
      width: 80,
    },
    {
      key: 'unitName',
      title: t('users.replenishmentRequests.requesterUnit'),
      dataIndex: 'unit',
      width: 160,
      render: (unit) => (
        <span style={{ textTransform: 'capitalize' }}>{unit.name}</span>
      ),
    },
    {
      key: 'currency',
      title: t('currency'),
      dataIndex: '',
      width: 160,
      render: (replen: IReplenishment) => (
        <CurrencyNetworkTd
          currency={replen.currency}
          network={replen.network}
        />
      ),
    },
    {
      key: 'amount',
      title: t('users.replenishmentRequests.amount'),
      dataIndex: 'amount',
      width: 120,
      render: (amount, row) => <CountUp end={amount} currency={row.currency} />,
    },
    {
      key: 'status',
      title: t('status'),
      dataIndex: 'status',
      width: 160,
      render: (status) => (
        <StatusBadge status={status} textTransform="capitalize" />
      ),
    },
    {
      key: 'walletAddress',
      title: t('users.replenishmentRequests.walletAddress'),
      dataIndex: 'walletAddress',
      width: 160,
    },
    {
      key: 'description',
      title: t('administration.financialManagement.fields.description'),
      dataIndex: 'description',
      width: 160,
      render: renderEmptyCell,
    },
    {
      key: 'createdAt',
      title: t('created'),
      dataIndex: 'createdAt',
      width: 160,
      render: (createdAt) => <DateTime value={createdAt} />,
    },
    {
      key: 'updatedAt',
      title: t('updated'),
      dataIndex: 'updatedAt',
      width: 160,
      render: (updatedAt) => <DateTime value={updatedAt} />,
    },
  ];

  columns.push({
    key: '__extra__',
    dataIndex: '',
    align: 'center',
    fixed: 'right',
    width: 66,
    render: (_, request) => {
      const verifyButton = (
        <VerifyButton
          data={request}
          onDecline={onDecline}
          onRefresh={onRefresh}
          onModalOpen={() => openVerifyModal(request)}
          disabled={!_.unit?.isActive}
        />
      );
      const upliftButton = (
        <UpliftRequestButton
          id={request.id}
          uuid={uuid}
          onRefresh={onRefresh}
        />
      );
      // const emptyBox = <div style={{ width: '20px', height: '50px' }} />;

      const { hasBalance } = checkIfEnoughBalance(wallets, request);
      const isCurrentUnit = _.unit.uuid === uuid;
      const hasParent = !!_.unit.parentId;
      const canUplift = !!currentUnit?.parentId;

      const showApproveButton =
        hasBalance || (hasBalance && isCurrentUnit && !canUplift);
      const showButton =
        request.status === PaymentStatusEnum.PENDING &&
        (!isCurrentUnit || !hasParent);

      const actionButton = showApproveButton ? (
        verifyButton
      ) : canUplift ? (
        upliftButton
      ) : (
        <></>
      );

      return !isCurrentUnit && showButton ? actionButton : <></>;
    },
  });

  return columns;
};

export default getColumns;
