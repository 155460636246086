import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { cleverRefetchQueries } from 'libs/reactQuery';
import { notification } from 'antd';
import useCreateUnit from 'api/unit/useCreateUnit';
import { UnknownType } from 'types/Unknown';
import formUnit from 'pages/Administration/Units/components/FormUnit/FormUnit';
import { StageEnum } from 'hooks/use2FA';
import { OTP_ERROR } from 'constants/index';
import { useAuthorisationContext } from 'contexts';
import { Button } from 'components';
import { ApartmentOutlined } from '@ant-design/icons';
import styles from './CreateUnitButton.module.scss';

export type CreateUnitButtonProps = {
  rootUnitId: number;
  unitId: number;
  disabled?: boolean;
};

const CreateUnitButton = ({ rootUnitId, unitId, disabled }: CreateUnitButtonProps) => {
  const { t } = useTranslation();
  const {
    twoFA: {
      onSuccess: onSuccess2fa,
      onError: onError2fa,
      setStage,
      stage,
    },
  } = useAuthorisationContext();

  const onSuccess = (res: UnknownType) => {
    onSuccess2fa(res, async () => {
      notification.success({ message: t('users.units.created') });
      await cleverRefetchQueries('unit-children');
      formUnit.closeDrawer();
    });
  };

  const onError = useCallback((error: UnknownType) => {
    onError2fa(error, () => {
      if (stage !== StageEnum.DEFAULT && error.response?.data?.message.includes(OTP_ERROR)) {
        setStage(StageEnum.DEFAULT);
      }

      if (error?.response?.status === 413) {
        notification.error({ message: t('business.invalidFileSize') });
      } else {
        notification.error({
          description: error?.response?.data?.message,
          message: error?.response?.status,
        });
      }
    });
  }, [onError2fa, setStage, stage, t]);

  const { mutate: createUnit, isPending: isCreating } = useCreateUnit({ onSuccess, onError });

  useEffect(() => {
    if (formUnit.isOpened()) {
      formUnit.updateDrawer({
        loading: isCreating,
      });
    }
  }, [isCreating]);

  const openCreateModal = useCallback(() => {
    formUnit.openDrawer({
      initialValues: {
        rootUnitId,
        unitId,
      },
      title: t('users.units.createUnit'),
      onSubmit: createUnit,
      loading: isCreating,
    });
  }, [createUnit, isCreating, rootUnitId, t, unitId]);

  return (
    <Button
      type="default"
      textSize="14px"
      disabled={disabled}
      onClick={openCreateModal}
      suffixIcon={<ApartmentOutlined style={{ fontSize: 16 }} />}
      className={styles.button}
    >
      {t('users.units.createChild')}
    </Button>
  );
};

export default CreateUnitButton;
