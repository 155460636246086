import React from 'react';
import ReactDOM from 'react-dom';
import { TruncatedTooltip } from 'components';
import { HEADER_CONTENT_ELEMENT_ID, HEADER_TITLE_ELEMENT_ID } from 'components/Header';

export type HeaderPortalProps = {
  title?: string;
  content?: React.ReactNode;
};

const HeaderPortal = ({ title, content }: HeaderPortalProps) => {
  const headerTitleElement = document.getElementById(HEADER_TITLE_ELEMENT_ID);
  const headerContentElement = document.getElementById(HEADER_CONTENT_ELEMENT_ID);

  return (
    <>
      {title && headerTitleElement && (
        ReactDOM.createPortal(
          <TruncatedTooltip text={title} max={32} />,
          headerTitleElement,
        )
      )}
      {content && headerContentElement && (
        ReactDOM.createPortal(
          content,
          headerContentElement,
        )
      )}
    </>
  );
};

export default HeaderPortal;
