import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { IActiveSession } from 'interfaces/IActiveSession';
import { DateTime } from 'components';
import { StopOutlined } from '@ant-design/icons';

export type UseGetColumnsParams = {
  currentSessionId: string;
  onRemoveSession: (sessionId: string) => void;
};

export const useGetColumns = ({
  currentSessionId,
  onRemoveSession,
}: UseGetColumnsParams): ColumnsType<IActiveSession> => {
  const { t } = useTranslation();

  const columns: ColumnsType<IActiveSession> = [
    {
      key: 'metadata.os.name',
      title: t('myAccount.columns.os'),
      dataIndex: 'metadata',
      render: (metadata) => metadata?.os?.name,
    },
    {
      key: 'metadata.ip',
      title: t('myAccount.columns.ipAddress'),
      dataIndex: 'metadata',
      render: (metadata) => metadata?.ip,
    },
    {
      key: 'metadata.browser.name',
      title: t('myAccount.columns.browser'),
      dataIndex: 'metadata',
      render: (metadata) => metadata?.browser?.name,
    },
    {
      key: 'sessionId',
      title: t('myAccount.columns.sessionId'),
      dataIndex: 'sessionId',
    },
    {
      key: 'updatedAt',
      title: t('myAccount.columns.lastSession'),
      dataIndex: 'updatedAt',
      render: (date) => <DateTime value={date} />,
    },
  ];

  columns.push({
    key: 'removeSession',
    dataIndex: '',
    width: '20px',
    render: (session: IActiveSession) =>
      session.sessionId !== currentSessionId && (
        <Button
          danger
          type="link"
          icon={<StopOutlined style={{ fontSize: '20px' }} />}
          onClick={() => onRemoveSession(session.sessionId)}
        />
      ),
  });

  return columns;
};
