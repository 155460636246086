import axios, { SERVICE } from 'libs/axios';
import { useQuery } from 'libs/reactQuery';
import { Client } from 'api/client/useGetClients';

const useGetClientByUuid = (uuid: string) => {
  const getClientByUuid = async () => {
    const { data } = await axios.get(`${SERVICE.CLIENT}/clients/${uuid}`);
    return data;
  };

  return useQuery<Client>({
    queryFn: getClientByUuid,
    queryKey: ['client', uuid],
    enabled: Boolean(uuid),
  });
};

export default useGetClientByUuid;
