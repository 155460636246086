import axios, { SERVICE } from 'libs/axios';
import { useQuery } from 'libs/reactQuery';
import { IBusiness } from 'interfaces/IBusiness';

export const useBusinessById = (id: number | null, staleTime?: number) => {
  const getBusinessById = async () => {
    const { data } = await axios.get(`${SERVICE.ADMIN}/root-units/${id}`);
    return data;
  };

  return useQuery<IBusiness>({
    queryKey: ['business', id],
    queryFn: getBusinessById,
    staleTime,
    enabled: !!id,
  });
};
