import { useState } from 'react';
import { Filter } from 'types/Filter';
import { convertFiltersToObject } from '../components/Filters/utils';
import { useTablePagination } from './useTablePagination';
import { Sort, useTableSort } from './useTableSort';
import { FilterField } from 'components/Filters';
import { PerPageNumber } from 'components/Pagination';
import { FilterRelation, useInitialFilters } from '../components/Filters';

export type UseTableFeaturesProps<SortField extends string> = {
  defaultSort?: Sort<SortField>;
  defaultPerPageOptions?: PerPageNumber[];
  allowedToSortFields?: SortField[];
  filterFields: Map<string, FilterField>;
};

export type UseTableFeaturesResult<I, SortField extends string> = {
  sort: Sort<SortField> | null;
  setSort: (value: Sort<SortField> | null) => void;
  filter: Filter<I>,
  setFilter: React.Dispatch<React.SetStateAction<Filter<I>>>,
  filters: Record<string, unknown>,
  filterRelation: FilterRelation,
  page: number;
  perPage: PerPageNumber;
  setPage: (value: number) => void;
  setPerPage: (value: PerPageNumber) => void;
};

const useTableFeatures = <I, SortField extends string>({
  defaultSort,
  defaultPerPageOptions,
  allowedToSortFields = [],
  filterFields,
}: UseTableFeaturesProps<SortField>): UseTableFeaturesResult<I, SortField> => {
  const { filterRelation, filters, searchValue } = useInitialFilters(filterFields);
  const [filter, setFilter] = useState<Filter<I>>(
    convertFiltersToObject(filterFields, filterRelation, filters, searchValue),
  );
  const { sort, setSort } = useTableSort<SortField>({
    defaultSort,
    allowedFields: allowedToSortFields,
  });

  const {
    page,
    perPage,
    setPage,
    setPerPage,
  } = useTablePagination({
    resetDependencies: [filter],
    allowedPerPageOptions: defaultPerPageOptions,
  });

  return {
    sort,
    setSort,
    filter,
    setFilter,
    filters,
    filterRelation,
    page,
    perPage,
    setPage,
    setPerPage,
  };
};

export default useTableFeatures;
