import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RiAddLine, RiFileCopyLine, RiInformationLine } from '@remixicon/react';
import { FormInstance, Tooltip, Typography } from 'antd';
import clsx from 'clsx';
import { IBusiness } from 'interfaces/IBusiness';
import { CurrencyEnum } from 'types/Currency';
import { UnknownType } from 'types/Unknown';
import { truncateNumber } from 'utils';
import { ExpirationCard } from '../ExpirationCard';
import { SubscriptionDetailsCard } from '../SubscriptionDetailsCard';
import { TopUpFromUnitTab } from '../TopUpFromUnitTab';
import { TransactionsTable } from '../TransactionsTable';
import { Button, CopyToClipboard, CurrencyBadge } from 'components';
import AlertBusinessExpired from 'components/AlertBusinessExpired/AlertBusinessExpired';
import styles from './BillingTab.module.scss';

type BillingTabProps = {
  business: IBusiness;
  onActiveTopUpTab: (status: boolean) => void;
  topUpForm: FormInstance<UnknownType>
  onSubmit: (values: UnknownType) => void;
};

const BillingTab = ({
  business,
  onActiveTopUpTab,
  topUpForm,
  onSubmit,
}: BillingTabProps) => {
  const { t } = useTranslation();
  const [isFirstTab, setIsFirstTab] = useState(true);

  const handleToggleTab = (status: boolean) => {
    setIsFirstTab(status);
    onActiveTopUpTab(!status);
  };

  return (
    <>
      {business?.isExpired && (
        <AlertBusinessExpired withButton={false} style={{ margin: 0 }} />
      )}
      <div className={clsx(styles.contentWrapper, {
        [styles.firstTab]: isFirstTab,
        [styles.secondTab]: !isFirstTab,
      })}>
        <div className={styles.wrapper}>
          <Typography.Title level={5} className={styles.headTitle}>
            {t('business.subscription.subscriptionDetails')}
          </Typography.Title>

          <div className={styles.card}>
            <Typography.Text className={styles.cardTitle}>
              {t('business.subscription.subscriptionWalletBalance')}
            </Typography.Text>
            {business.balance && (
              <Typography.Title level={4} className={styles.walletBalance}>
                {truncateNumber({ number: business.balance, currency: CurrencyEnum.USDT })}
                {' '} USDT
              </Typography.Title>
            )}
            {business.subscriptionWalletAddress && (
              <div className={styles.card}>
                <div className={styles.inlineFlex}>
                  <Typography.Text className={styles.cardTitle}>
                    {t('business.subscription.topUpAddress')}
                    <Tooltip title={t('business.subscription.topUpAddressDescription')}>
                      <RiInformationLine size={16} />
                    </Tooltip>
                  </Typography.Text>
                  <div className={styles.currencyBadge}>
                    <CurrencyBadge currency="USDT" withoutText />
                    <Typography.Text>
                      USDT <span className={styles.network}>TRC20</span>
                    </Typography.Text>
                  </div>
                </div>
                <Typography.Text className={styles.address}>
                  {business.subscriptionWalletAddress}
                  <CopyToClipboard text={business.subscriptionWalletAddress}>
                    <RiFileCopyLine size={16} style={{ cursor: 'pointer', color: 'var(--black-45-percent-color)' }} />
                  </CopyToClipboard>
                </Typography.Text>
              </div>
            )}

            {business.subscriptionWalletAddress && (
              <Button
                type="default"
                suffixIcon={<RiAddLine />}
                className={styles.topUpBtn}
                onClick={() => handleToggleTab(false)}
              >
                {t('business.subscription.topUpFromUnit')}
              </Button>
            )}
          </div>
          <div className={styles.inlineFlex}>
            <SubscriptionDetailsCard business={business} />
            <ExpirationCard business={business} />
          </div>
          <Typography.Title
            level={5}
            className={styles.headTitle}
            style={{ marginTop: '8px !important' }}
          >
            {t('transactions')}
          </Typography.Title>
          <TransactionsTable business={business} />
        </div>
        <div className={styles.wrapper}>
          <TopUpFromUnitTab
            onBack={() => handleToggleTab(true)}
            business={business}
            form={topUpForm}
            onSubmit={onSubmit}
          />
        </div>
      </div>
    </>
  );
};

export default BillingTab;
