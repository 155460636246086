import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import axios, { SERVICE } from 'libs/axios';
import { notification } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { useAuthorisationContext } from 'contexts';

export const useUploadAvatar = () => {
  const { t } = useTranslation();
  const { refreshUser } = useAuthorisationContext();

  const uploadAvatar = async (file: RcFile) => {
    const formData = new FormData();
    formData.append('file', file);

    const response = await axios.patch(SERVICE.ADMIN + '/users/avatar', formData);
    return response.data;
  };

  return useMutation({
    mutationFn: uploadAvatar,
    mutationKey: ['Upload Avatar'],
    onSuccess: () => {
      notification.success({
        message: t('file.uploadedAvatar'),
      });
      refreshUser();
    },
    onError: () => notification.error({ message: t('file.errorUploadAvatar') }),
  });
};

export const useDeleteAvatar = () => {
  const { t } = useTranslation();
  const { refreshUser } = useAuthorisationContext();

  const deleteAvatar = async () => {
    return axios.delete(SERVICE.ADMIN + '/users/avatar');
  };

  return useMutation({
    mutationFn: deleteAvatar,
    mutationKey: ['Delete Avatar'],
    onSuccess: () => {
      notification.success({ message: t('file.deletedAvatar') });
      refreshUser();
    },
    onError: () => notification.error({ message: t('file.errorDeletedAvatar') }),
  });
};
