const getSubscriptionStatusProps = (
  isExpired: boolean,
  isSubscriptionNotExpired: boolean,
) => {
  if (isExpired) {
    return { color: 'red', text: 'business.subscription.expired' };
  }

  if (isSubscriptionNotExpired) {
    return { color: 'green', text: 'active' };
  }

  return { color: 'orange', text: 'business.subscription.gracePeriod' };
};

export default getSubscriptionStatusProps;
