import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Image as AntdImage, notification, Upload } from 'antd';
import { RcFile } from 'antd/lib/upload';
import clsx from 'clsx';
import { ImageStatusEnum, useCheckImage } from 'hooks';
import { BASE_IMAGE_URL } from 'constants/image';
import { CloudUploadOutlined } from '@ant-design/icons';
import styles from './UploadLogo.module.scss';

const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

export type UploadLogoProps = {
  id?: string;
  shape?: 'circle' | 'square',
  value?: RcFile | string | null;
  onChange?: (fileImage: RcFile) => void;
};

const UploadLogo = ({
  id,
  shape = 'circle',
  value,
  onChange,
}: UploadLogoProps) => {
  const [fileImageUrl, setFileImageUrl] = useState<string>();
  const { t } = useTranslation();

  const status = useCheckImage({ src: value && typeof value === 'string' ? value : '' });

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      notification.error({ message: t('business.invalidFileType') });
      return false;
    }
    const isLt1MB = file.size / (1024 * 1024) < 1;
    if (!isLt1MB) {
      notification.error({ message: t('business.invalidFileSize') });
      return false;
    }

    getBase64(file, url => setFileImageUrl(url));
    if (onChange) onChange(file);

    return false;
  };

  useEffect(() => {
    setFileImageUrl(undefined);
  }, [value]);

  const initialImageUrl = useMemo(() => {
    return status === ImageStatusEnum.SUCCESS
      ? value && `${BASE_IMAGE_URL}${value}`
      : fileImageUrl;
  }, [fileImageUrl, status, value]);

  return (
    <Upload
      id={id}
      name="avatar"
      listType="picture-card"
      accept="image/png, image/jpeg"
      showUploadList={false}
      beforeUpload={beforeUpload}
      // openFileDialogOnClick={!initialImageUrl}
      className={clsx(styles.upload, {
        [styles.circle]: shape === 'circle',
        [styles.square]: shape === 'square',
      })}
    >
      {initialImageUrl
        ? <AntdImage src={initialImageUrl} alt="logo" preview={false} />
        : <CloudUploadOutlined className={styles.uploadIcon} />}
    </Upload>
  );
};

export default UploadLogo;
