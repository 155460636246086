import React, { ReactElement, useMemo } from 'react';
import { Button, Tooltip } from 'antd';
import clsx from 'clsx';
import { truncateString } from 'utils';
import { CopyToClipboard } from 'components/CopyToClipboard';
import { CopyOutlined } from '@ant-design/icons';
import styles from './WalletAddress.module.scss';

type WalletAddressType = {
  walletAddress: string;
  className?: string;
  endLength?: number;
  startLength?: number;
  textPlacement?: 'left' | 'right';
};

const WalletAddress = ({ walletAddress, className, endLength = 8, startLength = 8, textPlacement = 'right' }: WalletAddressType): ReactElement => {
  const AddressComponent = useMemo(() => {
    const truncatedAddress = walletAddress && truncateString(walletAddress, { type: 'center', endLength, startLength });

    return (
      <Tooltip title={walletAddress}>
        <span>{truncatedAddress}</span>
      </Tooltip>
    );
  }, [endLength, startLength, walletAddress]);

  if (!walletAddress) {
    return (
      <span className={styles.null}>
        {String(null)}
      </span>
    );
  }

  return (
    <div className={clsx(styles.address, className)}>

      {textPlacement === 'left' && (AddressComponent)}

      <CopyToClipboard text={walletAddress}>
        <Button
          type="text"
          size="middle"
          icon={<CopyOutlined className={styles.copyIcon} />}
          className={styles.copyBtn}
        />
      </CopyToClipboard>

      {textPlacement === 'right' && (AddressComponent)}
    </div>
  );
};

export default WalletAddress;
