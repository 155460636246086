import React from 'react';
import { Switch } from 'antd';
import styles from './SwitchItem.module.scss';

export type SwitchItemProps = {
  label: string;
  value?: boolean;
  onChange?: (value?: boolean) => void;
  checkedLabel?: React.ReactNode;
  uncheckedLabel?: React.ReactNode;
};

const SwitchItem = ({
  label,
  value,
  onChange,
  checkedLabel,
  uncheckedLabel,
}: SwitchItemProps) => {
  return (
    <div className={styles.wrapper}>
      <span className={styles.label}>{label}</span>

      <div className={styles.switchContainer}>
        <span
          className={styles.stateLabel}
          style={{
            color: value ? undefined : '#CF1322',
          }}
        >
          {uncheckedLabel}
        </span>

        <Switch checked={value} onChange={onChange} />

        <span
          className={styles.stateLabel}
          style={{
            color: value ? '#389E0D' : undefined,
          }}
        >
          {checkedLabel}
        </span>

      </div>
    </div>
  );
};

export default SwitchItem;
