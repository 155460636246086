import enAntdLocale from 'antd/lib/locale/en_GB';
import uaAntdLocale from 'antd/lib/locale/uk_UA';
import enDateFnsLocale from 'date-fns/locale/en-US';
import uaDateFnsLocale from 'date-fns/locale/uk';
import { Language, LanguageLocales } from './types';
import 'moment/locale/en-gb';
import 'moment/locale/uk';
import enFlagImage from 'assets/images/flags/en.png';
import uaFlagImage from 'assets/images/flags/ua.png';

// Flags images: https://icons8.ru/icon/set/%D1%84%D0%BB%D0%B0%D0%B3%D0%B8/color

const locales: LanguageLocales = {
  [Language.UA]: {
    antdLocale: uaAntdLocale,
    dateFnsLocale: uaDateFnsLocale,
    image: uaFlagImage,
  },
  [Language.EN]: {
    antdLocale: enAntdLocale,
    dateFnsLocale: enDateFnsLocale,
    image: enFlagImage,
  },
};

export default locales;
