import React, { HTMLAttributeAnchorTarget, ReactElement, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Permission } from '@fanckler/processing-auth';
import { Path } from 'routes/interfaces/Path';
import { AuthorisationContext } from 'contexts';

export type RelationLinkProps = {
  path: Path | string;
  permissions?: Permission[];
  filters?: Record<string, unknown>;
  target?: HTMLAttributeAnchorTarget,
  children: number | string | ReactElement | ReactElement[];
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
};

const RelationLink = ({
  path,
  target,
  filters,
  children,
  permissions,
  onClick,
}: RelationLinkProps): ReactElement => {
  const { checkPermissions } = useContext(AuthorisationContext);

  if (permissions && !checkPermissions(permissions)) {
    return children as ReactElement;
  }

  const query = filters ? `?filters=${JSON.stringify(filters)}` : '';

  return (
    <Link
      to={`${path}${query}`}
      target={target}
      style={{ cursor: 'pointer' }}
      onClick={onClick}
    >
      {children}
    </Link>
  );
};

export default RelationLink;
