import { Moment } from 'moment';

export enum NotificationStatus {
  ALL = 'all',
  READ = 'read',
  UNREAD = 'unread',
  ARCHIVE = 'archive',
}

export enum NotificationType {
  ALL = 'ALL',
  INFO = 'INFO',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
}

export type Filters = {
  status: NotificationStatus;
  types: NotificationType[];
  date: [Moment, Moment];
};

export type Totals = Record<NotificationStatus, number>;
