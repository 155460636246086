import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Permission } from '@fanckler/processing-auth';
import { Segmented } from 'antd';
import { UnknownType } from 'types/Unknown';
import { useAuthorisationContext } from 'contexts';
import { SubaccountsTab, TransactionsTab } from './tabs';
import styles from './ClientTabs.module.scss';

enum ClientTab {
  TRANSACTIONS,
  SUBACCOUNTS,
}

type ClientTabsProps = {
  clientUUID: string
};

const ClientTabs = ({ clientUUID }: ClientTabsProps) => {
  const { checkPermissions } = useAuthorisationContext();
  const { t } = useTranslation();

  const [tab, setTab] = useState<ClientTab>(ClientTab.TRANSACTIONS);

  const canSubaccountsRead = checkPermissions([Permission.CLIENT_SUB_WALLET_READ]);

  const options = useMemo(() => [
    { value: ClientTab.TRANSACTIONS, label: t('transactions') },
    (canSubaccountsRead && { value: ClientTab.SUBACCOUNTS, label: t('subaccount.many') }),
  ].filter(Boolean), [canSubaccountsRead, t]);

  return (
    <div className={styles.wrapper}>
      <Segmented
        value={tab}
        onChange={value => setTab(value as ClientTab)}
        options={options as UnknownType}
        className={styles.segmented}
      />

      <div className={styles.content}>
        {tab === ClientTab.TRANSACTIONS ? (
          <TransactionsTab clientUUID={clientUUID} />
        ) : (
          <SubaccountsTab clientUUID={clientUUID} />
        )}
      </div>
    </div>
  );
};

export default ClientTabs;
