import { UseQueryOptions } from 'libs/reactQuery';
import { BaseEntity, QueryParams, QueryResult } from 'api/types';

export enum NotificationType {
  INFO = 'INFO',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
}

export type Notification = BaseEntity & {
  userId: string;
  title: string;
  description: string;
  isAlert: boolean;
  payload: { [key: string]: unknown };
  type: NotificationType;
  deletedAt: string | null;
  readAt: string | null;
};

export type NotificationQueryParams = QueryParams<Notification>;

export type NotificationQueryOptions = Partial<UseQueryOptions<NotificationQueryResult>>;

export type NotificationQueryResult = QueryResult<Notification> & {
  totalReadCount: number;
  totalUnreadCount: number;
  totalArchiveCount: number;
};
