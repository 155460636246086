import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Layout } from 'antd';
import clsx from 'clsx';
import { AppState } from 'init/rootReducer';
import Header from 'components/Header';
import Sidebar from 'components/Sidebar';
import styles from './CommonLayout.module.scss';

export interface ICommonLayoutProps {
  withHeader?: boolean;
  withSidebar?: boolean;
  component: () => ReactElement;
}

const CommonLayout = ({
  withHeader,
  withSidebar,
  component: Component,
}: ICommonLayoutProps) => {
  const collapsed = useSelector((state: AppState) => state.sidebarReducer.collapsed);

  return (
    <Layout className={clsx(styles.layout, { [styles.sidebarCollapsed]: withSidebar && collapsed })}>
      {withSidebar && <Sidebar />}
      <Layout className={styles.layoutSite}>
        {withHeader && <Header />}
        <Layout.Content>
          <div className={clsx(styles.layoutContent, { [styles.layoutWithSidebar]: withSidebar })}>
            <Component />
          </div>
        </Layout.Content>
      </Layout>
    </Layout>
  );
};

export default CommonLayout;
