import React, { ReactElement, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';
import { Button, Card, Typography } from 'antd';
import clsx from 'clsx';
import { Path } from 'routes/interfaces/Path';
import { truncateString } from 'utils';
import { Modal } from 'components/Modal';
import {
  ArrowLeftOutlined,
  CopyOutlined,
  QrcodeOutlined,
} from '@ant-design/icons';
import styles from './LinkForPayment.module.scss';

export type LinkForPaymentProps = {
  path: Path;
  uuid: string;
  wallet?: string;
};

export const LinkForPayment = ({ path, uuid, wallet }: LinkForPaymentProps): ReactElement => {
  const [showQR, setShowQR] = useState(false);
  const { t } = useTranslation();

  const { Title, Text } = Typography;

  const link = useMemo(() => `${path}/${uuid}`, [path, uuid]);

  const hostname = useMemo(() => window.location.origin, []);

  const truncatedAddress = wallet && truncateString(wallet, {
    type: 'center',
    startLength: 9,
    endLength: 9,
  });

  const copyButtonJSX = truncatedAddress && (
    <Button
      type="ghost"
      onClick={() => navigator.clipboard.writeText(wallet)}
      icon={<CopyOutlined />}
      className={clsx(styles.button, styles.copy)}
    />
  );

  const qrCodeButtonJSX = (
    <Button
      type="ghost"
      onClick={() => setShowQR(true)}
      icon={<QrcodeOutlined />}
      className={clsx(styles.button, styles.qrCode)}
    />
  );

  const openLinkButtonJSX = (
    <Link
      to={link}
      target="_blank"
      className={styles.openButton}
    >
      {t('open')}
    </Link>
  );

  return (
    <>
      <Card className={styles.card}>
        {truncatedAddress && (
          <div className={styles.meta}>
            <Title className={styles.title}>{t('wallet')}</Title>
            <Text className={styles.linkText}>{truncatedAddress}</Text>
          </div>
        )}
        <div className={styles.buttons}>
          {copyButtonJSX}
          {qrCodeButtonJSX}
          {openLinkButtonJSX}
        </div>
      </Card>

      <Modal
        width={450}
        destroyOnClose
        isOpen={showQR}
        onClose={() => setShowQR(false)}
        title={t('QR.scan')}
      >
        <div className={styles.qrForm}>
          <QRCode
            className={styles.qrCode}
            value={`${hostname}${link}`}
          />

          <div className={styles.rightInfo}>
            <Typography.Text className={styles.text}>
              {t('QR.scanAndPress')}
            </Typography.Text>

            <div className={styles.buttons}>
              <Button
                type="primary"
                onClick={() => setShowQR(false)}
                className={styles.button}
              >
                {t('continue')}
              </Button>

              <Button
                type="ghost"
                onClick={() => setShowQR(false)}
                icon={<ArrowLeftOutlined className={styles.icon} />}
                className={styles.backButton}
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
