import React from 'react';
import { useTranslation } from 'react-i18next';
import { registerDrawer, SharedDrawerProps } from '@nuvalt/ui-kit';
import { IInvoice } from 'interfaces';
import { Path } from 'routes/interfaces/Path';
import { useConfirmRootAdmin } from 'hooks/useConfirmRootAdmin';
import { InvoiceView } from '../InvoiceView';
import { Drawer, DrawerContent, DrawerHeader } from 'components';
import { LinkForPayment } from 'components/CreateInvoice/components/LinkForPayment';

type InvoiceViewDrawerProps = {
  invoice: IInvoice;
} & SharedDrawerProps;

const InvoiceViewDrawer = ({
  invoice,
  closeDrawer,
}: InvoiceViewDrawerProps) => {
  const { t } = useTranslation();
  const { isRoot } = useConfirmRootAdmin();

  return (
    <Drawer>
      <DrawerHeader title={t('invoice.information')} onClose={closeDrawer} />
      <DrawerContent>
        {isRoot && (
          <LinkForPayment
            path={Path.INVOICES}
            uuid={invoice.uuid as string}
            wallet={invoice.wallets?.length ? invoice.wallets[0]?.walletAddress : ''}
          />
        )}

        <InvoiceView invoice={invoice} />
      </DrawerContent>
    </Drawer>
  );
};

export default registerDrawer(InvoiceViewDrawer, {
  id: 'invoiceViewDrawer',
});
