import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tag, Typography } from 'antd';
import clsx from 'clsx';
import { UnknownType } from 'types/Unknown';
import { RightOutlined } from '@ant-design/icons';
import styles from './ConnectionMethodButton.module.scss';

export type ConnectionMethodButtonProps = {
  title: string;
  icon: UnknownType;
  comingSoon?: boolean;
  action: () => void;
};

const ConnectionMethodButton = ({
  title,
  icon: Icon,
  comingSoon,
  action,
}: ConnectionMethodButtonProps) => {
  const { t } = useTranslation();

  return (
    <div
      onClick={comingSoon ? undefined : action}
      className={clsx(styles.wrapper, { [styles.comingSoon]: comingSoon })}
    >
      <div className={styles.iconWrapper}>
        <Icon />
      </div>

      <Typography.Text className={styles.title}>{title}</Typography.Text>

      {comingSoon ? (
        <Tag color="green" className={styles.badge}>{t('comingSoon')}</Tag>
      ) : (
        <RightOutlined className={styles.arrow} />
      )}
    </div>
  );
};

export default ConnectionMethodButton;
