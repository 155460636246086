import React, { useMemo } from 'react';
import { Dropdown, MenuProps } from 'antd';
import { Language } from 'i18n';
import { useLocale } from 'contexts';
import { UpOutlined } from '@ant-design/icons';
import styles from './LanguageDropdown.module.scss';

const LocaleDropdown = () => {
  const { changeLanguage, localeData, locales } = useLocale();

  const items: MenuProps['items'] = useMemo(() => (
    Object.entries(locales).map(([lng, { image }]) => ({
      key: lng,
      className: styles.option,
      label: (
        <div
          className={styles.language}
          onClick={() => changeLanguage(lng as Language)}
        >
          <img
            src={image}
            className={styles.icon}
          />
          <span className={styles.locale}>{lng}</span>
        </div>
      ),
    }))
  ), [changeLanguage, locales]);

  return (
    <Dropdown
      placement="bottomRight"
      menu={{ items, className: styles.menu }}
    >
      <div className={styles.wrapper}>
        <img
          src={localeData.image}
          className={styles.icon}
        />
        <div className={styles.languageWithArrow}>
          <span className={styles.language}>{localeData.lng}</span>
          <UpOutlined className={styles.arrow} />
        </div>
      </div>
    </Dropdown>
  );
};

export default LocaleDropdown;
