import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { cleverRefetchQueries } from 'libs/reactQuery';
import { notification } from 'antd';
import { useAddWhitelistWallet } from '../../hooks';
import { addWhitelistWalletForm } from '../AddWhitelistWalletForm';
import { Button } from 'components';
import { WalletOutlined } from '@ant-design/icons';
import styles from './AddWhitelistWalletButton.module.scss';

export type AddWhitelistWalletButtonProps = {
  uuid: string,
  disabled?: boolean;
};

const AddWhitelistWalletButton = ({ uuid, disabled }: AddWhitelistWalletButtonProps) => {
  const { t } = useTranslation();

  const { mutate, isPending } = useAddWhitelistWallet(uuid, {
    onSuccess: async () => {
      await cleverRefetchQueries('whitelist');
      addWhitelistWalletForm.closeDrawer();

      notification.success({
        message: t('coldStorage.successfullyAdded'),
        key: 'wallet-created',
      });
    },
    onError: () => {
      notification.error({
        message: t('coldStorage.cannotAdded'),
        key: 'wallet-created',
      });
    },
  });

  const handleOpen = () => {
    addWhitelistWalletForm.openDrawer({
      onSubmit: mutate,
      loading: isPending,
    });
  };

  useEffect(() => {
    if (addWhitelistWalletForm.isOpened()) {
      addWhitelistWalletForm.updateDrawer({
        loading: isPending,
      });
    }
  }, [isPending]);

  return (
    <Button
      type="default"
      textSize="14px"
      disabled={disabled}
      onClick={handleOpen}
      suffixIcon={<WalletOutlined style={{ fontSize: 16 }} />}
      className={styles.button}
    >
      {t('whitelist.addButton')}
    </Button>
  );
};

export default AddWhitelistWalletButton;
