import { useTranslation } from 'react-i18next';
import { Image, Select } from 'antd';
import { UnknownType } from 'types/Unknown';
import { CryptoCurrency } from 'components/ColdStorage/assets/crypto/types';
import getCryptoCurrencyIcon from 'components/ColdStorage/utils/getIcon';
import type { StepProps } from '../../AddAccounts';
import { Button } from 'components/Button';
import cryptoCurrencies from 'components/ColdStorage/assets/crypto/currencies';
import styles from './StepChooseCurrency.module.scss';

const LEDGER_CURRENCIES: CryptoCurrency[] = [
  cryptoCurrencies.ethereum,
  cryptoCurrencies.bitcoin,
  cryptoCurrencies.tron,
  cryptoCurrencies.bsc,
];

const StepChooseCurrency = ({ currency, setCurrency }: StepProps) => {
  const { t } = useTranslation();

  const makeSelectOptions = (currencies: CryptoCurrency[]) => {
    return currencies.map(item => ({
      data: item,
      value: item.id,
      label: (
        <div className={styles.option}>
          <Image
            preview={false}
            src={getCryptoCurrencyIcon(item as CryptoCurrency)}
            key={`option-icon-${(item as CryptoCurrency).id}`}
            className={styles.icon}
          />

          <span className={styles.label}>{item.name}</span>
        </div>
      ),
    }));
  };

  return (
    <div className={styles.body}>
      <Select
        value={currency?.id}
        onChange={(_, option: UnknownType) => setCurrency(option.data)}
        options={makeSelectOptions(LEDGER_CURRENCIES)}
        placeholder={t('coldStorage.chooseCurrency')}
        popupClassName={styles.selectPopup}
        className={styles.select}
      />
    </div>
  );
};

export default StepChooseCurrency;

export const StepChooseCurrencyFooter = ({
  transitionTo,
  currency,
}: StepProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.footer}>
      <div className={styles.currencyBadge}>
        {currency ? (
          <>
            <Image
              width={30}
              preview={false}
              key={`selected-icon-${currency.id}`}
              src={getCryptoCurrencyIcon(currency)}
            />

            <div className={styles.currencyName}>
              <span className={styles.ticker}>{currency.ticker}</span>
              <span className={styles.name}>{currency.managerAppName}</span>
            </div>
          </>
        ) : (
          <span className={styles.placeholder}>{t('coldStorage.chooseCurrency')}</span>
        )}
      </div>

      <Button
        disabled={!currency}
        onClick={() => transitionTo('connectDevice')}
        className={styles.button}
      >
        {t('continue')}
      </Button>
    </div>
  );
};
