import React, { useContext, useId, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Permission } from '@fanckler/processing-auth';
import { Dropdown } from 'antd';
import { AuthorisationContext } from 'contexts';
import { Button } from 'components';
import { MoreOutlined, UploadOutlined, VerticalAlignBottomOutlined } from '@ant-design/icons';
import styles from './ActionDropdown.module.scss';

type ActionDropdownProps = {
  disabled?: boolean;
  isLoading?: boolean;
  isFirstLvlUnit: boolean;
  isWalletsEmpty: boolean;
  onMassPayout: () => void;
  onRequestReplanishment: () => void;
};

const ActionDropdown = ({
  disabled,
  isLoading,
  isFirstLvlUnit,
  isWalletsEmpty,
  onMassPayout,
  onRequestReplanishment,
}: ActionDropdownProps) => {
  const { checkPermissions } = useContext(AuthorisationContext);
  const { t } = useTranslation();
  const buttonId = useId();

  const items = useMemo(() => (
    [
      {
        key: 'mass-po',
        icon: <UploadOutlined />,
        label: t('processing.paymentOrders.massPayout'),
        disabled: !checkPermissions([Permission.CLIENT_PAYMENT_ORDER_CREATE]),
        onClick: onMassPayout,
      },
      {
        key: 'request-replenishment',
        icon: <VerticalAlignBottomOutlined />,
        label: t('replanishment.requestReplanishment'),
        disabled: !checkPermissions([Permission.CLIENT_REPLENISHMENT_REQUEST_CREATE]) || isFirstLvlUnit,
        onClick: onRequestReplanishment,
      },
    ].filter(item => !item?.disabled)
  ), [checkPermissions, isFirstLvlUnit, onMassPayout, onRequestReplanishment, t]);

  const isItemsEmpty = useMemo(() => !items.length, [items.length]);

  return (
    <Dropdown
      arrow
      trigger={['click']}
      placement="bottomRight"
      align={{ offset: [0, 0] }}
      menu={{ items }}
      disabled={disabled || isItemsEmpty || (isWalletsEmpty && !isLoading)}
      getPopupContainer={() => document.getElementById(buttonId) as HTMLElement}
      overlayClassName={styles.dropdown}
    >
      <Button id={buttonId} type="link" suffixIcon={<MoreOutlined />}>
        {t('more')}
      </Button>
    </Dropdown>
  );
};

export default ActionDropdown;
