import React, { MutableRefObject, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { registerDrawer, SharedDrawerProps } from '@nuvalt/ui-kit';
import { Form, Input, Switch } from 'antd';
import { Client } from 'api/client/useGetClients';
import useUpdateClient from 'api/client/useUpdateClient';
import clsx from 'clsx';
import { Button, Drawer, DrawerContent, DrawerFooter, DrawerHeader } from 'components';
import styles from './ClientEditDrawer.module.scss';

type EditClientFormData = {
  name: string;
  isExternalParam: boolean;
};

export type ClientEditDrawerProps = {
  initialValues: Client;
  onRefreshOne: () => void;
  isChangedRef: MutableRefObject<boolean>;
} & SharedDrawerProps;

const ClientEditDrawer = ({
  initialValues,
  onRefreshOne,
  isChangedRef,
  closeDrawer,
  drawerId,
}: ClientEditDrawerProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const { mutate, isPending } = useUpdateClient({
    onSuccess: () => {
      isChangedRef.current = true;
      onRefreshOne();

      if (closeDrawer) {
        closeDrawer();
      }
    },
  });

  const handleFinish = useCallback((formData: EditClientFormData) => {
    mutate({ uuid: initialValues.uuid, body: formData });
  }, [initialValues.uuid, mutate]);

  // const handleRefreshLabels = useCallback(() => {
  //   isChangedRef.current = true;
  //   onRefreshOne();
  // }, [isChangedRef, onRefreshOne]);

  return (
    <Drawer id={drawerId}>
      <DrawerHeader title={t('client.edit')} onClose={closeDrawer} />
      <DrawerContent>
        <Form
          form={form}
          initialValues={{ ...initialValues }}
          onFinish={handleFinish}
          className={styles.form}
        >
          <Form.Item
            name="name"
            label={t('name')}
            rules={[{ max: 100 }, { required: true }]}
            className={styles.formItem}
          >
            <Input placeholder={t('name')} className={styles.input} />
          </Form.Item>

          {/* <ClientLabels
            clientUUID={initialValues.uuid}
            labels={initialValues.labels}
            onRefresh={handleRefreshLabels}
            editable
          /> */}

          <Form.Item
            name="isExternalParam"
            label={t('client.externalParam')}
            valuePropName="checked"
            className={clsx(styles.formItem, styles.switchFormItem)}
          >
            <Switch />
          </Form.Item>
        </Form>
      </DrawerContent>
      <DrawerFooter>
        <Button
          block
          loading={isPending}
          onClick={form.submit}
        >
          {t('save')}
        </Button>

        <Button
          block
          type="link"
          color="error"
          onClick={closeDrawer}
        >
          {t('cancel')}
        </Button>
      </DrawerFooter>
    </Drawer>
  );
};

export default registerDrawer(ClientEditDrawer, {
  id: 'ClientEditDrawer',
});
