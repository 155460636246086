import axios, { SERVICE } from 'libs/axios';
import { useMutation } from 'libs/reactQuery';
import { MutationOptions } from './types';

const useArchiveNotification = (mutationOptions?: MutationOptions) => {
  const archiveNotification = async (body: { ids: number[] }) => {
    const url = `${SERVICE.NOTIFICATION}/notifications/archive`;
    const { data } = await axios.post(url, body);
    return data;
  };

  return useMutation({ mutationFn: archiveNotification, ...mutationOptions });
};

export default useArchiveNotification;
