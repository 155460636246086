import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'antd';
import clsx from 'clsx';
import { IUser } from 'interfaces/IUser';
import { Chip } from 'components/UserAccount/components/Chip';
import { EyeOutlined, RightOutlined } from '@ant-design/icons';
import styles from './AssignedUnitsList.module.scss';

type AssignedUnitsListProps = {
  units: IUser['units'];
  showFullList?: boolean;
  limit?: number;
  onClick?: (unit: IUser['units'][0]) => void;
  className?: string;
};

const DEFAULT_LIMIT = 5;

const AssignedUnitsList = ({
  units,
  showFullList = false,
  limit = DEFAULT_LIMIT,
  onClick,
  className,
}: AssignedUnitsListProps) => {
  const { t } = useTranslation();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const shouldRenderShowMoreButton = Boolean(!showFullList && units.length > limit);
  const listToRender = showFullList ? units : units.slice(0, limit - (shouldRenderShowMoreButton ? 1 : 0));
  const otherUnits = units.slice(limit - 1);

  return (
    <div className={clsx(styles.assignedUnitsList, className)}>
      {(listToRender).map((unit) => (
        <Chip
          key={unit.id}
          onClick={() => onClick && onClick(unit)}
        >
          {unit.name}
        </Chip>
      ))}
      {shouldRenderShowMoreButton && (
        <Dropdown
          trigger={['click']}
          align={{ offset: [0, 8] }}
          placement="bottomCenter"
          open={isDropdownOpen}
          onOpenChange={setIsDropdownOpen}
          menu={{
            items: [
              {
                key: 'header',
                label: (
                  <span>{otherUnits.length} {t('assignedUnits.units')}:</span>
                ),
                className: styles.dropdownTitle,
              },
              ...otherUnits.map((unit) => ({
                key: unit.id.toString(),
                label: <>{unit.name} <RightOutlined /></>,
                onClick: () => {
                  if (onClick) {
                    onClick(unit);
                  }
                  setIsDropdownOpen(false);
                },
                className: styles.dropdownItem,
              })),
            ],
          }}
        >
          <Chip
            isActive={isDropdownOpen}
          >
            <EyeOutlined /> {t('assignedUnits.view')} {otherUnits.length} {t('assignedUnits.units')}
          </Chip>

        </Dropdown>
      )}
    </div>
  );
};

export default AssignedUnitsList;
