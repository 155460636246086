import axios, { SERVICE } from 'libs/axios';
import { useMutation } from 'libs/reactQuery';
import { MutationOptions } from './types';

const resetCounters = async ({ uuid }: { uuid: string }) => {
  const { data } = await axios.put(`${SERVICE.ADMIN}/users/${uuid}/reset`);
  return data;
};

const useResetUserCounters = (mutationOptions?: MutationOptions) => {
  return useMutation({
    mutationFn: resetCounters,
    ...mutationOptions,
  });
};

export default useResetUserCounters;
