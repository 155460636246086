import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, Form, FormInstance, Input, Space } from 'antd';
import { ISMTP } from 'interfaces/ISMTP';
import { POSITIVE_WHOLE_NUMBER } from 'constants/validation';
import SmtpStatus from 'components/SmtpSettings/components/SMTPStatus/SMTPStatus';
import styles from './FormSMTPSettings.module.scss';

export type FormSMTPSettingsPropsType = {
  initialValues: ISMTP | null;
  setConnected: Dispatch<SetStateAction<boolean>>
  form: FormInstance;
  isConnected: boolean;
  isLoading: boolean;
  isError: boolean;
  onReload: () => void;
};

const FormSMTPSettings = ({
  initialValues,
  setConnected,
  form,
  isConnected,
  isLoading,
  isError,
  onReload,
}: FormSMTPSettingsPropsType) => {
  const { t } = useTranslation();

  return (
    <Form
      form={form}
      name="smtp-settings"
      onValuesChange={() => setConnected(false)}
      className={styles.form}
      initialValues={initialValues || undefined}
    >
      <Space.Compact className={styles.inputsWrapper}>
        <Form.Item
          name="host"
          className={styles.formItem}
          rules={[{ required: true }, { min: 5 }]}
        >
          <Input
            placeholder={t('SMTP.fields.server')}
            className={styles.input}
          />
        </Form.Item>

        <Form.Item
          name="port"
          className={styles.formItem}
          rules={[{ required: true }, { pattern: POSITIVE_WHOLE_NUMBER }]}
        >
          <Input
            placeholder={t('SMTP.fields.port')}
            className={styles.input}
          />
        </Form.Item>
      </Space.Compact>

      <Space.Compact className={styles.inputsWrapper}>
        <Form.Item
          name="email"
          className={styles.formItem}
          rules={[{ required: true }, { type: 'email' }]}
        >
          <Input
            placeholder={t('SMTP.fields.user')}
            className={styles.input}
          />
        </Form.Item>

        <Form.Item
          name="password"
          className={styles.formItem}
          rules={[{ required: true }]}
        >
          <Input.Password
            autoComplete="new-password"
            placeholder={t('SMTP.fields.password')}
            className={styles.input}
          />
        </Form.Item>
      </Space.Compact>
      <Space.Compact className={styles.inputsWrapper}>
        <Form.Item name="secure" valuePropName="checked" className={styles.formItem}>
          <Checkbox name="SSL">{t('SMTP.fields.SSL')}</Checkbox>
        </Form.Item>
        <SmtpStatus
          isConnected={isConnected}
          onReload={onReload}
          isLoading={isLoading}
          isError={isError}
        />
      </Space.Compact>

    </Form>
  );
};

export default FormSMTPSettings;
