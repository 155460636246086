import { TFunction } from 'i18next';
import { IStatus } from '../../interfaces/IStatus';
import { TransactionStatusEnum } from 'types/TransactionStatus';
import { ColorEnum } from '../../types';
import {
  HourglassOutlined,
  LoadingOutlined,
  MehOutlined,
  SmileOutlined,
} from '@ant-design/icons';
import {
  ReactComponent as SmileSadOutlined,
} from 'assets/icons/SmileSadOutlined.svg';

export const getTxStatuses = (t: TFunction): IStatus[] => [
  {
    status: TransactionStatusEnum.CREATED,
    color: ColorEnum.IDLE,
    icon: MehOutlined,
  },
  {
    status: TransactionStatusEnum.SUBMITED,
    color: ColorEnum.IDLE,
    icon: MehOutlined,
  },
  {
    status: TransactionStatusEnum.COMPLETED,
    color: ColorEnum.SUCCESS,
    icon: SmileOutlined,
  },
  {
    status: TransactionStatusEnum.CANCELED,
    color: ColorEnum.ERROR,
    icon: SmileSadOutlined,
  },
  {
    status: TransactionStatusEnum.REJECTED,
    color: ColorEnum.ERROR,
    icon: SmileSadOutlined,
  },
  {
    status: TransactionStatusEnum.IN_FINALIZATION,
    color: ColorEnum.PENDING,
    icon: LoadingOutlined,
  },
  {
    status: TransactionStatusEnum.UNDER_REVIEW,
    color: ColorEnum.PENDING,
    icon: HourglassOutlined,
  },
  {
    status: TransactionStatusEnum.PENDING_APPROVAL,
    color: ColorEnum.PENDING,
    icon: HourglassOutlined,
  },
  {
    status: TransactionStatusEnum.APPROVED,
    color: ColorEnum.SUCCESS,
    icon: SmileOutlined,
  },
  {
    status: TransactionStatusEnum.SUSPENDED,
    color: ColorEnum.IDLE,
    icon: MehOutlined,
  },
  {
    status: TransactionStatusEnum.PENDING,
    color: ColorEnum.PENDING,
    icon: HourglassOutlined,
  },
  {
    status: TransactionStatusEnum.IN_PROCESS,
    title: 'In process',
    color: ColorEnum.PENDING,
    icon: LoadingOutlined,
  },
];
