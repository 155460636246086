import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Permission } from '@fanckler/processing-auth';
import useGetSubaccountsByUuid from 'api/client/useGetSubaccountsByUuid';
import useTableColumns from 'hooks/useTableColumns';
import { useAuthorisationContext } from 'contexts';
import { LocalStorageKey } from 'utils/localStorage';
import { getColumns, getFilterFields } from './utils';
import { Filters, Pagination } from 'components';
import DraggableTable from 'components/DraggableTable';
import { withFilters } from 'components/Filters';
import useFiltersContext from 'components/Filters/context/FiltersContext';
import { AddSubaccountButton } from './components';
import styles from './SubaccountsTab.module.scss';

export type SubaccountsTabProps = {
  clientUUID: string;
};

const SubaccountsTab = ({ clientUUID }: SubaccountsTabProps) => {
  const { checkPermissions } = useAuthorisationContext();
  const { t } = useTranslation();

  const {
    filter,
    page,
    perPage,
    setPage,
    setPerPage,
  } = useFiltersContext();

  const { data, isLoading, refetch } = useGetSubaccountsByUuid({
    paging: { limit: perPage, offset: (page - 1) * perPage },
    sorting: [{ field: 'createdAt', direction: 'DESC' }],
    filter,
    uuid: clientUUID,
  });

  useEffect(() => {
    refetch();
  }, [page, perPage, refetch]);

  const subaccounts = data?.nodes || [];
  const totalCount = data?.totalCount || 0;

  const initialColumns = useMemo(() => getColumns({ checkPermissions, t }), [checkPermissions, t]);

  const { columns, setOrder } = useTableColumns({
    storageKey: LocalStorageKey.USERS_CLIENTS_SUBACCOUNTS_COLUMNS,
    initialColumns,
  });

  return (
    <div className={styles.wrapper}>
      <Filters columns={columns} className={styles.filters}>
        {checkPermissions([Permission.CLIENT_SUB_WALLET_CREATE]) && (
          <AddSubaccountButton clientUUID={clientUUID} />
        )}
      </Filters>
      <DraggableTable
        setOrder={setOrder}
        onRefresh={refetch}
        dataSource={subaccounts}
        rootClassName={styles.tableWrapper}
        loading={isLoading}
        columns={columns}
        scroll={{ x: 'max-content' }}
        pagination={false}
        rowKey="id"
      />
      <Pagination
        page={page}
        perPage={perPage}
        onPageChange={setPage}
        totalCount={totalCount}
        onPerPageChange={setPerPage}
        disablePageChange={isLoading || !totalCount}
        disablePerPageHandler={isLoading || !totalCount}
        elementsSize="xs"
      />
    </div>
  );
};

export default withFilters(SubaccountsTab, {
  getFilterFieldsFn: getFilterFields,
});
