import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Permission } from '@fanckler/processing-auth';
import { RiCheckboxCircleFill, RiCloseCircleFill } from '@remixicon/react';
import { Form, FormInstance, Input } from 'antd';
import { IBusiness } from 'interfaces/IBusiness';
import { UnknownType } from 'types/Unknown';
import { POSITIVE_WHOLE_NUMBER } from 'constants/validation';
import { useAuthorisationContext } from 'contexts';
import { OrganizationalLogoUpload } from 'components';
import SmtpSettings from 'components/SmtpSettings/SmtpSettings';
import { ApiItem, SwitchItem } from 'drawers/root-unit/FormEditBusiness/components/index';
import styles from 'drawers/root-unit/FormEditBusiness/FormEditBusiness.module.scss';

type SettingsTabProps = {
  form: FormInstance;
  onSubmit: (values: FormData) => void;
  business: IBusiness;
};

type BusinessFormData = {
  id?: number;
  name: string;
  logo?: string;
  postbackUrl?: string;
  is2faRequired?: boolean;
  isIpRequired?: boolean;
  isActive?: boolean;
  activeSessionCount?: string;
  authFailedCount?: string;
};

const SettingsTab = ({
  form,
  onSubmit,
  business,
}: SettingsTabProps) => {
  const { t } = useTranslation();
  const { checkPermissions } = useAuthorisationContext();
  const [changedValues, setChangedValues] = useState<string[]>([]);

  const isLogoChanged = useMemo(() => changedValues.includes('logo'), [changedValues]);

  const handleFinish = useCallback((values: BusinessFormData) => {
    const formData = new FormData();

    if (values.logo && isLogoChanged) {
      formData.set('file', values.logo);
    }
    if (!values.logo && isLogoChanged) {
      formData.set('isRemoveLogo', 'true');
    }
    if (values.name?.trim()) {
      formData.set('name', values.name.trim());
    }
    if (values.postbackUrl) {
      formData.set('postbackUrl', values.postbackUrl);
    }
    if (values.isActive !== undefined) {
      formData.set('isActive', values.isActive.toString());
    }
    if (values.isIpRequired !== undefined) {
      formData.set('isIpRequired', values.isIpRequired.toString());
    }
    if (values.is2faRequired !== undefined) {
      formData.set('is2faRequired', values.is2faRequired.toString());
    }
    if (values.activeSessionCount !== undefined) {
      formData.set('activeSessionCount', values.activeSessionCount);
    }
    if (values.authFailedCount !== undefined) {
      formData.set('authFailedCount', values.authFailedCount);
    }

    onSubmit(formData);
  }, [isLogoChanged, onSubmit]);

  const handleValuesChange = useCallback((values: UnknownType) => {
    const [changedValueKey] = Object.keys(values);

    if (changedValues.includes(changedValueKey)) return;

    setChangedValues(prevState => [...prevState, changedValueKey]);
  }, [changedValues]);

  return (
    <>
      <Form
        name="bussiness-form"
        form={form}
        onFinish={handleFinish}
        initialValues={business}
        onValuesChange={handleValuesChange}
        className={styles.form}
      >
        <Form.Item name="logo"><OrganizationalLogoUpload type="business" /></Form.Item>

        <div className={styles.formItems}>
          <Form.Item
            name="name"
            required
            label={t('business.name')}
            className={styles.formItem}
            rules={[
              { min: 1, max: 100 },
              { required: true, message: t('business.pleaseEnterName') },
            ]}
          >
            <Input placeholder={t('business.enterName')} className={styles.input} />
          </Form.Item>

          <Form.Item
            name="postbackUrl"
            label={t('postbackUrl')}
            className={styles.formItem}
            rules={[{ type: 'url' }]}
          >
            <Input placeholder={t('postbackUrlPlaceholder')} className={styles.input} />
          </Form.Item>

          {business.apiKey && (
            <ApiItem apiKey={business.apiKey} />
          )}

          <SmtpSettings
            smtp={business.smtp}
            rootUnitId={business.id}
          />
          <Form.Item name="is2faRequired" style={{ marginBottom: 0 }}>
            <SwitchItem label={t('business.twoFactorAuthRequired')} />
          </Form.Item>
          <Form.Item name="isIpRequired" style={{ marginBottom: 0 }}>
            <SwitchItem label={t('business.ipAddressConfirmation')} />
          </Form.Item>
          {business.id && checkPermissions([Permission.ADMIN_IAM_ROOT_UNIT_ACTIVATE]) && (
            <Form.Item name="isActive" style={{ marginBottom: 0 }}>
              <SwitchItem
                label={t('business.activationStatus')}
                checkedLabel={(
                  <>
                    {t('activated')}
                    <RiCheckboxCircleFill size={16} />
                  </>
                )}
                uncheckedLabel={(
                  <>
                    <RiCloseCircleFill size={16} />
                    {t('blocked')}
                  </>
                )}
              />
            </Form.Item>
          )}

          <div className={styles.alignItems}>
            <Form.Item
              name="activeSessionCount"
              label={t('business.activeSessions')}
              rules={[{ required: true, pattern: POSITIVE_WHOLE_NUMBER }]}
              className={styles.formItem}
            >
              <Input className={styles.input} />
            </Form.Item>

            <Form.Item
              name="authFailedCount"
              label={t('business.maximumFailedAuths')}
              rules={[{ required: true, pattern: POSITIVE_WHOLE_NUMBER }]}
              className={styles.formItem}
            >
              <Input className={styles.input} />
            </Form.Item>
          </div>
        </div>
      </Form>
    </>
  );
};

export default SettingsTab;
