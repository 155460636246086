import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input, notification } from 'antd';
import { IWallet } from 'types/IWallet';
import { UnknownType } from 'types/Unknown';
import { formatCurrencyAmount, truncateNumber } from 'utils';
import internalValidateAmount from './validateAmountInput';
import { Button, CountUp } from 'components';
import styles from '../../FormWithdraw.module.scss';

type AmountInputProps = {
  isDisabled: boolean;
  onChangeAmount: (value: string) => void;
  amount: string;
  form: UnknownType;
  withSetMaxButton?: boolean;
  setAmountValid: (value: boolean) => void;
  isWithdraw: boolean;
  currency: string;
  network: string;
  wallets: IWallet[];
  walletBalance: (wallets: UnknownType, currency: string) => number;
  payway: string | null;
  getCommission: (balance: string) => Promise<UnknownType>;
};

const CHANGE_AMOUNT_TIMEOUT = 1000;

const AmountInput = ({
  isDisabled,
  amount,
  onChangeAmount,
  form,
  withSetMaxButton,
  setAmountValid,
  isWithdraw,
  currency,
  network,
  wallets,
  walletBalance,
  payway,
  getCommission,
}: AmountInputProps) => {
  const [internalAmount, setInternalAmount] = useState(amount);
  const { t } = useTranslation();
  const debounceTimeout = useRef<NodeJS.Timeout | null>(null);

  const onSetMaxAmount = useCallback(
    async () => {
      if (!payway) {
        form.setFields([{ name: 'amount', errors: [t('processing.pleaseSelectPayway')] }]);
        setAmountValid(false);
        return;
      }
      form.validateFields(['walletAddress']);

      const [cr, pw]: string[] = payway.split('|');
      const w = wallets.find(
        (wallet) => wallet.network === pw && wallet.currency === cr,
      );
      const maxBalance = formatCurrencyAmount(w?.balance || 0, cr, true);

      try {
        const { serviceFee, error } = await getCommission(maxBalance);

        if (error) {
          return notification.error({
            message: error,
          });
        }

        const maxAmount = Number(maxBalance) - Number(serviceFee);
        if (maxAmount <= 0) {
          setInternalAmount('');
          onChangeAmount('');
          form.setFields([{ name: 'amount', value: '', errors: [t('processing.justNotEnoughFunds')] }]);
          notification.close('justNotEnoughFunds');
          return notification.error({
            message: t('processing.justNotEnoughFunds'),
            key: 'justNotEnoughFunds',
          });
        }
        const truncatedMaxAmount = truncateNumber({
          number: maxAmount,
          currency,
          asString: true,
        });
        setInternalAmount(String(truncatedMaxAmount));
        onChangeAmount(String(truncatedMaxAmount));
        form.setFields([{ name: 'amount', value: String(truncatedMaxAmount), errors: [] }]);
        form.validateFields(['amount']);
        setAmountValid(true);
      } catch (e) {
        form.setFields([{ name: 'amount', value: maxBalance, errors: [] }]);
        setAmountValid(true);
      }
    },
    [payway, form, wallets, getCommission, currency, onChangeAmount, setAmountValid, t],
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.replace(',', '.');
    setInternalAmount(value);

    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => {
      onChangeAmount(value);
    }, CHANGE_AMOUNT_TIMEOUT);
  };

  useEffect(() => {
    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
  }, []);

  const amountValidator = useCallback(async (_: unknown, value: string) => {
    try {
      const response = await internalValidateAmount({
        value,
        currency,
        network,
        wallets,
        isWithdraw,
        t,
        form,
      });
      setAmountValid(true);
      return await Promise.resolve(response);
    } catch (error) {
      setAmountValid(false);
      return Promise.reject(error);
    }
  }, [currency, form, isWithdraw, network, setAmountValid, t, wallets]);

  const conversationAmount = useMemo(() => {
    return walletBalance([{ currency, balance: internalAmount }], 'USDT');
  }, [currency, internalAmount, walletBalance]);

  return (
    <Form.Item
      required
      name="amount"
      label={t('processing.paymentOrders.amount')}
      className={styles.formItem}
      rules={[{ validator: amountValidator }]}
    >
      <div style={{ position: 'relative' }}>
        {isWithdraw && conversationAmount > 0 && (
          <div className={styles.conversionContainer}>
            <CountUp
              prefix="≈ "
              isRoundDown
              suffix=" USDT"
              currency="USDT"
              end={conversationAmount}
            />
          </div>
        )}
        {withSetMaxButton && (
          <Button
            type="link"
            withoutBgShadow
            withoutWaveEffect
            disabled={isDisabled}
            onClick={onSetMaxAmount}
            className={styles.maxButton}
          >
            Max
          </Button>
        )}

        <Input
          value={internalAmount}
          onChange={handleChange}
          onBlur={() => form.validateFields(['walletAddress'])}
          placeholder={t('processing.paymentOrders.amount')}
          disabled={isDisabled}
          className={styles.input}
        />
      </div>
    </Form.Item>
  );
};

export default AmountInput;
