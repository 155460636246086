import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import HttpBackend from 'i18next-http-backend';
import { LocaleNamespace } from './types';
import CustomLanguageDetector from './CustomLanguageDetector';
import getSupportedLocales from './getSupportedLocales';

export const supportedLocales = getSupportedLocales();
const supportedLanguages = Object.keys(supportedLocales);

const languageDetector = new CustomLanguageDetector(supportedLanguages);

const initI18n = () => {
  i18n
    .use(HttpBackend)
    .use(languageDetector)
    .use(initReactI18next)
    .init({
      fallbackLng: supportedLanguages[0],
      supportedLngs: supportedLanguages,
      ns: ['translation', 'permissions', 'faq'] as LocaleNamespace[],
      defaultNS: 'translation' as LocaleNamespace,
      backend: {
        loadPath: '/locales/{{lng}}/{{ns}}.json',
      },
      detection: {
        order: ['localStorage', 'navigator'],
        caches: ['localStorage'],
      },
      interpolation: {
        escapeValue: false,
      },
      debug: false,
    });
};

export default initI18n;
