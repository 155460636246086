import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { notification } from 'antd';
import useTransferFundsInvoice from 'api/invoice/useTransferFundsInvoice';
import { IInvoice } from 'interfaces/IInvoice';
import { useTablePagination } from 'hooks';
import { useConfirmRootAdmin } from 'hooks/useConfirmRootAdmin';
import { useManualTransfer } from 'hooks/useManualTransfer';
import useTableColumns, { filterByDisplayed } from 'hooks/useTableColumns';
import { useInvoicesByUnit } from './hooks';
import { useColdStorageHardwareId } from 'components/ColdStorage/hooks';
import { useAuthorisationContext } from 'contexts';
import { LocalStorageKey } from 'utils/localStorage';
import { formatExpiryTime, getColumns } from './utils';
import { Pagination, transferFundsForm } from 'components';
import { invoiceViewDrawer } from 'components/CreateInvoice';
import DraggableTable from 'components/DraggableTable';
import styles from './InvoicesTab.module.scss';

export type InvoicesTabProps = {
  uuid: string | undefined;
};

const InvoicesTab = ({ uuid }: InvoicesTabProps) => {
  const { twoFA: { onSuccess, onError } } = useAuthorisationContext();
  const hardwareId = useColdStorageHardwareId();
  const { isRoot } = useConfirmRootAdmin();
  const { t } = useTranslation();

  const { page, perPage, setPage, setPerPage } = useTablePagination({
    withoutURL: true,
  });

  const { data, isLoading, refetch } = useInvoicesByUnit(uuid, {
    paging: { limit: perPage, offset: (page - 1) * perPage },
    sorting: [{ field: 'createdAt', direction: 'DESC' }],
    filter: { and: [{ hardwareId: { [hardwareId === null ? 'is' : 'eq']: `${hardwareId}` } }] },
  });

  const { mutate: onManuallyTransfer } = useManualTransfer();

  const { mutate, isPending } = useTransferFundsInvoice({
    onSuccess: (res) => {
      onSuccess(res, () => {
        transferFundsForm.closeDrawer();
        refetch();
      });
    },
    onError: (error) => {
      onError(error, () => {
        notification.error({
          message: error.message,
          description: error.response?.data.message || t('somethingWrong'),
        });
      });
    },
  });

  const onOpenTransferFundsDrawer = useCallback(() => {
    // transferFundsForm.openDrawer({
    //   loading: isPending,
    //   onSubmit: mutate,
    //   wallets,
    // });
  }, [isPending, mutate]);

  useEffect(() => {
    if (transferFundsForm.isOpened()) {
      transferFundsForm.updateDrawer({
        loading: isPending,
      });
    }
  }, [isPending]);

  const onViewInvoice = useCallback((invoice: IInvoice) => {
    invoiceViewDrawer.openDrawer({
      invoice: {
        ...invoice,
        expirationAt: formatExpiryTime(invoice.createdAt, invoice.expirationAt),
      },
    });
  }, []);

  const invoices = data?.nodes || [];
  const totalCount = data?.totalCount || 0;

  const initialColumns = useMemo(() => (
    getColumns({
      t,
      isRoot,
      onViewInvoice,
      onOpenTransferFundsDrawer: onManuallyTransfer,
    })
  ), [t, isRoot, onViewInvoice, onManuallyTransfer]);

  const { columns, setOrder, displayed } = useTableColumns({
    storageKey: LocalStorageKey.USERS_INVOICES_COLUMNS,
    initialColumns,
  });

  return (
    <div className={styles.wrapper}>
      <DraggableTable
        setOrder={setOrder}
        onRefresh={refetch}
        dataSource={invoices}
        rootClassName={styles.tableWrapper}
        loading={isLoading}
        columns={filterByDisplayed(columns, displayed)}
        scroll={{ x: 'max-content' }}
        pagination={false}
        rowKey="id"
      />
      <Pagination
        totalCount={totalCount}
        page={page}
        perPage={perPage}
        onPageChange={setPage}
        onPerPageChange={setPerPage}
        disablePageChange={isLoading || !totalCount}
        disablePerPageHandler={isLoading || !totalCount}
        elementsSize="xs"
      />
    </div>
  );
};

export default InvoicesTab;
