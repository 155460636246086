import { differenceInDays, differenceInSeconds, format } from 'date-fns';
import { TFunction } from 'i18next';

export const isSubscriptionActive = (expirationDate: Date | null) => {
  return expirationDate && differenceInSeconds(expirationDate, new Date()) > 0;
};

export const isGracePeriodActive = (gracePeriodEndDate: Date | null) => {
  return gracePeriodEndDate && differenceInSeconds(gracePeriodEndDate, new Date()) > 0;
};

export const getDisplayDate = (
  expirationDate: Date | null,
  gracePeriodEndDate: Date | null,
) => {
  if (expirationDate && isSubscriptionActive(expirationDate)) return format(expirationDate, 'MMM dd, yyyy');
  if (gracePeriodEndDate && isGracePeriodActive(gracePeriodEndDate)) return format(gracePeriodEndDate, 'MMM dd, yyyy');
  return null;
};

const getDaysLeft = (
  expirationDate: Date | null,
  gracePeriodEndDate: Date | null,
) => {
  if (expirationDate && isSubscriptionActive(expirationDate)) return differenceInDays(expirationDate, new Date());
  if (gracePeriodEndDate && isGracePeriodActive(gracePeriodEndDate)) return differenceInDays(gracePeriodEndDate, new Date());
  return null;
};

export const getStatusText = (t: TFunction, expirationDate: Date | null) => {
  return isSubscriptionActive(expirationDate)
    ? t('business.subscription.renewAt')
    : t('business.subscription.gracePeriodTill');
};

export const getDaysLeftText = (
  t: TFunction,
  expirationDate: Date | null,
  gracePeriodEndDate: Date | null,
) => {
  const daysLeft = getDaysLeft(expirationDate, gracePeriodEndDate);
  return daysLeft
    ? `${daysLeft} ${t('business.subscription.daysLeft')}`
    : t('business.subscription.expired');
};
