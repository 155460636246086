import { TFunction } from 'i18next';
import { IStatus } from '../../interfaces/IStatus';
import { ColorEnum } from '../../types';
import { SmileOutlined } from '@ant-design/icons';
import { ReactComponent as SmileSadOutlined } from 'assets/icons/SmileSadOutlined.svg';

export const getSMTPStatuses = (t: TFunction): IStatus[] => [
  {
    status: 'connected',
    title: t('SMTP.status.connected'),
    color: ColorEnum.SUCCESS,
    icon: SmileOutlined,
  },
  {
    status: 'not_connected',
    title: t('SMTP.status.notConnected'),
    color: ColorEnum.ERROR,
    icon: SmileSadOutlined,
  },
];
