import { useTranslation } from 'react-i18next';
import axios, { SERVICE } from 'libs/axios';
import { useMutation } from 'libs/reactQuery';
import { notification } from 'antd';
import { MutationOptions } from './types';

const useTransferFundsInvoice = (mutationOptions?: MutationOptions) => {
  const { t } = useTranslation();

  const mutationFn = async (uuid: string) => {
    const url = `${SERVICE.CLIENT}/invoices/funds/transfer/${uuid}`;

    const { data } = await axios.post(url);
    return data;
  };

  return useMutation({
    ...mutationOptions,
    mutationFn,
    onSuccess(data, variables, context) {
      notification.success({ message: t('invoice.successfullyDebitted') });

      if (mutationOptions?.onSuccess) {
        mutationOptions.onSuccess(data, variables, context);
      }
    },
    onError(error, variables, context) {
      if (mutationOptions?.onError) {
        mutationOptions.onError(error, variables, context);
      }
    },
  });
};

export default useTransferFundsInvoice;
