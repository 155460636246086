import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, CheckboxProps, Drawer, Space } from 'antd';
import useArchiveNotification from 'api/notification/useArchiveNotification';
import useGetNotifications, { Notification } from 'api/notification/useGetNotifications';
import moment from 'moment';
import { AppState } from 'init/rootReducer';
import {
  Filters,
  NotificationStatus,
  NotificationType,
  Totals,
} from 'components/Notifications/types';
import { useTablePagination } from 'hooks';
import { SocketContext } from 'contexts';
import getNotificationParams from 'components/Notifications/utils/getNotificationParams';
import getSortedNotifications from 'components/Notifications/utils/getSortedNotifications';
import { Button } from 'components';
import NotificationItem from 'components/Notifications/components/NotificationItem';
import NotificationList from 'components/Notifications/components/NotificationList';
import NotificationView from 'components/Notifications/components/NotificationView';
import QuickFilters from 'components/Notifications/components/QuickStatusSegmented';
import RangeDatePicker from 'components/Notifications/components/RangeDatePicker';
import TypeSelect from 'components/Notifications/components/TypeSelect';
import { setNotificationFilters, setNotificationPayload } from 'components/Notifications/redux/actions';
import { CloseOutlined, InboxOutlined } from '@ant-design/icons';
import { ReactComponent as ArrowRight } from 'assets/icons/ArrowRight.svg';
import styles from './NotificationsDrawer.module.scss';

const initialFilters: Filters = {
  status: NotificationStatus.ALL,
  types: [NotificationType.ALL],
  date: [
    moment().subtract(1, 'weeks').set('h', 0).set('m', 0).set('s', 0).set('ms', 0),
    moment().set('h', 23).set('m', 59).set('s', 59).set('ms', 999),
  ],
};

const initialTotals: Totals = {
  all: 0,
  read: 0,
  unread: 0,
  archive: 0,
};

const NotificationsDrawer = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const [openData, setOpenData] = useState<Notification | null>(null);
  const [filters, setFilters] = useState<Filters>(initialFilters);
  const [checkedList, setCheckedList] = useState<number[]>([]);
  const [totals, setTotals] = useState<Totals>(initialTotals);

  const {
    payload,
    filters: relationFilters,
  } = useSelector((state: AppState) => state.notificationReducer);

  const { socket } = useContext(SocketContext);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    setOpenData(payload);
  }, [payload]);

  const {
    page,
    setPage,
    perPage,
    setPerPage,
  } = useTablePagination({
    allowedPerPageOptions: [10, 15, 25],
    resetDependencies: [filters],
    withoutURL: true,
  });

  const {
    data,
    refetch,
    isLoading,
  } = useGetNotifications(getNotificationParams({ ...filters, page, perPage }));

  const { mutate: archivate } = useArchiveNotification({
    onSuccess: () => {
      setCheckedList([]);
      refetch();
    },
  });

  useEffect(() => {
    if (data) {
      setTotals({
        all: data.totalReadCount + data.totalUnreadCount,
        read: data.totalReadCount,
        unread: data.totalUnreadCount,
        archive: data.totalArchiveCount,
      });
    }
  }, [data]);

  useEffect(() => {
    if (socket) {
      socket.on('notification', async () => {
        await refetch();
      });
    }
  }, [socket, refetch]);

  useEffect(() => {
    setFilters(prevFilters => ({ ...prevFilters, ...relationFilters }));
  }, [relationFilters]);

  const handleChangeFilters = (
    key: keyof Filters,
    value: Filters[keyof Filters],
  ) => {
    if (relationFilters) dispatch(setNotificationFilters(null));

    setFilters(
      prevFilters => ({ ...prevFilters, [key]: value }),
    );
  };

  const nodes = data?.nodes || [];

  const checkAll = nodes.length > 0 && nodes.length === checkedList.length;
  const indeterminate = checkedList.length > 0 && checkedList.length < nodes.length;

  const onCheckAllChange: CheckboxProps['onChange'] = (e) => {
    setCheckedList(e.target.checked ? nodes.map(item => item.id) : []);
  };

  const { todayList, laterList } = getSortedNotifications(nodes);

  const handleBack = () => {
    setOpenData(null);

    if (payload) {
      dispatch(setNotificationPayload(null));
    }
  };

  return (
    <Drawer
      width={600}
      open={isOpen}
      onClose={onClose}
      destroyOnClose
      className={styles.drawer}
      headerStyle={{ display: 'none' }}
    >
      <Space.Compact direction="vertical" className={styles.header}>
        <Space style={{ justifyContent: 'space-between' }}>
          {!openData ? (
            <QuickFilters
              totals={totals}
              value={filters.status}
              onChange={value => handleChangeFilters('status', value)}
            />
          ) : (
            <Button
              type="link"
              withoutBgShadow
              withoutWaveEffect
              onClick={handleBack}
              className={styles.backButton}
              prefixIcon={<ArrowRight className={styles.icon} />}
            >
              {t('back')}
            </Button>
          )}

          <Button
            onClick={onClose}
            prefixIcon={<CloseOutlined />}
            className={styles.closeButton}
          />
        </Space>

        {!openData && (
          <Space style={{ justifyContent: 'space-between' }}>
            <Checkbox
              indeterminate={indeterminate}
              onChange={onCheckAllChange}
              checked={checkAll}
              disabled={!data?.nodes?.length || filters.status === NotificationStatus.ARCHIVE}
              className={styles.checkbox}
            />

            <Space>
              {Boolean(checkedList.length) && (
                <Button
                  type="link"
                  withoutBgShadow
                  withoutWaveEffect
                  suffixIcon={<InboxOutlined />}
                  onClick={() => archivate({ ids: checkedList })}
                  className={styles.archiveButton}
                >
                  {t('notifications.archive')}
                </Button>
              )}

              <RangeDatePicker
                value={filters.date}
                onChange={value => handleChangeFilters('date', value)}
              />
              <TypeSelect
                value={filters.types}
                onChange={value => handleChangeFilters('types', value)}
              />
            </Space>
          </Space>
        )}
      </Space.Compact>

      {openData ? (
        <NotificationView data={openData} />
      ) : (
        <NotificationList
          page={page}
          setPage={setPage}
          perPage={perPage}
          setPerPage={setPerPage}
          isLoading={isLoading}
          totalCount={totals[filters.status]}
        >
          {todayList.length !== 0 && (
            <div className={styles.timeline}>
              <span className={styles.text}>{t('notifications.today')}</span>

              {todayList.map(item => (
                <NotificationItem
                  key={item.id}
                  data={item}
                  value={checkedList}
                  onOpen={setOpenData}
                  onChange={setCheckedList}
                  onRefresh={refetch}
                  disabled={filters.status === NotificationStatus.ARCHIVE}
                />
              ))}
            </div>
          )}

          {laterList.length !== 0 && (
            <div className={styles.timeline}>
              <span className={styles.text}>{t('notifications.later')}</span>

              {laterList.map(item => (
                <NotificationItem
                  key={item.id}
                  data={item}
                  value={checkedList}
                  onOpen={setOpenData}
                  onChange={setCheckedList}
                  onRefresh={refetch}
                  disabled={filters.status === NotificationStatus.ARCHIVE}
                />
              ))}
            </div>
          )}
        </NotificationList>
      )}
    </Drawer>
  );
};

export default NotificationsDrawer;
