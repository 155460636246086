import { Permission } from '@fanckler/processing-auth';
import {
  RiAppsFill,
  RiCoinFill,
  RiDashboard3Fill,
  RiFileList2Fill,
  RiNotificationBadgeFill,
  RiParentFill,
  RiPercentFill,
  RiReceiptFill,
  RiRefreshFill,
  RiSwap2Fill,
  RiSwapBoxFill,
  RiTeamFill,
  RiTimeFill,
  RiTokenSwapFill,
  RiVipCrownFill,
} from '@remixicon/react';
import { TFunction } from 'i18next';
import { Path } from 'routes/interfaces/Path';
import { CustomMenuItem } from './types';

export const menuItemsConfig = (authorizedPath: string | null, t: TFunction): CustomMenuItem[] => [
  {
    type: 'group',
    label: t('administration.title'),
    children: [
      {
        label: t('users.units.title'),
        path: authorizedPath || '',
        icon: <RiAppsFill size={16} />,
        permissions: [Permission.ADMIN_IAM_ROOT_UNIT_READ],
      },
      {
        label: t('administration.financialManagement.title'),
        path: Path.ADMINISTRATION_FIN_MANAGEMENT,
        icon: <RiTokenSwapFill size={16} />,
        permissions: [
          Permission.CLIENT_TRANSACTION_GROUP_READ,
          Permission.CLIENT_TRANSACTION_CATEGORY_READ,
        ],
      },
      {
        label: t('users.replenishmentRequests.title'),
        path: Path.ADMINISTRATION_REPLENISHMENT_REQUESTS,
        icon: <RiSwap2Fill size={16} />,
        permissions: [Permission.CLIENT_UNIT_READ],
      },
      {
        label: t('administration.managers.title'),
        path: Path.ADMINISTRATION_MANAGERS,
        icon: <RiTeamFill size={16} />,
        permissions: [Permission.ADMIN_IAM_USER_READ],
      },
      {
        label: t('administration.roles.title'),
        path: Path.ADMINISTRATION_ROLES,
        icon: <RiVipCrownFill size={16} />,
        permissions: [Permission.ADMIN_IAM_GROUP_READ],
      },
      {
        label: t('exchangeModule.title'),
        path: Path.ADMINISTRATION_EXCHANGE_MODULE,
        icon: <RiSwapBoxFill size={16} />,
        permissions: [Permission.CLIENT_EXCHANGE_READ],
      },
    ],
  },
  {
    type: 'group',
    label: t('users.title'),
    children: [
      {
        label: t('users.clients.title'),
        path: Path.USERS_CLIENTS,
        icon: <RiParentFill size={16} />,
        permissions: [Permission.CLIENT_USER_READ],
      },
      {
        label: t('users.transactions.title'),
        path: Path.USERS_TRANSACTIONS,
        icon: <RiRefreshFill size={16} />,
        permissions: [Permission.CLIENT_TRANSACTION_READ],
      },
      {
        label: t('invoice.title'),
        path: Path.USERS_INVOICES,
        icon: <RiReceiptFill size={16} />,
        permissions: [Permission.CLIENT_INVOICE_READ],
      },
    ],
  },
  {
    type: 'group',
    label: t('processing.title'),
    children: [
      {
        label: t('processing.balance.title'),
        path: Path.PROCESSING_BALANCE,
        icon: <RiCoinFill size={16} />,
        permissions: [Permission.PROCESSING_BALANCE_READ],
      },
      {
        label: t('processing.paymentOrders.title'),
        path: Path.PROCESSING_PAYMENT_ORDERS,
        icon: <RiFileList2Fill size={16} />,
        permissions: [Permission.PROCESSING_PAYMENT_ORDER_READ],
      },
      {
        label: t('processing.transactions.title'),
        path: Path.PROCESSING_TRANSACTIONS,
        icon: <RiRefreshFill size={16} />,
        permissions: [Permission.PROCESSING_TRANSACTION_READ],
      },
      {
        label: t('processing.resources.title'),
        path: Path.PROCESSING_RESOURCES,
        icon: <RiDashboard3Fill size={16} />,
        permissions: [Permission.PROCESSING_RESOURCE_READ],
      },
    ],
  },
  {
    type: 'group',
    label: t('system.title'),
    children: [
      {
        label: t('commissions.title'),
        path: Path.SYSTEM_COMMISSIONS,
        icon: <RiPercentFill size={16} />,
        permissions: [Permission.CLIENT_COMMISSION_READ],
      },
      {
        label: t('activityLog.title'),
        path: Path.SYSTEM_ACTIVITY_LOG,
        icon: <RiTimeFill size={16} />,
        permissions: [Permission.ADMIN_IAM_LOGS_READ],
      },
      {
        label: t('system.firewall.title'),
        path: Path.SYSTEM_FIREWALL,
        icon: <RiNotificationBadgeFill size={16} />,
        permissions: [Permission.ADMIN_IAM_ALLOWED_IP_READ],
      },
    ],
  },
];
