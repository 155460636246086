import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RiArrowDownSLine, RiArrowUpSLine } from '@remixicon/react';
import { Form, FormInstance, Input, notification } from 'antd';
import useGetPaymentOrderServiceFee from 'api/commission/useGetPaymentOrderServiceFee';
import clsx from 'clsx';
import { head } from 'lodash';
import { IBusiness } from 'interfaces/IBusiness';
import { UnknownType } from 'types/Unknown';
import { WalletCurrencyEnum } from 'types/WalletCurrencyEnum';
import { WalletNetworkEnum } from 'types/WalletNetworkEnum';
import { IUnitTree } from 'components/TreeUnitsSelect/types';
import { balanceValidator } from 'pages/Administration/ExchangeModule/components/ExchangeForm/components/FormExchangeCurrency/utils/inputValidator';
import { CommissionView } from 'pages/Administration/Units/components/FormWithdraw/components';
import { useCurrencyRate } from 'hooks';
import { useUnitsTree } from 'components/TreeUnitsSelect/hooks';
import { POSITIVE_FRACTIONAL_NUMBER } from 'constants/validation';
import { formatCurrencyAmount, truncateNumber } from 'utils';
import findElementById from 'components/TreeUnitsSelect/utils/findElementById';
import { TreeUnitsSelect } from 'components';
import styles from 'drawers/root-unit/FormEditBusiness/components/TopUpWallet/TopUpWallet.module.scss';

type TopUpWalletProps = {
  business: IBusiness;
  form: FormInstance<UnknownType>
  onSubmit: (values: UnknownType) => void;
};

const CHANGE_AMOUNT_TIMEOUT = 1000;

const TopUpWallet = ({
  business,
  form,
  onSubmit,
}: TopUpWalletProps) => {
  const { t } = useTranslation();
  const [selectedUnit, setSelectedUnit] = useState<IUnitTree>();
  const [internalAmount, setInternalAmount] = useState('');
  const [amount, setAmount] = useState('');
  const [validationError, setValidationError] = useState<string | null>(null);
  const debounceTimeout = useRef<NodeJS.Timeout | null>(null);

  const { data, isLoading } = useUnitsTree({
    rootUnitId: business.id,
    nested: true,
    balances: true,
  });

  const handleSelectUnit = (id: (string | number | undefined)[]) => {
    if (!data) return;
    const targetId = head(id);
    if (!targetId) return setSelectedUnit(undefined);
    const unit = findElementById(data, targetId);
    setSelectedUnit(unit);
    form.setFieldValue('unitId', targetId);
  };

  const selectedWallet = selectedUnit?.wallets?.find((wallet) => wallet.currency === WalletCurrencyEnum.USDT && wallet.network === WalletNetworkEnum.TRC20);

  const validateAmount = async (value: string) => {
    try {
      if (!value) {
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw t('processing.pleaseEnterAmount');
      }
      if (Number(value) <= 0 || !value.match(POSITIVE_FRACTIONAL_NUMBER)) {
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw t('processing.amountRules');
      }
      if (selectedWallet) {
        await balanceValidator({ t, value, balance: selectedWallet?.balance || 0, currency: WalletCurrencyEnum.USDT });
      }
      return await Promise.resolve();
    } catch (e) {
      if (typeof e === 'string') {
        return Promise.reject(e);
      }
      return Promise.reject((e as UnknownType).message);
    }
  };

  const handleChangeAmount = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.replace(',', '.').trim();

    try {
      await validateAmount(value);
      setValidationError(null);
    } catch (er: UnknownType) {
      setValidationError(er);
    }
    setInternalAmount(value);

    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => {
      setAmount(value);
      form.setFieldValue('amount', value);
    }, CHANGE_AMOUNT_TIMEOUT);
  };

  useEffect(() => {
    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
  }, []);

  const commissionQuery = useMemo(() => ({
    destinationAddress: business.subscriptionWalletAddress || '',
    addressFrom: selectedWallet?.walletAddress || '',
    rootUnitId: `${business.id}`,
    currency: WalletCurrencyEnum.USDT,
    network: WalletNetworkEnum.TRC20,
    amount,
  }), [business, selectedWallet, amount]);

  const enabledCommission = useMemo(() => {
    const addressForm = selectedWallet?.walletAddress;
    const addressTo = business.subscriptionWalletAddress;

    return Boolean(addressForm && addressTo && amount && !validationError);
    //   do not add validationError to dependencies, it will cause unnecessary query
  }, [selectedWallet?.walletAddress, business.subscriptionWalletAddress, amount]);

  const { data: paymentOrderTariff, refreshWithParams: refetchCommission } = useGetPaymentOrderServiceFee(commissionQuery, {
    enabled: enabledCommission,
    onError: error => {
      setValidationError(error.message);
    },
  });
  const { walletBalance, isLoading: rateLoading } = useCurrencyRate();

  const onSetMaxAmount = useCallback(
    async () => {
      const maxBalance = formatCurrencyAmount(selectedWallet?.balance || 0, WalletCurrencyEnum.USDT, true);

      try {
        const { serviceFee, error } = await refetchCommission({
          ...commissionQuery,
          amount: maxBalance,
        });
        if (error) {
          return notification.error({
            message: error,
          });
        }

        const maxAmount = Number(maxBalance) - Number(serviceFee);
        if (maxAmount <= 0) {
          setAmount('');
          form.setFields([{ name: 'amount', value: '', errors: [t('processing.justNotEnoughFunds')] }]);
          notification.close('justNotEnoughFunds');
          return notification.error({
            message: t('processing.justNotEnoughFunds'),
            key: 'justNotEnoughFunds',
          });
        }
        const truncatedMaxAmount = truncateNumber({
          number: maxAmount,
          currency: WalletCurrencyEnum.USDT,
          asString: true,
        });
        setAmount(String(truncatedMaxAmount));
        form.setFields([{ name: 'amount', value: String(truncatedMaxAmount), errors: [] }]);
        form.validateFields(['amount']);
      } catch (e) {
        form.setFields([{ name: 'amount', value: maxBalance, errors: [] }]);
      }
    },
    [selectedWallet?.balance, refetchCommission, commissionQuery, form, t],
  );

  const handleSubmit = async () => {
    try {
      await validateAmount(amount);
      onSubmit({
        uuid: selectedUnit?.uuid,
        body: {
          feeValue: paymentOrderTariff?.feeValue.toString(),
          totalFee: paymentOrderTariff?.totalFee.toString(),
          walletAddress: business.subscriptionWalletAddress,
          amount,
          category: 1,
          group: 1,
          currency: WalletCurrencyEnum.USDT,
          payway: WalletNetworkEnum.TRC20,
        },
      });
    } catch (er: UnknownType) {
      setValidationError(er);
    }
  };

  const handleDisableCondition = useCallback((unit: IUnitTree) => {
    return unit.wallets?.every((wallet) => {
      return wallet.currency !== WalletCurrencyEnum.USDT || wallet.network !== WalletNetworkEnum.TRC20;
    });
  }, []);

  return (
    <>
      <Form
        style={{ marginBottom: 'auto' }}
        form={form}
        name="topUpForm"
        onFinish={handleSubmit}
        className={styles.card}
      >
        <div className={styles.wrapper}>
          <Form.Item name="unitId" className={styles.formItem}>
            <TreeUnitsSelect
              isMultiply={false}
              withSelectAllButton={false}
              itemIndicatorType="radio"
              dropdownAlign={[0, 54]}
              onSelect={handleSelectUnit}
              data={data || []}
              isLoading={isLoading}
              disabled={false}
              disableDeactivatedUnits
              disableCondition={handleDisableCondition}
            >
              {({ isOpen, setIsOpen }) => {
                return (
                  <div
                    onMouseDown={(event) => {
                      if (isLoading) return;
                      event.stopPropagation();
                      setIsOpen(!isOpen);
                    }}
                    className={clsx(styles.inputWrapper, styles.pickUnitInput, {
                      [styles.isPlaceholder]: !selectedUnit,
                      [styles.disabled]: isLoading,
                      [styles.opened]: isOpen,
                    })}
                  >
                    {selectedUnit?.name || t('business.subscription.selectUnit')}

                    <span className={styles.inputIcon}>
                      {isOpen ? <RiArrowUpSLine size={20} /> : <RiArrowDownSLine size={20} />}
                    </span>
                  </div>
                );
              }}
            </TreeUnitsSelect>
          </Form.Item>
        </div>
        <Form.Item name="amount" className={styles.formItem}>
          <Input
            disabled={isLoading || !selectedUnit}
            placeholder={t('business.subscription.enterAmount')}
            className={styles.input}
            onChange={handleChangeAmount}
            value={internalAmount}
          />
        </Form.Item>
        <div className={styles.belowInputContent}>
          <span className={styles.error}>{validationError}</span>
          <span className={styles.balanceWrapper}>
            <span>{t('balance')}:</span>
            <span className={styles.amount}>{truncateNumber({
              number: selectedWallet?.balance || 0,
              currency: WalletCurrencyEnum.USDT,
            })} USDT</span>
            <span
              onClick={onSetMaxAmount}
              className={styles.max}
            >
            Max
            </span>
          </span>
        </div>
      </Form>
      <div className={styles.commissionViewWrapper}>
        <CommissionView
          amount={Number(amount)}
          currency={WalletCurrencyEnum.USDT}
          commission={paymentOrderTariff}
          convert={walletBalance}
          convertLoading={rateLoading}
          withFloatingValue={false}
        />
      </div>
    </>
  );
};

export default TopUpWallet;
