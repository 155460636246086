import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Upload } from 'antd';
import Dragger from 'antd/es/upload/Dragger';
import { RcFile } from 'antd/lib/upload';
import { FileExcelOutlined } from '@ant-design/icons';
import styles from './index.module.scss';

type UploadFileType = {
  onUpload: (file: RcFile) => void;
  children?: ReactElement;
};
export const UploadFile = ({ onUpload, children }: UploadFileType): ReactElement => {
  const { t } = useTranslation();

  return (
    <>
      {children ? (
        <Upload
          name="file"
          accept=".xlsx"
          multiple={false}
          showUploadList={false}
          beforeUpload={onUpload}
          className={styles.upload}
        >
          {children}
        </Upload>
      ) : (
        <Dragger
          name="file"
          accept=".xlsx"
          showUploadList={false}
          beforeUpload={onUpload}
          multiple={false}
          className={styles.container}
        >
          <p className="ant-upload-drag-icon">
            <FileExcelOutlined />
          </p>
          <p className={styles.text}>{t('file.uploadXLSXFile')}</p>
          <p className={styles.hint}>{t('file.selectOrDragAndDrop')}</p>
        </Dragger>
      )}
    </>
  );
};
