import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { registerDrawer, SharedDrawerProps } from '@nuvalt/ui-kit';
import { Button, Form, Image, Input, Select, Switch } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { UnknownType } from 'types/Unknown';
import { WalletCurrencyEnum } from 'types/WalletCurrencyEnum';
import { WalletNetworkEnum } from 'types/WalletNetworkEnum';
import { ExpiryTimeType, InvoiceFormDataType } from 'components/CreateInvoice/types';
import { EMAIL_SCHEMA } from 'constants/validation';
import { CurrencyIcon } from 'utils/currencyIcon';
import { amountValidator } from './utils';
import { InvoiceView } from '../InvoiceView';
import { Drawer, DrawerContent, DrawerFooter, DrawerHeader } from 'components';
import { SelectWithCheckbox } from './components';
import { CustomExpiryTime } from './components/CustomExpiryTime';
import styles from './CreateInvoiceForm.module.scss';

export enum CreateInvoiceStep {
  CREATE = 'create',
  CONFIRM = 'confirm',
}

export type CreateInvoiceFormProps = {
  isLoading: boolean;
  onSubmit: (formData: InvoiceFormDataType & { isAnyAmount: boolean }) => void;
} & SharedDrawerProps;

type Option<T extends string> = { value: T, label: T };

const currencyOpts: Option<WalletCurrencyEnum>[] = [
  { value: WalletCurrencyEnum.BTC, label: WalletCurrencyEnum.BTC },
  { value: WalletCurrencyEnum.ETH, label: WalletCurrencyEnum.ETH },
  { value: WalletCurrencyEnum.TRX, label: WalletCurrencyEnum.TRX },
  { value: WalletCurrencyEnum.BNB, label: WalletCurrencyEnum.BNB },
  { value: WalletCurrencyEnum.DAI, label: WalletCurrencyEnum.DAI },
  { value: WalletCurrencyEnum.USDT, label: WalletCurrencyEnum.USDT },
  { value: WalletCurrencyEnum.USDC, label: WalletCurrencyEnum.USDC },
];

const networkOpts: Option<WalletNetworkEnum>[] = [
  { value: WalletNetworkEnum.BITCOIN, label: WalletNetworkEnum.BITCOIN },
  { value: WalletNetworkEnum.ERC20, label: WalletNetworkEnum.ERC20 },
  { value: WalletNetworkEnum.TRC20, label: WalletNetworkEnum.TRC20 },
  { value: WalletNetworkEnum.BEP20, label: WalletNetworkEnum.BEP20 },
];

const networkCurrencyMapping = new Map([
  [WalletNetworkEnum.BITCOIN, [WalletCurrencyEnum.BTC]],
  [WalletNetworkEnum.ERC20, [
    WalletCurrencyEnum.USDT,
    WalletCurrencyEnum.USDC,
    WalletCurrencyEnum.ETH,
  ]],
  [WalletNetworkEnum.TRC20, [WalletCurrencyEnum.TRX, WalletCurrencyEnum.USDT]],
  [WalletNetworkEnum.BEP20, [
    WalletCurrencyEnum.USDT,
    WalletCurrencyEnum.USDC,
    WalletCurrencyEnum.BNB,
    WalletCurrencyEnum.DAI,
  ]],
]);

export const MAX_INVOICE_DAYS = 14;

const CreateInvoiceForm = ({
  isLoading,
  onSubmit,
  closeDrawer,
}: CreateInvoiceFormProps): ReactElement => {
  const [isAnyAmount, setAnyAmount] = useState(false);
  const [selectedNetwork, setSelectedNetwork] = useState<WalletNetworkEnum | null>(null);
  const [selectedCurrency, setSelectedCurrency] = useState<WalletCurrencyEnum | null>(null);
  const [currencies, setCurrencies] = useState(currencyOpts);

  const [step, setStep] = useState<CreateInvoiceStep>(CreateInvoiceStep.CREATE);
  const [invoice, setInvoice] = useState<InvoiceFormDataType | null>(null);
  const [customTime, setCustomTime] = useState<string>('');
  const { t } = useTranslation();
  const [form] = useForm();

  const onChangeCustomTime = (value: string) => {
    if (value.includes('custom')) {
      setCustomTime(value.split('|')[1]);
    }
  };

  const formatExpiryTime = (expiryTime: string) => {
    if (expiryTime.includes('custom')) {
      const time: ExpiryTimeType = JSON.parse(expiryTime.split('|')[1]);

      if (!isEmpty(time)) {
        return moment().add(time.days, 'd').add(time.hours, 'h').toISOString();
      } else {
        return moment().add(3, 'd').add(0, 'h').toISOString();
      }
    } else {
      const time: ExpiryTimeType = JSON.parse(expiryTime);
      return moment().add(time.days, 'd').add(time.hours, 'h').toISOString();
    }
  };

  useEffect(() => {
    if (isAnyAmount) {
      form.setFieldsValue({
        expirationAt: JSON.stringify({ days: MAX_INVOICE_DAYS, hours: 0 }),
      });
    } else {
      form.resetFields(['expirationAt']);
    }
  }, [isAnyAmount, form]);

  const onFinishForm = (formData: InvoiceFormDataType) => {
    setInvoice(formData);
    setStep(CreateInvoiceStep.CONFIRM);
  };

  const onCofirmSubmit = (formData: InvoiceFormDataType) => {
    onSubmit({
      ...formData,
      expirationAt: formatExpiryTime(formData.expirationAt),
      isAnyAmount,
    });
  };

  const handleSelectNetwork = useCallback((newSelectedNetwork: WalletNetworkEnum) => {
    setSelectedNetwork(newSelectedNetwork);

    const currenciesByNetwork = networkCurrencyMapping.get(newSelectedNetwork) || [];
    const filteredCurrencies = currencyOpts.filter(currency => currenciesByNetwork.includes(currency.value));
    setCurrencies(filteredCurrencies);

    form.setFieldValue('currency', filteredCurrencies[0].value);
    form.validateFields(['currency']);

    setSelectedCurrency(filteredCurrencies[0].value);
  }, [form]);

  const expiryTimeValidator = (_: UnknownType, value: string) => {
    if (!value) return Promise.reject(t('invoice.validation.pleaseSelectExpiryTime'));

    if (!value.match(/^(?!custom\|$).*/)) {
      return Promise.reject(t('invoice.validation.pleaseSelectExpiryTime'));
    } else {
      if (value.split('|')[1]) {
        const time = JSON.parse(value.split('|')[1]);

        if (!time.days && !time.hours) {
          return Promise.reject(t('invoice.validation.minValue'));
        } else {
          return Promise.resolve();
        }
      } else {
        return Promise.resolve();
      }
    }
  };

  return (
    <Drawer>
      <DrawerHeader title={t('invoice.createInvoice')} onClose={closeDrawer}>
        {step === CreateInvoiceStep.CREATE && (
          <Switch
            checked={isAnyAmount}
            checkedChildren="Open amount"
            unCheckedChildren="Open amount"
            onChange={() => {
              setAnyAmount((prev) => !prev);
            }}
          />
        )}
      </DrawerHeader>
      <DrawerContent className={styles.formWrapper}>
        {step === CreateInvoiceStep.CREATE && (
          <Form
            form={form}
            onFinish={onFinishForm}
            className={styles.form}
          >
            {!isAnyAmount && (
              <Form.Item
                name="title"
                label={t('invoice.fields.title')}
                className={styles.formItem}
                rules={[
                  { required: true, message: t('invoice.validation.pleaseInputTitle') },
                  { pattern: /^(?=.*[^ ]).+$/gm, message: t('invalidCharacter') },
                ]}
              >
                <Input className={styles.input} placeholder={t('invoice.fields.title')} />
              </Form.Item>
            )}

            <div className={styles.alignItems}>
              <Form.Item
                name="network"
                className={styles.formItem}
                label={t('processing.payway')}
                rules={[{ required: true, message: t('processing.pleaseSelectPayway') }]}
              >
                <Select
                  placeholder="ERC20"
                  value={selectedNetwork}
                  onSelect={handleSelectNetwork}
                  popupClassName={styles.selectCurrencyPopup}
                  className={styles.selectCurrency}
                >
                  {networkOpts.map(option => (
                    <Select.Option key={option.value} value={option.value}>
                      <div className={styles.option}>
                        <Image
                          preview={false}
                          src={CurrencyIcon[option.value]}
                          key={`option-icon-${option.value}`}
                          className={styles.icon}
                        />

                        <span className={styles.label}>{option.label}</span>
                      </div>
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="currency"
                className={styles.formItem}
                label={t('processing.currency')}
                rules={[{ required: true, message: t('processing.pleaseSelectCurrency') }]}
              >
                <Select
                  placeholder="Ethereum"
                  value={selectedCurrency}
                  onSelect={setSelectedCurrency}
                  popupClassName={styles.selectCurrencyPopup}
                  className={styles.selectCurrency}
                >
                  {currencies.map(option => (
                    <Select.Option key={option.value} value={option.value}>
                      <div className={styles.option}>
                        <Image
                          preview={false}
                          src={CurrencyIcon[option.value]}
                          key={`option-icon-${option.value}`}
                          className={styles.icon}
                        />

                        <span className={styles.label}>{option.label}</span>
                      </div>
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>

            {!isAnyAmount && (
              <Form.Item
                required
                name="amount"
                label={t('processing.paymentOrders.amount')}
                className={styles.formItem}
                normalize={(value: string) => value.replace(',', '.')}
                rules={isAnyAmount ? [] : [{ validator: (_, value) => amountValidator(value, selectedCurrency, t) }]}
              >
                <Input
                  disabled={isAnyAmount}
                  className={styles.input}
                  placeholder={t('processing.paymentOrders.amount')}
                />
              </Form.Item>
            )}

            <Form.Item
              name="customerEmail"
              initialValue=""
              label={t('invoice.fields.emailCustomer')}
              className={styles.formItem}
              rules={[{ pattern: EMAIL_SCHEMA, message: t('fields.email.validation.rules') }]}
            >
              <Input className={styles.input} placeholder={t('invoice.fields.emailCustomer')} />
            </Form.Item>

            <Form.Item
              required
              name="expirationAt"
              className={styles.formItem}
              label={t('invoice.fields.expiryTime')}
              rules={[
                { validator: expiryTimeValidator },
              ]}
            >
              <SelectWithCheckbox
                placeholder={t('invoice.fields.expiryTime')}
                expandedContent={CustomExpiryTime}
                wrapperId="expiryTimeSelect"
                className={styles.select}
                onChange={onChangeCustomTime}
                value={customTime}
                disabled={isAnyAmount}
                options={[
                  { label: '3 hours', value: JSON.stringify({ days: 0, hours: 3 }) },
                  { label: '1 day', value: JSON.stringify({ days: 1, hours: 0 }) },
                  { label: '3 days', value: JSON.stringify({ days: 3, hours: 0 }) },
                  { label: '7 days', value: JSON.stringify({ days: 7, hours: 0 }) },
                  { label: t('invoice.select.customValue'), value: `custom|${customTime}` },
                  ...(isAnyAmount ? [{
                    label: `${MAX_INVOICE_DAYS} days`,
                    value: JSON.stringify({ days: MAX_INVOICE_DAYS, hours: 0 }),
                  }] : []),
                ]}
              />
            </Form.Item>

            <Form.Item
              name="description"
              initialValue=""
              label={t('administration.financialManagement.fields.description')}
              className={styles.formItem}
            >
              <Input
                className={styles.input}
                placeholder={t('administration.financialManagement.fields.enterDescription')}
              />
            </Form.Item>
          </Form>
        )}
        {(!isEmpty(invoice) && step === CreateInvoiceStep.CONFIRM) && (
          <InvoiceView invoice={invoice} isAnyAmount={isAnyAmount} />
        )}
      </DrawerContent>
      <DrawerFooter>
        {step === CreateInvoiceStep.CREATE && (
          <Button
            type="primary"
            htmlType="submit"
            onClick={form.submit}
          >
            {t('continue')}
          </Button>
        )}
        {(!isEmpty(invoice) && step === CreateInvoiceStep.CONFIRM) && (
          <>
            <Button
              type="primary"
              loading={isLoading}
              onClick={() => onCofirmSubmit(invoice)}
            >
              {t('send')}
            </Button>

            <Button
              danger
              type="link"
              onClick={() => setStep(CreateInvoiceStep.CREATE)}
            >
              {t('back')}
            </Button>
          </>
        )}
      </DrawerFooter>
    </Drawer>
  );
};

export default registerDrawer(CreateInvoiceForm, {
  id: 'CreateInvoiceForm',
});
