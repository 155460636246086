import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Location, useLocation, useNavigate } from 'react-router-dom';
import { RiArrowLeftLine } from '@remixicon/react';
import { Button } from 'components';
import styles from './BackButton.module.scss';

const BackButton = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const previousLocation = useRef<Location>(location);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);

  const isSameLocation = useCallback((loc1: Location, loc2: Location) => (
    loc1.pathname === loc2.pathname &&
    loc1.search === loc2.search &&
    loc1.hash === loc2.hash
  ), []);

  useEffect(() => {
    setIsDisabled(isSameLocation(location, previousLocation.current));
  }, [location, isSameLocation]);

  const handleBackClick = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <Button
      type="default"
      disabled={isDisabled}
      onClick={handleBackClick}
      prefixIcon={<RiArrowLeftLine size={20} />}
      className={styles.button}
    />
  );
};

export default BackButton;
