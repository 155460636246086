import { FilterField, FilterFieldType } from 'components/Filters';

const stringifyFilters = (
  filterFields: Map<string, FilterField>,
  filters: Record<string, unknown>,
): string => {
  const result: Record<string, string> = {};

  Object.keys(filters).forEach((key) => {
    const filterField = filterFields.get(key);

    if (filterField) {
      const { type, monthOnly } = filterField;
      const value = filters[key];

      switch (type) {
        case FilterFieldType.DATE:
          result[key] = monthOnly
            ? (new Date(value as Date)).toDateString()
            : (new Date(value as Date)).toISOString();
          break;
        case FilterFieldType.SELECT:
        case FilterFieldType.NUMBER:
        case FilterFieldType.TEXT:
        default: {
          result[key] = String(value);
        }
      }
    }
  });

  return JSON.stringify(result);
};

export default stringifyFilters;
