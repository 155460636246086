import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RiSideBarFill, RiSideBarLine } from '@remixicon/react';
import { Layout } from 'antd';
import clsx from 'clsx';
import { AppState, useSelector } from 'init/rootReducer';
import { toggleSidebarVisible } from './actions';
import { AppLogo, Button } from 'components';
import { Menu } from './components';
import styles from './Sidebar.module.scss';

const Sidebar = () => {
  const collapsed = useSelector((state: AppState) => state.sidebarReducer.collapsed);
  const dispatch = useDispatch();

  const [hovered, setHovered] = useState(false);
  const [isAnimating, setIsAnimating] = useState<boolean>(false);

  const toggleCollapsed = useCallback(() => {
    dispatch(toggleSidebarVisible(!collapsed));
    setHovered(false);

    // if (!collapsed) {
    //   setIsAnimating(true);
    // }

    if (!collapsed) {
      setIsAnimating(true);

      setTimeout(() => {
        setIsAnimating(false);
      }, 500);
    }
  }, [collapsed, dispatch]);

  const handleMouseEnter = useCallback(() => {
    if (!isAnimating && collapsed) {
      setHovered(true);
    }
  }, [isAnimating, collapsed]);

  const handleMouseLeave = useCallback(() => {
    if (!isAnimating && collapsed) {
      setHovered(false);
    }
  }, [isAnimating, collapsed]);

  // const handleTransitionEnd = useCallback((event: React.TransitionEvent<HTMLDivElement>) => {
  //   if (event.propertyName === 'width') {
  //     setIsAnimating(false);
  //   }
  // }, []);

  const isCollapsed = collapsed && !hovered;

  return (
    <Layout.Sider
      collapsible
      collapsed={isCollapsed}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      // onTransitionEnd={handleTransitionEnd}
      collapsedWidth={81}
      width={261}
      trigger={null}
      className={clsx(styles.wrapper, {
        [styles.collapsed]: collapsed,
        [styles.hovered]: hovered,
      })}
    >
      <div className={styles.header}>
        <AppLogo type="sidebar" />

        {!isCollapsed && (
          <Button
            type="link"
            withoutBgShadow
            withoutWaveEffect
            onClick={toggleCollapsed}
            className={clsx(styles.collapseButton, { [styles.collapsed]: isCollapsed })}
            prefixIcon={collapsed
              ? <RiSideBarFill size={16} color="var(--black-85-percent-color)" />
              : <RiSideBarLine size={16} />}
          />
        )}
      </div>

      <Menu />
    </Layout.Sider>
  );
};

export default Sidebar;
