import React from 'react';
import { useTranslation } from 'react-i18next';
import { Permission } from '@fanckler/processing-auth';
import { RiAddLine } from '@remixicon/react';
import { useAuthorisationContext } from 'contexts';
import { Button } from 'components';
import formCreateClient from './components/FormCreateClient/FormCreateClient';

type CreateClientButtonProps = {
  unitId?: number;
  rootUnitId?: number;
  disabled?: boolean;
  onRefresh?: () => void;
  className?: string;
};

export const CreateClientButton = ({
  unitId,
  disabled,
  onRefresh,
  rootUnitId,
  className,
}: CreateClientButtonProps) => {
  const { checkPermissions, user } = useAuthorisationContext();
  const { t } = useTranslation();

  const openModalHandler = () => {
    formCreateClient.openDrawer({
      unitId,
      rootUnitId: rootUnitId || user?.rootUnitId as number,
      onRefresh,
    });
  };

  if (!checkPermissions([Permission.CLIENT_USER_CREATE])) {
    return null;
  }

  return (
    <Button
      {...(unitId ? { } : { suffixIcon: <RiAddLine /> })}
      {...(unitId ? { } : { style: { padding: '7px 15px' } })}
      type={(unitId ? 'link' : 'primary') as 'link'}
      withoutWaveEffect={!!unitId}
      withoutBgShadow={!!unitId}
      disabled={disabled}
      onClick={openModalHandler}
      className={className}
    >
      {t('client.create')}
    </Button>
  );
};
