import React, { useContext, useEffect, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { RiNotification4Line } from '@remixicon/react';
import { Badge, notification } from 'antd';
import clsx from 'clsx';
import { AppState } from 'init/rootReducer';
import { NotificationSocket } from 'interfaces/INotification';
import useWelcomeNotification from 'hooks/useNotifications/useWelcomeNotification';
import { SocketContext } from 'contexts';
import { Button } from 'components';
import NotificationsDrawer from 'components/Notifications/components/NotificationsDrawer';
import { setNotificationPayload, setNotificationVisible } from 'components/Notifications/redux/actions';
import styles from './Notifications.module.scss';

const Notifications = () => {
  const [isOpenDrawer, setOpenDrawer] = useState(false);
  const [count, setCount] = useState(0);
  const { socket } = useContext(SocketContext);

  const { isOpen, payload } = useSelector((state: AppState) => state.notificationReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    setOpenDrawer(isOpen);
  }, [isOpen]);

  useWelcomeNotification(setCount);

  useEffect(() => {
    if (socket) {
      socket.on('notification', (data: NotificationSocket) => {
        if (!data.isAlert && data.title && data.description) {
          notification.info({
            message: data.title,
            description: data.description,
            icon: <RiNotification4Line />,
            top: 100,
          });
        }

        setCount((prevCount) => prevCount + 1);
      });
    }
  }, [socket]);

  const handleClose = () => {
    setOpenDrawer(false);

    if (isOpen) {
      batch(() => {
        dispatch(setNotificationVisible(false));
        if (payload) dispatch(setNotificationPayload(null));
      });
    }
  };

  return (
    <>
      <Badge
        count={count}
        overflowCount={9}
        className={clsx(styles.badge, { [styles.hide]: !count })}
      >
        <Button
          type="link"
          withoutBgShadow
          withoutWaveEffect
          prefixIcon={<RiNotification4Line size={18} />}
          onClick={() => setOpenDrawer(true)}
          className={styles.button}
        />
      </Badge>

      <NotificationsDrawer isOpen={isOpenDrawer} onClose={handleClose} />
    </>
  );
};

export default Notifications;
