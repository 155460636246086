import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { batch } from 'react-redux';
import { cleverRefetchQueries } from 'libs/reactQuery';
import { notification } from 'antd';
import useUpdateUnit from 'api/unit/useUpdateUnit';
import clsx from 'clsx';
import { IUnit } from 'interfaces';
import { UnknownType } from 'types/Unknown';
import formUnit from 'pages/Administration/Units/components/FormUnit/FormUnit';
import { useUnitChildren } from 'pages/Administration/Units/hooks';
import getColumns from 'pages/Administration/Units/utils/getColumns';
import { useNavigateToUnit, useTablePagination } from 'hooks';
import { StageEnum } from 'hooks/use2FA';
import { useModal } from 'components/Modal/hooks/useModal';
import { OTP_ERROR } from 'constants/index';
import { useAuthorisationContext } from 'contexts';
import { ModalFreezeUnit } from '../../../ModalFreezeUnit';
import { Pagination } from 'components';
import Table from 'components/Table';
import TreeOrgStructure, { useDetectTreeStartPosition } from 'components/TreeOrgStructure';
import styles from './UnitsTab.module.scss';

export type UnitsTabProps = {
  uuid: string;
};

const UnitsTab = ({ uuid }: UnitsTabProps) => {
  const [expandedRowKeys, setExpandedRowKeys] = useState<React.Key[]>([]);
  const {
    checkPermissions,
    twoFA: {
      onSuccess,
      onError,
      stage,
      setStage,
    },
  } = useAuthorisationContext();
  const { ref, width } = useDetectTreeStartPosition();
  const navigate = useNavigateToUnit();
  const { t } = useTranslation();

  const {
    isOpen,
    payload,
    onModalOpen,
    onModalClose,
    onSetModalPayload,
  } = useModal('unit-tab-units-update-child');

  const { page, perPage, setPage, setPerPage } = useTablePagination({
    withoutURL: true,
  });

  const { data, isLoading, refetch } = useUnitChildren({
    paging: { limit: perPage, offset: (page - 1) * perPage },
    uuid,
  });

  const openToggleModal = useCallback((unit: IUnit) => {
    batch(() => {
      onSetModalPayload({ ...unit });
      onModalOpen();
    });
  }, [onModalOpen, onSetModalPayload]);

  const onRefresh = useCallback(async () => {
    await refetch();
  }, [refetch]);

  const handleSuccess = useCallback((res: UnknownType) => {
    onSuccess(res, () => {
      notification.success({ message: t('users.units.updated') });
      onRefresh();
      onModalClose();
      formUnit.closeDrawer();
    });
  }, [onModalClose, onRefresh, onSuccess, t]);

  useEffect(() => {
    onRefresh();
  }, [onRefresh, page, perPage]);

  const { mutate: updateUnit, isPending: isUpdating } = useUpdateUnit({
    onSuccess: handleSuccess,
    onError: (error: UnknownType) => {
      onError(error, () => {
        if (stage !== StageEnum.DEFAULT && error.response?.data?.message.includes(OTP_ERROR)) {
          setStage(StageEnum.DEFAULT);
        }

        if (error?.response?.status === 413) {
          notification.error({ message: t('business.invalidFileSize') });
        } else {
          notification.error({
            description: error?.response?.data?.message,
            message: error?.response?.status,
          });
        }
      });
    },
  });

  useEffect(() => {
    if (formUnit.isOpened()) {
      formUnit.updateDrawer({
        loading: isUpdating,
      });
    }
  }, [isUpdating]);

  const openEditModal = useCallback((unit: IUnit) => {
    formUnit.openDrawer({
      initialValues: unit,
      title: t('users.units.updateUnit'),
      onSubmit: (formData) => updateUnit({ id: unit.id, body: formData }),
      loading: isUpdating,
    });
  }, [isUpdating, t, updateUnit]);

  const units = data || [];
  const totalCount = data?.length || 0;

  const columns = useMemo(() => (
    getColumns({
      t,
      openEditModal,
      openToggleModal,
      checkPermissions,
      expandedRowKeys,
      setExpandedRowKeys,
    })
  ), [
    t,
    openEditModal,
    openToggleModal,
    checkPermissions,
    expandedRowKeys,
  ]);

  return (
    <div className={styles.wrapper} ref={ref}>
      <Table
        rowKey="id"
        columns={columns}
        loading={isLoading}
        dataSource={units}
        onRefresh={onRefresh}
        pagination={false}
        scroll={{ x: 'max-content' }}
        rootClassName={styles.tableWrapper}
        rowClassName={record => clsx(styles.rowClassName, { [styles.deactivated]: !record.isActive })}
        onRow={record => ({
          onClick: () => navigate(record.rootUnitId, record.uuid),
        })}
        expandable={{
          expandedRowRender: record => <TreeOrgStructure parent={record} width={width} />,
          expandedRowClassName: () => styles.expandedRowClassName,
          showExpandColumn: false,
          expandedRowKeys,
        }}
      />
      <Pagination
        totalCount={totalCount}
        page={page}
        perPage={perPage}
        onPageChange={setPage}
        onPerPageChange={setPerPage}
        disablePageChange={isLoading || !totalCount}
        disablePerPageHandler={isLoading || !totalCount}
        elementsSize="xs"
      />
      <ModalFreezeUnit
        isOpen={isOpen}
        onModalClose={onModalClose}
        onComplete={async () => {
          await cleverRefetchQueries('unit-children');
        }}
        payload={payload as UnknownType}
      />
    </div>
  );
};

export default UnitsTab;
