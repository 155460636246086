import { TFunction } from 'i18next';
import { IStatus } from '../../interfaces/IStatus';
import { PaymentStatusEnum } from 'types/PaymentStatus';
import { ColorEnum } from '../../types';
import {
  HourglassOutlined,
  LoadingOutlined,
  MehOutlined,
  SmileOutlined,
} from '@ant-design/icons';
import {
  ReactComponent as SmileSadOutlined,
} from 'assets/icons/SmileSadOutlined.svg';

export const getPoStatuses = (t: TFunction): IStatus[] => [
  {
    status: PaymentStatusEnum.CREATED,
    color: ColorEnum.IDLE,
    icon: MehOutlined,
  },
  {
    status: PaymentStatusEnum.UNDER_REVIEW,
    color: ColorEnum.PENDING,
    icon: HourglassOutlined,
  },
  {
    status: PaymentStatusEnum.PENDING_APPROVAL,
    color: ColorEnum.PENDING,
    icon: HourglassOutlined,
  },
  {
    status: PaymentStatusEnum.CANCELED,
    color: ColorEnum.ERROR,
    icon: SmileSadOutlined,
  },
  {
    status: PaymentStatusEnum.APPROVED,
    color: ColorEnum.SUCCESS,
    icon: SmileOutlined,
  },
  {
    status: PaymentStatusEnum.SUSPENDED,
    color: ColorEnum.PENDING,
    icon: MehOutlined,
  },
  {
    status: PaymentStatusEnum.IN_SUSPENDED_FINALIZATION,
    color: ColorEnum.PENDING,
    icon: HourglassOutlined,
  },
  {
    status: PaymentStatusEnum.PENDING,
    color: ColorEnum.PENDING,
    icon: HourglassOutlined,
  },
  {
    status: PaymentStatusEnum.IN_PROCESS,
    color: ColorEnum.PENDING,
    icon: LoadingOutlined,
  },
  {
    status: PaymentStatusEnum.REJECTED,
    color: ColorEnum.ERROR,
    icon: SmileSadOutlined,
  },
  {
    status: PaymentStatusEnum.COMPLETED,
    color: ColorEnum.SUCCESS,
    icon: SmileOutlined,
  },
];
