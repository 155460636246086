import React from 'react';
import { useTranslation } from 'react-i18next';
import { RegisterSuccessReason } from '../../hooks/useRegisterDevice';
import { useLocale } from 'contexts';

const TranslatedSuccess = ({
  success,
  field,
}: {
  success: { key: RegisterSuccessReason, model: string, name: string },
  field: 'title' | 'description'
}) => {
  const { t } = useTranslation();
  const { localeData } = useLocale();

  const args = { name: success.name, model: success.model };

  if (success.key) {
    const translation = t(`coldStorage.ledger.successes.${success.key}.${field}`, args);

    if (translation !== `${localeData.lng}.coldStorage.ledger.successes.${success.key}.${field}`) {
      return <>{translation}</>;
    } else {
      return <>{t(`coldStorage.ledger.successes.unknown.${field}`)}</>;
    }
  }

  return null;
};

export default TranslatedSuccess;
