import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { registerDrawer, SharedDrawerProps } from '@nuvalt/ui-kit';
import { RiRefreshLine } from '@remixicon/react';
import { Select, Space, Typography } from 'antd';
import clsx from 'clsx';
import _size from 'lodash/size';
import { UnknownType } from 'types/Unknown';
import { FilterInput } from '../FilterInput';
import { SelectFilterFields } from '../SelectFilterFields';
import { Button } from 'components/Button';
import { Drawer, DrawerContent, DrawerFooter, DrawerHeader } from 'components/Drawer';
import { FilterField, FilterRelation } from 'components/Filters';
import styles from './ConfigureFiltersDrawer.module.scss';

type ApplyFilterArgs = {
  filters: Record<string, UnknownType>;
  filterRelation: FilterRelation;
  visibleFields: Record<string, boolean>;
};

type ConfigureFiltersDrawerProps = {
  fields?: Map<string, FilterField>;
  initialData?: {
    filters?: Record<string, unknown>;
    filterRelation?: FilterRelation;
  };
  hideOrRelation?: boolean;
  applyFilter: (args: ApplyFilterArgs) => void;
  clearFilters: () => void;
} & SharedDrawerProps;

const ConfigureFiltersDrawer = ({
  fields = new Map(),
  initialData,
  closeDrawer,
  hideOrRelation,
  applyFilter,
  clearFilters,
  drawerId,
}: ConfigureFiltersDrawerProps) => {
  const { t } = useTranslation();

  const [filterRelation, setFilterRelation] = useState<FilterRelation>(
    initialData?.filterRelation || FilterRelation.OR,
  );
  const [filters, setFilters] = useState<Record<string, unknown>>(initialData?.filters || {});
  const [visibleFields, setVisibleFields] = useState<Record<string, boolean>>(
    [...fields].reduce((acc, [key, filterField]) => ({
      ...acc,
      [key]: Boolean(
        filterField.alwaysVisible || (initialData?.filters && key in initialData.filters),
      ),
    }), {}),
  );

  const onFieldChange = useCallback((fieldKey: string, value: unknown) => {
    setFilters((prevState) => {
      const state = { ...prevState };

      if (value !== '') {
        return { ...prevState, [fieldKey]: value };
      }

      delete state[fieldKey];

      return state;
    });

  }, [setFilters]);

  const onDeleteFilterClick = useCallback((filterKey: string) => {
    setVisibleFields((prev) => ({ ...prev, [filterKey]: !prev[filterKey] }));
    setFilters((prev) => {
      const newFilters = { ...prev };

      delete newFilters[filterKey];

      return newFilters;
    });
  }, [setVisibleFields]);

  const handleClearFilters = useCallback(() => {
    setFilters({});

    if (clearFilters) {
      clearFilters();
    }
  }, [clearFilters]);

  const renderFilterField = useCallback(
    ([key, filterField]: [string, FilterField]) => (
      <>
        {visibleFields[key] && (
          <div className={styles.inputWrapper}>
            <span className={styles.label}>{filterField.label}</span>
            <FilterInput
              key={key}
              filterKey={key}
              filters={filters}
              value={filters[key]}
              onChange={onFieldChange}
              filterField={filterField}
              onDeleteClick={onDeleteFilterClick}
            />
          </div>
        )}
      </>
    ), [filters, visibleFields, onFieldChange, onDeleteFilterClick],
  );

  const areThereVisibleFilters = Object.values(visibleFields).includes(true);

  const submit = useCallback(() => {
    applyFilter({ filters, filterRelation, visibleFields });

    if (closeDrawer) {
      closeDrawer();
    }
  }, [applyFilter, filters, filterRelation, visibleFields, closeDrawer]);

  return (
    <Drawer id={drawerId}>
      <DrawerHeader title={t('filter')} onClose={closeDrawer}>
        <Space className={styles.filterButtons}>
          {areThereVisibleFilters && (
            <Button
              type="link"
              className={clsx(styles.button, styles.resetButton)}
              onClick={handleClearFilters}
              suffixIcon={<RiRefreshLine size={16} />}
              size="large"
              danger
            >
              {t('resetFilter')}
            </Button>
          )}
          <SelectFilterFields
            fields={fields}
            visibleFields={visibleFields}
            setVisibleFields={setVisibleFields}
            activatedFiltersCount={_size(initialData?.filters) || 0}
          />
        </Space>
      </DrawerHeader>
      <DrawerContent>
        <Space className={styles.fields} size={15}>
          {[...fields].map(renderFilterField)}
        </Space>
        <div className={styles.relationToggler}>
          <Typography.Title level={5} className={styles.relationFields}>{t('relationFields')}:</Typography.Title>
          <Select
            options={[
              { label: t('or'), value: FilterRelation.OR, disabled: hideOrRelation },
              { label: t('and'), value: FilterRelation.AND },
            ]}
            value={filterRelation}
            onChange={(value) => setFilterRelation(value as FilterRelation)}
            style={{ width: 65 }}
          />
        </div>
      </DrawerContent>
      <DrawerFooter>
        <Button onClick={submit} className={clsx(styles.button, styles.saveButton)}>{t('acceptAction')}</Button>
        <Button onClick={closeDrawer} type="link" className={clsx(styles.button, styles.resetButton)}>{t('cancel')}</Button>
      </DrawerFooter>
    </Drawer>
  );
};

export default registerDrawer(ConfigureFiltersDrawer, {
  id: 'configureFiltersDrawer',
});
