import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Permission } from '@fanckler/processing-auth';
import { registerDrawer, SharedDrawerProps } from '@nuvalt/ui-kit';
import { notification } from 'antd';
import _ from 'lodash';
import { IUser } from 'interfaces/IUser';
import { useUsers } from 'pages/Administration/Managers/hooks/useUsers';
import { AuthorisationContext } from 'contexts';
import { Button, Drawer, DrawerContent, DrawerFooter, DrawerHeader, With2FA } from 'components';
import {
  AssignableUserItemType,
  AssignableUserList,
  AssignableUserValues,
  SearchInput,
} from '../../../../components';
import styles from './AssignManagerForm.module.scss';

export type AssignManagerFormProps = {
  loading: boolean;
  onSubmit: (data: AssignableUserValues, otpCode?: string) => void;
  initialValues: { managerIds: number[], rootUnitId: number };
} & SharedDrawerProps;

const AssignManagerForm = ({
  loading: isMutating,
  onSubmit,
  initialValues,
  closeDrawer,
}: AssignManagerFormProps) => {
  const { user } = useContext(AuthorisationContext);
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [list, setList] = useState<IUser[]>([]);
  const [search, setSearch] = useState<string>('');
  const [added, setAdded] = useState<AssignableUserValues['added']>([]);
  const [removed, setRemoved] = useState<AssignableUserValues['removed']>([]);

  const { data, isLoading, refetch, isRefetching } = useUsers({
    paging: { limit: 25, offset: (page - 1) * 25 },
    sorting: [{ field: 'createdAt', direction: 'DESC' }],
    filter: {
      and: [
        { rootUnitId: { eq: initialValues.rootUnitId } },
      ],
      or: [
        { name: { iLike: search ? `%${search}%` : null } },
        { email: { iLike: search ? `%${search}%` : null } },
      ],
    },
  });

  const nodes = useMemo(() => data?.nodes || [], [data?.nodes]);
  const total = useMemo(() => data?.totalCount || 0, [data?.totalCount]);

  useEffect(() => {
    refetch();
  }, [refetch, page]);

  useEffect(() => {
    if (page === 1) {
      setList(nodes);
    } else {
      setList(prevList => [...prevList, ...nodes]);
    }
  }, [nodes, page]);

  const debouncedSearch = useMemo(() => _.debounce((searchValue: string) => {
    setSearch(searchValue);
    setPage(1);
  }, 300), []);

  const handleLoadMore = useCallback(() => {
    if (list.length < total) {
      setPage(prevPage => prevPage + 1);
    }
  }, [list.length, total]);

  const handleSubmit = useCallback(() => {
    if (!added.length && !removed.length) {
      return notification.error({ message: t('noChanges') });
    }

    onSubmit({ added, removed }, '123');
  }, [added, onSubmit, removed, t]);

  const handleSubmitOpt = useCallback((code: string) => {
    onSubmit({ added, removed }, code);
  }, [added, onSubmit, removed]);

  const checkAssignment = useCallback((manager: IUser) => {
    if (user?.id === manager.id) {
      return { disabled: true, reason: t('users.units.itIsYou') };
    }
    if (manager.group.permissions.some(p => p.name === Permission.ADMIN_IAM_ROOT_UNIT_ADMIN)) {
      return { disabled: true, reason: t('users.units.adminOfBusiness') };
    }
    return { disabled: false };
  }, [user, t]);

  const items: AssignableUserItemType[] = useMemo(() => {
    const removedIds = new Set(removed.map(({ id }) => id));
    const addedIds = new Set(added.map(({ id }) => id));

    return list.map((manager: IUser) => {
      const checked = addedIds.has(manager.id) ? true : removedIds.has(manager.id)
        ? false : initialValues.managerIds.includes(manager.id);

      const item = {
        id: manager.id,
        name: manager.name,
        email: manager.email,
        initialChecked: initialValues.managerIds.includes(manager.id),
        checked,
        ...checkAssignment(manager),
      };

      return item;
    });
  }, [removed, added, list, initialValues.managerIds, checkAssignment]);

  return (
    <Drawer>
      <DrawerHeader title={t('users.units.assignManagers')} onClose={closeDrawer} />
      <With2FA onSubmit={handleSubmitOpt} isDrawer>
        <DrawerContent className={styles.wrapper}>
          <SearchInput onSearch={debouncedSearch} loading={isLoading} />

          <AssignableUserList
            items={items}
            loading={isLoading}
            onLoadMore={handleLoadMore}
            moreLoading={page === 1 ? false : isRefetching}
            handlers={{ add: setAdded, remove: setRemoved }}
          />
        </DrawerContent>
        <DrawerFooter>
          <Button
            block
            onClick={handleSubmit}
            loading={isMutating}
          >
            {t('save')}
          </Button>

          <Button
            block
            type="link"
            color="error"
            onClick={closeDrawer}
          >
            {t('cancel')}
          </Button>
        </DrawerFooter>
      </With2FA>
    </Drawer>
  );
};

export default registerDrawer(AssignManagerForm, {
  id: 'AssignManagerForm',
});
