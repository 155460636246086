import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from 'antd';
import { AppState } from '../../../../../init/rootReducer';
import { IReplenishment } from 'interfaces/IReplenishment';
import { removeRequestFromApprove } from '../../utils/actions';
import { ProgressButton } from '../ProgressButton';
import { Button } from 'components';
import { AuditOutlined } from '@ant-design/icons';
import styles from './VerifyButton.module.scss';

export interface IEditButtonProps {
  data: IReplenishment,
  onDecline: (id: number) => void,
  onRefresh: () => void;
  onModalOpen: () => void;
  disabled?: boolean;
}

const VerifyButton = ({ data, onDecline, onRefresh, onModalOpen, disabled }: IEditButtonProps): ReactElement => {
  const requestIds = useSelector((state: AppState) => state.approvePOReducer);
  const [isApproved, setApproved] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    setApproved(requestIds.includes(data.id as never));
  }, [data.id, requestIds]);

  const declineHandler = async () => {
    dispatch(removeRequestFromApprove(data.id));
    await onDecline(data.id);
  };

  const refresh = useCallback(async () => {
    await onRefresh();
    setApproved(false);
  }, [onRefresh]);

  return (
    <>
      {isApproved ? (
        <ProgressButton
          onRefresh={refresh}
          declineHandler={declineHandler}
          onFinish={() => dispatch(removeRequestFromApprove(data.id))}
          disabled={disabled}
        />
      ) : (
        <Tooltip
          placement="left"
          title={t('users.replenishmentRequests.approveOrDecline')}
        >
          <Button
            type="link"
            disabled={disabled}
            onClick={onModalOpen}
            prefixIcon={<AuditOutlined style={{ fontSize: 18 }} />}
            className={styles.button}
          />
        </Tooltip>
      )}
    </>
  );
};

export default VerifyButton;
