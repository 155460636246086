import React, { useCallback } from 'react';
import { RiFileDownloadLine } from '@remixicon/react';
import { Dropdown } from 'antd';
import clsx from 'clsx';
import { noop } from 'lodash';
import { FileExtension } from 'hooks/useExportFile';
import { Button } from 'components/Button';
import { FileExcelOutlined, FileExclamationOutlined } from '@ant-design/icons';
import styles from './ExportData.module.scss';

type ExportDataProps = {
  onExport?: (type: FileExtension) => void;
};

const ExportData = ({
  onExport,
}: ExportDataProps) => {
  const handleExportClick = useCallback((type: FileExtension) => {
    return () => onExport ? onExport(type) : noop;
  }, [onExport]);

  if (!onExport) {
    return null;
  }

  return (
    <Dropdown
      overlayClassName={styles.dropdown}
      menu={{
        items: [
          {
            label: (
              <span onClick={handleExportClick(FileExtension.XLSX)}>
                <FileExcelOutlined />
                {FileExtension.XLSX}
              </span>
            ),
            key: '0',
          },
          {
            label: (
              <span onClick={handleExportClick(FileExtension.CSV)}>
                <FileExcelOutlined />
                {FileExtension.CSV}
              </span>
            ),
            key: '1',
          },
          {
            label: (
              <span onClick={handleExportClick(FileExtension.JSON)}>
                <FileExclamationOutlined />
                {FileExtension.JSON}
              </span>
            ),
            key: '3',
          },
        ],
      }}
      trigger={['click']}
    >
      <Button
        type="default"
        className={clsx(styles.exportButton)}
        suffixIcon={<RiFileDownloadLine size={16} />}
      />
    </Dropdown>
  );
};

export default ExportData;
