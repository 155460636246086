import axios, { SERVICE } from 'libs/axios';
import { useQuery } from 'libs/reactQuery';
import {
  NotificationQueryOptions,
  NotificationQueryParams,
  NotificationQueryResult,
} from './types';

const useGetNotifications = (
  queryParams?: NotificationQueryParams,
  queryOptions?: NotificationQueryOptions,
) => {
  const getNotifications = async () => {
    const url = `${SERVICE.NOTIFICATION}/notifications`;

    const { data } = await axios.get(url, { params: queryParams });
    return data;
  };

  return useQuery<NotificationQueryResult>({
    ...queryOptions,
    queryKey: ['notifications', queryParams],
    queryFn: getNotifications,
  });
};

export default useGetNotifications;
