import React from 'react';
import { Link } from 'react-router-dom';
import { CopyToClipboard as CopyAntd } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { registerDrawer, SharedDrawerProps } from '@nuvalt/ui-kit';
import { RiFileDownloadLine } from '@remixicon/react';
import { Button as AntdButton, notification, Tooltip } from 'antd';
import clsx from 'clsx';
import { IClientTransaction } from 'interfaces/IClientTransaction';
import { useConfirmRootAdmin } from 'hooks/useConfirmRootAdmin';
import { formatDate, generateBlockchainScanLink, truncateString } from 'utils';
import TransactionDetail from './TransactionDetail';
import { Button, CountUp, CurrencyBadge, Drawer, DrawerContent, DrawerHeader, StatusBadge } from 'components';
import PDFTransactionReceipt from 'components/PDFGenerator/documents/PDFTransactionReceipt';
import PDFGenerator from 'components/PDFGenerator/PDFGenerator';
import { CopyOutlined, EyeOutlined } from '@ant-design/icons';
import styles from './TransactionView.module.scss';

type TransactionViewProps = {
  transaction: IClientTransaction;
} & SharedDrawerProps;

const TransactionView = ({
  transaction,
  closeDrawer,
}: TransactionViewProps) => {
  const { isRoot: isRootAdmin } = useConfirmRootAdmin();
  const { t } = useTranslation();
  const {
    txId,
    amount,
    currency,
    network,
    fee,
    totalFee,
    serviceFee,
    type,
    direction,
    createdAt,
    updatedAt,
    availableAmount,
    remainingBalance,
  } = transaction;

  const handleDownloadSuccess = () => {
    notification.success({ message: t('transaction.downloaded') });
  };

  return (
    <Drawer>
      <DrawerHeader title={t('transaction.transactionReceipt')} onClose={closeDrawer}>
        <PDFGenerator
          data={transaction}
          document={PDFTransactionReceipt}
          onSuccess={handleDownloadSuccess}
          fileName="transaction-receipt"
        >
          <Button
            type="default"
            className={styles.downloadButton}
            suffixIcon={<RiFileDownloadLine />}
          >
            {t('transaction.downloadPDF')}
          </Button>
        </PDFGenerator>
      </DrawerHeader>
      <DrawerContent>
        {txId && (
          <div className={clsx(styles.card, styles.inlineContainer)}>
            <div>
              <p className={styles.label}>TxID</p>
              <div className={clsx(styles.value, styles.limitWidth)}>
                <Tooltip title={txId}>
                  {truncateString(txId, {
                    type: 'center',
                    startLength: 13,
                    endLength: 13,
                  })}
                </Tooltip>
              </div>
            </div>

            <div className={styles.headerButtons}>
              <CopyAntd text={txId} onCopy={() => notification.success({ message: t('copied') })}>
                <Tooltip title={t('clickToCopy')}>
                  <AntdButton
                    type="ghost"
                    icon={<CopyOutlined />}
                    className={styles.headerIconButton}
                  />
                </Tooltip>
              </CopyAntd>
              <Link
                target={'_blank'}
                to={generateBlockchainScanLink(network, txId)}
                className={styles.headerIconButton}
              >
                <Tooltip title={t('blockchainScan.viewOn')} placement="topLeft">
                  <AntdButton
                    type="ghost"
                    icon={<EyeOutlined />}
                    className={styles.headerIconButton}
                  />
                </Tooltip>
              </Link>
            </div>
          </div>
        )}

        <div className={styles.card}>
          <TransactionDetail label={t('transaction.amount')}>
            <div className={styles.amount}>
              <CountUp
                isRoundDown
                end={Number(amount || 0)}
                currency={currency}
                className={styles.balance}
              />
              <CurrencyBadge currency={currency} width="18px" textSize="16px" gap={5} textWeight={600} />
            </div>
          </TransactionDetail>
          <TransactionDetail label={t('transaction.network')} value={network} />
          {isRootAdmin && <TransactionDetail label={t('transaction.networkFee')} value={fee || 0} />}
          {isRootAdmin && <TransactionDetail label={t('transaction.serviceFee')} value={serviceFee || 0} />}
          <TransactionDetail label={t('transaction.totalFee')} value={totalFee || 0} />

          {transaction.direction !== 'payout' && (
            <TransactionDetail label={t('transaction.availableAmount')}>
              {availableAmount ? (
                <div className={styles.amount}>
                  <CountUp
                    isRoundDown
                    end={Number(availableAmount || 0)}
                    currency={currency}
                    className={styles.balance}
                  />
                  <CurrencyBadge currency={currency} width="18px" textSize="16px" gap={5} textWeight={600} />
                </div>
              ) : '-'}
            </TransactionDetail>
          )}

          <TransactionDetail label={t('transaction.remainingBalance')}>
            {remainingBalance ? (
              <div className={styles.amount}>
                <CountUp
                  isRoundDown
                  end={Number(remainingBalance || 0)}
                  currency={currency}
                  className={styles.balance}
                />
                <CurrencyBadge currency={currency} width="18px" textSize="16px" gap={5} textWeight={600} />
              </div>
            ) : '-'}
          </TransactionDetail>

          <TransactionDetail label={t('transaction.direction')}>
            <StatusBadge
              status={type === 'FEE' && direction !== 'refund' ? type : direction}
              textTransform="uppercase"
            />
          </TransactionDetail>
          <TransactionDetail label={t('transaction.receiptDate')} value={formatDate(updatedAt || createdAt)} />
        </div>
      </DrawerContent>
    </Drawer>
  );
};

export default registerDrawer(TransactionView, {
  id: 'TransactionView',
});
