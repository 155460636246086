import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Checkbox, Space } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { Notification } from 'api/notification/useGetNotifications';
import useReadNotification from 'api/notification/useReadNotification';
import clsx from 'clsx';
import moment from 'moment';
import { NotificationType } from 'components/Notifications/types';
import { Button } from 'components';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { ReactComponent as ReadIcon } from 'assets/icons/Read.svg';
import { ReactComponent as UnreadIcon } from 'assets/icons/Unread.svg';
import styles from './NotificationItem.module.scss';

const NotificationItem = ({
  data,
  value,
  onOpen,
  onChange,
  onRefresh,
  disabled,
}: {
  data: Notification;
  value: number[];
  disabled: boolean;
  onOpen: (item: Notification) => void;
  onChange: (list: number[]) => void;
  onRefresh: () => void;
}) => {
  const { t } = useTranslation();

  const { mutate } = useReadNotification({
    onSuccess: () => onRefresh(),
  });

  const handleRead = () => {
    onOpen(data);

    if (!data.readAt) mutate(data.id);
  };

  const handleCheck = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      return onChange([...value, data.id]);
    }

    onChange(value.filter(id => id !== data.id));
  };

  return (
    <Card className={clsx(styles.card, { [styles.unread]: !data.readAt })}>
      <Space direction="vertical" size={4} style={{ width: '100%' }}>
        <Space style={{ width: '100%', justifyContent: 'space-between' }}>
          <Space size={10}>
            <Checkbox
              checked={value.includes(data.id)}
              onChange={handleCheck}
              disabled={disabled}
            />
            <span
              className={clsx(styles.title, {
                [styles.warning]: data.type === NotificationType.WARNING,
                [styles.error]: data.type === NotificationType.ERROR,
              })}
            >
              {data.title}
            </span>
            {data.isAlert && <ExclamationCircleOutlined className={styles.alertIcon} />}
          </Space>

          {data.readAt ? <ReadIcon /> : <UnreadIcon />}
        </Space>

        <Space
          style={{
            width: '100%',
            paddingLeft: 26,
            alignItems: 'end',
            justifyContent: 'space-between',
          }}
        >
          <span className={styles.description}>
            <span className={styles.text}>{data.description}</span>

            <Button
              type="link"
              withoutBgShadow
              withoutWaveEffect
              onClick={handleRead}
              className={styles.button}
            >
              {t('notifications.readMore')}
            </Button>
          </span>

          <span className={styles.date}>{moment(data.createdAt).format('DD.MM.YYYY')}</span>
        </Space>
      </Space>
    </Card>
  );
};

export default NotificationItem;
