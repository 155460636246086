import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { RiArrowDownSLine } from '@remixicon/react';
import { ColumnsType } from 'antd/es/table';
import { UnknownType } from 'types/Unknown';
import { SelectItem, SelectWindow } from '../SelectWindow';
import { Button } from 'components/Button';
import styles from 'components/Filters/Filters.module.scss';

type SelectDisplayedColumnsProps = {
  columns?: ColumnsType<UnknownType>;
  displayedColumns?: string[];
  updateDisplayedColumns?: (columnKeys: string[]) => void;
  alwaysDisplayedColumns?: string[];
};

const SelectDisplayedColumns = ({
  columns,
  displayedColumns,
  updateDisplayedColumns,
  alwaysDisplayedColumns = [],
}: SelectDisplayedColumnsProps) => {
  const { t } = useTranslation();

  const onColumnSelect = useCallback((item: SelectItem, status: boolean) => {
    if (!displayedColumns || !alwaysDisplayedColumns || !updateDisplayedColumns) {
      return;
    }

    const columnsSet = new Set(displayedColumns);

    if (status) {
      columnsSet.add(item.key);
    } else {
      columnsSet.delete(item.key);
    }

    updateDisplayedColumns(Array.from(columnsSet));
  }, [alwaysDisplayedColumns, displayedColumns, updateDisplayedColumns]);

  return (
    <>
      {columns && displayedColumns && updateDisplayedColumns && (
        <SelectWindow
          items={columns.filter(col => !col.fixed).map(({ key, title }) => ({
            key: key as string,
            label: title as string,
            disabled: alwaysDisplayedColumns?.includes(key as string),
            selected: displayedColumns.includes(key as string),
          }))}
          onItemClick={onColumnSelect}
        >
          <Button
            type="link"
            className={styles.filterButton}
            suffixIcon={<RiArrowDownSLine size={16} />}
          >
            {t('columns')}
          </Button>
        </SelectWindow>
      )}
    </>
  );
};

export default SelectDisplayedColumns;
