import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { cleverRefetchQueries } from 'libs/reactQuery';
import clsx from 'clsx';
import { IClientTransaction } from 'interfaces/IClientTransaction';
import transactionView from 'pages/Administration/Units/components/NestedUnit/tabs/TransactionsTab/components/TransactionView';
import { useUpdateAnnotation } from 'pages/Users/Transactions/hooks';
import { getFilterFields } from 'pages/Users/Transactions/utils';
import { useGetClientTransactions } from 'hooks';
import { useConfirmRootAdmin } from 'hooks/useConfirmRootAdmin';
import useTableColumns, { filterByDisplayed } from 'hooks/useTableColumns';
import { LocalStorageKey } from 'utils';
import { getColumns } from './utils';
import { Filters, Pagination } from 'components';
import DraggableTable from 'components/DraggableTable';
import { withFilters } from 'components/Filters';
import useFiltersContext from 'components/Filters/context/FiltersContext';
import styles from './TransactionsTab.module.scss';

type TransactionsTabProps = {
  clientUUID: string;
};

const TransactionsTab = ({ clientUUID }: TransactionsTabProps) => {
  const { isRoot: isRootAdmin } = useConfirmRootAdmin();
  const { t } = useTranslation();

  const {
    filter,
    page,
    perPage,
    setPage,
    setPerPage,
  } = useFiltersContext();

  const {
    data,
    refetch,
    isLoading,
    isRefetching,
  } = useGetClientTransactions({
    paging: { limit: perPage, offset: (page - 1) * perPage },
    sorting: [{ field: 'createdAt', direction: 'DESC' }],
    filter: {
      and: [
        { unitId: { is: 'null' } },
        { client: { uuid: { eq: clientUUID } } },
      ],
    },
    enabled: Boolean(clientUUID),
    page: 'client',
  });

  const { mutateAsync: updateAnnotationAsync } = useUpdateAnnotation();

  const handleUpdateAnnotation = useCallback(async (id: number, annotation: string) => {
    try {
      await updateAnnotationAsync({ id, annotation });
      await cleverRefetchQueries('transactions');
      return { error: false };
    } catch (error) {
      return { error: true };
    }
  }, [updateAnnotationAsync]);

  const onViewTx = useCallback((transaction: IClientTransaction) => {
    transactionView.openDrawer({ transaction });
  }, []);

  useEffect(() => {
    if (clientUUID) refetch();
  }, [perPage, page, clientUUID, refetch]);

  const transactions = data?.nodes || [];
  const totalCount = data?.totalCount || 0;

  const getRowClassName = (record: IClientTransaction) => clsx(styles.row, {
    [styles.suspiciousRow]: record.origin === 'external_payout',
    // [styles.highlighted]: record.category === null,
  });

  const initialColumns = useMemo(() => (
    getColumns({
      t,
      isRootAdmin,
      onViewTx,
      onSaveAnnotation: handleUpdateAnnotation,
    })
  ), [handleUpdateAnnotation, isRootAdmin, onViewTx, t]);

  const { columns, setOrder, displayed } = useTableColumns({
    storageKey: LocalStorageKey.USERS_CLIENTS_TRANSACTIONS_COLUMNS,
    initialColumns,
  });

  return (
    <div className={styles.wrapper}>
      <Filters columns={columns} className={styles.filters} />
      <DraggableTable
        setOrder={setOrder}
        onRefresh={clientUUID ? refetch : undefined}
        columns={filterByDisplayed(columns, displayed)}
        dataSource={transactions}
        loading={isLoading || isRefetching}
        scroll={{ x: 'max-content' }}
        rowClassName={getRowClassName}
        rootClassName={styles.tableWrapper}
        pagination={false}
        rowKey="id"
      />
      <Pagination
        page={page}
        perPage={perPage}
        onPageChange={setPage}
        totalCount={totalCount}
        onPerPageChange={setPerPage}
        disablePageChange={isLoading || isRefetching || !totalCount}
        disablePerPageHandler={isLoading || isRefetching || !totalCount}
        elementsSize="xs"
      />
    </div>
  );
};

export default withFilters(TransactionsTab, {
  getFilterFieldsFn: getFilterFields,
});
