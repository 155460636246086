import React, { ReactElement, Suspense } from 'react';
import { DrawerRenderer, ThemeProvider } from '@nuvalt/ui-kit';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { queryClient, QueryClientProvider } from 'libs/reactQuery';
import { ConfigProvider as AntdConfigProvider } from 'antd';
import routes from './routes';
import { useLocale } from 'contexts/LocaleContext';
import { AuthorisationProvider } from './contexts';
import { PageLoader, Router } from './components';
import './App.scss';

function App(): ReactElement {
  const { localeData } = useLocale();

  return (
    <AntdConfigProvider locale={localeData.antdLocale}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider>
          <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-right" />
          <AuthorisationProvider>
            <DrawerRenderer />
            <Suspense fallback={<PageLoader />}>
              <Router routes={routes} />
            </Suspense>
          </AuthorisationProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </AntdConfigProvider>
  );
}

export default App;
