import _ from 'lodash';
import { UnknownType } from 'types/Unknown';

const uniqueValues = <T extends Record<string, UnknownType>>(
  obj1: T,
  obj2: T,
) => {
  return _.reduce(obj2, (result, value, key) => {
    if (!_.isEqual(value, obj1[key])) {
      (result as UnknownType)[key] = value;
    }
    return result;
  }, {} as T);
};

export default uniqueValues;
