import React from 'react';
import { useTranslation } from 'react-i18next';
import { RiCloseLine } from '@remixicon/react';
import { Tooltip } from 'antd';
import clsx from 'clsx';
import styles from './Chip.module.scss';

type ChipProps = {
  children: React.ReactNode;
  onClick?: () => void;
  isActive?: boolean;
  onDelete?: () => void;
};

const Chip = ({ children, onClick, isActive, onDelete }: ChipProps) => {
  const { t } = useTranslation();
  return (
    <span
      className={clsx(styles.chip, isActive && styles.active)}
      onClick={onClick}
    >
      {children}
      <Tooltip title={t('cancel')}>
        <RiCloseLine size={16} className={styles.cross} onClick={onDelete} />
      </Tooltip>
    </span>
  );
};

export default Chip;
