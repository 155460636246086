import React, { PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { RiFilter3Line } from '@remixicon/react';
import { Input } from 'antd';
import { ColumnsType } from 'antd/es/table';
import clsx from 'clsx';
import qs from 'qs';
import { UnknownType } from 'types/Unknown';
import { FileExtension } from 'hooks/useExportFile';
import { convertFiltersToObject, stringifyFilters } from 'components/Filters/utils';
import useFiltersContext from './context/FiltersContext';
import { Button } from 'components/Button';
import { FilterRelation } from 'components/Filters';
import { AppliedFiltersList, configureFiltersDrawer, ExportData, SelectDisplayedColumns } from './components';
import styles from './Filters.module.scss';

type FiltersProps = PropsWithChildren<{
  onExport?: (type: FileExtension) => void;
  columns?: ColumnsType<UnknownType>;
  displayedColumns?: string[];
  updateDisplayedColumns?: (columnKeys: string[]) => void;
  alwaysDisplayedColumns?: string[];
  className?: string;
  disabled?: boolean;
}>;

const Filters = ({
  onExport,
  columns,
  displayedColumns,
  updateDisplayedColumns,
  alwaysDisplayedColumns = [],
  children,
  className,
  disabled,
}: FiltersProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState(() => {
    const {
      searchValue: searchValueField,
    } = qs.parse(location.search, { ignoreQueryPrefix: true });
    return (searchValueField as string | undefined) ?? '';
  });
  const {
    setFilter,
    filters,
    filterRelation,
    filterFields,
  } = useFiltersContext();
  const filtersArray = useMemo(() => Object.entries(filters), [filters]);

  const clearFilters = useCallback(() => {
    setFilter({});

    const {
      filters: filtersObject,
      filterRelation: filterRelationField,
      searchValue: searchValueField,
      ...rest
    } = qs.parse(location.search, { ignoreQueryPrefix: true });

    navigate({
      pathname: location.pathname,
      search: qs.stringify(rest),
    }, { replace: true });
  }, [navigate, setFilter]);

  const applyFilter = useCallback((
    args: {
      filters: Record<string, UnknownType>,
      filterRelation?: FilterRelation,
      visibleFields?: Record<string, boolean>,
      searchValue?: string,
    },
  ) => {
    let visibleFields = args.visibleFields as Record<string, boolean>;
    if (!visibleFields) {
      visibleFields = [...filterFields].reduce((acc, [key, filterField]) => ({
        ...acc,
        [key]: Boolean(
          filterField.alwaysVisible || (filters && key in filters),
        ),
      }), {});
    }
    const filledFilters = Object.keys(args.filters).filter((key) => visibleFields[key]);

    if (filledFilters.length === 0 && !(args.searchValue ?? searchValue)) {
      clearFilters();
      return;
    }

    const search: Record<string, unknown> = convertFiltersToObject(
      filterFields,
      args.filterRelation || filterRelation,
      filledFilters.reduce((acc, key) => ({ ...acc, [key]: args.filters[key] }), {}),
      args.searchValue ?? searchValue,
    );

    const {
      filters: filtersObject,
      ...rest
    } = qs.parse(location.search, { ignoreQueryPrefix: true });

    navigate({
      pathname: location.pathname,
      search: qs.stringify({
        ...rest,
        page: 1,
        filterRelation: args.filterRelation || filterRelation,
        filters: stringifyFilters(filterFields, args.filters),
        searchValue: (args.searchValue ?? searchValue) || undefined,
      }),
    }, { replace: true });

    return setFilter(search);
  }, [clearFilters, filterFields, filterRelation, filters, navigate, searchValue, setFilter]);

  const handleOpenDrawer = () => {
    configureFiltersDrawer.openDrawer({
      initialData: { filters, filterRelation },
      fields: filterFields,
      applyFilter,
      clearFilters,
    });
  };

  const handleSearch = (value: string) => {
    setSearchValue(value);
    applyFilter({ filters, searchValue: value });
  };

  return (
    <div className={clsx(styles.wrapper, className)}>
      <div className={styles.head}>
        <Input.Search
          placeholder={`${t('search')}...`}
          onSearch={handleSearch}
          defaultValue={searchValue}
          style={{ width: 264 }}
          disabled={disabled}
        />
        {children}
        <SelectDisplayedColumns
          columns={columns}
          displayedColumns={displayedColumns}
          updateDisplayedColumns={updateDisplayedColumns}
          alwaysDisplayedColumns={alwaysDisplayedColumns}
        />
        {onExport && (
          <ExportData onExport={onExport} />
        )}
        <Button
          type="default"
          onClick={handleOpenDrawer}
          suffixIcon={<RiFilter3Line size={16} />}
          className={styles.filterButton}
          disabled={disabled}
        >
          {t('filter')}
          {filtersArray.length > 0 ? ` (${filtersArray.length})` : ''}
        </Button>
      </div>
      <AppliedFiltersList
        applyFilter={applyFilter}
        searchValue={searchValue}
        onClearSearch={() => handleSearch('')}
      />
    </div>
  );
};

export default Filters;
