import React from 'react';
import { Link } from 'react-router-dom';
import { MenuProps as AntdMenuProps } from 'antd';
import { CustomMenuItem } from '../types';
import { CheckPermissionsFunction } from 'contexts/AuthorisationContext/AuthorisationContext';

const generateMenuItems = (
  items: CustomMenuItem[],
  checkPermissions: CheckPermissionsFunction,
  disabled?: boolean,
): AntdMenuProps['items'] => {
  return items
    .filter(item => !item.permissions || checkPermissions(item.permissions))
    .map(item => {
      const { children, path, type, label, ...rest } = item;
      const childItems = children ? generateMenuItems(children, checkPermissions, disabled) : undefined;

      if (type === 'group' && (!childItems || childItems.length === 0)) {
        return null;
      }

      return {
        ...rest,
        disabled,
        key: path || String(label),
        label: path ? <Link to={path}> {label} </Link> : label,
        children: childItems,
        type: type === 'group' || type === 'divider' ? type : undefined,
      };
    })
    .filter(item => item !== null);
};

export default generateMenuItems;
