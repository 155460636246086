import React from 'react';
import { Image } from 'antd';
import clsx from 'clsx';
import { ImageStatusEnum, useCheckImage } from 'hooks';
import { StatusStateEnum } from 'hooks/useUpdateStatus';
import { BASE_IMAGE_URL } from 'constants/image';
import { StatusBadge } from 'components/ActivityStatusDropdown';
import styles from './UserAvatar.module.scss';

export type UserAvatarProps = {
  name: string;
  status?: StatusStateEnum;
  role?: string;
  altSrc?: string;
  src?: string | null;
  size?: number;
  withoutText?: boolean;
  className?: string;
};

const UserAvatar = ({
  name,
  status,
  role,
  src,
  altSrc,
  size = 44,
  withoutText,
  className,
}: UserAvatarProps) => {
  const avatarImageStatus = useCheckImage({ src: src || '' });

  const AvatarCircleJSX = (
    <div
      style={{ width: size, height: size }}
      className={clsx(styles.avatar, className)}
    >
      {avatarImageStatus === ImageStatusEnum.SUCCESS ? (
        <Image src={`${BASE_IMAGE_URL}${src}`} preview={false} alt={name} />
      ) : altSrc ? (
        <Image src={altSrc} preview={false} alt={name} />
      ) : (
        name[0]
      )}
    </div>
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.logo}>
        {status ? (
          <StatusBadge status={status} absolute>
            {AvatarCircleJSX}
          </StatusBadge>
        ) : AvatarCircleJSX}
      </div>
      {!withoutText && (
        <div className={styles.info}>
          <span className={styles.name}>{name}</span>
          <span className={styles.role}>{role}</span>
        </div>
      )}
    </div>
  );
};

export default UserAvatar;
