import LanguageDetector from 'i18next-browser-languagedetector';
import languageMapping from './languageMapping';

class CustomLanguageDetector extends LanguageDetector {
  private supportedLanguages: string[];

  constructor(supportedLanguages: string[]) {
    super();
    this.supportedLanguages = supportedLanguages;
  }

  detect(): string | undefined {
    let detectedLng = super.detect();

    if (Array.isArray(detectedLng)) {
      detectedLng = detectedLng[0];
    }

    if (detectedLng) {
      if (languageMapping[detectedLng]) {
        detectedLng = languageMapping[detectedLng];
      }

      if (!this.supportedLanguages.includes(detectedLng)) {
        return this.supportedLanguages[0];
      }

      return detectedLng;
    }

    return this.supportedLanguages[0];
  }
}

export default CustomLanguageDetector;
