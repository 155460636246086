import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { notification } from 'antd';
import { IReplenishment } from 'interfaces/IReplenishment';
import { NestedUnitType } from 'interfaces/IUnit';
import { formVerifyRequest } from 'pages/Administration/ReplenishmentRequests/components';
import useApproveReplenishmentRequest from 'pages/Administration/ReplenishmentRequests/hooks/useApproveReplenishmentRequest';
import useRejectReplenishmentRequest from 'pages/Administration/ReplenishmentRequests/hooks/useRejectReplenishmentRequest';
import { useTablePagination } from 'hooks';
import useTableColumns from 'hooks/useTableColumns';
import { useReplenishmentsById } from './hooks';
import { useAuthorisationContext } from 'contexts';
import { LocalStorageKey } from 'utils/localStorage';
import getColumns from './utils/getColumns';
import { moveItemsToFront } from './utils/moveItemsToFront';
import { Pagination } from 'components';
import DraggableTable from 'components/DraggableTable';
import styles from './PendingApprovalsTab.module.scss';

export type PendingApprovalsTabProps = {
  unit: NestedUnitType | undefined;
};

const PendingApprovalsTab = ({ unit }: PendingApprovalsTabProps) => {
  const { t } = useTranslation();

  const { page, perPage, setPage, setPerPage } = useTablePagination({ withoutURL: true });

  const {
    twoFA: {
      onError,
      onSuccess,
    },
  } = useAuthorisationContext();

  const { data, isLoading, refetch } = useReplenishmentsById(unit?.uuid, {
    sorting: [{ field: 'createdAt', direction: 'DESC' }],
    paging: { limit: perPage, offset: (page - 1) * perPage },
  });

  const { mutate: approveRequest, isPending: isApprovePending } = useApproveReplenishmentRequest({
    onSuccess: (_, variables) => {
      onSuccess(_, () => {
        formVerifyRequest.closeDrawer();

        notification.success({
          message: `${t('users.replenishmentRequests.title')} "${variables?.id}"`,
          description: t('users.replenishmentRequests.approved', { id: `${variables?.id}` }),
        });

        refetch();
      });
    },
    onError: (error) => {
      onError(error, () => {
        notification.error({
          description: error.response?.data.message,
          message: error.response?.statusText,
        });
      });
    },
  });

  const { mutate: rejectRequest, isPending: isRejectPending } = useRejectReplenishmentRequest({
    onSuccess: (_, variables) => {
      onSuccess(_, () => {
        formVerifyRequest.closeDrawer();
        notification.success({
          message: `${t('users.replenishmentRequests.title')} "${variables?.id}"`,
          description: t('users.replenishmentRequests.declined', { id: `${variables?.id}` }),
        });

        refetch();
      });
    },
    onError: (error) => {
      onError(error, () => {
        notification.error({
          description: error.response?.data.message,
          message: error.response?.statusText,
        });
      });
    },
  });

  const sortedReplenishmmentRequests = moveItemsToFront(data?.nodes || [], unit?.uuid);
  const totalCount = data?.totalCount || 0;
  const wallets = unit?.wallets;

  const getRowClassName = (record: IReplenishment) => {
    if (record.unit.uuid === unit?.uuid) {
      return `${styles.row} ${styles.highlighted}`;
    }
    return styles.row;
  };

  const openVerifyModal = useCallback((request: IReplenishment) => {
    formVerifyRequest.openDrawer({
      initialValues: request,
      loading: isApprovePending || isRejectPending,
      onApprove: approveRequest,
      onReject: rejectRequest,
    });
  }, [approveRequest, isApprovePending, isRejectPending, rejectRequest]);

  const initialColumns = useMemo(() => getColumns({
    t,
    wallets: wallets || [],
    currentUnit: unit,
    uuid: unit?.uuid,
    openVerifyModal,
    onRefresh: refetch,
    onDecline: rejectRequest,
  }), [t, wallets, unit, openVerifyModal, refetch, rejectRequest]);

  const { columns, setOrder } = useTableColumns({
    storageKey: LocalStorageKey.UNIT_PENDING_APPROVAL_COLUMNS,
    initialColumns,
  });

  return (
    <div className={styles.wrapper}>
      <DraggableTable
        setOrder={setOrder}
        onRefresh={refetch}
        dataSource={sortedReplenishmmentRequests}
        rowClassName={getRowClassName}
        rootClassName={styles.tableWrapper}
        loading={isLoading}
        columns={columns}
        scroll={{ x: 'max-content' }}
        pagination={false}
        rowKey="id"
      />
      <Pagination
        page={page}
        perPage={perPage}
        onPageChange={setPage}
        totalCount={totalCount}
        onPerPageChange={setPerPage}
        disablePageChange={isLoading || !totalCount}
        disablePerPageHandler={isLoading || !totalCount}
        elementsSize="xs"
      />
    </div>
  );
};

export default PendingApprovalsTab;
