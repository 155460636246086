import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'antd';
import { Path } from 'routes/interfaces/Path';
import { EyeOutlined } from '@ant-design/icons';

type UnitLinkPropsType = {
  uuid: string;
  name: string;
};

export const UnitLink = ({ uuid, name }: UnitLinkPropsType): ReactElement => {
  const { t } = useTranslation();

  return (
    <Link to={`${Path.ADMINISTRATION_BUSINESSES}/units/${uuid}`} target="_blank">
      <Tooltip placement="topLeft" title={t('users.units.viewUnit')}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <EyeOutlined style={{ fontSize: '17px', marginRight: 10 }} /> {name}
        </div>
      </Tooltip>
    </Link>
  );
};
