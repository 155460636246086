import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { format as formatDate } from 'date-fns';
import { TFunction } from 'i18next';
import { utils, write } from 'xlsx';
import { UnknownType } from 'types/Unknown';

const dateFields = ['createdAt', 'updatedAt', 'completedAt', 'expirationAt'];
const dateFormat = 'dd.MM.yyyy HH:mm:ss';

export enum FileExtension {
  CSV = 'csv',
  XLSX = 'xlsx',
  JSON = 'json',
}

type ExportFileType = {
  filename: string;
  columns: UnknownType[];
  data: UnknownType[];
  type: FileExtension
};

type DownloadFileType = {
  filename: string;
  fileType: string;
  data: string;
};

type FileType = {
  name: string;
  format: FileExtension;
  prefix?: string | number
};

const getFileType = (type: FileExtension) => {
  switch (type) {
    case FileExtension.CSV:
      return 'text/csv';
    case FileExtension.XLSX:
      return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    case FileExtension.JSON:
      return 'application/json';
    default:
      return 'text/csv';
  }
};

type PrepareDateToExportCsv = Pick<ExportFileType, 'columns' | 'data'> & { translate: TFunction };

const prepareDataToExportCsv = ({ columns, data, translate }: PrepareDateToExportCsv) => {
  const column = columns.filter((i) => i.dataIndex !== '' && i.key);
  const headers = column.reduce((a, i) => {
    a.push(i.title);
    return a;
  }, []).join(',');

  const body = data.reduce((a, b) => {
    const rows: unknown[] = [];

    column.forEach((i) => {
      const key = i?.key ?? i.dataIndex;
      const k = key.includes('.') ? key.split('.') : key;
      if (typeof k === 'string') {
        if (k === 'uuid') {
          rows.push(`${process.env.REACT_APP_FRONT_CUSTOMER}/auth/signup?ref=${b[k]}`);
        } else if (k === 'category') {
          rows.push(`${b[k]?.group?.name ?? ''} >> ${b[k]?.name ?? ''}`);
        } else if (k === 'name') {
          if (b.firstName || b.lastName) {
            rows.push(`${b?.firstName} ${b?.lastName}`);
          } else {
            rows.push(b[k]);
          }
        } else if (dateFields.includes(k)) {
          rows.push(formatDate(new Date(b[k]), dateFormat));
        } else if (k === 'confirmation') {
          rows.push(`${translate('email')}: ${b?.isEmailConfirmed}; ${translate('phone')}: ${b?.isPhoneConfirmed}`);
        } else {
          rows.push(b[k]);
        }
      } else if (Array.isArray(b[k[0]])) {
        const deep = b[k[0]].reduce((d: unknown[], j: unknown[]) => {
          d.push(j[k[1]]);
          return d;
        }, []);
        rows.push(deep.join(';'));
      } else if (dateFields.some(field => k.includes(field))) {
        rows.push(formatDate(new Date(b[k[0]][k[1]]), dateFormat));
      }
    });

    a.push([rows].join(','));

    return a;
  }, []);

  return [...[headers], ...body].join('\n');
};

const prepareDataToExportXlsx = ({ data, columns }: ExportFileType) => {
  const workbook = utils.book_new();

  // Create a map of column keys to titles
  const keyToTitleMap = columns.reduce((map, column) => {
    map[column.key] = column.title;
    return map;
  }, {} as Record<string, string>);

  const formattedData = data.map((item) => {
    const result: Record<string, UnknownType> = {};
    columns.forEach(column => {
      if (item[column.key]) {
        result[keyToTitleMap[column.key]] = item[column.key];
      }
    });

    // Format date fields
    dateFields.forEach(field => {
      if (result[keyToTitleMap[field]]) {
        result[keyToTitleMap[field]] = formatDate(new Date(result[keyToTitleMap[field]]), dateFormat);
      }
    });
    return result;
  });

  const worksheet = utils.json_to_sheet(formattedData, {
    header: columns.map(column => column.title),
  });
  utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  return write(workbook, { bookType: 'xlsx', type: 'array' });
};

const prepareDataToExportJson = ({ data }: Pick<ExportFileType, 'data'>) => {
  return JSON.stringify(data);
};

const getFunctionByType = (type: FileExtension) => {
  switch (type) {
    case FileExtension.CSV:
      return prepareDataToExportCsv;
    case FileExtension.XLSX:
      return prepareDataToExportXlsx;
    case FileExtension.JSON:
      return prepareDataToExportJson;
    default:
      return prepareDataToExportCsv;
  }
};

export const useExportFile = () => {
  const { t } = useTranslation();

  const downloadFile = useCallback(({ data, filename, fileType }: DownloadFileType) => {
    const blob = new Blob([data], { type: fileType });

    const a = document.createElement('a');
    a.download = filename;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  }, []);

  const exportFile = useCallback((args: ExportFileType): void => {
    const columnKeys = args.columns.map(column => column.key);
    const formattedData = (args.data || []).map((item) => {
      return Object.keys(item).filter(key => columnKeys.includes(key)).reduce(
        (obj, key) => ({ ...obj, [key]: item[key] }),
        {},
      );
    });
    const { type, filename } = args;
    if (!type) return;
    const fileType = getFileType(type);
    const data = getFunctionByType(type)({
      ...args,
      translate: t,
      data: formattedData,
    });

    downloadFile({
      data,
      filename,
      fileType,
    });
  }, [downloadFile, t]);

  const generateFilename = ({ name, format, prefix }: FileType): string => {
    const date = formatDate(new Date(), 'yyyyddMM_HHmmss');
    return `${name}${prefix ? `_${prefix}` : ''}_${date}.${format}`;
  };

  return {
    exportFile,
    generateFilename,
  };
};
