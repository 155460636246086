import React, { PropsWithChildren } from 'react';
import clsx from 'clsx';
import styles from './DrawerContent.module.scss';

type DrawerContentProps = PropsWithChildren & {
  className?: string;
};

const DrawerContent = ({ children, className }: DrawerContentProps) => {
  return (
    <div className={clsx(styles.drawerContent, className)}>
      {children}
    </div>
  );
};

export default DrawerContent;
