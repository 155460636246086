import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { cleverRefetchQueries } from 'libs/reactQuery';
import { notification } from 'antd';
import useUpdateUnitManagers from 'api/unit/useUpdateUnitManagers';
import { StageEnum } from 'hooks/use2FA';
import { OTP_ERROR } from 'constants/index';
import { useAuthorisationContext } from 'contexts';
import { assignManagerForm } from '../AssignManagerForm';
import { Button } from 'components';
import { AssignableUserValues } from '../../../../components';
import { UserAddOutlined } from '@ant-design/icons';
import styles from './AssignManagerButton.module.scss';

export type AssignManagerButtonProps = {
  unitId: number;
  rootUnitId: number;
  managerIds: number[];
  disabled?: boolean;
};

const AssignManagerButton = ({
  unitId,
  rootUnitId,
  managerIds,
  disabled,
}: AssignManagerButtonProps) => {
  const { t } = useTranslation();
  const {
    twoFA: {
      onSuccess,
      onError,
      stage,
      setStage,
    },
  } = useAuthorisationContext();

  const { mutate, isPending } = useUpdateUnitManagers({
    onSuccess: async (res) => {
      onSuccess(res, async () => {
        notification.success({ message: t('users.units.managersUpdated') });

        await cleverRefetchQueries('unit');
        assignManagerForm.closeDrawer();
      });
    },
    onError: (error) => {
      onError(error, () => {
        if (stage !== StageEnum.DEFAULT && error.response?.data?.message.includes(OTP_ERROR)) {
          setStage(StageEnum.DEFAULT);
        }

        notification.error({
          description: error?.response?.data?.message,
          message: error?.response?.status,
        });
      });
    },
  });

  const handleSubmit = useCallback((data: AssignableUserValues, otpCode?: string) => {
    mutate({ id: unitId, body: { ...data, otpCode } });
  }, [mutate, unitId]);

  const handleOpen = () => {
    assignManagerForm.openDrawer({
      loading: isPending,
      onSubmit: handleSubmit,
      initialValues: { managerIds, rootUnitId },
    });
  };

  useEffect(() => {
    if (assignManagerForm.isOpened()) {
      assignManagerForm.updateDrawer({ loading: isPending });
    }
  }, [isPending]);

  return (
    <Button
      type="default"
      textSize="14px"
      disabled={disabled}
      onClick={handleOpen}
      suffixIcon={<UserAddOutlined style={{ fontSize: 16 }} />}
      className={styles.button}
    >
      {t('users.units.assignManagers')}
    </Button>
  );
};

export default AssignManagerButton;
