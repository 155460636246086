import { TFunction } from 'i18next';
import { ALLOWED_EMAIL_SYMBOLS } from 'constants/validation';
import { ComparisonType, FilterField, FilterFieldType, SelectOption } from 'components/Filters';

const getFilterFields = ({
  translate,
  nameOptions,
}: {
  translate: TFunction,
  nameOptions?: SelectOption[],
}): Map<string, FilterField> => new Map<string, FilterField>([
  ['name', {
    searchable: true,
    label: translate('name'),
    type: FilterFieldType.SELECT,
    field: 'name',
    alwaysVisible: true,
    comparisonType: ComparisonType.EQUAL,
    options: nameOptions || [],
  }],
  ['updatedBy.email', {
    searchable: true,
    label: translate('email'),
    type: FilterFieldType.TEXT,
    field: 'updatedBy.email',
    maxLength: 318,
    alwaysVisible: true,
    comparisonType: ComparisonType.I_LIKE,
    regex: ALLOWED_EMAIL_SYMBOLS,
  }],
  ['isActive', {
    label: translate('status'),
    type: FilterFieldType.SELECT,
    field: 'isActive',
    comparisonType: ComparisonType.IS,
    options: [
      { label: translate('activated'), value: true },
      { label: translate('deactivated'), value: false },
    ],
  }],
  ['rootUnit.name', {
    searchable: true,
    label: translate('business.name'),
    type: FilterFieldType.TEXT,
    field: 'rootUnit.name',
    maxLength: 100,
    comparisonType: ComparisonType.I_LIKE,
  }],
]);

export default getFilterFields;
