import React, { useCallback, useId, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, Dropdown } from 'antd';
import { IWallet } from 'types/IWallet';
import { WalletNetworkEnum } from 'types/WalletNetworkEnum';
import { filterExistingNetworks } from '../../../../pages/Administration/Units/components/NestedUnit/utils';
import { networkByTypeMap } from 'constants/wallet';
import { CurrencyIcon } from 'utils/currencyIcon';
import { Button } from 'components';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import styles from './NetworkSelection.module.scss';

type NetworkSelectionProps = {
  selectedNetworks: WalletNetworkEnum[];
  onToggleSelectedNetwork: (network: WalletNetworkEnum) => void;
  wallets: IWallet[];
  isLoading?: boolean;
};

const TRIGGER: ['click'] = ['click'];

const NetworkSelection = ({ selectedNetworks, onToggleSelectedNetwork, wallets, isLoading }: NetworkSelectionProps) => {
  const { t } = useTranslation();
  const buttonId = useId();
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = useCallback((network: WalletNetworkEnum) => (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation();

    onToggleSelectedNetwork(network);
  }, [onToggleSelectedNetwork]);

  const availableNetworks = useMemo(() => filterExistingNetworks(wallets), [wallets]);

  const selectedNetworksIcons = useMemo(() => {
    if (isLoading) {
      return [];
    }
    return selectedNetworks.filter(
      (network) => availableNetworks.includes(network),
    ).map(item => CurrencyIcon[item]);
  }, [availableNetworks, isLoading, selectedNetworks]);

  const dropdownItems = useMemo(
    () => {
      return availableNetworks.map(item => {
        const name = networkByTypeMap.get(item);
        return ({
          key: item,
          label: (
            <span onClick={handleClick(item)}>
              <Checkbox
                checked={selectedNetworks.includes(item)}
                disabled={selectedNetworks.length === 1 && selectedNetworks[0] === item}
              />
              <img src={CurrencyIcon[item]} alt={name} />
              {name}
            </span>
          ),
        });
      });
    },
    [availableNetworks, handleClick, selectedNetworks],
  );

  return (
    <Dropdown
      onOpenChange={setIsOpen}
      overlayClassName={styles.dropdown}
      menu={{ items: dropdownItems }}
      trigger={TRIGGER}
      placement="bottomRight"
      getPopupContainer={() => document.getElementById(buttonId) as HTMLElement}
      arrow
      disabled={availableNetworks.length === 0 || isLoading}
    >
      <Button
        id={buttonId}
        withoutBgShadow
        withoutWaveEffect
        type="link"
        prefixIcon={availableNetworks.length > 0 && selectedNetworks.length > 0 && (
          <span className={styles.buttonIcons}>
            {selectedNetworksIcons.map((icon, index) => {
              if (typeof icon === 'string') {
                return (<img key={index} src={icon} alt={networkByTypeMap.get(selectedNetworks[index])} />);
              }
              return icon;
            })}
          </span>
        )}
        suffixIcon={isOpen ? <UpOutlined /> : <DownOutlined />}
        className={styles.button}
      >

        {t('chooseNetwork')}
      </Button>
    </Dropdown>
  );
};

export default NetworkSelection;
