import { useTranslation } from 'react-i18next';
import axios, { SERVICE } from 'libs/axios';
import { useMutation } from 'libs/reactQuery';
import { notification } from 'antd';
import { CreateLabelVariables, MutationOptions } from './types';

const useCreateLabel = (clientUUID: string, mutationOptions: MutationOptions) => {
  const { t } = useTranslation();

  const createLabel = async (body: CreateLabelVariables) => {
    const url = `${SERVICE.CLIENT}/labels/create/${clientUUID}`;
    await axios.post(url, body);
  };

  return useMutation({
    ...mutationOptions,
    mutationFn: createLabel,
    onSuccess(data, variables, context) {
      notification.success({ message: t('labelSucceffullyCreated') });

      if (mutationOptions?.onSuccess) {
        mutationOptions.onSuccess(data, variables, context);
      }
    },
    onError(error, variables, context) {
      notification.error({ message: error.name, description: error.message });

      if (mutationOptions?.onError) {
        mutationOptions.onError(error, variables, context);
      }
    },
  });
};

export default useCreateLabel;
