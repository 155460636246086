import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { registerDrawer, SharedDrawerProps } from '@nuvalt/ui-kit';
import { Form, Input } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { IUnit } from 'interfaces';
import { UnknownType } from 'types/Unknown';
import { UnitFormData } from '../../types';
import { Button, Drawer, DrawerContent, DrawerFooter, DrawerHeader, OrganizationalLogoUpload, With2FA } from 'components';
import styles from './FormUnit.module.scss';

export type InitialValues = IUnit & { rootUnitId: number; unitId: number };

export type ModalUnitFormProps = {
  title: string;
  initialValues: Partial<InitialValues>;
  loading: boolean;
  onSubmit: (data: FormData) => void;
} & SharedDrawerProps;

const FormUnit = ({
  title,
  loading,
  onSubmit,
  initialValues,
  closeDrawer,
}: ModalUnitFormProps) => {
  const { t } = useTranslation();
  const [form] = useForm();
  const savedData = useRef<UnitFormData>();

  const [changedValues, setChangedValues] = useState<string[]>([]);

  const isLogoChanged = useMemo(() => changedValues.includes('logo'), [changedValues]);

  const handleFinish = useCallback((values: UnitFormData, otpCode?: string) => {
    if (!otpCode) {
      savedData.current = values;
    }
    const formData = new FormData();

    if (values.logo && isLogoChanged) {
      formData.set('file', values.logo);
    }
    if (!values.logo && isLogoChanged) {
      formData.set('isRemoveLogo', 'true');
    }
    if (values.name?.trim()) {
      formData.set('name', values.name.trim());
    }
    if (values.description?.trim()) {
      formData.set('description', values.description.trim());
    }
    if (values.isActive !== undefined) {
      formData.set('isActive', JSON.stringify(values.isActive));
    }
    if (values.rootUnitId) {
      formData.set('rootUnitId', values.rootUnitId.toString());
    }
    if (initialValues.rootUnitId) {
      formData.set('rootUnitId', initialValues.rootUnitId.toString());
    }
    if (initialValues.unitId) {
      formData.set('parent', initialValues.unitId.toString());
    }
    if (otpCode) {
      formData.set('otpCode', otpCode);
    }

    onSubmit(formData);
  }, [initialValues, isLogoChanged, onSubmit]);

  const handleSubmitOtp = useCallback((otp: string) => {
    if (!savedData.current) return;

    handleFinish(savedData.current, otp);
  }, [handleFinish, savedData]);

  const handleValuesChange = useCallback((values: UnknownType) => {
    const [changedValueKey] = Object.keys(values);

    if (changedValues.includes(changedValueKey)) return;

    setChangedValues(prevState => [...prevState, changedValueKey]);
  }, [changedValues]);

  return (
    <Drawer>
      <DrawerHeader title={title} onClose={closeDrawer} />
      <With2FA onSubmit={handleSubmitOtp} isDrawer>
        <DrawerContent>
          <Form
            form={form}
            onFinish={handleFinish}
            onValuesChange={handleValuesChange}
            initialValues={initialValues}
            className={styles.form}
          >
            <div>
              <Form.Item name="logo">
                <OrganizationalLogoUpload type="unit" />
              </Form.Item>

              <Form.Item
                name="name"
                label={t('users.units.unitName')}
                className={styles.formItem}
                rules={[
                  { min: 1, max: 100 },
                  {
                    required: true, whitespace: false,
                    message: t('users.units.validation.enterUnitName'),
                  },
                ]}
              >
                <Input
                  className={styles.input}
                  placeholder={t('users.units.validation.enterUnitName')}
                />
              </Form.Item>

              <Form.Item
                name="description"
                label={t('users.units.desc')}
                className={styles.formItem}
              >
                <Input
                  className={styles.input}
                  placeholder={t('users.units.validation.enterDesc')}
                />
              </Form.Item>
            </div>
          </Form>
        </DrawerContent>
        <DrawerFooter>
          <Button
            block
            htmlType="submit"
            loading={loading}
            disabled={loading}
            onClick={() => form.submit()}
          >
            {t('save')}
          </Button>

          <Button
            block
            type="link"
            color="error"
            disabled={loading}
            onClick={closeDrawer}
          >
            {t('cancel')}
          </Button>
        </DrawerFooter>
      </With2FA>
    </Drawer>
  );
};

export default registerDrawer(FormUnit, {
  id: 'form-unit',
});
