import { TFunction } from 'i18next';
import { IWallet } from 'types/IWallet';
import { UnknownType } from 'types/Unknown';
import { checkIfEnoughBalance } from 'pages/Administration/Units/components/NestedUnit/tabs/PendingApprovalsTab/utils/checkIfEnoughBalance';
import { POSITIVE_FRACTIONAL_NUMBER } from 'constants/validation';

type InternalValidateArgs = {
  value: string;
  currency: string;
  network: string;
  t: TFunction;
  form: UnknownType;
  isWithdraw: boolean;
  wallets: IWallet[];
};

const internalValidateAmount = ({ value, currency, t, isWithdraw, network, wallets, form }: InternalValidateArgs) => {
  if (!value) {
    return Promise.reject(t('processing.pleaseEnterAmount'));
  }

  if (Number(value) <= 0 || !value.match(POSITIVE_FRACTIONAL_NUMBER)) {
    return Promise.reject(t('processing.amountRules'));
  }

  if (isWithdraw) {
    const { hasBalance, balance } = checkIfEnoughBalance(wallets, {
      amount: value,
      currency,
      network,
    });
    if (!hasBalance) {
      return Promise.reject(
        t('processing.notEnoughFunds', {
          value: `${Number(value) - Number(balance)} ${currency}`,
        }),
      );
    }
  }

  try {
    const curr = currency || form.getFieldValue('currency').split(' ')[0];

    if ((curr === 'USDT' || curr === 'USDC') && Number(value) < 1) {
      return Promise.reject(
        t('processing.amountLessThan', {
          currency: curr,
          amount: '1',
        }),
      );
    }
    if (curr === 'TRX' && Number(value) < 10) {
      return Promise.reject(
        t('processing.amountLessThan', {
          currency: curr,
          amount: '10',
        }),
      );
    }
    if (['ETH', 'BTC'].includes(curr) && Number(value) < 0.00001) {
      return Promise.reject(
        t('processing.amountLessThan', {
          currency: curr,
          amount: '0.00001',
        }),
      );
    }
  } catch (e) {
    // console.log('Error: choose wallet first');
  }
  return Promise.resolve();
};

export default internalValidateAmount;
