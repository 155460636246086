import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import WAValidator from 'multicoin-address-validator';
import { UnknownType } from 'types/Unknown';

export const useWalletValidator = (currency?: null | string, network?: null | string) => {
  const { t } = useTranslation();

  const walletValidator = useCallback(async (_: UnknownType, value: string, setWalletValid?: UnknownType) => {
    if (!value) {
      return Promise.reject(t('processing.destinationAddressisRequired'));
    }
    switch (network as string) {
      case 'TRC20':
        if (!WAValidator.validate(value, 'TRX', process.env.REACT_APP_NETWORK_ID)) {
          setWalletValid(false);

          return Promise.reject(
            t('walletAddressValidate.isNotValidate', { currency: currency || '' }),
          );
        }
        setWalletValid(true);
        return Promise.resolve();
      case 'BEP20':
      case 'ERC20':
        if (!WAValidator.validate(value, 'ETH', process.env.REACT_APP_NETWORK_ID)) {
          setWalletValid(false);

          return Promise.reject(
            t('walletAddressValidate.isNotValidate', { currency: currency || '' }),
          );
        }
        setWalletValid(true);
        return Promise.resolve();
      case 'BTC':
      case 'Bitcoin':
        if (!WAValidator.validate(value, 'BTC', process.env.REACT_APP_NETWORK_ID)) {
          setWalletValid(false);

          return Promise.reject(
            t('walletAddressValidate.isNotValidate', { currency: currency || '' }),
          );
        }
        setWalletValid(true);
        return Promise.resolve();
      default:
        setWalletValid(false);
        return Promise.reject(t('processing.destinationAddressisRequired'));
    }
  }, [currency, network, t]);

  return {
    walletValidator,
  };
};
