import { TFunction } from 'i18next';
import { WalletCurrencyEnum } from 'types/WalletCurrencyEnum';
import { POSITIVE_FRACTIONAL_NUMBER } from 'constants/validation';

export const amountValidator = (
  value: string,
  currency: WalletCurrencyEnum | undefined | null,
  t: TFunction,
) => {
  if (!value) return Promise.reject(t('processing.pleaseEnterAmount'));

  if (Number(value) <= 0 || !value.match(POSITIVE_FRACTIONAL_NUMBER)) {
    return Promise.reject(t('processing.amountRules'));
  }

  try {
    if ([
      WalletCurrencyEnum.USDT,
      WalletCurrencyEnum.USDC,
      WalletCurrencyEnum.BUSD,
      WalletCurrencyEnum.DAI,
    ].includes(currency as WalletCurrencyEnum) && Number(value) < 100) {
      return Promise.reject(
        t('processing.amountLessThan', {
          currency: currency as string,
          amount: '100',
        }),
      );
    }
    if (currency === WalletCurrencyEnum.TRX && Number(value) < 100) {
      return Promise.reject(
        t('processing.amountLessThan', {
          currency,
          amount: '100',
        }),
      );
    }
    if (currency === WalletCurrencyEnum.ETH && Number(value) < 0.06) {
      return Promise.reject(
        t('processing.amountLessThan', {
          currency,
          amount: '0.06',
        }),
      );
    }
    if (currency === WalletCurrencyEnum.BTC && Number(value) < 0.004) {
      return Promise.reject(
        t('processing.amountLessThan', {
          currency,
          amount: '0.004',
        }),
      );
    }

    if (currency === WalletCurrencyEnum.BNB && Number(value) < 0.33) {
      return Promise.reject(
        t('processing.amountLessThan', {
          currency,
          amount: '0.33',
        }),
      );
    }
  } catch (e) {
    console.log('Error: choose currency first');
  }

  return Promise.resolve();
};
