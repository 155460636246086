import React from 'react';
import { Permission } from '@fanckler/processing-auth';
import { Button, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { TFunction } from 'i18next';
import { isEmpty } from 'lodash';
import { IClientTransaction, IManager } from 'interfaces/IClientTransaction';
import { Path } from 'routes/interfaces/Path';
import { UnknownType } from 'types/Unknown';
import { TransactionsSortField } from '../types';
import { Sort } from 'hooks/useTableSort';
import { truncateNumber } from 'utils';
import renderEmptyCell from 'utils/renderEmptyCell';
import Annotation from '../component/Annotation/Annotation';
import {
  CurrencyNetworkTd,
  DateTime,
  OwnerIdTd,
  RelationLink,
  RiskScore,
  ScanLink,
  WalletAddress,
} from 'components';
import CategorizeTransactionCell from 'components/CategorizeTransactionCell';
import CurrencyCell from 'components/CurrencyCell';
import { StatusBadge } from 'components/StatusBadge';
import { ExclamationCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';

export type GetColumnsParams = {
  t: TFunction,
  sort?: Sort<TransactionsSortField> | null,
  isRootAdmin: boolean;
  onViewTx: (transaction: IClientTransaction) => void;
  onSaveAnnotation: (id: number, annotation: string) => Promise<{ error: boolean }>;
};

export const renderPayload = (payload: UnknownType) => {
  if (isEmpty(payload)) {
    return renderEmptyCell(null);
  }
  if (payload?.accountFrom) {
    return `Account from: ${payload.accountFrom}`;
  }
  if (payload?.cardId) {
    return `Card ID: ${payload.cardId}`;
  }

  return JSON.stringify(payload);
};

const getColumns = ({
  t,
  sort,
  isRootAdmin,
  onViewTx,
  onSaveAnnotation,
}: GetColumnsParams): ColumnsType<IClientTransaction> => {
  const handleView = (transaction: IClientTransaction) => () => {
    onViewTx(transaction);
  };

  return ([
    {
      key: 'id',
      title: t('id'),
      dataIndex: 'id',
      width: 80,
      sorter: sort !== undefined,
      sortOrder: sort && sort.field === 'id' ? sort.order : undefined,
    },
    {
      key: 'createdAt',
      title: t('created'),
      dataIndex: 'createdAt',
      width: 160,
      sorter: sort !== undefined,
      sortOrder: sort && sort.field === 'createdAt' ? sort.order : undefined,
      render: (date: Date) => date ? <DateTime value={date} /> : renderEmptyCell(date),
    },
    {
      key: 'amount',
      title: t('transaction.amount'),
      dataIndex: 'amount',
      align: 'right',
      width: 120,
      sorter: sort !== undefined,
      sortOrder: sort && sort.field === 'amount' ? sort.order : undefined,
      render: (amount, record) => record.origin === 'external_payout' ? (
        <span style={{ color: 'var(--red-error-color)' }}>{amount}</span>
      ) : amount,
    },
    {
      key: 'remainingBalance',
      title: t('transaction.remainingBalance'),
      dataIndex: 'remainingBalance',
      align: 'right',
      width: 170,
      sorter: sort !== undefined,
      sortOrder: sort && sort.field === 'remainingBalance' ? sort.order : undefined,
      render: (remainingBalance: IClientTransaction['remainingBalance']) => (
        remainingBalance ? remainingBalance : renderEmptyCell(null)
      ),
    },
    {
      key: 'currency',
      title: t('processing.currency'),
      dataIndex: 'currency',
      width: 160,
      sorter: sort !== undefined,
      sortOrder: sort && sort.field === 'currency' ? sort.order : undefined,
      render: (_, tx: IClientTransaction) => <CurrencyNetworkTd currency={tx.currency} network={tx.network} />,
    },
    {
      key: 'direction',
      title: t('processing.direction'),
      dataIndex: 'direction',
      width: 160,
      sorter: sort !== undefined,
      sortOrder: sort && sort.field === 'direction' ? sort.order : undefined,
      render: (direction, { type }) => (
        <StatusBadge
          status={type === 'FEE' && direction !== 'refund' ? type : direction}
          textTransform="uppercase"
        />
      ),
    },
    {
      key: 'status',
      title: t('processing.status'),
      dataIndex: 'status',
      width: 160,
      sorter: sort !== undefined,
      sortOrder: sort && sort.field === 'status' ? sort.order : undefined,
      render: (status, { errorText }) => (
        <StatusBadge
          status={status}
          textTransform="capitalize"
          withTooltip={Boolean(errorText)}
          tooltipText={errorText}
        />
      ),
    },
    {
      key: 'riskScore',
      title: t('riskScore'),
      dataIndex: 'riskScore',
      width: 160,
      sorter: sort !== undefined,
      sortOrder: sort && sort.field === 'riskScore' ? sort.order : undefined,
      render: (_, { riskScore, payload }: UnknownType) => (
        <RiskScore riskScore={riskScore} signals={payload?.signals} />
      ),
    },
    {
      key: 'txId',
      title: t('transaction.txId'),
      dataIndex: 'txId',
      width: 160,
      sorter: sort !== undefined,
      sortOrder: sort && sort.field === 'txId' ? sort.order : undefined,
      render: (txId, item) => txId
        ? <ScanLink txId={txId} network={item.network} withCopy />
        : renderEmptyCell(txId),
    },
    {
      key: 'addressFrom',
      title: t('users.transactions.addressFrom'),
      dataIndex: 'addressFrom',
      width: 160,
      sorter: sort !== undefined,
      sortOrder: sort && sort.field === 'addressFrom' ? sort.order : undefined,
      render: (address) => <WalletAddress walletAddress={address} />,
    },
    {
      key: 'addressTo',
      title: t('users.transactions.addressTo'),
      dataIndex: 'addressTo',
      width: 160,
      sorter: sort !== undefined,
      sortOrder: sort && sort.field === 'addressTo' ? sort.order : undefined,
      render: (address) => <WalletAddress walletAddress={address} />,
    },
    {
      key: 'annotation',
      title: t('transaction.annotation'),
      dataIndex: 'annotation',
      width: 230,
      render: (el, { id }) => (
        <Annotation
          annotation={el}
          onSave={(annotation) => onSaveAnnotation(id, annotation)}
        />
      ),
    },
    {
      key: 'ownerId',
      title: t('transaction.source'),
      dataIndex: 'ownerId',
      width: 160,
      render: (_, tx) => (
        <OwnerIdTd
          hardwareId={tx.hardwareId}
          invoice={tx.invoice}
          client={tx.client}
          subId={tx.subId}
          unit={tx.unit}
        />
      ),
    },
    {
      key: 'manager',
      title: t('transaction.manager'),
      dataIndex: 'manager',
      width: 160,
      render: (manager: IManager | null) => isEmpty(manager) ? renderEmptyCell(null) : (
        <RelationLink
          path={Path.ADMINISTRATION_MANAGERS}
          filters={{ email: manager.email }}
          permissions={[Permission.ADMIN_IAM_USER_READ]}
        >
          {manager.name}
        </RelationLink>
      ),
    },
    {
      key: 'network',
      title: t('processing.payway'),
      dataIndex: 'network',
      width: 160,
      sorter: sort !== undefined,
      sortOrder: sort && sort.field === 'network' ? sort.order : undefined,
    },
    isRootAdmin && {
      key: 'fee',
      title: t('transaction.networkFee'),
      dataIndex: 'fee',
      width: 160,
      sorter: sort !== undefined,
      sortOrder: sort && sort.field === 'fee' ? sort.order : undefined,
      render: (fee, tx: IClientTransaction) => (
        <CurrencyCell network={tx.network} amount={fee || 0} isNative />
      ),
    },
    isRootAdmin && {
      key: 'serviceFee',
      title: t('transaction.serviceFee'),
      dataIndex: 'serviceFee',
      width: 160,
      sorter: sort !== undefined,
      sortOrder: sort && sort.field === 'serviceFee' ? sort.order : undefined,
      render: (el, { currency }) => el ? truncateNumber({ number: el, currency }) : '0',
    },
    {
      key: 'totalFee',
      title: t('transaction.totalFee'),
      dataIndex: 'totalFee',
      width: 160,
      sorter: sort !== undefined,
      sortOrder: sort && sort.field === 'totalFee' ? sort.order : undefined,
      render: (el, { currency }) => el ? truncateNumber({ number: el, currency }) : '0',
    },
    {
      key: 'availableAmount',
      title: t('transaction.availableAmount'),
      dataIndex: 'availableAmount',
      width: 180,
      sorter: sort !== undefined,
      sortOrder: sort && sort.field === 'availableAmount' ? sort.order : undefined,
      render: (el, { currency }) => el ? truncateNumber({ number: el, currency }) : '0',
    },
    {
      key: 'category',
      title: t('transaction.groupsAndCategories'),
      dataIndex: '',
      width: 180,
      render: (transaction: IClientTransaction) => <CategorizeTransactionCell transaction={transaction} />,
    },
    {
      key: 'externalId',
      title: 'externalId',
      dataIndex: 'externalId',
      width: 160,
      sorter: sort !== undefined,
      sortOrder: sort && sort.field === 'externalId' ? sort.order : undefined,
      render: renderEmptyCell,
    },
    {
      key: 'paymentOrderId',
      title: t('transaction.paymentOrderId'),
      dataIndex: 'paymentOrderId',
      width: 160,
      sorter: sort !== undefined,
      sortOrder: sort && sort.field === 'paymentOrderId' ? sort.order : undefined,
      render: renderEmptyCell,
    },
    {
      key: 'payload',
      title: 'payload',
      dataIndex: 'payload',
      width: 160,
      sorter: sort !== undefined,
      sortOrder: sort && sort.field === 'payload' ? sort.order : undefined,
      render: renderPayload,
    },
    {
      key: 'origin',
      title: t('transaction.origin'),
      dataIndex: 'origin',
      width: 160,
      sorter: sort !== undefined,
      sortOrder: sort && sort.field === 'origin' ? sort.order : undefined,
      render: renderEmptyCell,
    },
    {
      key: 'updatedAt',
      title: t('updated'),
      dataIndex: 'updatedAt',
      width: 160,
      sorter: sort !== undefined,
      sortOrder: sort && sort.field === 'updatedAt' ? sort.order : undefined,
      render: (date: Date) => date ? <DateTime value={date} /> : renderEmptyCell(date),
    },
    {
      key: '__extra__',
      dataIndex: '',
      width: 66,
      fixed: 'right',
      align: 'center',
      render: (transaction: IClientTransaction) => {
        const isCompletedPayTransaction = transaction.status === 'completed' && ['payin', 'payout'].includes(transaction.direction);
        const isExternalPayout = transaction.origin === 'external_payout';

        const tooltipTitle = isExternalPayout ? t('transaction.scam') : t('transaction.transactionReceipt');

        return isCompletedPayTransaction ? (
          <Tooltip title={tooltipTitle} placement="left">
            <Button
              type="link"
              onClick={handleView(transaction)}
              style={{ height: 'auto' }}
              icon={isExternalPayout ? (
                <ExclamationCircleOutlined style={{ color: 'var(--red-error-color)' }} />
              ) : (
                <InfoCircleOutlined />
              )}
            />
          </Tooltip>
        ) : isExternalPayout ? (
          <Tooltip title={t('transaction.scam')} placement="left">
            <ExclamationCircleOutlined style={{
              color: 'var(--red-error-color)',
              display: 'block',
              margin: 'auto',
            }} />
          </Tooltip>
        ) : null;
      },
    },
  ] as ColumnsType<IClientTransaction>).filter(Boolean);
};

export default getColumns;
