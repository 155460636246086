import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Space } from 'antd';
import { PaymentOrderServiceFee } from 'api/commission/useGetPaymentOrderServiceFee';
import { Wallet } from 'hooks/useCurrencyRate';
import { CountUp, CurrencyBadge } from 'components';
import styles from './CommissionView.module.scss';

export type CommissionViewPropsType = {
  currency: string;
  amount: number;
  commission: PaymentOrderServiceFee | undefined,
  convertLoading: boolean;
  convert: (wallets: Wallet[], currency: string) => number;
  withFloatingValue?: boolean;
};

export const CommissionView = ({
  amount,
  convert,
  currency,
  commission,
  convertLoading,
  withFloatingValue = true,
}: CommissionViewPropsType): ReactElement => {
  const { t } = useTranslation();

  const totalAmount = commission ? amount + Number(commission.serviceFee) : 0;

  return (
    <div className={styles.container}>
      <div className={styles.field}>
        <span className={styles.label}>{t('commission.title')}</span>
        <Space.Compact style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
          {commission ? (
            <CountUp
              isRoundDown
              currency={currency}
              end={Number(commission.serviceFee)}
              className={styles.commission}
            />
          ) : (
            <span className={styles.totalAmount}>00,00</span>
          )}
          <CurrencyBadge currency={currency} textSize="16px" width="18px" gap={7} />
        </Space.Compact>
        {withFloatingValue && (
          <>
            {commission?.serviceFee && !convertLoading ? (
              <CountUp
                prefix="≈ "
                isRoundDown
                suffix=" USDT"
                currency="USDT"
                end={convert([{ currency, balance: commission.serviceFee }], 'USDT')}
                className={styles.converted}
              />
            ) : (
              <span className={styles.converted}>≈ 00.00 USDT</span>
            )}
          </>
        )}
      </div>

      <div className={styles.field}>
        <span className={styles.label}>{t('commission.amountReceived')}</span>
        <Space.Compact style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
          {commission ? (
            <CountUp
              isRoundDown
              end={totalAmount}
              currency={currency}
              className={styles.totalAmount}
            />
          ) : (
            <span className={styles.totalAmount}>00,00</span>
          )}
          <CurrencyBadge currency={currency} textSize="16px" width="18px" gap={7} />
        </Space.Compact>
        {withFloatingValue && (
          <>
            {totalAmount && !convertLoading ? (
              <CountUp
                prefix="≈ "
                isRoundDown
                suffix=" USDT"
                currency="USDT"
                end={convert([{ currency, balance: totalAmount }], 'USDT')}
                className={styles.converted}
              />
            ) : (
              <span className={styles.converted}>≈ 00.00 USDT</span>
            )}
          </>
        )}
      </div>
    </div>
  );
};
