import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Menu as AntdMenu } from 'antd';
import { useAuthorisationContext } from 'contexts';
import { findActiveMenuItem, generateMenuItems } from './utils';
import { menuItemsConfig } from './MenuConfig';
import styles from './Menu.module.scss';

const Menu = () => {
  const { checkPermissions, path: authorizedPath, user } = useAuthorisationContext();
  const location = useLocation();
  const { t } = useTranslation();

  const isBusinessExpired = useMemo(() => user?.rootUnit?.isExpired, [user?.rootUnit?.isExpired]);

  const menuItems = useMemo(() => menuItemsConfig(authorizedPath, t), [authorizedPath, t]);

  const items = useMemo(() => generateMenuItems(menuItems, checkPermissions, isBusinessExpired), [isBusinessExpired, menuItems, checkPermissions]);

  const activeKey = useMemo(() => findActiveMenuItem(menuItems, location.pathname) || '', [menuItems, location.pathname]);

  return <AntdMenu items={items} selectedKeys={[activeKey]} className={styles.menu} />;
};

export default Menu;
