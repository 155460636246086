import React, { PropsWithChildren, useContext, useEffect, useState } from 'react';
import io, { Socket } from 'socket.io-client';
import { StatusStateEnum } from 'hooks/useUpdateStatus';
import LocalStorage, { LocalStorageKey } from 'utils/localStorage';
import { useAuthorisationContext } from '../AuthorisationContext';
import { SocketContext } from './SocketContext';

const SocketProvider = ({ children }: PropsWithChildren) => {
  const [socket, setSocket] = useState<Socket | null>(null);
  const { logout, user, setUser } = useAuthorisationContext();

  useEffect(() => {
    const socketIo = io(process.env.REACT_APP_WS as string, {
      withCredentials: true,
      transports: ['websocket'],
      auth: {
        token: LocalStorage.get(LocalStorageKey.ACCESS_TOKEN) || '',
        app: 'fgty-admin',
      },
    });

    setSocket(socketIo);

    socketIo.on('update_current_user_status', () => {
      if (user) setUser({ ...user, status: StatusStateEnum.ONLINE });
    });

    socketIo.on('session_logout', (reason: string) => {
      logout({ message: reason, withoutRequest: true });
    });

    return () => {
      socketIo.disconnect();
    };
  }, []); // eslint-disable-line

  return (
    <SocketContext.Provider value={{ socket }}>
      {children}
    </SocketContext.Provider>
  );
};

export const useSocket = () => useContext(SocketContext);

export default SocketProvider;
