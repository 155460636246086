import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { registerDrawer, SharedDrawerProps } from '@nuvalt/ui-kit';
import { Form, Image, Input, Select } from 'antd';
import { WalletCurrencyEnum } from 'types/WalletCurrencyEnum';
import { WalletNetworkEnum } from 'types/WalletNetworkEnum';
import { CurrencyIcon } from 'utils';
import { Button, Drawer, DrawerContent, DrawerFooter, DrawerHeader } from 'components';
import styles from './AddWhitelistWalletForm.module.scss';

export type FormData = {
  name: string;
  network: string;
  currency: string;
  walletAddress: string;
};

type Option<T extends string> = {
  value: T;
  label: T;
};

const currencyOpts: Option<WalletCurrencyEnum>[] = [
  { value: WalletCurrencyEnum.BTC, label: WalletCurrencyEnum.BTC },
  { value: WalletCurrencyEnum.ETH, label: WalletCurrencyEnum.ETH },
  { value: WalletCurrencyEnum.TRX, label: WalletCurrencyEnum.TRX },
  { value: WalletCurrencyEnum.BNB, label: WalletCurrencyEnum.BNB },
  { value: WalletCurrencyEnum.DAI, label: WalletCurrencyEnum.DAI },
  { value: WalletCurrencyEnum.USDT, label: WalletCurrencyEnum.USDT },
  { value: WalletCurrencyEnum.USDC, label: WalletCurrencyEnum.USDC },
];

const networkOpts: Option<WalletNetworkEnum>[] = [
  { value: WalletNetworkEnum.BITCOIN, label: WalletNetworkEnum.BITCOIN },
  { value: WalletNetworkEnum.ERC20, label: WalletNetworkEnum.ERC20 },
  { value: WalletNetworkEnum.TRC20, label: WalletNetworkEnum.TRC20 },
  { value: WalletNetworkEnum.BEP20, label: WalletNetworkEnum.BEP20 },
];

const networkCurrencyMapping = new Map([
  [WalletNetworkEnum.BITCOIN, [WalletCurrencyEnum.BTC]],
  [WalletNetworkEnum.ERC20, [
    WalletCurrencyEnum.USDT,
    WalletCurrencyEnum.USDC,
    WalletCurrencyEnum.ETH,
  ]],
  [WalletNetworkEnum.TRC20, [WalletCurrencyEnum.TRX, WalletCurrencyEnum.USDT]],
  [WalletNetworkEnum.BEP20, [
    WalletCurrencyEnum.USDT,
    WalletCurrencyEnum.USDC,
    WalletCurrencyEnum.BNB,
    WalletCurrencyEnum.DAI,
  ]],
]);

type AddWhitelistWalletFormProps = {
  onSubmit: (data: FormData) => void;
  loading: boolean;
} & SharedDrawerProps;

const AddWhitelistWalletForm = ({
  onSubmit,
  loading,
  closeDrawer,
}: AddWhitelistWalletFormProps) => {
  const [availableCurrencies, setAvailableCurrencies] = useState(currencyOpts);
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const updateAvailableCurrencies = () => {
    const selectedNetwork = form.getFieldValue('network');

    if (selectedNetwork) {
      const currencies = networkCurrencyMapping.get(selectedNetwork) || [];
      const filteredCurrencies = currencyOpts.filter(currency => currencies.includes(currency.value));
      setAvailableCurrencies(filteredCurrencies);

      form.setFieldValue('currency', filteredCurrencies[0].value);
      form.validateFields(['currency']);

      const currentCurrency = form.getFieldValue('currency');
      if (!currencies.includes(currentCurrency)) {
        form.setFieldValue('currency', undefined);
      }
    }
  };

  return (
    <Drawer>
      <DrawerHeader title={t('whitelist.form.title')} onClose={closeDrawer} />
      <DrawerContent>
        <Form
          form={form}
          onFinish={onSubmit}
          name="add-whitelist-wallet"
          className={styles.form}
        >
          <Form.Item
            name="name"
            label={t('whitelist.form.fields.name')}
            className={styles.formItem}
            rules={[{ required: true, message: t('whitelist.form.validation.enterName') }]}
          >
            <Input className={styles.input} placeholder="Wallet 1" />
          </Form.Item>

          <Form.Item
            name="walletAddress"
            label={t('whitelist.form.fields.address')}
            className={styles.formItem}
            rules={[{ required: true, message: t('whitelist.form.validation.enterAddress') }]}
          >
            <Input placeholder={t('whitelist.form.fields.address')} className={styles.input} />
          </Form.Item>

          <div className={styles.alignItems}>
            <Form.Item
              name="network"
              label={t('whitelist.form.fields.network')}
              className={styles.formItem}
              rules={[{ required: true, message: t('whitelist.form.validation.selectNetwork') }]}
            >
              <Select
                placeholder="ERC20"
                className={styles.select}
                popupClassName={styles.selectPopup}
                onChange={() => updateAvailableCurrencies()}
              >
                {networkOpts.map(option => (
                  <Select.Option key={option.value} value={option.value}>
                    <div className={styles.option}>
                      <Image
                        preview={false}
                        src={CurrencyIcon[option.value]}
                        key={`option-icon-${option.value}`}
                        className={styles.icon}
                      />

                      <span className={styles.label}>{option.label}</span>
                    </div>
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="currency"
              label={t('whitelist.form.fields.currency')}
              className={styles.formItem}
              rules={[{ required: true, message: t('whitelist.form.validation.selectCurrency') }]}
            >
              <Select
                placeholder="Ethereum"
                className={styles.select}
                popupClassName={styles.selectPopup}
              >
                {availableCurrencies.map(option => (
                  <Select.Option key={option.value} value={option.value}>
                    <div className={styles.option}>
                      <Image
                        preview={false}
                        src={CurrencyIcon[option.value]}
                        key={`option-icon-${option.value}`}
                        className={styles.icon}
                      />

                      <span className={styles.label}>{option.label}</span>
                    </div>
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
        </Form>
      </DrawerContent>
      <DrawerFooter>
        <Button
          block
          htmlType="submit"
          loading={loading}
          disabled={loading}
          onClick={form.submit}
        >
          {t('whitelist.addButton')}
        </Button>

        <Button
          block
          type="link"
          color="error"
          onClick={closeDrawer}
        >
          {t('cancel')}
        </Button>
      </DrawerFooter>
    </Drawer>
  );
};

export default registerDrawer(AddWhitelistWalletForm, {
  id: 'AddWhitelistWalletForm',
});
