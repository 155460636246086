import { Language } from './types';

/**
 * Maps browser language codes to application language codes.
 * This ensures that detected language codes are correctly interpreted by the app.
 */

const languageMapping: Record<string, Language> = {
  'uk': Language.UA,
};

export default languageMapping;
