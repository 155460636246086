import React, { lazy } from 'react';
import lazyRetry from 'setup/lazyRetry';
import type { IRoute } from './interfaces/IRoute';
import { Path } from './interfaces/Path';
import administrationRoutes from './administration';
import processingRoutes from './processing';
import systemRoutes from './system';
import usersRoutes from './users';

const LoginPage = lazy(() => lazyRetry(() => import('../pages/Login/Login')));
const InvoicesPage = lazy(() => lazyRetry(() => import('../pages/Invoices/Invoices')));
const HelpCenter = lazy(() => lazyRetry(() => import('../pages/HelpCenter/HelpCenter')));
const FaqTopicPage = lazy(() => lazyRetry(() => import('../pages/HelpCenter/components/FaqTopicPage/FaqTopicPage')));
const ErrorPage = lazy(() => lazyRetry(() => import('../pages/ErrorPage/ErrorPage')));

const routes: IRoute[] = [
  {
    path: Path.LOGIN,
    isPrivate: false,
    component: () => <LoginPage />,
  },
  {
    path: Path.INVOICES_UUID,
    isPrivate: false,
    component: () => <InvoicesPage />,
  },
  {
    path: Path.HELP_CENTER,
    isPrivate: true,
    withHeader: true,
    withSideBar: true,
    component: () => <HelpCenter />,
  },
  {
    path: Path.HELP_CENTER + '/:categoryId',
    isPrivate: true,
    withHeader: true,
    withSideBar: true,
    component: () => <HelpCenter />,
  },
  {
    path: Path.HELP_CENTER + '/:categoryId/:topicId',
    isPrivate: true,
    withHeader: true,
    withSideBar: true,
    component: () => <FaqTopicPage />,
  },
  {
    path: Path.ERROR_PAGE,
    isPrivate: true,
    withHeader: false,
    withSideBar: true,
    component: () => <ErrorPage />,
  },
  ...usersRoutes,
  ...administrationRoutes,
  ...systemRoutes,
  ...processingRoutes,
];

export default routes;
