import React, { ReactElement, useMemo } from 'react';
import { Tooltip } from 'antd';
import { Language } from 'i18n';
import { useLocale } from 'contexts';
import { LocaleItem } from './components';
import styles from './LocaleDropdown.module.scss';

const LocaleDropdown = (): ReactElement => {
  const { locales, changeLanguage, localeData } = useLocale();

  const options = useMemo(() => (
    <div className={styles.container}>
      {Object.entries(locales).map(([lng, { image }]) => (
        <LocaleItem
          withPaddings
          key={lng}
          locale={lng}
          image={image}
          onClick={() => changeLanguage(lng as Language)}
          selected={localeData.lng === lng}
        />
      ))}
    </div>
  ), [changeLanguage, localeData.lng, locales]);

  return (
    <Tooltip
      color="white"
      trigger="click"
      title={options}
      placement="bottomLeft"
      key={localeData.lng}
      overlayClassName={styles.tooltip}
    >
      <LocaleItem image={localeData.image} locale={localeData.lng} />
    </Tooltip>
  );
};

export default LocaleDropdown;
