import axios, { SERVICE } from 'libs/axios';
import { keepPreviousData, useQuery } from 'libs/reactQuery';
import { GetTariffsResponse, QueryOptions, QueryParams } from './types';

const useGetTariffs = ({ rootUnitId }: QueryParams, queryOptions?: Partial<QueryOptions>) => {
  const getTariffs = async () => {
    const url = `${SERVICE.ADMIN}/tariffs/${rootUnitId}`;

    const { data } = await axios.get(url);
    return data;
  };

  return useQuery<GetTariffsResponse>({
    ...queryOptions,
    queryKey: ['tariffs', rootUnitId],
    queryFn: getTariffs,
    staleTime: 30000,
    placeholderData: keepPreviousData,
  });
};

export default useGetTariffs;
