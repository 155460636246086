import { UnknownType } from 'types/Unknown';
import { Sort } from 'hooks';
import { FiltersProvider } from '../context';
import { PerPageNumber } from 'components/Pagination';

type WithFiltersProps = {
  getFilterFieldsFn: (args: UnknownType) => Map<string, UnknownType>;
  allowedToSortFields?: string[];
  defaultSort?: Sort<string>;
  defaultPerPageOptions?: PerPageNumber[];
};

const withFilters = (Component: React.ComponentType<UnknownType>, mainProps: WithFiltersProps) => {
  return (props: UnknownType) => {
    return (
      <FiltersProvider
        getFilterFieldsFn={mainProps.getFilterFieldsFn}
        allowedToSortFields={mainProps.allowedToSortFields}
        defaultSort={mainProps.defaultSort}
        defaultPerPageOptions={mainProps.defaultPerPageOptions}
      >
        <Component {...props} />
      </FiltersProvider>
    );
  };
};

export default withFilters;
