import React from 'react';
import { useTranslation } from 'react-i18next';
import { Skeleton, Typography } from 'antd';
import useGetTariffs from 'api/commission/useGetTariffs';
import clsx from 'clsx';
import { CurrencyBadge, NoDataPlaceholder } from 'components';
import styles from 'drawers/root-unit/FormEditBusiness/components/CommissionTab/CommissionTab.module.scss';

type CommissionTabProps = {
  businessId: number;
};

const CommissionTab = ({ businessId }: CommissionTabProps) => {
  const { t } = useTranslation();
  const { data, isLoading } = useGetTariffs({
    rootUnitId: businessId,
  }, {
    enabled: Boolean(businessId),
  });

  return (
    <>
      {data?.nodes.map((node) => (
        <div className={styles.card}>
          <div className={clsx(styles.item, styles.currency)}>
            <CurrencyBadge currency={node.currency} network={node.network} withoutText width="24px" />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography.Text className={styles.text}>{node.currency}</Typography.Text>
              <Typography.Text className={styles.title}>{node.network}</Typography.Text>
            </div>
          </div>
          <div className={styles.item}>
            <Typography.Text className={styles.title}>{t('commissions.depositFee')}</Typography.Text>
            <Typography.Text className={styles.text}>{node.depositFeePercent}%</Typography.Text>
          </div>
          <div className={styles.item}>
            <Typography.Text className={styles.title}>{t('commissions.paymentOrderFee')}</Typography.Text>
            <Typography.Text className={styles.text}>{node.paymentOrderFee}</Typography.Text>
          </div>
          <div className={styles.item}>
            <Typography.Text className={styles.title}>{t('commissions.invoiceDepositFee')}</Typography.Text>
            <Typography.Text className={styles.text}>{node.invoiceDepositFeePercent}%</Typography.Text>
          </div>
        </div>
      ))}
      {!isLoading && data?.nodes.length === 0 && (
        <NoDataPlaceholder />
      )}
      {isLoading && (
        <Skeleton active />
      )}
    </>
  );
};

export default CommissionTab;
