import React, { useId } from 'react';
import { useTranslation } from 'react-i18next';
import { Space } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { Button, UploadLogo } from 'components';
import { CloudUploadOutlined, DeleteOutlined } from '@ant-design/icons';
import styles from './OrganizationalLogoUpload.module.scss';

export type OrganizationalLogoUploadProps = {
  value?: RcFile | string | null;
  onChange?: (value: RcFile | string | null) => void;
  type: 'business' | 'unit';
};

const OrganizationalLogoUpload = ({ value, onChange, type }: OrganizationalLogoUploadProps) => {
  const { t } = useTranslation();
  const logoId = useId();

  const handleUploadLogo = () => {
    document.getElementById(logoId)?.click();
  };

  const handleRemoveLogo = () => {
    if (onChange) {
      onChange(null);
    }
  };

  return (
    <div className={styles.wrapper}>
      <div>
        <UploadLogo
          id={logoId}
          value={value}
          onChange={onChange}
          shape="square"
        />
      </div>

      <Space direction="vertical" size={5}>
        <span className={styles.rules}>{t('business.uploadLogoRules', {
          type: t('business.' + type),
        })}</span>

        <div className={styles.buttons}>
          {value ? (
            <Button
              type="link"
              color="error"
              withoutBgShadow
              withoutWaveEffect
              // disabled={isLoading}
              onClick={handleRemoveLogo}
              className={styles.button}
              suffixIcon={<DeleteOutlined className={styles.icon} />}
            >
              {t('business.removeLogo')}
            </Button>
          ) : (
            <Button
              type="link"
              withoutBgShadow
              withoutWaveEffect
              onClick={handleUploadLogo}
              className={styles.button}
              suffixIcon={<CloudUploadOutlined className={styles.icon} />}
            >
              {t('business.uploadLogo')}
            </Button>
          )}
        </div>
      </Space>
    </div>
  );
};

export default OrganizationalLogoUpload;
