import axios, { SERVICE } from 'libs/axios';
import { useQuery, UseQueryResult } from 'libs/reactQuery';
import { LabelQueryOptions, LabelQueryParams, LabelQueryResult } from './types';

const useGetLabels = (queryParams?: LabelQueryParams, queryOptions?: Partial<LabelQueryOptions>) => {
  const getLabels = async () => {
    const url = `${SERVICE.CLIENT}/labels`;
    const { data } = await axios.get(url, { params: queryParams });
    return data;
  };

  return useQuery<LabelQueryResult>({
    ...queryOptions,
    queryKey: ['clients', queryParams?.filter],
    queryFn: getLabels,
    placeholderData: (prevData) => prevData ?? { totalCount: 0, nodes: [] },
  }) as UseQueryResult<LabelQueryResult> & { data: LabelQueryResult };
};

export default useGetLabels;
