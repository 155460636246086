import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocale } from 'contexts';

const TranslatedError = ({
  error,
  field,
}: {
  error: Error,
  field: 'title' | 'description'
}) => {
  const { t } = useTranslation();
  const { localeData } = useLocale();

  if (!error) return null;

  if (typeof error !== 'object') {
    console.error(`TranslatedError invalid usage: ${String(error)}`);

    if (typeof error === 'string') {
      return error;
    }

    return null;
  }

  const args = { message: error.message };

  if (error.name) {
    const translation = t(`coldStorage.ledger.errors.${error.name}.${field}`, args);

    if (translation !== `${localeData.lng}.coldStorage.ledger.errors.${error.name}.${field}`) { // THIS A HACK
      // It is translated
      return <>{translation}</>;
    } else {
      // It is not translated
      return <>{t(`coldStorage.ledger.errors.unknown.${field}`)}</>;
    }
  }

  return null;
};

export default TranslatedError;
