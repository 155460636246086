import axios, { SERVICE } from 'libs/axios';
import { useQuery } from 'libs/reactQuery';
import { GetUserResponse, QueryOptions, QueryParams } from './types';

const getUser = ({ uuid }: QueryParams) => async () => {
  const { data } = await axios.get(`${SERVICE.ADMIN}/users/${uuid}`);
  return data;
};

const useGetUser = ({ uuid }: QueryParams, queryOptions?: QueryOptions) => {
  return useQuery<GetUserResponse>({
    ...queryOptions,
    queryKey: ['user', { uuid }],
    enabled: !!uuid,
    queryFn: getUser({ uuid }),
  });
};

export default useGetUser;
