import { useTranslation } from 'react-i18next';
import axios, { SERVICE } from 'libs/axios';
import { useMutation } from 'libs/reactQuery';
import { notification } from 'antd';

export const useUpliftReplenishmentRequest = ({ onRefresh }: { onRefresh: () => void }) => {
  const { t } = useTranslation();

  const upliftRequest = async ({ id, uuid }: { id: number; uuid: string | undefined }) => {
    const { data } = await axios.post(SERVICE.CLIENT + `/replenishment-requests/lift-up/${id}/${uuid}`);
    return data;
  };

  const { mutate: mutationUpliftReplenishmentRequest, isPending: isReplenishmentRequestFetching } =
    useMutation({
      mutationKey: ['uplift-replenishment-request'],
      mutationFn: upliftRequest,
      onSuccess: () => {
        notification.success({ message: t('users.replenishmentRequests.upliftSent') });
        onRefresh();
      },
      onError: () =>
        notification.error({ message: t('users.replenishmentRequests.upliftError') }),
    });

  return {
    mutationUpliftReplenishmentRequest,
    isReplenishmentRequestFetching,
  };
};
