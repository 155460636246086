import { Permission } from '@fanckler/processing-auth';
import { ColumnType } from 'antd/lib/table';
import { TFunction } from 'i18next';
import { IWallet } from 'interfaces';
import { CheckPermissionsFunction } from 'contexts/AuthorisationContext/AuthorisationContext';
import { Button, CountUp, RiskScore, WalletAddress } from 'components';
import CurrencyCell from 'components/CurrencyCell';
import { VerticalAlignTopOutlined } from '@ant-design/icons';
import styles from '../SubaccountsTab.module.scss';

export type GetColumnsParams = {
  t: TFunction;
  isActive: boolean;
  checkPermissions: CheckPermissionsFunction;
  onWithdrawModalOpen: (wallet: IWallet) => void;
};

const getColumns = ({
  t,
  isActive,
  checkPermissions,
  onWithdrawModalOpen,
}: GetColumnsParams) => {
  const columns: ColumnType<IWallet>[] = [
    {
      key: 'name',
      title: t('subaccount.columns.name'),
      dataIndex: 'name',
      width: 160,
      render: (name: IWallet['name']) => (
        <span style={{ fontWeight: 500 }}>{name}</span>
      ),
    },
    {
      key: 'currency',
      title: t('subaccount.columns.currency'),
      dataIndex: 'currency',
      width: 160,
      render: (currency, { network }: IWallet) => (
        <CurrencyCell currency={currency} network={network} />
      ),
    },
    {
      key: 'balance',
      title: t('subaccount.columns.balance'),
      dataIndex: 'balance',
      width: 160,
      render: (balance: IWallet['balance'], { currency }: IWallet) => (
        <CountUp end={Number(balance)} currency={currency} isRoundDown />
      ),
    },
    {
      key: 'walletAddress',
      title: t('subaccount.columns.walletAddress'),
      dataIndex: 'walletAddress',
      width: 160,
      render: (walletAddress: IWallet['walletAddress']) => (
        <WalletAddress walletAddress={walletAddress} startLength={6} endLength={4} />
      ),
    },
    {
      key: 'riskScore',
      title: t('subaccount.columns.riskScore'),
      dataIndex: 'riskScore',
      width: 160,
      render: (riskScore: IWallet['riskScore']) => (
        <RiskScore riskScore={riskScore} />
      ),
    },
  ];

  if (checkPermissions([Permission.CLIENT_PAYMENT_ORDER_CREATE])) {
    columns.push({
      key: '__extra__',
      dataIndex: '',
      width: 66,
      fixed: 'right',
      align: 'center',
      render: (record: IWallet) => Number(record.balance) > 0 && (
        <Button
          block
          type="link"
          withoutBgShadow
          withoutWaveEffect
          disabled={!isActive}
          className={styles.withdrawButton}
          prefixIcon={<VerticalAlignTopOutlined style={{ fontSize: 18 }} />}
          onClick={e => {
            e.stopPropagation();
            onWithdrawModalOpen(record);
          }}
        />
      ),
    });
  }

  return columns;
};

export default getColumns;
