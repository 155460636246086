import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RiCheckLine, RiInformationFill } from '@remixicon/react';
import { notification } from 'antd';
import { useGetNotificationSettings, useUpdateNotificationSettings } from 'api/notification-setting';
import clsx from 'clsx';
import { isEqual } from 'lodash';
import { NotificationSettings } from 'types/NotificationSettings';
import styles from 'pages/Administration/Managers/components/FormViewUser/FormViewUser.module.scss';
import { Button } from 'components';

const useChangeNotifications = (uuid: string | undefined, isActive: boolean) => {
  const { t } = useTranslation();
  const [updatedSettings, setUpdatedSettings] = useState<NotificationSettings>({} as NotificationSettings);
  const { data: notificationSettings, isLoading } = useGetNotificationSettings(uuid, {
    enabled: isActive,
  });
  const initialSettings = useRef<NotificationSettings>();

  const {
    mutate,
    isPending,
  } = useUpdateNotificationSettings({
    onSuccess: (data) => {
      initialSettings.current = data;
      setUpdatedSettings(data);
    },
  });

  useEffect(() => {
    if (notificationSettings) {
      initialSettings.current = notificationSettings;
      setUpdatedSettings(notificationSettings);
    }
  }, [notificationSettings]);

  const handleChangeNotification = (option: Record<string, boolean>) => {
    setUpdatedSettings((prevState) => ({
      ...prevState,
      ...option,
    }));
  };

  const handleSave = () => {
    if (!uuid) return;

    mutate({
      uuid,
      body: updatedSettings,
    });
  };

  const handleResetChanges = () => {
    if (notificationSettings) {
      setUpdatedSettings(notificationSettings);
      initialSettings.current = notificationSettings;
    }
  };

  const showSavingAlert = () => {
    notification.close('notification-settings');
    notification.warn({
      icon: (
        <RiInformationFill
          style={{
            color: 'var(--brand-color)',
          }}
        />
      ),
      key: 'notification-settings',
      message: t('notifications.settings.notSavedYet'),
      btn: (
        <Button
          type="default"
          className={clsx(styles.editButton, styles.saveButton)}
          suffixIcon={<RiCheckLine size={16} />}
          onClick={handleSave}
        >
          {t('saveChanges')}
        </Button>
      ),
    });
  };

  return {
    showSavingAlert,
    resetChanges: handleResetChanges,
    notificationSettings: updatedSettings,
    changeNotification: handleChangeNotification,
    save: handleSave,
    isLoading,
    isUpdating: isPending,
    isChanged: !isLoading
        && initialSettings.current
        && updatedSettings
        && !isEqual(initialSettings.current, updatedSettings),
  };
}
;

export default useChangeNotifications;
