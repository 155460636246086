import React, { PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react';
import CopyToClipboardComponent from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'antd';

type CopyToClipboardProps = {
  text: string;
} & PropsWithChildren;

const CopyToClipboard = ({
  text,
  children,
}: CopyToClipboardProps) => {
  const { t } = useTranslation();
  const [isCopied, setIsCopied] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const handleCopy = useCallback(() => {
    setIsCopied(true);
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      setIsCopied(false);
    }, 1500);
  }, []);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, []);

  return (
    <CopyToClipboardComponent text={text} onCopy={handleCopy}>
      <Tooltip title={isCopied ? t('copied') : t('clickToCopy')}>
        {children}
      </Tooltip>
    </CopyToClipboardComponent>
  );
};

export default CopyToClipboard;
