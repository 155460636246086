import axios, { SERVICE } from 'libs/axios';
import { useQuery } from 'libs/reactQuery';
import { GetNotificationSettingsResponse, QueryParams } from './types';

const url = `${SERVICE.ADMIN}/notification-setting`;

const getNotificationSettings = (uuid: string | undefined) => async () => {
  const { data } = await axios.get(`${url}/${uuid}`);

  return data;
};

const useGetNotificationSettings = (uuid: string | undefined, options?: QueryParams) => {
  return useQuery<GetNotificationSettingsResponse>({
    ...options,
    queryKey: ['notification-settings', uuid],
    queryFn: getNotificationSettings(uuid),
  });
};

export default useGetNotificationSettings;
