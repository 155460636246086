import { PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Language, LocaleData, supportedLocales } from 'i18n';
import LocaleContext from './LocaleContext';

const LocaleProvider = ({ children }: PropsWithChildren) => {
  const { i18n, t } = useTranslation();

  const [localeData, setLocaleData] = useState<LocaleData & { lng: Language }>({
    ...supportedLocales[i18n.language as Language] as LocaleData,
    lng: i18n.language as Language,
  });

  useEffect(() => {
    const handleUpdateLocaleData = (lng: Language) => {
      setLocaleData({ ...supportedLocales[lng] as LocaleData, lng });
    };

    i18n.on('languageChanged', handleUpdateLocaleData);

    return () => {
      i18n.off('languageChanged', handleUpdateLocaleData);
    };
  }, [i18n]);

  const handleChangeLanguage = useCallback((lng: Language) => {
    i18n.changeLanguage(lng);
  }, [i18n]);

  return (
    <LocaleContext.Provider
      value={{
        localeData,
        translate: t,
        locales: supportedLocales,
        changeLanguage: handleChangeLanguage,
      }}
    >
      {children}
    </LocaleContext.Provider>
  );
};

export default LocaleProvider;

export const useLocale = () => {
  const context = useContext(LocaleContext);

  if (!context) {
    throw new Error('useLocale must be used within a LocaleProvider');
  }

  return context;
};
