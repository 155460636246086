import React from 'react';
import { IUnit } from 'interfaces';
import styles from './ClientSummary.module.scss';

type ClientSummaryProps = {
  name: string;
  email: string;
  unit: IUnit | null;
};

const ClientSummary = ({ name, email, unit }: ClientSummaryProps) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.namedAvatar}>{name[0]}</div>
      <div className={styles.infoWrapper}>
        <div className={styles.headerInfo}>
          <span className={styles.name}>{name}</span>
          {unit?.name && <div className={styles.unit}>{unit.name}</div>}
        </div>
        <span className={styles.email}>{email}</span>
      </div>
    </div>
  );
};

export default ClientSummary;
