import { Locale as antdLocale } from 'antd/lib/locale-provider';
import { Locale as DateFnsLocale } from 'date-fns';

export type LocaleNamespace = 'translation' | 'permissions' | 'faq';

export enum Language {
  UA = 'ua',
  EN = 'en',
}

export type LocaleData = {
  image: string;
  antdLocale: antdLocale;
  dateFnsLocale: DateFnsLocale;
};

export type LanguageLocales = { [K in Language]: LocaleData };
