import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { RiArrowRightUpLine } from '@remixicon/react';
import { Space } from 'antd';
import { TFunction } from 'i18next';
import { IExternalUser, IInvoice, IUnit } from 'interfaces';
import { Path } from 'routes/interfaces/Path';
import { truncateString } from 'utils/truncateString';
import Chip from './Chip';
import { RelationLink } from 'components';
import { UsbOutlined, WalletOutlined } from '@ant-design/icons';

export type OwnerIdTdPropsType = {
  client: IExternalUser | null;
  hardwareId: string | null;
  invoice: IInvoice | null;
  subId: number | null;
  unit: IUnit | null;
  t: TFunction;
};

const Arrow = () => (
  <RiArrowRightUpLine size={20} style={{ marginLeft: '4px' }} />
);

export const OwnerIdTd = ({
  hardwareId,
  invoice,
  client,
  subId,
  unit,
}: Omit<OwnerIdTdPropsType, 't'>): ReactElement => {
  const { t } = useTranslation();

  const truncateValue = (value: string | number) => {
    return truncateString(value.toString(), { type: 'end', startLength: 12 });
  };

  const customerEmailTdJSX = invoice?.customerEmail && (
    <RelationLink path={Path.USERS_INVOICES} filters={{ 'customerEmail': invoice.customerEmail }} target="_blank">
      <Space.Compact style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
        <Chip>{t('email')}</Chip>
        {truncateValue(invoice.customerEmail)}
        <Arrow />
      </Space.Compact>
    </RelationLink>
  );

  const invoiceTdJSX = invoice?.id && (
    <RelationLink path={Path.USERS_INVOICES} filters={{ 'id': invoice.id }} target="_blank">
      <Space.Compact style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
        <Chip>{t('invoice.one')}</Chip>
        {truncateValue(invoice.id)}
        <Arrow />
      </Space.Compact>
    </RelationLink>
  );

  const clientTdJSX = client?.clientId && client?.uuid && (
    <RelationLink path={`${Path.USERS_CLIENTS}/${client?.uuid}`} target="_blank">
      <Space.Compact style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
        <Chip>{t('users.client.title')}</Chip>
        {truncateValue(client.name || client.email || client.id)}
        <Arrow />
      </Space.Compact>
    </RelationLink>
  );

  const unitTdJSX = unit && (
    <RelationLink path={Path.USERS_TRANSACTIONS} filters={{ 'unit.id': unit.id }} target="_blank">
      <Space.Compact style={{ display: 'flex', alignItems: 'center' }}>
        <Chip>{t('users.units.one')}</Chip>
        {truncateValue(unit.name || unit.id)}
        <Arrow />
      </Space.Compact>
    </RelationLink>
  );

  const subIdJSX = subId && (
    <span style={{ color: 'var(--primary-color)', display: 'flex', alignItems: 'center', gap: 10 }}>
      <Chip><span><WalletOutlined /> {t('subaccount.one')}</span></Chip>
      {truncateValue(subId)}
    </span>
  );

  const hardwareIdJSX = hardwareId && (
    <span style={{ color: 'var(--primary-color)', display: 'flex', alignItems: 'center', gap: 10 }}>
      <Chip><span><UsbOutlined /> {t('coldStorage.coldWallet')}</span></Chip>
      {truncateValue(hardwareId)}
    </span>
  );

  return <>{(hardwareIdJSX || subIdJSX || customerEmailTdJSX || invoiceTdJSX) || clientTdJSX || unitTdJSX}</>;
};

export const OwnerIdTdLikeString = ({
  hardwareId,
  invoice,
  client,
  subId,
  unit,
  t,
}: OwnerIdTdPropsType) => {
  const customerEmailTdJSX = invoice?.customerEmail;
  const invoiceTdJSX = invoice?.id && `${t('invoice.one')}-${invoice.id}`;
  const clientTdJSX = client?.clientId && client?.uuid && `${t('users.client.title')}: ${client.name || client.email || client.id}`;
  const unitTdJSX = unit && `${t('users.units.one')}: ${unit.name || unit.id}`;
  const subIdJSX = subId && `${`${t('subaccount.one')}: ${subId}`}`;
  const hardwareIdJSX = hardwareId && `${`${t('coldStorage.coldWallet')}: ${hardwareId}`}`;

  return (hardwareIdJSX || subIdJSX || customerEmailTdJSX || invoiceTdJSX) || clientTdJSX || unitTdJSX;
};
