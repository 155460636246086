import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, Space, Tooltip } from 'antd';
import clsx from 'clsx';
import { noop } from 'lodash';
import { StatusVariant } from 'components/ColdStorage/types';
import { getCurrencyLabelJSX } from 'components/CurrencySelect/CurrencySelect';
import { ReloadOutlined } from '@ant-design/icons';
import styles from './SelectWalletCheckbox.module.scss';

export type SelectWalletCheckboxProps = {
  currency: string;
  checked?: boolean;
  onClick?: () => void;
  disabled?: boolean;
  status?: StatusVariant | false;
  onRetry?: () => void;
};

export const SelectWalletCheckbox = ({
  currency,
  checked = false,
  onClick = noop,
  disabled = false,
  status,
  onRetry,
}: SelectWalletCheckboxProps): ReactElement => {
  const { t } = useTranslation();

  const handleClick = () => {
    if (!disabled) {
      onClick();
    }
  };

  return (
    <div className={styles.wrapper}>
      <Space size={10} onClick={handleClick} style={{ cursor: 'pointer' }}>
        <Checkbox checked={checked} disabled={disabled} />
        <span className={styles.currency}>{getCurrencyLabelJSX(currency)}</span>
      </Space>

      {status && (
        <span
          className={clsx(styles.status, {
            [styles.statusLoading]: status === StatusVariant.Loading || status === StatusVariant.Connecting,
            [styles.statusSuccess]: status === StatusVariant.Success,
            [styles.statusError]: status === StatusVariant.Error,
          })}
        >
          {status === StatusVariant.Error && (
            <Tooltip title="Retry connection">
              <ReloadOutlined onClick={onRetry && onRetry} className={styles.reloadIcon} />
            </Tooltip>
          )}
          {t(status)}
        </span>
      )}
    </div>
  );
};

export default SelectWalletCheckbox;
