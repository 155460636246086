import _isFunction from 'lodash/isFunction';
import _isNumber from 'lodash/isNumber';
import moment from 'moment';
import { FilterField, FilterFieldType } from 'components/Filters';

const parseFilters = (
  filterFields: Map<string, FilterField>,
  filters: Record<string, string>,
): Record<string, unknown> => {
  const result: Record<string, unknown> = {};

  Object.keys(filters).forEach((key) => {
    const filterField = filterFields.get(key);

    if (filterField) {
      const value = filters[key];
      const { type } = filterField;

      switch (type) {
        case FilterFieldType.DATE:
          const parsedDate = moment(value);
          const { maxDate, minDate } = filterField;

          const maxDateValue = _isFunction(maxDate) ? maxDate(filters) : maxDate;
          const minDateValue = _isFunction(minDate) ? minDate(filters) : minDate;

          if (
            parsedDate.isValid()
            && (maxDateValue ? parsedDate.isBefore(maxDateValue) : true)
            && (minDateValue ? parsedDate.isAfter(minDateValue) : true)
          ) {
            result[key] = parsedDate;
          }

          break;
        case FilterFieldType.SELECT:
          const { options } = filterField;
          const option = options.find((item) => String(item.value) === value);

          result[key] = option ? option.value : value;

          break;
        case FilterFieldType.NUMBER: {
          const {
            float, minValue, maxValue, regex,
          } = filterField;

          const parsedNumber = float ? parseFloat(value) : parseInt(value, 10);
          const maxNumberValue = _isFunction(maxValue) ? maxValue(filters) : maxValue;
          const minNumberValue = _isFunction(minValue) ? minValue(filters) : minValue;

          if (
            !Number.isNaN(parsedNumber)
            && (filterField.maxLength ? `${parsedNumber}`.length <= filterField.maxLength : true)
            && (_isNumber(maxNumberValue) ? parsedNumber <= maxNumberValue : true)
            && (_isNumber(minNumberValue) ? parsedNumber >= minNumberValue : true)
            && (regex ? regex.test(value) : true)
          ) {
            result[key] = parsedNumber;
          }

          break;
        }
        case FilterFieldType.TEXT:
          const { trimOnBlur, regex, maxLength } = filterField;

          if (
            (maxLength ? value.length <= maxLength : true)
            && (regex ? regex.test(value) : true)
          ) {
            result[key] = trimOnBlur ? value.trim() : value;
          }

          break;
        default:
          break;
      }
    }
  });

  return result;
};

export default parseFilters;
