import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
import { addDays } from 'date-fns';
import { IBusiness } from 'interfaces/IBusiness';
import styles from 'drawers/root-unit/FormEditBusiness/components/BillingTab/BillingTab.module.scss';
import { getDaysLeftText, getDisplayDate, getStatusText } from 'drawers/root-unit/FormEditBusiness/components/ExpirationCard/calculateExpirationDate';

type ExpirationCardProps = {
  business: IBusiness;
};

const ExpirationCard = ({
  business,
}: ExpirationCardProps) => {
  const { t } = useTranslation();

  const expirationDate = business.expirationAt ? new Date(business.expirationAt) : null;
  const gracePeriodEndDate =
    expirationDate && business.gracePeriodDays
      ? addDays(expirationDate, business.gracePeriodDays)
      : null;

  if (!expirationDate) return null;

  return (
    <div className={styles.card} style={{ width: '100%' }}>
      <Typography.Text className={styles.cardTitle}>
        {getStatusText(t, expirationDate)}
      </Typography.Text>
      <Typography.Title level={5} className={styles.title}>
        {getDisplayDate(expirationDate, gracePeriodEndDate)}
      </Typography.Title>
      <Typography.Text className={styles.cardTitle}>
        {getDaysLeftText(t, expirationDate, gracePeriodEndDate)}
      </Typography.Text>
    </div>
  );
};

export default ExpirationCard;
