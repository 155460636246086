import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormInstance, Select } from 'antd';
import { IWallet } from 'types/IWallet';
import styles from './WalletSelect.module.scss';

export type WalletOptionsType = {
  key: React.Key;
  label: string;
  value: string;
  currency: string;
  network: string;
};

export type WalletSelectPropsType = {
  form: FormInstance;
  wallets: IWallet[];
  className?: string;
};

export const WalletSelect = ({
  form,
  wallets,
  className,
}: WalletSelectPropsType): ReactElement => {
  const [walletAddress, setWalletAddress] = useState<string | undefined>();
  const { t } = useTranslation();

  const walletOptions: WalletOptionsType[] =
    (wallets || []).map(wallet => ({
      key: wallet.id,
      label: wallet?.walletAddress,
      value: wallet?.walletAddress,
      currency: `${wallet?.currency} ${(wallet?.network)?.toLowerCase()}`,
      network: ['USDT', 'USDC', 'DAI', 'ETH', 'BTC', 'BUSD']
        .includes(wallet?.currency) ? (wallet?.network === 'BEP20' ? wallet?.network : wallet?.currency) : wallet?.currency,
    }));

  const onSelectAddress = (e: string) => {
    const [wallet, network, currency] = e.split(',');
    setWalletAddress(wallet);
    form.setFieldsValue({ walletAddress: e, network, currency });
    form.validateFields(['amount']);
  };

  const handleClick = () => {
    if (wallets.length > 1 || wallets.length === 0) {
      return;
    }

    const { network, currency, value: wallet } = walletOptions[0] || {};
    const concatWallet = `${wallet},${network},${currency}`;
    setWalletAddress(wallet);
    form.setFieldsValue({ walletAddress: concatWallet, network, currency });
    form.validateFields(['amount']);
  };

  return (
    <Select
      value={walletAddress}
      onSelect={onSelectAddress}
      onClick={handleClick}
      disabled={wallets?.length === 0}
      showArrow={wallets?.length > 1}
      dropdownMatchSelectWidth={wallets?.length <= 1}
      dropdownStyle={{ display: wallets?.length <= 1 ? 'none' : '' }}
      placeholder={t('processing.destinationAddress')}
      className={className}
    >
      {walletOptions && walletOptions.map(item => (
        <Select.Option
          key={`${item.value}_${item.currency}`}
          value={`${item.value},${item.network},${item.currency}`}
        >
          <div className={styles.option}>
            <span className={styles.optionLabel}>{item.label}</span>
            <span className={styles.currency}>{item.currency}</span>
          </div>
        </Select.Option>
      ))}
    </Select>
  );
};
