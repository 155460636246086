import React, { useCallback, useRef } from 'react';
import { Empty } from 'antd';
import clsx from 'clsx';
import VirtualList from 'rc-virtual-list';
import { UnknownType } from 'types/Unknown';
import AssignableUserItem from './AssignableUserItem';
import { LoadingOutlined } from '@ant-design/icons';
import styles from './AssignableUserList.module.scss';

export type AssignableUserValues = {
  added: { id: number; email: string }[];
  removed: { id: number; email: string }[];
};

export type AssignableUserItemType = {
  id: number;
  name: string;
  email: string;
  reason?: string;
  disabled?: boolean;
  checked?: boolean;
  initialChecked?: boolean;
};

export type AssignableUserListProps = {
  items: AssignableUserItemType[];
  loading?: boolean;
  moreLoading?: boolean;
  value?: number[];
  handlers: {
    add: (callback: UnknownType) => void;
    remove: (callback: UnknownType) => void;
  };
  onLoadMore?: () => void;
};

const AssignableUserList = ({
  items,
  loading,
  handlers,
  onLoadMore,
  moreLoading,
}: AssignableUserListProps) => {
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const containerHeight = wrapperRef.current?.clientHeight || 400;
  const { add, remove } = handlers;

  const handleChange = useCallback((item: AssignableUserItemType) => (checked: boolean) => {
    add((state: AssignableUserItemType[]) => {
      if (checked && !item.initialChecked) {
        return [...state, { id: item.id, email: item.email }];
      }
      return [...state].filter((i) => i.id !== item.id);
    });

    remove((state: AssignableUserItemType[]) => {
      if (!checked && item.initialChecked) {
        return [...state, { id: item.id, email: item.email }];
      }
      return [...state].filter((i) => i.id !== item.id);
    });
  }, [add, remove]);

  const handleScroll = useCallback((e: React.UIEvent<HTMLElement, UIEvent>) => {
    if (Math.abs(e.currentTarget.scrollHeight - e.currentTarget.scrollTop - containerHeight) <= 1) {
      if (onLoadMore) onLoadMore();
    }
  }, [containerHeight, onLoadMore]);

  return (
    <div className={styles.wrapper} ref={wrapperRef}>
      {loading && <LoadingOutlined className={styles.loader} />}
      {!loading && items.length > 0 ? (
        <VirtualList
          itemKey="id"
          itemHeight={44}
          height={containerHeight}
          data={items}
          onScroll={handleScroll}
          className={styles.list}
        >
          {(item: AssignableUserItemType) => (
            <div className={styles.itemWrapper}>
              <AssignableUserItem
                item={item}
                checked={!!item.checked}
                onChange={handleChange(item)}
              />
            </div>
          )}
        </VirtualList>
      ) : !loading && items.length === 0 && (
        <Empty className={styles.empty} image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}

      <div className={clsx(styles.moreLoader, { [styles.active]: moreLoading })}>
        <LoadingOutlined />
      </div>
    </div>
  );
};

export default AssignableUserList;
