import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { registerDrawer, SharedDrawerProps } from '@nuvalt/ui-kit';
import { RiPencilLine } from '@remixicon/react';
import useAddClientWallet from 'api/client/useAddClientWallet';
import useGetClientByUuid from 'api/client/useGetClientByUuid';
import { Client } from 'api/client/useGetClients';
import ClientEditDrawer from '../ClientEditDrawer';
import { Button, Drawer, DrawerContent, DrawerHeader } from 'components';
import WalletOverview from 'components/WalletOverview';
import { ClientBalance, ClientLabels, ClientSummary, ClientTabs } from './components';
import styles from './ClientProfileDrawer.module.scss';

type ClientProfileDrawerProps = {
  initialData: Client;
  onRefreshAll: () => void;
} & SharedDrawerProps;

const ClientProfileDrawer = ({ initialData, onRefreshAll, closeDrawer }: ClientProfileDrawerProps) => {
  const { t } = useTranslation();
  const isChangedRef = useRef(false);

  useEffect(() => {
    return () => {
      if (isChangedRef.current) onRefreshAll();
    };
  }, [onRefreshAll]);

  const { data, refetch: onRefreshOne } = useGetClientByUuid(initialData.uuid);
  const currentData = useMemo(() => ({ ...initialData, ...data }), [data, initialData]);

  const handleRefresh = useCallback(() => {
    isChangedRef.current = true;
    onRefreshOne();
  }, [isChangedRef, onRefreshOne]);

  const { mutate: createWallet, isPending: isCreateWalletPending } = useAddClientWallet({
    onSuccess: handleRefresh,
  });

  useEffect(() => {
    if (ClientEditDrawer.isOpened()) {
      ClientEditDrawer.updateDrawer({
        initialValues: currentData,
      });
    }
  }, [currentData]);

  const openEditClientDrawer = useCallback(() => {
    ClientEditDrawer.openDrawer({
      initialValues: currentData,
      onRefreshOne,
      isChangedRef,
    });
  }, [currentData, onRefreshOne]);

  const handleCreateWallet = useCallback((body: { network: string, currency: string }) => {
    createWallet({ uuid: currentData.uuid, body });
  }, [createWallet, currentData.uuid]);

  return (
    <Drawer>
      <DrawerHeader title={t('users.client.profile')} onClose={closeDrawer}>
        <Button
          type="default"
          onClick={openEditClientDrawer}
          suffixIcon={<RiPencilLine size={16} />}
          style={{ padding: '3px 15px', borderRadius: 8 }}
        >
          {t('edit')}
        </Button>
      </DrawerHeader>
      <DrawerContent className={styles.content}>
        <ClientSummary
          name={currentData.name}
          email={currentData.email}
          unit={currentData.unit}
        />
        <ClientLabels
          labels={currentData.labels}
          clientUUID={currentData.uuid}
          onRefresh={handleRefresh}
        />
        <ClientBalance
          wallets={currentData.wallets}
          clientUUID={currentData.uuid}
        />
        <WalletOverview
          wallets={currentData.wallets}
          isLoading={false}
          isCreating={isCreateWalletPending}
          onCreateWallet={handleCreateWallet}
          className={styles.walletsWrapper}
        />
        <ClientTabs
          clientUUID={currentData.uuid}
        />
      </DrawerContent>
    </Drawer>
  );
};

export default registerDrawer(ClientProfileDrawer, {
  id: 'clientProfileDrawer',
});
