import React from 'react';
import { Permission } from '@fanckler/processing-auth';
import { Space, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { formatDistanceToNow } from 'date-fns';
import enLocale from 'date-fns/locale/en-US';
import ukLocale from 'date-fns/locale/uk';
import { Language } from 'i18n';
import { TFunction } from 'i18next';
import { IGroup } from 'interfaces/IGroup';
import { IUser } from 'interfaces/IUser';
import { Path } from 'routes/interfaces/Path';
import styles from 'pages/Administration/Managers/Managers.module.scss';
import { StatusStateEnum } from 'hooks/useUpdateStatus';
import renderEmptyCell from 'utils/renderEmptyCell';
import { AssignedUnitsList, DateTime, MaybeRenderEmptyValue, RelationLink, StatusBadge } from 'components';
import { StatusBadge as ActivityStatusBadge } from 'components/ActivityStatusDropdown';

export const getColumns = ({
  t,
  locale,
  onUnitClick,
}: {
  t: TFunction,
  locale: Language,
  onUnitClick?: (unit: IUser['units'][0], rootUnitId: number | null) => void;
}): ColumnsType<IUser> => {
  const renderNameWithStatus = (name: IUser['name'], record: IUser) => {
    const localeSettings = locale === 'en' ? enLocale : ukLocale;

    const timeAgo = formatDistanceToNow(new Date(record.visit.updatedAt), {
      addSuffix: true,
      locale: { ...localeSettings },
    });

    const statusTooltip = record.status === StatusStateEnum.ONLINE
      ? t('lastLogin', { time: timeAgo })
      : t('lastOnline', { time: timeAgo });

    return (
      <Space size={10}>
        <Tooltip title={statusTooltip} placement="top">
          <div><ActivityStatusBadge status={record.status} size={10} /></div>
        </Tooltip>

        <span>{name}</span>
      </Space>
    );
  };

  const columns: ColumnsType<IUser> = [
    {
      key: 'id',
      title: t('id'),
      dataIndex: 'id',
      width: 80,
      // width: '110px',
      // sorter: true,
      // sortOrder: sort && sort.field === 'id' ? sort.order : undefined,
    },
    {
      key: 'name',
      title: t('name'),
      dataIndex: 'name',
      width: 160,
      // width: '250px',
      // sorter: true,
      // sortOrder: sort && sort.field === 'name' ? sort.order : undefined,
      render: renderNameWithStatus,
    },
    {
      key: 'email',
      title: t('email'),
      dataIndex: 'email',
      width: 160,
      // width: '250px',
      // sorter: true,
      // sortOrder: sort && sort.field === 'email' ? sort.order : undefined,
      render: renderEmptyCell,
    },
    {
      key: 'group.name',
      title: t('administration.roles.roleName'),
      dataIndex: 'group',
      width: 160,
      render: ({ name }: IGroup) => (
        <RelationLink
          filters={{ name }}
          path={Path.ADMINISTRATION_ROLES}
          permissions={[Permission.ADMIN_IAM_GROUP_READ]}
        >
          {name}
        </RelationLink>
      ),
    },
    {
      key: 'rootUnit.name',
      title: t('business.title'),
      dataIndex: 'rootUnit',
      width: 160,
      render: (item) => item?.name,
    },
    {
      key: 'isActive',
      title: t('status'),
      dataIndex: 'isActive',
      width: 160,
      render: (isActive) => <StatusBadge status={isActive ? 'activated' : 'blocked'} />,
    },
    {
      key: 'units',
      title: t('administration.financialManagement.fields.units'),
      dataIndex: 'units',
      render: (_, { units, rootUnitId }) => (
        <MaybeRenderEmptyValue condition={units.length > 0} minWidth={'50px'}>
          <AssignedUnitsList
            units={units}
            onClick={(unit) => onUnitClick && onUnitClick(unit, rootUnitId)}
            showFullList={false}
            className={styles.assignedUnitsList}
          />
        </MaybeRenderEmptyValue>
      ),
    },
    {
      key: 'createdAt',
      title: t('created'),
      dataIndex: 'createdAt',
      width: 160,
      // sorter: true,
      // sortOrder: sort && sort.field === 'createdAt' ? sort.order : undefined,
      render: (createdAt) => <DateTime value={createdAt} />,
    },
    {
      key: 'updatedAt',
      title: t('updated'),
      dataIndex: 'updatedAt',
      width: 160,
      // sorter: true,
      // sortOrder: sort && sort.field === 'updatedAt' ? sort.order : undefined,
      render: (updatedAt) => <DateTime value={updatedAt} />,
    },
  ];

  return columns;
};
