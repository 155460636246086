import React from 'react';
import { RiCloseLine } from '@remixicon/react';
import styles from './DrawerHeader.module.scss';

type DrawerHeaderProps = {
  title?: string;
  onClose?: () => void;
  children?: React.ReactNode | React.ReactNode[];
};

const DrawerHeader = ({ title, onClose, children }: DrawerHeaderProps) => {
  return (
    <div className={styles.drawerHeader}>
      <p className={styles.title}>{title}</p>

      {children && (
        <div>{children}</div>
      )}

      <button
        className={styles.closeButton}
        onClick={onClose}
      >
        <RiCloseLine size={20} />
      </button>
    </div>
  );
};

export default DrawerHeader;
