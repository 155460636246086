import axios, { SERVICE } from 'libs/axios';
import { useMutation } from 'libs/reactQuery';
import { IUser } from 'interfaces/IUser';
import { OptionType } from './types';

const useUserUpdate = (id: number, options: OptionType) => {
  const updateUser = async (body: Partial<IUser>) => {
    const { data } = await axios.put(SERVICE.ADMIN + `/users/update/${id}`, body);
    return data;
  };

  return useMutation({
    mutationFn: updateUser,
    ...options,
  });
};

export default useUserUpdate;
