import _ from 'lodash';
import { Language, LanguageLocales } from './types';
import locales from './locales';

const getSupportedLocales = (): Partial<LanguageLocales> => {
  const envLangs = process.env.REACT_APP_LANG?.split(',') || [];
  const availableLocales = locales;

  if (envLangs.length > 0) {
    const supportedLocales = _.pick(availableLocales, envLangs);

    if (Object.keys(supportedLocales).length === 0) {
      return { [Language.EN]: availableLocales[Language.EN] };
    }

    return supportedLocales;
  }

  return availableLocales;
};

export default getSupportedLocales;
