import { Spin } from 'antd';
import { TFunction } from 'i18next';
import { CryptoCurrency } from 'components/ColdStorage/assets/crypto/types';
import { DeviceModelId } from 'components/ColdStorage/ledger/types';
import { RegisterSuccessReason } from '../hooks/useRegisterDevice';
import { Animation, Button } from 'components';
import getDeviceAnimation from 'components/ColdStorage/ledger/animations';
import ErrorIcon from 'components/ColdStorage/ledger/components/ErrorIcon';
import TranslatedError from '../components/TranslatedError';
import TranslatedSuccess from '../components/TranslatedSuccess';
import { CheckCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import styles from './rendering.module.scss';

type RenderLoadingOpts = { children?: React.ReactNode };
export const renderLoading = (opts?: RenderLoadingOpts) => (
  <div className={styles.renderLoading}>
    <Spin
      className={styles.spinner}
      indicator={<LoadingOutlined style={{ fontSize: 70 }} spin />}
    />

    {opts?.children}
  </div>
);

export const renderRequestOpenApp = ({ modelId, currency, type, t }: {
  modelId: DeviceModelId,
  type: 'light' | 'dark',
  currency: CryptoCurrency,
  t?: TFunction,
}) => (
  <div className={styles.renderAnimation}>
    <Animation animation={getDeviceAnimation(modelId, type, 'openApp')} />

    <span className={styles.title}>
      {t && t('coldStorage.ledger.requestOpenApp', { app: currency.name })}
    </span>
  </div>
);

export const renderVerifyAddress = ({ modelId, currency, type, t }: {
  modelId: DeviceModelId,
  type: 'light' | 'dark',
  currency: CryptoCurrency,
  t?: TFunction,
}) => (
  <div className={styles.renderAnimation}>
    <Animation animation={getDeviceAnimation(modelId, type, 'validate')} />

    <span className={styles.title}>
      {t && t('coldStorage.ledger.requestVerifyAddress', { app: currency.name })}
    </span>
  </div>
);

export const renderAllowManager = ({ modelId, type, text }: {
  modelId: DeviceModelId,
  type: 'light' | 'dark',
  text: string,
}) => (
  <div className={styles.renderAnimation}>
    <Animation animation={getDeviceAnimation(modelId, type, 'allowManager')} />

    <span className={styles.title}>{text}</span>
  </div>
);

export const renderError = ({
  t,
  error,
  onRetry,
}: {
  t: TFunction,
  error: Error,
  onRetry: () => void;
}) => {
  return (
    <div className={styles.renderError}>
      <ErrorIcon error={error} size={20} />

      <div className={styles.textWrapper}>
        <div className={styles.title}>
          <TranslatedError error={error} field="title" />
        </div>
        <div className={styles.description}>
          <TranslatedError error={error} field="description" />
        </div>
      </div>

      <Button onClick={onRetry} className={styles.retryButton}>{t('retry')}</Button>
    </div>
  );
};

export const renderSuccess = ({
  name,
  model,
  success,
  children,
}: {
  name: string;
  model: string;
  success: RegisterSuccessReason;
  children?: React.ReactNode;
}) => {
  return (
    <div className={styles.renderSuccess}>
      <CheckCircleOutlined
        style={{ fontSize: 20 }}
        className={styles.icon}
      />

      <div className={styles.textWrapper}>
        <div className={styles.title}>
          <TranslatedSuccess success={{ key: success, model, name }} field="title" />
        </div>
        <div className={styles.description}>
          <TranslatedSuccess success={{ key: success, model, name }} field="description" />
        </div>
      </div>

      {children}
    </div>
  );
};
