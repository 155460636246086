import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tag, Typography } from 'antd';
import { IBusiness } from 'interfaces/IBusiness';
import { getSubscriptionStatusProps } from 'drawers/root-unit/FormEditBusiness/utils';
import styles from 'drawers/root-unit/FormEditBusiness/components/BillingTab/BillingTab.module.scss';
import { isSubscriptionActive } from 'drawers/root-unit/FormEditBusiness/components/ExpirationCard/calculateExpirationDate';

type SubscriptionDetailsCardProps = {
  business: IBusiness;
};

const SubscriptionDetailsCard = ({
  business,
}: SubscriptionDetailsCardProps) => {
  const { t } = useTranslation();

  const expirationDate = business.expirationAt ? new Date(business.expirationAt) : null;
  const isSubscriptionNotExpired = isSubscriptionActive(expirationDate);

  const tagProps = getSubscriptionStatusProps(Boolean(business.isExpired), Boolean(isSubscriptionNotExpired));

  return (
    <>
      {business.subscriptionPlan && business.subscriptionType && (
        <div className={styles.card} style={{ width: '100%' }}>
          <Typography.Text className={styles.cardTitle}>
            {t('business.subscription.subscriptionDetails')}
          </Typography.Text>
          <div className={styles.inlineFlex} style={{ gap: 0 }}>
            <Typography.Title level={5} className={styles.title}>
              {business.subscriptionPlan[`${business.subscriptionType}Cost`]} USDT
            </Typography.Title>
            <Typography.Text className={styles.cardTitle}>
              /{t(`business.subscription.${business.subscriptionType}`)}
            </Typography.Text>
          </div>
          <Typography.Text className={styles.cardTitle}>
            {t('business.subscription.payingForServerAndSystem')}
          </Typography.Text>
          <Tag color={tagProps.color} className={styles.tag}>
            {t(tagProps.text)}
          </Tag>
        </div>
      )}
    </>
  );
};

export default SubscriptionDetailsCard;
