import React, { ReactElement, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { registerDrawer, SharedDrawerProps } from '@nuvalt/ui-kit';
import { Form, FormInstance, Input } from 'antd';
import { RuleObject } from 'antd/es/form';
import { StoreValue } from 'antd/lib/form/interface';
import clsx from 'clsx';
import { ChangePasswordBodyType } from '../../hooks/types';
import { PASSWORD_REGEX } from 'constants/validation';
import { generatePassword } from 'utils';
import { Button, Drawer, DrawerContent, DrawerFooter, DrawerHeader, With2FA } from 'components';
import { LockOutlined, SyncOutlined } from '@ant-design/icons';
import styles from './FormChangePassword.module.scss';

export type FormChangePassDataType = {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
  otpCode: string;
};

export type FormChangePassPropsType = {
  onSubmit: (body: ChangePasswordBodyType) => void;
  form: FormInstance;
  loading: boolean;
} & SharedDrawerProps;

const FormChangePassword = ({
  onSubmit,
  loading,
  form,
  closeDrawer,
  drawerId,
}: FormChangePassPropsType): ReactElement => {
  const savedData = useRef<FormChangePassDataType>();
  const { t } = useTranslation();

  const onGeneratePassword = () => {
    const newPassword = generatePassword();
    form.setFieldsValue({ newPassword, confirmPassword: newPassword });
  };

  const handleFinish = (values: FormChangePassDataType, otpCode?: string) => {
    savedData.current = values;
    onSubmit({
      oldPassword: values.oldPassword,
      password: values.newPassword,
      otpCode: otpCode as string,
    });
  };

  const handleSubmitOtp = (otpCode: string) => {
    if (!savedData.current) return;

    handleFinish(savedData.current, otpCode);
  };

  const passwordRules = { pattern: PASSWORD_REGEX, message: t('passwordRules') };

  const confirmPasswordValidator = (_: RuleObject, value: StoreValue) => {
    if (!value || form.getFieldValue('newPassword') === value) {
      return Promise.resolve();
    }
    return Promise.reject(t('myAccount.validation.passwordMismatch'));
  };

  return (
    <Drawer id={drawerId}>
      <DrawerHeader title={t('myAccount.changePassword')} onClose={closeDrawer} />
      <With2FA onSubmit={handleSubmitOtp} isDrawer>
        <DrawerContent>
          <Form
            form={form}
            name="password_change"
            onFinish={handleFinish}
            className={styles.form}
          >
            <div>
              <Form.Item
                name="oldPassword"
                label={t('myAccount.fields.oldPassword')}
                className={styles.formItem}
                rules={[{ required: true, message: t('myAccount.validation.pleaseInputOldPassword') }]}
              >
                <Input.Password
                  prefix={<LockOutlined />}
                  placeholder={t('myAccount.fields.oldPassword')}
                  className={styles.input}
                />
              </Form.Item>

              <div className={clsx(styles.formItem, styles.withAddonBtn)}>
                <Form.Item
                  name="newPassword"
                  label={t('myAccount.fields.newPassword')}
                  rules={[{ required: true, message: t('myAccount.validation.pleaseInputNewPassword') }, passwordRules]}
                >
                  <Input.Password
                    prefix={<LockOutlined />}
                    placeholder={t('myAccount.fields.newPassword')}
                    className={styles.input}
                  />
                </Form.Item>

                <Button
                  type="link"
                  textSize="14px"
                  withoutBgShadow
                  withoutWaveEffect
                  prefixIcon={<SyncOutlined />}
                  className={styles.genereatePassBtn}
                  onClick={onGeneratePassword}
                >
                  {t('myAccount.generatePassword')}
                </Button>
              </div>

              <Form.Item
                name="confirmPassword"
                className={styles.formItem}
                label={t('myAccount.fields.confirmPassword')}
                rules={[
                  { required: true, message: t('myAccount.validation.pleaseConfirmPassword') },
                  { validator: confirmPasswordValidator }, passwordRules,
                ]}
              >
                <Input.Password
                  placeholder={t('myAccount.fields.confirmPassword')}
                  prefix={<LockOutlined />}
                  className={styles.input}
                />
              </Form.Item>
            </div>
          </Form>
        </DrawerContent>
        <DrawerFooter>
          <Button
            block
            htmlType="submit"
            loading={loading}
            disabled={loading}
            onClick={form.submit}
          >
            {t('save')}
          </Button>

          <Button
            block
            type="link"
            color="error"
            disabled={loading}
            onClick={closeDrawer}
          >
            {t('cancel')}
          </Button>
        </DrawerFooter>
      </With2FA>
    </Drawer>
  );
};

export default registerDrawer(FormChangePassword, {
  id: 'FormChangePassword',
});
