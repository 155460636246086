import React, { PropsWithChildren, useMemo } from 'react';
import { drawerStore } from '@nuvalt/ui-kit';
import styles from './Drawer.module.scss';

type DrawerProps = PropsWithChildren & {
  id?: string;
};

const Drawer = ({ children, id }: DrawerProps) => {
  const opened = drawerStore.use.opened();
  const zIndex = useMemo(() => {
    if (!id) return 0;
    const index = opened.findIndex((drawer) => drawer.id === id);
    return index === -1 ? 0 : index;
  }, [id, opened]);

  return (
    <div
      style={{ zIndex: 999 + zIndex }}
      className={styles.drawer}
    >
      {children}
    </div>
  );
};

export default Drawer;
