import React from 'react';
import { useTranslation } from 'react-i18next';
import { RiCheckboxCircleFill, RiCloseCircleFill, RiGlobalFill, RiRefreshLine } from '@remixicon/react';
import clsx from 'clsx';
import styles from './SmtpStatus.module.scss';

type SmtpStatusProps = {
  isLoading: boolean;
  isConnected: boolean;
  isError: boolean;
  onReload: () => void;
};

const getStatusClass = ({ isConnected, isError, isLoading }: SmtpStatusProps) => {
  if (isError) {
    return styles.error;
  }
  if (isLoading) {
    return styles.loading;
  }
  if (isConnected) {
    return styles.success;
  }
  return styles.default;
};
const getIcon = ({ isConnected, isError, isLoading }: SmtpStatusProps) => {
  if (isError) {
    return RiCloseCircleFill;
  }
  if (isLoading) {
    return RiGlobalFill;
  }
  if (isConnected) {
    return RiCheckboxCircleFill;
  }
  return RiGlobalFill;
};

const getTextKey = ({ isConnected, isError, isLoading }: SmtpStatusProps) => {
  if (isError) {
    return 'error';
  }
  if (isLoading) {
    return 'loading';
  }
  if (isConnected) {
    return 'connected';
  }
  return 'check';
};

const SmtpStatus = (props: SmtpStatusProps) => {
  const { t } = useTranslation();
  const Icon = getIcon(props);

  return (
    <div
      className={clsx(styles.wrapper, getStatusClass(props))}
      onClick={props.onReload}
    >
      <Icon size={16} />
      <span>{t(`SMTP.status.${getTextKey(props)}`)}</span>
      <RiRefreshLine size={16} className={styles.reloadIcon} />
    </div>
  );
};

export default SmtpStatus;
