import React from 'react';
import { Language, LanguageLocales, LocaleData } from 'i18n';
import { TFunction } from 'i18next';
import { noop } from 'lodash';
import { UnknownType } from 'types/Unknown';

export type ChangeLanguageFunc = (lng: Language) => void;

export type LocaleContextProps = {
  translate: TFunction;
  locales: Partial<LanguageLocales>;
  changeLanguage: ChangeLanguageFunc;
  localeData: LocaleData & { lng: Language };
};

const LocaleContext = React.createContext<LocaleContextProps>({
  localeData: {} as UnknownType,
  changeLanguage: noop,
  translate: noop as UnknownType,
  locales: {},
});

export default LocaleContext;
