import React, { ReactElement, useContext, useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { notification } from 'antd';
import { Path } from 'routes/interfaces/Path';
import routes from 'routes';
import { AuthorisationContext } from 'contexts';

const Home = (): ReactElement | null => {
  const { t } = useTranslation();
  const { checkPermissions, logout, loading, path } = useContext(AuthorisationContext);
  const navigate = useNavigate();

  const firstPermittedRoute = routes.find(({ permissions }) => (
    permissions && checkPermissions(permissions)));

  useEffect(() => {
    if (!loading && !firstPermittedRoute) {
      notification.error({
        message: t('permissionError'),
        description: t('youDontHaveAnyReadPermissions'),
      });

      navigate(Path.HELP_CENTER);
      // logout();
    }
  }, [firstPermittedRoute, loading, logout, t]);

  return path ? (
    <Navigate to={path} />
  ) : null;
};

export default Home;
