import React from 'react';
import styles from './MaybeRenderEmptyValue.module.scss';

type Props = {
  children?: React.ReactNode;
  minWidth?: string;
  condition?: boolean;
  onClick?: (event: React.MouseEvent<HTMLSpanElement>) => void;
};

const MaybeRenderEmptyValue = ({ children, condition = true, minWidth, onClick }: Props) => {
  return (
    <span
      onClick={onClick}
      className={children && condition ? '' : styles.nullableValue}
      style={{ minWidth }}
    >
      {children && condition ? children : 'null'}
    </span>
  );
};

export default MaybeRenderEmptyValue;
