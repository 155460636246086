import React from 'react';
import { ColumnsType } from 'antd/es/table';
import { TFunction } from 'i18next';
import { IBillingRecord } from 'interfaces/IBusiness';
import renderEmptyCell from 'utils/renderEmptyCell';
import { CurrencyBadge, DateTime, MaybeRenderEmptyValue, ScanLink, StatusBadge, WalletAddress } from 'components';
import styles from 'drawers/root-unit/FormEditBusiness/components/BillingTab/BillingTab.module.scss';

export interface GetColumnsParams {
  t: TFunction;
}

//   id: number;
//   amount: string;
//   createdAt: string;
//   addressFrom: string;
//   txId: string;
//   status: string;
//   type: string;

const getColumns = ({
  t,
}: GetColumnsParams) => {
  const columns: ColumnsType<IBillingRecord> = [
    {
      key: 'id',
      title: t('id'),
      dataIndex: 'id',
      width: 80,
    },
    {
      title: t('transaction.amount'),
      dataIndex: 'amount',
      key: 'amount',
      width: 160,
      render: (amount, { type }) => (
        <div className={styles.tdAmount}>
          <CurrencyBadge currency={'USDT'} withoutText />
          <span
            style={{
              color: type === 'credit' ? '#389E0D' : undefined,
            }}
          >
            {type === 'credit' ? '+' : '-'}
            {amount} USDT
          </span>
        </div>
      ),
    },
    {
      key: 'createdAt',
      title: t('date'),
      dataIndex: 'createdAt',
      width: 160,
      render: (date: Date) => date ? <DateTime value={date} /> : renderEmptyCell(date),
    },
    {
      key: 'addressFrom',
      title: t('from'),
      dataIndex: 'addressFrom',
      width: 160,
      render: (address) => <WalletAddress
        walletAddress={address}
        startLength={4}
        endLength={4}
      />,
    },
    {
      key: 'txId',
      title: t('processing.txId'),
      dataIndex: 'txId',
      width: 160,
      render: (txId) => (
        <MaybeRenderEmptyValue>
          {txId && (
            <ScanLink network={'trc20'} txId={txId} withCopy />
          )}
        </MaybeRenderEmptyValue>
      ),
    },
    {
      key: 'status',
      title: t('processing.status'),
      dataIndex: 'status',
      width: 160,
      render: (status) => <StatusBadge status={status} textTransform="capitalize" />,
    },
  ];

  return columns;
};

export default getColumns;
