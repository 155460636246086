import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Image, Typography } from 'antd';
import { DrawerContent, DrawerFooter } from 'components';
import { ArrowLeftOutlined } from '@ant-design/icons';
import styles from './FormScanQR.module.scss';

export type FormScanQRProps = {
  qrCode: string;
  onBack?: () => void;
  onContinue?: () => void;
  isDrawer?: boolean;
  style?: React.CSSProperties;
};

export const FormScanQR = ({
  qrCode,
  onBack,
  onContinue,
  isDrawer = false,
  style,
}: FormScanQRProps): ReactElement => {
  const { t } = useTranslation();

  if (isDrawer) {
    return (
      <>
        <DrawerContent>
          <div className={styles.container}>
            <Image
              src={qrCode}
              draggable={false}
              preview={false}
              className={styles.image}
            />

            <div className={styles.rightInfo}>
              <Typography.Text className={styles.text}>
                {t('QR.scanAndPress')}
              </Typography.Text>
            </div>
          </div>
        </DrawerContent>
        <DrawerFooter>
          <Button
            type="primary"
            onClick={onContinue}
            className={styles.button}
          >
            {t('continue')}
          </Button>

          {onBack && (
            <Button
              type="ghost"
              onClick={onBack}
              className={styles.backButton}
            >
              {t('back')}
            </Button>
          )}
        </DrawerFooter>
      </>
    );
  }

  return (
    <div className={styles.container} style={style}>
      <Image
        src={qrCode}
        draggable={false}
        preview={false}
        className={styles.image}
      />

      <div className={styles.rightInfo}>
        <Typography.Text className={styles.text}>
          {t('QR.scanAndPress')}
        </Typography.Text>

        <div className={styles.buttons}>
          <Button
            type="primary"
            onClick={onContinue}
            className={styles.button}
          >
            {t('continue')}
          </Button>

          {onBack && (
            <Button
              type="ghost"
              onClick={onBack}
              icon={<ArrowLeftOutlined className={styles.icon} />}
              className={styles.backButton}
            />
          )}
        </div>
      </div>
    </div>
  );
};
