import { TFunction } from 'i18next';
import { IStatus } from '../../interfaces/IStatus';
import { ColorEnum } from '../../types';
import { SmileOutlined } from '@ant-design/icons';
import {
  ReactComponent as SmileSadOutlined,
} from 'assets/icons/SmileSadOutlined.svg';
import {
  ReactComponent as BlockedIcon,
} from 'assets/icons/Stop.svg';

export const getUserStatuses = (t: TFunction): IStatus[] => [
  {
    status: 'activated',
    title: t('activated'),
    color: ColorEnum.SUCCESS,
    icon: SmileOutlined,
  },
  {
    status: 'deactivated',
    title: t('deactivated'),
    color: ColorEnum.ERROR,
    icon: SmileSadOutlined,
  },
  {
    status: 'blocked',
    title: t('blocked'),
    color: ColorEnum.ERROR,
    icon: BlockedIcon,
  },
];
