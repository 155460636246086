import React, { useId, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'antd';
import { IInvoice } from 'interfaces/IInvoice';
import { Button, CurrencyNetworkTd, MaybeRenderEmptyValue, ScanLink, StatusBadge } from 'components';
import styles from './Txns.module.scss';

type TxnsProps = {
  transactions: IInvoice['transactions']
};

const Txns = ({ transactions = [] }: TxnsProps) => {
  const { t } = useTranslation();
  const buttonId = useId();

  const items = useMemo(() => transactions.sort((a, b) => b.id - a.id).map((transaction) => ({
    label: (
      <span className={styles.item}>
        <div>
          <StatusBadge status={transaction.direction} withoutText withTooltip />
        </div>
        <CurrencyNetworkTd currency={transaction.currency} network={transaction.network} />
        <span className={styles.amount}>{transaction.amount}</span>
        {transaction.txId && <ScanLink txId={transaction.txId} network={transaction.network} withCopy withoutText />}
      </span>
    ),
    key: transaction.id,
  })), [transactions]);

  if (transactions.length === 0) {
    return (
      <div className={styles.null}>
        {String(null)}
      </div>
    );
  }
  if (transactions.length === 1) {
    const head = transactions[0];
    if (!head.txId) {
      return (<MaybeRenderEmptyValue />);
    }
    return (
      <ScanLink txId={head.txId} network={head.network} withCopy />
    );
  }

  return (
    <Dropdown
      getPopupContainer={() => document.getElementById(buttonId) as HTMLElement}
      overlayClassName={styles.dropdown}
      menu={{ items }}
      trigger={['hover']}
      placement="top"
      arrow
    >
      <Button
        withoutBgShadow
        withoutWaveEffect
        id={buttonId}
        type="link"
        className={styles.button}
      >
        {t('showDetails')}
      </Button>

    </Dropdown>
  );
};

export default Txns;
