import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { RiErrorWarningFill } from '@remixicon/react';
import { formEditBusiness } from 'drawers';
import { IBusiness } from 'interfaces/IBusiness';
import { useAuthorisationContext } from 'contexts';
import { Button } from 'components/Button';
import styles from './AlertBusinessExpired.module.scss';

type AlertBusinessExpiredProps = {
  withButton?: boolean;
  style?: React.CSSProperties;
};

const AlertBusinessExpired = ({
  withButton = true,
  style,
}: AlertBusinessExpiredProps) => {
  const { t } = useTranslation();
  const { user } = useAuthorisationContext();

  const handleOpenDrawer = useCallback(() => {
    formEditBusiness.openDrawer({
      initialValues: {
        id: user?.rootUnit?.id,
      } as IBusiness,
      defaultTab: 'billing',
    });
  }, [user?.rootUnit?.id]);

  useEffect(() => {
    if (!user?.rootUnit?.isExpired) {
      return;
    }
    handleOpenDrawer();
  }, [handleOpenDrawer, user?.rootUnit?.isExpired, user?.rootUnitId]);

  if (!user?.rootUnit?.isExpired) {
    return null;
  }

  return (
    <div className={styles.container} style={style}>
      <div className={styles.leftContent}>
        <span className={styles.iconWrapper}>
          <RiErrorWarningFill size={18} />
        </span>
        <span className={styles.columnContent}>
          {t('business.subscription.businessIsBlockedReason')}
        </span>
      </div>
      {withButton && (
        <Button
          className={styles.agreeButton}
          onClick={handleOpenDrawer}
        >
          {t('business.subscription.renewSubscription')}
        </Button>
      )}
    </div>
  );
};

export default AlertBusinessExpired;
