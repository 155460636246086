import React from 'react';
import { useTranslation } from 'react-i18next';
import { Permission } from '@fanckler/processing-auth';
import { RiArrowRightSLine, RiMoneyDollarCircleFill } from '@remixicon/react';
import clsx from 'clsx';
import { differenceInDays } from 'date-fns';
import { formEditBusiness } from 'drawers';
import { UnknownType } from 'types/Unknown';
import { useConfirmRootAdmin } from 'hooks/useConfirmRootAdmin';
import { useAuthorisationContext } from 'contexts';
import { isSubscriptionActive } from 'drawers/root-unit/FormEditBusiness/components/ExpirationCard/calculateExpirationDate';
import styles from './SubscriptionStatus.module.scss';

const SubscriptionStatus = () => {
  const { t } = useTranslation();
  const {
    user,
    checkPermissions,
  } = useAuthorisationContext();
  const { isRoot } = useConfirmRootAdmin();

  const handleClick = () => {
    formEditBusiness.openDrawer({
      initialValues: {
        id: user?.rootUnit?.id,
      } as UnknownType,
      defaultTab: 'billing',
    });
  };

  if (isRoot || !checkPermissions([Permission.ADMIN_IAM_SUBSCRIPTION_CONTROL])) {
    return null;
  }

  const expirationDate = user?.rootUnit?.expirationAt ? new Date(user.rootUnit.expirationAt) : null;
  const isSubscriptionNotExpired = isSubscriptionActive(expirationDate);

  const expirationInDays = expirationDate && differenceInDays(expirationDate, new Date());

  if (expirationInDays && expirationInDays > 7) {
    return null;
  }

  return (
    <div
      className={styles.wrapper}
      onClick={handleClick}
    >
      <RiMoneyDollarCircleFill
        size={20}
        className={clsx(styles.icon, {
          [styles.grace]: !isSubscriptionNotExpired && !user?.rootUnit?.isExpired,
          [styles.expired]: user?.rootUnit?.isExpired,
        })}
      />
      {isSubscriptionNotExpired && (`${expirationInDays} ${t('business.subscription.daysLeft')}`)}
      {!isSubscriptionNotExpired && !user?.rootUnit?.isExpired && t('business.subscription.gracePeriod')}
      {user?.rootUnit?.isExpired && t('business.subscription.expired')}
      <RiArrowRightSLine size={16} className={styles.arrow} />
    </div>
  );
};

export default SubscriptionStatus;
