import React, { memo, useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import { isEqual } from 'lodash';
import { useUnitsTree } from 'components/TreeUnitsSelect/hooks';
import { TreeUnitsSelect } from 'components';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import styles from './UnitsSelect.module.scss';

export type UnitsSelectPropsType = {
  buttonPlaceholder: string;
  disabled: boolean;
  value?: number[];
  onChange?: (unitsIds: number[]) => void;
  businessId?: number | null;
};

export const UnitsSelect = memo(({
  buttonPlaceholder,
  disabled,
  value = [],
  onChange,
  businessId,
}: UnitsSelectPropsType) => {
  const [initialSelected] = useState(value);
  const { data, isLoading } = useUnitsTree({
    rootUnitId: businessId,
    nested: true,
  });

  const onSelect = useCallback((ids: number[]) => {
    if (!onChange || isEqual(value || [], ids)) {
      return null;
    }
    onChange(ids);
  }, [onChange, value]);

  return (
    <TreeUnitsSelect
      initialSelectedValues={initialSelected}
      data={data || []}
      isLoading={isLoading}
      onSelect={onSelect}
      withSelectAllButton={false}
      dropdownAlign={[0, 24]}
    >
      {({ isOpen, setIsOpen }) => (
        <div
          onMouseDown={(event) => {
            event.stopPropagation();
            setIsOpen(!isOpen);
          }}
          className={clsx(styles.inputWrapper, styles.pickUnitInput, {
            [styles.disabled]: disabled,
          })}
        >
          {buttonPlaceholder}

          <span className={styles.inputIcon}>
            {isOpen ? <UpOutlined /> : <DownOutlined />}
          </span>
        </div>
      )}
    </TreeUnitsSelect>
  );
});
