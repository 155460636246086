import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { cleverRefetchQueries } from 'libs/reactQuery';
import { Dropdown, MenuProps, notification } from 'antd';
import useUpdateUnitClients from 'api/unit/useUpdateUnitClients';
import { NestedUnitType } from 'interfaces';
import { UnknownType } from 'types/Unknown';
import { StageEnum } from 'hooks/use2FA';
import { OTP_ERROR } from 'constants/index';
import { useAuthorisationContext } from 'contexts';
import { assignClientForm } from '../AssignClientForm';
import { Button, CreateClientButton } from 'components';
import { AssignableUserValues } from '../../../../components';
import { UserAddOutlined } from '@ant-design/icons';
import styles from './AddClientDropdown.module.scss';

export type AddClientDropdownProps = {
  unit: NestedUnitType;
  disabled?: boolean;
};

const AddClientDropdown = ({ unit, disabled }: AddClientDropdownProps) => {
  const { t } = useTranslation();
  const { twoFA: {
    onSuccess,
    onError,
    setStage,
    stage,
  } } = useAuthorisationContext();

  const onRefresh = async () => {
    await cleverRefetchQueries('clients');
  };

  const { mutate, isPending } = useUpdateUnitClients({
    onSuccess: async (res) => {
      onSuccess(res, async () => {
        notification.success({ message: t('users.units.clientsUpdated') });

        await onRefresh();
        assignClientForm.closeDrawer();
      });
    },
    onError: (error: UnknownType) => {
      onError(error, () => {
        if (stage !== StageEnum.DEFAULT && error.response?.data?.messagemessage.includes(OTP_ERROR)) {
          setStage(StageEnum.DEFAULT);
        }

        notification.error({
          description: error?.response?.data?.message,
          message: error?.response?.status,
        });
      });
    },
  });

  const handleSubmit = useCallback((data: AssignableUserValues, otpCode?: string) => {
    mutate({ id: unit.id, body: { ...data, otpCode } });
  }, [mutate, unit]);

  const handleOpen = useCallback(() => {
    assignClientForm.openDrawer({
      onSubmit: handleSubmit,
      loading: isPending,
      initialValues: { unitId: unit?.id, rootUnitId: unit?.rootUnitId },
    });
  }, [handleSubmit, isPending, unit?.id, unit?.rootUnitId]);

  useEffect(() => {
    if (assignClientForm.isOpened()) {
      assignClientForm.updateDrawer({
        loading: isPending,
      });
    }
  }, [isPending]);

  const items: MenuProps['items'] = useMemo(() => ([
    {
      key: 'createNew',
      label: (
        <CreateClientButton
          unitId={unit?.id}
          disabled={disabled}
          rootUnitId={unit?.rootUnitId as number}
          className={styles.createClientButton}
          onRefresh={onRefresh}
        />
      ),
    },
    {
      key: 'selectExisting',
      label: t('client.selectExisting'),
      disabled,
      onClick: handleOpen,
    },
  ]), [unit, disabled, t, handleOpen]);

  return (
    <Dropdown
      menu={{ items }}
      placement="bottomRight"
      trigger={['click']}
      disabled={disabled}
      className={styles.dropdown}
      overlayClassName={styles.overlay}
    >
      <Button
        type="default"
        textSize="14px"
        disabled={disabled}
        suffixIcon={<UserAddOutlined style={{ fontSize: 16 }} />}
        className={styles.button}
      >
        {t('users.units.assignClients')}
      </Button>
    </Dropdown>
  );
};

export default AddClientDropdown;
