import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { notification } from 'antd';
import { useSelector } from 'init/rootReducer';
import { NestedUnitType } from 'interfaces';
import { OptionsKeys } from './types';
import { useToggleOption } from './hooks';
import { useCreateWallet } from '../../../../../../../components/WalletOverview/hooks';
import { useModal } from 'components/Modal/hooks/useModal';
import { AllocationTable } from '../AllocationTable';
import { Modal, TotalBalance } from 'components';
import { ChooseMethodConnection } from 'components/ColdStorage/components';
import WalletOverview from 'components/WalletOverview';
import styles from '../../NestedUnit.module.scss';

type WalletsAndBalanceProps = {
  unit: NestedUnitType | undefined;
  isLoading: boolean;
  onRefresh: () => Promise<void>;
};

const currencyOptions = [
  { value: 'USDT', label: 'USDT' },
  { value: 'USDC', label: 'USDC' },
  // { value: 'BUSD', label: 'BUSD' },
  { value: 'BNB', label: 'BNB' },
  { value: 'DAI', label: 'DAI' },
  { value: 'TRX', label: 'TRX' },
  { value: 'BTC', label: 'BTC' },
  { value: 'ETH', label: 'ETH' },
  { value: 'USD', label: 'USD' },
  { value: 'EUR', label: 'EUR' },
  { value: 'UAH', label: 'UAH' },
  { value: 'GBP', label: 'GBP' },
  { value: 'ILS', label: 'ILS' },
  { value: 'AED', label: 'AED' },
];

const WalletsAndBalance = ({ unit, isLoading, onRefresh }: WalletsAndBalanceProps) => {
  const isColdStorageMode = useSelector((state) => state.coldStorageReducer.isColdStorageMode);
  const [currency, setCurrency] = useState(currencyOptions[0]?.value);
  const { t } = useTranslation();

  const {
    isOpen: isOpenModalConnectColdStorage,
    onModalClose: onModalCloseConnectColdStorage,
    onModalOpen: onModalOpenConnectColdStorage,
  } = useModal('coldStorage');

  const {
    wallets,
    togglerOptions,
    balance,
    title,
    checkedOption,
    toggleOption,
    isLoading: isLoadingBalance,
    reducedBalances,
  } = useToggleOption({ unit, currency });

  const { createWallet, isLoading: isCreating } = useCreateWallet(unit?.id, {
    onSuccess: async () => {
      onRefresh();

      notification.success({
        message: t('coldStorage.successfullyAdded'),
        key: 'wallet-created',
      });
    },
    onError: () => {
      notification.error({
        message: t('coldStorage.cannotAdded'),
        key: 'wallet-created',
      });
    },
  });

  return (
    <>
      <div style={{ display: 'flex', gap: '20px' }}>
        <TotalBalance
          onRefresh={onRefresh}
          reducedBalances={reducedBalances}
          options={togglerOptions}
          unit={unit}
          currency={currency}
          setCurrency={setCurrency}
          balance={balance}
          title={title}
          onToggle={toggleOption}
          checkedOption={checkedOption}
          isLoading={isLoading || isLoadingBalance}
        />

        {wallets && (
          <WalletOverview
            wallets={wallets}
            isLoading={isLoading}
            isCreating={isCreating}
            onCreateWallet={createWallet}
            onAddColdWallet={onModalOpenConnectColdStorage}
          />
        )}
      </div>

      {isColdStorageMode && checkedOption === OptionsKeys.External && (
        <AllocationTable />
      )}

      <Modal
        destroyOnClose
        width={450}
        isOpen={isOpenModalConnectColdStorage}
        onClose={onModalCloseConnectColdStorage}
        className={styles.modal}
      >
        <ChooseMethodConnection onModalClose={onModalCloseConnectColdStorage} />
      </Modal>
    </>
  );
};

export default WalletsAndBalance;
