import React, { ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { registerDrawer, SharedDrawerProps } from '@nuvalt/ui-kit';
import { Divider, Form, Image, Input, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import useGetPaymentOrderServiceFee from 'api/commission/useGetPaymentOrderServiceFee';
import { IWallet } from 'types/IWallet';
import { UnknownType } from 'types/Unknown';
import { ITransactionCategory, ITransactionGroup } from 'pages/Administration/FinancialManagement/api/types';
import useGetTransactionGroups from 'pages/Administration/FinancialManagement/api/group/useGetTransactionGroups';
import { useWalletValidator } from 'pages/Processing/PaymentOrders/components/FormCreatePaymentOrder/hooks/useWalletValidator';
import { useCurrencyRate } from 'hooks';
import useGetWhitelist from '../../hooks/useGetWhitelist';
import { CurrencyIcon, formatCurrencyAmount } from 'utils';
import filterWhitelistWallets from './utils/filterWhitelistWallets';
import { Button, Drawer, DrawerContent, DrawerFooter, DrawerHeader, With2FA } from 'components';
import { AmountInput, CommissionView, WalletSelect } from './components';
import WalletInputWithWhitelist from './components/WalletInputWithWhitelist';
import styles from './FormWithdraw.module.scss';

export type WithdrawFormDataReturned = {
  walletAddress: string;
  currency: string;
  network: string;
  amount: string;
  feeValue: string;
  totalFee: string;
  gasPrice?: string;
  group: number;
  category: number;
  description?: string;
  annotation?: string;
  otpCode?: string;
};

export type WithdrawFormData = {
  walletAddress: string;
  amount: string;
  network: string;
  currency: string;
  description?: string; // for Replenishment request
  annotation?: string; // for Create PO
  group: number;
  category: number;

  isWalletValid?: boolean;
  isAmountValid?: boolean;
};

export type FormWidthdrawPropsType = {
  unitId: string;
  rootUnitId: string;
  onSubmit: (formData: UnknownType) => void;
  isLoading: boolean;
  isWithdraw: boolean;
  wallets: IWallet[];
  initialValues?: WithdrawFormData;
  title: string;
} & SharedDrawerProps;

const INIT_NETWORK_OPTIONS = [
  { label: 'USDT (trc20)', value: 'USDT|TRC20' },
  { label: 'USDT (erc20)', value: 'USDT|ERC20' },
  { label: 'USDT (bep20)', value: 'USDT|BEP20' },
  { label: 'USDC (erc20)', value: 'USDC|ERC20' },
  { label: 'USDC (bep20)', value: 'USDC|BEP20' },
  { label: 'ETH (erc20)', value: 'ETH|ERC20' },
  { label: 'ETH (bep20)', value: 'ETH|BEP20' },
  { label: 'BTC (bep20)', value: 'BTC|BEP20' },
  { label: 'DAI (bep20)', value: 'DAI|BEP20' },
  // { label: 'BUSD (bep20)', value: 'BUSD|BEP20' },
  { label: 'BNB (bep20)', value: 'BNB|BEP20' },
  { label: 'TRX (Tron)', value: 'TRX|TRC20' },
  { label: 'BTC (Bitcoin)', value: 'BTC|Bitcoin' },
];

const FormWithdraw = ({
  unitId,
  wallets,
  onSubmit,
  isLoading,
  isWithdraw,
  initialValues,
  rootUnitId,
  closeDrawer,
  title,
}: FormWidthdrawPropsType): ReactElement => {
  const [amount, setAmount] = useState('');
  const [payway, setPayway] = useState<string | null>(null);
  const [currency, network] = payway?.split('|') || [];
  const [walletAddress, setWalletAddress] = useState<string>('');

  const [selectedGroup, setSelectedGroup] = useState<number | null>(null);
  const [selectedCategory, setSelectedCategory] = useState<number | null>(null);

  const [isWalletValid, setWalletValid] = useState(!!initialValues?.isWalletValid);
  const [isAmountValid, setAmoutValid] = useState(!!initialValues?.isAmountValid);

  const savedData = useRef<WithdrawFormData>();

  const { data: whitelistData } = useGetWhitelist({ uuid: unitId });

  const { walletBalance, isLoading: rateLoading } = useCurrencyRate();

  const filteredWhitelistWallets = useMemo(() =>
    filterWhitelistWallets({
      wallets: whitelistData?.nodes || [],
      currency,
      network,
    }),
  [currency, network, whitelistData]);

  const { data } = useGetTransactionGroups({ unitId });
  const { t } = useTranslation();
  const [form] = useForm();

  const addressFrom = useMemo(() => wallets.find(
    (w) => w.network === network && w.currency === currency,
  )?.walletAddress || '',
  [currency, network, wallets]);

  const groups = data?.map((group: ITransactionGroup) => ({
    value: group.id,
    label: group.name,
  }));

  const categories = data
    ?.find((group: ITransactionGroup) => group?.id === selectedGroup)
    ?.categories?.map((category: ITransactionCategory) => ({ value: category.id, label: category.name }));

  const { walletValidator } = useWalletValidator(currency, network);

  // const enabledCommission = useMemo(() =>
  //   (isAmountValid && !!amount) &&
  //     (isWalletValid && !!walletAddress) &&
  //     Number(amount) !== 0,
  // [isAmountValid, amount, isWalletValid, walletAddress]);

  const enabledCommission = useMemo(() => {
    const hasValidAmount = isAmountValid && Number(amount) !== 0;
    const hasValidWallet = isWalletValid && !!walletAddress;

    return hasValidAmount && hasValidWallet;
  }, [isAmountValid, amount, isWalletValid, walletAddress]);

  // const handleCommissionSuccess = useCallback((response: UnknownType) => {
  //   if (response.error) {
  //     form.setFields([{ name: 'amount', errors: [response.error] }]);
  //     setAmoutValid(false);
  //   }
  // }, [form]);

  const commissionQuery = useMemo(() => ({
    destinationAddress: walletAddress,
    addressFrom,
    rootUnitId,
    currency,
    network,
    amount,
  }), [rootUnitId, walletAddress, addressFrom, currency, network, amount]);

  const { data: paymentOrderTariff, refreshWithParams } = useGetPaymentOrderServiceFee(commissionQuery, {
    enabled: enabledCommission,
    onError: error => {
      form.setFields([{ name: 'amount', errors: [error.message] }]);
      setAmoutValid(false);
    },
  });

  // const currentBalance = wallets.find(
  //   (wallet) => wallet.network === network && wallet.currency === currency,
  // )?.balance || 0;

  const currentBalance = useMemo(() => {
    const matchingWallet = wallets.find(wallet =>
      wallet.network === network && wallet.currency === currency,
    );

    return matchingWallet ? matchingWallet.balance : 0;
  }, [currency, network, wallets]);

  const isAmountReceivedExceedsBalance = useMemo(() => {
    const serviceFee = paymentOrderTariff?.serviceFee || 0;
    const totalAmountRequired = Number(amount) + Number(serviceFee);
    return totalAmountRequired > Number(currentBalance);
  }, [amount, currentBalance, paymentOrderTariff?.serviceFee]);

  // const isAmountReceivedMoreThanBalanceWallet = (+amount + (paymentOrderTariff?.serviceFee || 0)) > +currentBalance;

  useEffect(() => {
    if (initialValues) {
      const tether = ['USDT', 'USDC'].includes(initialValues.currency);

      const pw = tether
        ? `${initialValues.currency}|${initialValues.network}`
        : initialValues.network === 'TRX'
          ? 'TRX|TRC20'
          : initialValues.network === 'ETH'
            ? `ETH|${initialValues.network}`
            : initialValues.currency === 'BNB'
              ? 'BNB|BEP20'
              : `${initialValues.currency}|${initialValues.network}`;

      form.setFieldsValue(initialValues);
      setAmount(initialValues.amount);
      setPayway(pw);
      setWalletAddress(initialValues.walletAddress);
      setSelectedGroup(initialValues.group);
      setSelectedCategory(initialValues.category);
    }
  }, [form, initialValues]);

  const onSelectGroup = async (value: number) => {
    setSelectedGroup(value);

    form.setFieldsValue({ group: value });
  };

  const onSelectCategory = async (value: number) => {
    setSelectedCategory(value);

    form.setFieldsValue({ category: value });
  };

  const onFinish = (formData: WithdrawFormData, otpCode?: string) => {
    if (!otpCode) {
      savedData.current = formData;
    }
    const currentWallet = formData.walletAddress.split(',');
    const walletCurrency = currentWallet[2]?.split(' ')[0];

    onSubmit({
      amount: formData.amount,
      currency: walletCurrency || currency,
      network: network || currentWallet[2]?.split(' ')[1],
      walletAddress: currentWallet[0],
      description: formData.description, // for Replenishment request
      annotation: formData.annotation, // for Create PO
      group: selectedGroup,
      category: selectedCategory,

      feeValue: paymentOrderTariff?.feeValue?.toString(),
      gasPrice: paymentOrderTariff?.gasPrice?.toString(),
      totalFee: paymentOrderTariff?.totalFee?.toString(),

      isWalletValid,
      isAmountValid,
      otpCode,
    });
  };

  const resetFields = () => {
    form.resetFields(['amount', 'walletAddress']);
    setAmount('');
    setWalletAddress('');
  };

  const onSelect = (e: string) => {
    resetFields();
    form.validateFields(['walletAddress']);
    form.setFieldsValue({ network: e });
    setPayway(e);
  };

  const onWalletBlur = () => {
    form.validateFields(['walletAddress']);
  };

  const walletAddressValidator = (_: unknown, value: string) => {
    const selfWallet = wallets.find((wallet) => wallet.walletAddress === value);

    if (selfWallet) {
      return Promise.reject(t('selfAddressValidate'));
    } else {
      try {
        return walletValidator(_, value, setWalletValid);
      } catch (error) {
        return Promise.resolve();
      }
    }
  };

  const onAmountChange = async (newAmount: string) => {
    setAmount(newAmount);
    form.setFieldsValue({ amount: newAmount });
  };

  const networkOptions = useMemo(() => INIT_NETWORK_OPTIONS.filter((item) =>
    wallets.find((wallet) => {
      const [cr, pw] = item.value.split('|');
      return wallet.network === pw && wallet.currency === cr;
    })),
  [wallets]);

  const getCommission = async (balance: string) => {
    const { serviceFee, error } = await refreshWithParams({
      destinationAddress: form.getFieldValue('walletAddress'),
      addressFrom,
      rootUnitId,
      network,
      currency,
      amount: balance,
    });

    if (error) {
      form.setFields([{ name: 'amount', errors: [error] }]);
      setAmoutValid(false);
    }

    return { serviceFee, error };
  };

  const handleSubmitCode = (code: string) => {
    if (!savedData.current) return;
    onFinish(savedData.current, code);
  };

  return (
    <Drawer>
      <DrawerHeader title={title} onClose={closeDrawer} />
      <With2FA onSubmit={handleSubmitCode} isDrawer>
        <DrawerContent>
          <Form
            form={form}
            name="createPayment"
            onFinish={onFinish}
            className={styles.form}
          >
            <div>
              {isWithdraw && (
                <Form.Item
                  name="network"
                  label={t('processing.payway')}
                  rules={[{ required: true, message: t('processing.pleaseSelectPayway') }]}
                  className={styles.formItem}
                >
                  <Select
                    value={payway}
                    onSelect={onSelect}
                    placeholder={t('processing.payway')}
                    className={styles.select}
                    popupClassName={styles.networkSelectPopup}
                  >
                    {networkOptions.map((item) => {
                      const [cr, pw]: string[] = item.value.split('|');
                      const w = wallets.find(
                        (wallet) => wallet.network === pw && wallet.currency === cr,
                      );

                      return (
                        <Select.Option key={item.label} value={item.value}>
                          <div className={styles.option}>
                            <span className={styles.optionLabel}>{item.label}</span>
                            <span className={styles.optionCurrency}>
                              {formatCurrencyAmount(w?.balance || 0, cr, true)}

                              <Image
                                src={CurrencyIcon[cr]}
                                width="18px"
                                preview={false}
                                rootClassName={styles.currencyIcon}
                              />
                            </span>
                          </div>
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              )}

              <Form.Item
                required
                name="walletAddress"
                label={t('processing.destinationAddress')}
                className={styles.formItem}
                {...(isLoading && { hasFeedback: true, validateStatus: 'validating' })}
                rules={[
                  ...(isWithdraw
                    ? [{ validator: walletAddressValidator, validateTrigger: ['blur', 'change'] }]
                    : [{ required: true, message: t('processing.destinationAddressisRequired') }]),
                ]}
              >
                {isWithdraw ? (
                  <WalletInputWithWhitelist
                    disabled={isLoading}
                    value={walletAddress}
                    onBlur={onWalletBlur}
                    onChange={value => {
                      setWalletValid(false);
                      setWalletAddress(value);
                      form.setFieldValue('walletAddress', value);
                    }}
                    placeholder={t('processing.destinationAddress')}
                    whitelistedWallets={filteredWhitelistWallets}
                    className={styles.input}
                  />
                ) : (
                  <WalletSelect
                    form={form}
                    wallets={wallets}
                    className={styles.select}
                  />
                )}
              </Form.Item>

              <AmountInput
                isDisabled={isWithdraw ? (!payway || !isWalletValid) : false}
                onChangeAmount={onAmountChange}
                amount={amount}
                form={form}
                withSetMaxButton={isWithdraw}
                setAmountValid={setAmoutValid}
                isWithdraw={isWithdraw}
                currency={currency}
                network={network}
                wallets={wallets}
                walletBalance={walletBalance}
                getCommission={getCommission}
                payway={payway}
              />

              {!isWithdraw && (
                <Form.Item
                  name="description"
                  label={t('administration.financialManagement.fields.description')}
                  className={styles.formItem}
                >
                  <Input
                    placeholder={t('administration.financialManagement.fields.enterDescription')}
                    className={styles.input}
                  />
                </Form.Item>
              )}

              {isWithdraw && (
                <div className={styles.alignItems}>
                  <Form.Item
                    name="group"
                    label={t('transaction.transactionGroup')}
                    className={styles.formItem}
                    rules={[{ required: true, message: t('transaction.pleaseSelectGroup') }]}
                  >
                    <Select
                      value={selectedGroup}
                      options={groups}
                      placeholder={t('transaction.transactionGroup')}
                      onChange={onSelectGroup}
                      className={styles.select}
                    />
                  </Form.Item>

                  <Form.Item
                    name="category"
                    label={t('transaction.transactionCategory')}
                    className={styles.formItem}
                    rules={[{ required: true, message: t('transaction.pleaseSelectCategory') }]}
                  >
                    <Select
                      options={categories}
                      value={selectedCategory}
                      onChange={onSelectCategory}
                      disabled={!selectedGroup}
                      placeholder={t('transaction.transactionCategory')}
                      className={styles.select}
                    />
                  </Form.Item>
                </div>
              )}

              {isWithdraw && (
                <Form.Item
                  name="annotation"
                  label={t('administration.financialManagement.fields.description')}
                  rules={[{ max: 100 }]}
                  className={styles.formItem}
                >
                  <Input
                    placeholder={t('administration.financialManagement.fields.enterDescription')}
                    className={styles.input}
                  />
                </Form.Item>
              )}

              {isWithdraw && (
                <>
                  <Divider className={styles.divider} />
                  <CommissionView
                    amount={Number(amount)}
                    currency={currency}
                    commission={paymentOrderTariff}
                    convert={walletBalance}
                    convertLoading={rateLoading}
                  />
                </>
              )}
            </div>
          </Form>
        </DrawerContent>
        <DrawerFooter>
          <Button
            block
            htmlType="submit"
            loading={isLoading}
            disabled={isWithdraw
              ? (!isAmountValid && !isWalletValid) || isLoading || isAmountReceivedExceedsBalance
              : isLoading
            }
            onClick={() => form.submit()}
          >
            {t('create')}
          </Button>

          <Button
            block
            type="link"
            color="error"
            onClick={closeDrawer}
          >
            {t('cancel')}
          </Button>
        </DrawerFooter>
      </With2FA>
    </Drawer>
  );
};

export default registerDrawer(FormWithdraw, {
  id: 'form-withdraw',
});
