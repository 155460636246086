import Decimal from 'decimal.js';
import { TFunction } from 'i18next';
import { WalletCurrencyEnum } from 'types/WalletCurrencyEnum';
import { truncateNumber } from 'utils';

const minValueByCurrencyMap = new Map([
  [WalletCurrencyEnum.USDT, 200],
  [WalletCurrencyEnum.USDC, 200],
  [WalletCurrencyEnum.TRX, 2000],
  [WalletCurrencyEnum.ETH, 0.1],
  [WalletCurrencyEnum.BTC, 0.005],
]);

const getMinValue = (currency: WalletCurrencyEnum) => minValueByCurrencyMap.get(currency) || 1;

type InputValidatorProps = {
  t: TFunction;
  value: string;
  currency: WalletCurrencyEnum;
};

const inputValidator = ({ t, currency, value }: InputValidatorProps) => {
  const minValue = getMinValue(currency);
  return !value || isNaN(Number(value)) || parseFloat(value) >= minValue
    ? Promise.resolve()
    : Promise.reject(new Error(t('processing.amountLessThan', {
      currency: currency,
      amount: `${minValue}`,
    })));
};

type BalanceValidatorProps = {
  t: TFunction;
  value: string;
  currency: WalletCurrencyEnum;
  balance: number;
};

export const balanceValidator = ({ t, value, currency, balance }: BalanceValidatorProps) => {
  const minValue = getMinValue(currency);
  const truncatedBalance = new Decimal(truncateNumber({ number: Number(balance), currency }));
  const inputValue = new Decimal(Number(value));
  const missing = inputValue.minus(truncatedBalance).toNumber();

  return !value || isNaN(Number(value)) || parseFloat(value) <= balance || Number(value) < minValue
    ? Promise.resolve()
    : Promise.reject(new Error(t('processing.notEnoughFunds', {
      value: `${truncateNumber({ number: missing, currency })} ${currency}`,
    })));
};

export default inputValidator;
