import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RiArrowDownSLine, RiArrowRightUpLine, RiArrowUpSLine } from '@remixicon/react';
import { Image, notification, Select } from 'antd';
import useWithdrawSubaccount from 'api/client/useWithdrawSubaccount';
import { IWallet } from 'interfaces';
import { WalletCurrencyEnum } from 'types/WalletCurrencyEnum';
import { useCurrencyRate } from 'hooks';
import { useAuthorisationContext } from 'contexts';
import { CurrencyIcon, unifyWalletsStructure } from 'utils';
import { Button, CountUp, transferFundsForm } from 'components';
import styles from './ClientBalance.module.scss';

type ClientBalanceProps = {
  clientUUID: string;
  wallets: IWallet[];
};

const currencies = [
  WalletCurrencyEnum.BTC,
  WalletCurrencyEnum.ETH,
  WalletCurrencyEnum.TRX,
  WalletCurrencyEnum.BNB,
  WalletCurrencyEnum.USDT,
  WalletCurrencyEnum.USDC,
];

const ClientBalance = ({ wallets, clientUUID }: ClientBalanceProps) => {
  const { twoFA: { onSuccess, onError } } = useAuthorisationContext();
  const { walletBalance } = useCurrencyRate();
  const { t } = useTranslation();

  const [currency, setCurrency] = useState<WalletCurrencyEnum>(WalletCurrencyEnum.USDT);
  const [open, setOpen] = useState<boolean>(false);

  const getWalletBalance = useCallback((curr: WalletCurrencyEnum) => Number(
    walletBalance(unifyWalletsStructure(wallets), curr),
  ), [walletBalance, wallets]);

  const { mutate: withdraw, isPending: isWithdrawPending } = useWithdrawSubaccount({
    onSuccess: (res) => {
      onSuccess(res, () => {
        transferFundsForm.closeDrawer();
        // refetch();
      });
    },
    onError: (error) => {
      onError(error, () => {
        notification.error({
          message: error.message,
          description: error.response?.data.message || t('somethingWrong'),
        });
      });
    },
  });

  const onOpenWithdrawDrawer = useCallback(() => {
    transferFundsForm.openDrawer({
      loading: isWithdrawPending,
      onSubmit: withdraw,
      wallets: wallets,
      uuid: clientUUID,
    });
  }, [clientUUID, isWithdrawPending, wallets, withdraw]);

  const ArrowIcon = useMemo(() => open ? RiArrowUpSLine : RiArrowDownSLine, [open]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.headerInfo}>
        <span className={styles.title}>{t('users.client.balanceTitle')}</span>

        <div className={styles.controls}>
          <Select
            open={open}
            onDropdownVisibleChange={setOpen}
            value={currency}
            suffixIcon={<ArrowIcon size={16} color="var(--black-45-percent-color)" />}
            onChange={setCurrency}
            className={styles.select}
            popupClassName={styles.dropdown}
          >
            {currencies.map(item => (
              <Select.Option key={item}>
                <Image src={CurrencyIcon[item]} width={16} preview={false} />
                <span style={{ width: 38, fontWeight: 500 }}>{item}</span>
              </Select.Option>
            ))}
          </Select>

          <Button
            onClick={onOpenWithdrawDrawer}
            suffixIcon={<RiArrowRightUpLine size={16} />}
            className={styles.withdrawButton}
          >
            {t('withdraw')}
          </Button>
        </div>
      </div>
      <div className={styles.balanceInfo}>
        <div className={styles.mainBalance}>
          <CountUp
            start={0}
            isRoundDown
            suffix={` ${currency}`}
            currency={currency}
            end={getWalletBalance(currency)}
          />
          <Image src={CurrencyIcon[currency]} width={24} preview={false} />
        </div>

        <CountUp
          start={0}
          isRoundDown
          prefix="≈ "
          suffix=" USDT"
          currency={WalletCurrencyEnum.USDT}
          end={getWalletBalance(WalletCurrencyEnum.USDT)}
          className={styles.reducedBalance}
        />
      </div>
    </div>
  );
};

export default ClientBalance;
