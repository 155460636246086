import React from 'react';
import { useTranslation } from 'react-i18next';
import { registerDrawer, SharedDrawerProps } from '@nuvalt/ui-kit';
import { Form } from 'antd';
import { IBusiness } from 'interfaces/IBusiness';
import { Button, Drawer, DrawerContent, DrawerFooter, DrawerHeader } from 'components';
import { SettingsTab } from './components';

type FormCreateBusinessProps = {
  isLoading?: boolean;
  initialValues?: IBusiness;
  onSubmit: (formData: FormData) => void;
} & SharedDrawerProps;

const FormCreateBusiness = ({
  onSubmit,
  isLoading,
  initialValues = {} as IBusiness,
  closeDrawer,
}: FormCreateBusinessProps) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const handleSubmit = () => {
    form.submit();
  };

  return (
    <Drawer>
      <DrawerHeader title={t('createBusiness')} onClose={closeDrawer} />
      <DrawerContent>
        <SettingsTab
          form={form}
          onSubmit={onSubmit}
          business={initialValues}
        />
      </DrawerContent>
      <DrawerFooter>
        <Button
          block
          htmlType="submit"
          loading={isLoading}
          disabled={isLoading}
          onClick={handleSubmit}
        >
          {t('createBusiness')}
        </Button>

        <Button
          block
          type="link"
          color="error"
          onClick={closeDrawer}
        >
          {t('cancel')}
        </Button>
      </DrawerFooter>
    </Drawer>
  );
};

export default registerDrawer(FormCreateBusiness);
