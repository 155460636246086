import { useTranslation } from 'react-i18next';
import axios, { SERVICE } from 'libs/axios';
import { useMutation } from 'libs/reactQuery';
import { notification } from 'antd';
import { CreateInvoiceDto, MutationOptions } from './types';

const useCreateInvoice = (mutationOptions?: MutationOptions) => {
  const { t } = useTranslation();

  const mutationFn = async (body: CreateInvoiceDto) => {
    const { data } = await axios.post(`${SERVICE.CLIENT}/invoices/create`, body);
    return data;
  };

  return useMutation({
    ...mutationOptions,
    mutationFn,
    onSuccess(data, variables, context) {
      notification.success({ message: t('invoice.notify.createSuccess') });

      if (mutationOptions?.onSuccess) {
        mutationOptions.onSuccess(data, variables, context);
      }
    },
    onError(error, variables, context) {
      notification.error({
        message: error.message,
        description: error.response?.data.message || t('somethingWrong'),
      });

      if (mutationOptions?.onError) {
        mutationOptions.onError(error, variables, context);
      }
    },
  });
};

export default useCreateInvoice;
