import axios, { SERVICE } from 'libs/axios';
import { useQuery } from 'libs/reactQuery';
import { UnknownType } from 'types/Unknown';
import { PaymentOrderServiceFee, QueryOptions, QueryParams } from './types';

const useGetPaymentOrderServiceFee = (queryParams: QueryParams, queryOptions?: Partial<QueryOptions>) => {
  const getPaymentOrderServiceFee = async (context: UnknownType) => {
    const url = `${SERVICE.CLIENT}/balance/withdraw/service-fee`;

    try {
      const { data } = await axios.get(url, {
        params: context.queryKey[1] || queryParams,
      });
      return data;
    } catch (error: UnknownType) {
      return {
        serviceFee: 0,
        error: error?.response?.data?.message,
      };
    }
  };

  const query = useQuery<PaymentOrderServiceFee>({
    ...queryOptions,
    queryKey: ['payment-order-service-fee', queryParams],
    queryFn: getPaymentOrderServiceFee,
  });

  const refreshWithParams = (newQueryParams: QueryParams) => {
    return getPaymentOrderServiceFee({
      queryKey: ['commission', newQueryParams],
    });
  };

  return { ...query, refreshWithParams };
};

export default useGetPaymentOrderServiceFee;
