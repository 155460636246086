import { TFunction } from 'i18next';
import { MAX_INTEGER } from '../../../../constants';
import {
  ComparisonType,
  FilterField,
  FilterFieldType,
} from 'components/Filters';

const getFilterFields = (
  { translate }: { translate: TFunction },
): Map<string, FilterField> => new Map<string, FilterField>([
  ['id', {
    searchable: true,
    searchableComparisonType: ComparisonType.EQUAL,
    label: translate('id'),
    type: FilterFieldType.NUMBER,
    field: 'id',
    alwaysVisible: true,
    maxLength: 10,
    minValue: 0,
    maxValue: MAX_INTEGER,
    comparisonType: ComparisonType.EQUAL,
    regex: /^\d+$/i,
  }],
  ['name', {
    searchable: true,
    label: translate('name'),
    type: FilterFieldType.TEXT,
    field: 'name',
    maxLength: 100,
    trimOnBlur: true,
    alwaysVisible: true,
    comparisonType: ComparisonType.I_LIKE,
    regex: /^[a-zA-Zа-яА-ЯҐЄІЇ-ґєіїёЁ ]+$/i,
  }],
]);

export default getFilterFields;
