import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Divider } from 'antd';
import clsx from 'clsx';
import { truncate } from 'lodash';
import { IBusiness } from 'interfaces/IBusiness';
import { IChildrenUnit, IUnit } from 'interfaces/IUnit';
import { TreeOrgStructureDatum } from '../../utils/getTreeStructure';
import { CountUp, OrganizationalLogo } from 'components';
import styles from './TreeOrgStructureItem.module.scss';

export type TreeOrgStructureItemProps<ParentType extends IBusiness | IUnit | IChildrenUnit> = {
  parentNode: ParentType;
  nodeDatum: TreeOrgStructureDatum;
  onNodeClick: (event: React.MouseEvent) => void;
  foreignObjectProps: {
    width: number;
    height: number;
    x: string;
    y: string;
  };
};

const TreeOrgStructureItem = <ParentType extends IBusiness | IUnit | IChildrenUnit>({
  parentNode,
  nodeDatum,
  onNodeClick,
  foreignObjectProps,
}: TreeOrgStructureItemProps<ParentType>) => {
  const { name, balance, currency, isActive } = nodeDatum;

  const { pathname } = useLocation();
  const { t } = useTranslation();

  const isBusinessLevel = pathname === '/administration/businesses';
  const baseLink = `${parentNode.id}/units`;
  const businessLevelLink = nodeDatum.uuid ? `${baseLink}/${nodeDatum.uuid}` : baseLink;
  const path = isBusinessLevel ? businessLevelLink : nodeDatum.uuid || '';

  return (
    <g>
      <foreignObject {...foreignObjectProps}>
        <Link to={path} className={styles.link}>
          <div
            onClick={onNodeClick}
            className={clsx(styles.treeFlowItem, { [styles.deactivated]: !isActive })}
          >
            <OrganizationalLogo
              name={name}
              logoPath={'logo' in nodeDatum ? nodeDatum.logo as string : null}
            />

            {/* <div className={styles.avatar} style={{ backgroundColor: `#${colorWithAlpha}`, color: `#${color}` }}>
              {name.slice(0, 1).toUpperCase()}
            </div> */}

            <Divider type="vertical" className={styles.divider} />

            <div className={styles.information}>
              <div className={styles.name}>{truncate(nodeDatum.name, { length: 20 })}</div>
              {balance !== undefined && !!currency ? (
                <div className={styles.balance}>
                  <CountUp
                    withIcon
                    isRoundDown
                    end={balance}
                    currency={currency}
                  />
                </div>
              ) : (
                <span className={styles.placeholder}>{t('thisBusiness')}</span>
              )}
            </div>
          </div>
        </Link>
      </foreignObject>
    </g>
  );
};

export default TreeOrgStructureItem;
