import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RiCheckLine, RiCloseLine } from '@remixicon/react';
import { Select } from 'antd';
import useAssignLabel from 'api/label/useAssignLabel';
import useCreateLabel from 'api/label/useCreateLabel';
import useGetLabels, { Label } from 'api/label/useGetLabels';
import clsx from 'clsx';
import { debounce } from 'lodash';
import { UnknownType } from 'types/Unknown';
import styles from './ClientLabels.module.scss';

type ClientLabelsProps = {
  labels: Label[];
  clientUUID: string;
  onRefresh: () => void;
};

const ClientLabels = ({ labels, clientUUID, onRefresh }: ClientLabelsProps) => {
  const [searchValue, setSearchValue] = useState('');
  const [inputValue, setInputValue] = useState('');
  const { t } = useTranslation();

  const { mutate: createLabel } = useCreateLabel(clientUUID, { onSuccess: onRefresh });
  const { mutate: assignLabel } = useAssignLabel(clientUUID, { onSuccess: onRefresh });

  const { data, isLoading } = useGetLabels({
    filter: { name: { iLike: `%${searchValue}%` } },
    // paging: { limit: 10, offset: 0 },
  });

  const options = useMemo(() => data.nodes.map(node => ({
    value: String(node.id),
    label: node.name,
  })), [data]);

  const debouncedSearch = useMemo(() => debounce(setSearchValue, 300), []);
  const handleInputChange = useCallback((value: string) => {
    debouncedSearch(value);
    setInputValue(value);
  }, [debouncedSearch]);

  const handleBlur = useCallback(() => {
    setSearchValue('');
    setInputValue('');
  }, []);

  const handleInputKeyDown = useCallback((event: any) => {
    if (event.key === 'Enter' && inputValue) {
      const existingTag = options.find((option) => option.label === inputValue);

      if (existingTag) {
        assignLabel({ labelId: Number(existingTag.value), addLabel: true });
      } else {
        createLabel({ name: inputValue });
      }

      setInputValue('');
      event.preventDefault();
    }
  }, [assignLabel, createLabel, inputValue, options]);

  const handleSelect = useCallback((labelId: string) => () => {
    const existingTag = labels.find((option) => option.id === Number(labelId));

    if (existingTag) {
      assignLabel({ labelId: Number(labelId), addLabel: false });
    } else {
      assignLabel({ labelId: Number(labelId), addLabel: true });
    }
  }, [assignLabel, labels]);

  const handleRemoveLabel = useCallback((removedLabel: string) => () => {
    const label = labels.find(option => option.name === removedLabel);

    if (label?.id) {
      assignLabel({ labelId: label.id, addLabel: false });
    }
  }, [assignLabel, labels]);

  const dropdownRender = useCallback(() => (
    <div className={clsx(styles.list, 'no-custom-scroll')}>
      {options.map(option => {
        const isSelected = labels.map(label => String(label.id)).includes(option.value);
        return (
          <div
            key={option.value}
            className={clsx(styles.item, { [styles.selected]: isSelected })}
            onClick={handleSelect(option.value)}
          >
            {option.label}
            {isSelected && <RiCheckLine size={16} color="var(--primary-color)" />}
          </div>
        );
      })}
    </div>
  ), [handleSelect, labels, options]);

  const tagRender = useCallback((props: UnknownType) => (
    <div className={styles.label}>
      <span>{props.label}</span>
      <RiCloseLine
        size={16}
        className={styles.close}
        onClick={handleRemoveLabel(props.value)}
      />
    </div>
  ), [handleRemoveLabel]);

  return (
    <div className={styles.wrapper}>
      <span className={styles.title}>{t('users.clients.labels')}</span>

      <Select
        mode="tags"
        loading={isLoading}
        style={{ width: '100%' }}
        placeholder={t('users.clients.labels')}
        value={labels.map(({ name }) => name)}
        suffixIcon={<RiCheckLine size={16} color="var(--primary-color)" />}
        searchValue={inputValue}
        onSearch={handleInputChange}
        onInputKeyDown={handleInputKeyDown}
        onBlur={handleBlur}
        dropdownRender={dropdownRender}
        tagRender={tagRender}
        popupClassName={styles.dropdown}
        className={styles.select}
      />
    </div>
  );
};

export default ClientLabels;
