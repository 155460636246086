import React from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { CopyToClipboard } from 'components';
import { CopyOutlined } from '@ant-design/icons';
import styles from './ApiItem.module.scss';

export type ApiItemProps = {
  apiKey: string;
  expirationAt?: string | null;
};

const ApiItem = ({ apiKey, expirationAt }: ApiItemProps) => {
  const { t } = useTranslation();

  const formattedDate = expirationAt && format(new Date(expirationAt), 'dd MMM yyyy, HH:mm');

  return (
    <div className={styles.wrapper}>
      <div className={styles.flex}>
        <div className={styles.label}>API {t('key')}</div>
        <CopyToClipboard text={apiKey}>
          <CopyOutlined style={{ fontSize: 16 }} />
        </CopyToClipboard>
      </div>

      <div className={styles.value}>{apiKey}</div>
      {formattedDate && (
        <div className={styles.date}>{t('business.expireOn')} {formattedDate}</div>
      )}
    </div>
  );
};

export default ApiItem;
