import axios, { AxiosError, SERVICE } from 'libs/axios';
import { UseMutateFunction, useMutation } from 'libs/reactQuery';
import { IGroup } from 'interfaces/IGroup';
import { IGroupVariables } from '../types';

type OptionType = {
  onSuccess?: (data: IGroup) => Promise<void>;
  onError?: (e: AxiosError<{ message: string; }>) => void;
};

type GroupMutationsReturned = {
  mutationCreateGroup: UseMutateFunction<IGroup, AxiosError<{
    message: string
  }>, IGroupVariables, unknown>,
  mutationUpdateGroup: UseMutateFunction<IGroup, AxiosError<{
    message: string
  }>, IGroupVariables & { id: number }, unknown>,
  isCreateGroupFetching: boolean,
  isUpdateGroupFetching: boolean,
};

const useGroupMutations = (options?: OptionType): GroupMutationsReturned => {
  const createGroup = async (body: IGroupVariables) => {
    const { data } = await axios.post(SERVICE.ADMIN + '/groups/create', body);
    return data;
  };

  const updateGroup = async (body: IGroupVariables & { id: number }) => {
    const { data } = await axios.put(SERVICE.ADMIN + `/groups/update/${body.id}`, body);
    return data;
  };

  const { mutate: mutationCreateGroup, isPending: isCreateGroupFetching } = useMutation({ mutationFn: createGroup, ...options });
  const { mutate: mutationUpdateGroup, isPending: isUpdateGroupFetching } = useMutation({ mutationFn: updateGroup, ...options });

  return {
    mutationCreateGroup,
    mutationUpdateGroup,
    isCreateGroupFetching,
    isUpdateGroupFetching,
  };
};

export default useGroupMutations;
