import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { RiFilter3Line } from '@remixicon/react';
import clsx from 'clsx';
import { SelectItem, SelectWindow } from '../SelectWindow';
import { Button } from 'components/Button';
import { FilterField } from 'components/Filters';
import styles from 'components/Filters/components/ConfigureFiltersDrawer/ConfigureFiltersDrawer.module.scss';

type SelectFilterFieldsProps = {
  fields: Map<string, FilterField>;
  setVisibleFields: React.Dispatch<React.SetStateAction<Record<string, boolean>>>
  visibleFields: Record<string, boolean>;
  activatedFiltersCount: number;
};

const SelectFilterFields = ({
  fields,
  setVisibleFields,
  visibleFields,
  activatedFiltersCount,
}: SelectFilterFieldsProps) => {
  const { t } = useTranslation();

  const changeFilterFieldVisibility = useCallback((item: SelectItem, status: boolean) => {
    const { key } = item;

    setVisibleFields((prev) => ({ ...prev, [key]: status }));
  }, [setVisibleFields]);

  return (
    <SelectWindow
      items={[...fields].map(([key, { label, alwaysVisible }]) => ({
        key,
        label,
        disabled: alwaysVisible,
        selected: visibleFields[key],
      }))}
      onItemClick={changeFilterFieldVisibility}
    >
      <Button
        type="default"
        className={clsx(styles.button, styles.selectButton)}
        suffixIcon={<RiFilter3Line size={16} />}
      >
        <span>{t('selectFields')}{activatedFiltersCount > 0 ? ` (${activatedFiltersCount})` : ''}</span>
      </Button>
    </SelectWindow>
  );
};

export default SelectFilterFields;
