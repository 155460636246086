import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IBusiness } from 'interfaces/IBusiness';
import Table from 'components/Table';
import getColumns from 'drawers/root-unit/FormEditBusiness/components/TransactionsTable/getColumns';

type TransactionsTableProps = {
  business: IBusiness;
};

const TransactionsTable = ({
  business,
}: TransactionsTableProps) => {
  const { t } = useTranslation();

  const columns = useMemo(() => getColumns({ t }), [t]);

  return (
    <Table
      rowKey="id"
      columns={columns}
      pagination={false}
      dataSource={business.billingRecords || []}
      scroll={{ x: 'max-content', y: 300 }}
    />
  );
};

export default TransactionsTable;
