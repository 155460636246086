import { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';

const SERVICE_NAME = 'ADMIN-FRONT';

const initSentry = (): void => {
  try {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      beforeSend: (event) => {
        const exceptionValues = event.exception?.values?.map((value) => ({
          ...value,
          type: `[${SERVICE_NAME}]: ${value.type || 'Unknown error'}`,
        }));

        return {
          ...event,
          exception: {
            ...event.exception,
            values: exceptionValues,
          },
        };
      },
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
        Sentry.replayIntegration(),
      ],
      tracesSampleRate: 1.0,
    });
  } catch {
    console.log('[SENTRY]: Sentry init error');
    // Sentry init error
  }
};

export default initSentry;
