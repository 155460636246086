import React from 'react';
import { Image } from 'antd';
import clsx from 'clsx';
import { useLogo } from './hooks';
import { getLogoByIndex } from './utils';
import styles from './AppLogo.module.scss';

export type AppLogoProps = {
  type: 'sidebar' | 'login',
  rootClassName?: string;
  className?: string;
};

const AppLogo = ({ type, className, rootClassName }: AppLogoProps) => {
  const defaultLogo = getLogoByIndex(Number(process.env.REACT_APP_CLIENT), type === 'sidebar');

  const logo = useLogo({ defaultLogo });

  return (
    <div className={clsx(styles.wrapper, rootClassName, {
      [styles.sidebar]: type === 'sidebar',
      [styles.login]: type === 'login',
    })}>
      <Image
        src={logo}
        preview={false}
        className={clsx(styles.logo, className)}
      />
    </div>
  );
};

export default AppLogo;
