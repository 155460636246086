import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { RiAddLine } from '@remixicon/react';
import { cleverRefetchQueries } from 'libs/reactQuery';
import useAddClientWallet from 'api/client/useAddClientWallet';
import { addSubaccountForm } from 'pages/Administration/Units/components/NestedUnit/tabs/SubaccountsTab/components';
import { FormData } from 'pages/Administration/Units/components/NestedUnit/tabs/SubaccountsTab/components/AddSubaccountForm';
import { Button } from 'components';
import styles from './AddSubaccountButton.module.scss';

export type AddSubaccountButtonProps = {
  clientUUID: string;
};

const AddSubaccountButton = ({ clientUUID }: AddSubaccountButtonProps) => {
  const { t } = useTranslation();

  const { mutate, isPending } = useAddClientWallet({
    onSuccess: async () => {
      await cleverRefetchQueries('client-subaccounts');
      addSubaccountForm.closeDrawer();
    },
  });

  const handleSubmit = useCallback((data: FormData) => {
    mutate({ uuid: clientUUID, body: { ...data, isSubWallet: true } });
  }, [clientUUID, mutate]);

  const handleOpen = useCallback(() => {
    addSubaccountForm.openDrawer({
      onSubmit: handleSubmit,
      loading: isPending,
    });
  }, [handleSubmit, isPending]);

  useEffect(() => {
    if (addSubaccountForm.isOpened()) {
      addSubaccountForm.updateDrawer({ loading: isPending });
    }
  }, [isPending]);

  return (
    <Button
      onClick={handleOpen}
      suffixIcon={<RiAddLine size={16} />}
      className={styles.button}
    >
      {t('subaccount.form.title')}
    </Button>
  );
};

export default AddSubaccountButton;
