import { CustomMenuItem } from '../types';

const findActiveMenuItem = (items: CustomMenuItem[], pathname: string): string | undefined => {
  for (const item of items) {
    if (item.path && (pathname.includes(item.path) || item.path.includes(pathname))) {
      return item.path;
    }

    if (item.children) {
      const found = findActiveMenuItem(item.children, pathname);
      if (found) {
        return found;
      }
    }
  }
  return undefined;
};

export default findActiveMenuItem;
