import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Permission } from '@fanckler/processing-auth';
import { registerDrawer, SharedDrawerProps } from '@nuvalt/ui-kit';
import { Form, Input } from 'antd';
import { UnknownType } from 'types/Unknown';
import { ITransactionCategory, ITransactionGroup } from '../../api/types';
import { useConfirmRootAdmin } from 'hooks/useConfirmRootAdmin';
import { useAuthorisationContext } from 'contexts';
import { ModalType } from '../../FinancialManagement';
import { BusinessSelect } from '../BusinessSelect';
import { ColorPicker } from '../ColorPicker';
import { UnitsSelect } from '../UnitsSelect';
import { Button, Drawer, DrawerContent, DrawerFooter, DrawerHeader } from 'components';
import { DeleteOutlined } from '@ant-design/icons';
import styles from './FormCreateUpdate.module.scss';

export type InitialValues =
  | ({ modalType: ModalType, rootUnitId: never })
  | (ITransactionGroup & { modalType: ModalType })
  | (Omit<ITransactionCategory, 'units'> & {
    rootUnitId: ITransactionGroup['rootUnitId'];
    modalType: ModalType;
    groupId: number;
    units: number[];
  });

export type FormDataResult = {
  id: number;
  name?: string;
  color?: string;
  description?: string;
  rootUnitId?: number;
  unitIds?: number[];
  groupId?: number;
};

export type FormCreateUpdatePropsType = {
  isEdit: boolean;
  businessId?: number | null;
  initialValues: Partial<InitialValues>;
  loading: boolean;
  onSubmit: (formData: FormDataResult) => void;
  onDeleteGroup?: (group: ITransactionGroup) => void;
  onDeleteCategory?: (category: ITransactionCategory) => void;
  title: string;
} & SharedDrawerProps;

const FormCreateUpdate = ({
  isEdit,
  initialValues,
  businessId,
  onSubmit,
  loading,
  onDeleteGroup,
  onDeleteCategory,
  closeDrawer,
  title,
}: FormCreateUpdatePropsType) => {
  const [form] = Form.useForm();
  const { checkPermissions } = useAuthorisationContext();
  const [business, setBusiness] = useState(businessId);
  const { isRoot } = useConfirmRootAdmin();
  const { t } = useTranslation();

  const onFinish = (data: UnknownType) => {
    onSubmit({
      ...initialValues,
      ...data,
      rootUnitId: business,
      unitIds: data.units || [],
    });
  };

  const isGroup = initialValues.modalType === ModalType.GROUP;

  const nameFieldLabel =
    t(`administration.financialManagement.fields.${isGroup ? 'group' : 'category'}Name`);

  const nameFieldMessage =
    t(`administration.financialManagement.validation.${isGroup ? 'group' : 'category'}NameRequired`);

  const nameFieldPlaceholder =
    t(`administration.financialManagement.fields.enter${isGroup ? 'Group' : 'Category'}Name`);

  const nameFieldRules =
    t('administration.financialManagement.validation.nameRules');

  const unitsFieldMessage =
    t('administration.financialManagement.fields.selectUnits');

  return (
    <Drawer>
      <DrawerHeader title={title} onClose={closeDrawer}>
        {isEdit && (
          <Button
            type="link"
            color="error"
            withoutBgShadow
            loading={loading}
            disabled={loading || !checkPermissions([
              (isGroup
                ? Permission.CLIENT_TRANSACTION_GROUP_DELETE
                : Permission.CLIENT_TRANSACTION_CATEGORY_DELETE),
            ])}
            prefixIcon={<DeleteOutlined />}
            onClick={isGroup
              ? () => onDeleteGroup && onDeleteGroup(initialValues as UnknownType)
              : () => onDeleteCategory && onDeleteCategory(initialValues as UnknownType)}
          >
            {t(`administration.financialManagement.delete${isGroup ? 'Group' : 'Category'}`)}
          </Button>
        )}
      </DrawerHeader>
      <DrawerContent>
        <Form
          form={form}
          onFinish={onFinish}
          className={styles.form}
          initialValues={initialValues}
        >
          <Form.Item
            required
            name="name"
            label={nameFieldLabel}
            className={styles.formItem}
            rules={[
              { required: true, message: nameFieldMessage },
              { pattern: /^(?=.*[^ ]).+$/gm, message: nameFieldRules },
            ]}
          >
            <Input className={styles.input} placeholder={nameFieldPlaceholder} />
          </Form.Item>
          {(isRoot && !initialValues?.rootUnitId && isGroup) && (
            <Form.Item
              name="rootUnitId"
              className={styles.formItem}
              label={t('business.title')}
              rules={[{ required: true, message: t('business.pleaseSelect') }]}
            >
              <BusinessSelect
                onChange={setBusiness}
                inputPlaceholder={t('business.select')}
              />
            </Form.Item>
          )}
          {!isGroup && (
            <Form.Item
              name="units"
              className={styles.formItem}
              label={t('administration.financialManagement.fields.units')}
            >
              <UnitsSelect
                disabled={!business}
                businessId={business}
                buttonPlaceholder={unitsFieldMessage}
              />
            </Form.Item>
          )}
          {isGroup && (
            <Form.Item
              name="description"
              label={t('administration.financialManagement.fields.description')}
              className={styles.formItem}
            >
              <Input
                className={styles.input}
                placeholder={t('administration.financialManagement.fields.enterDescription')}
              />
            </Form.Item>
          )}
          <Form.Item
            name="color"
            label={t('administration.financialManagement.fields.color')}
            className={styles.formItem}
          >
            <ColorPicker />
          </Form.Item>
        </Form>
      </DrawerContent>
      <DrawerFooter>
        <Button
          block
          htmlType="submit"
          loading={loading}
          disabled={loading}
          onClick={() => form.submit()}
        >
          {t('save')}
        </Button>

        <Button
          block
          type="link"
          color="error"
          disabled={loading}
          onClick={closeDrawer}
        >
          {t('cancel')}
        </Button>
      </DrawerFooter>
    </Drawer>
  );
};

export default registerDrawer(FormCreateUpdate, {
  id: 'formCreateUpdate',
});
